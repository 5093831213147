import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: '100%',

      '& > div': {
        minWidth: '100%',
      },
    },
  })
);

export default useStyles;
