import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '20px 20px 0px 20px',
      height: 'calc(100vh - 248px)',
      overflowY: 'auto',
    },
    table: {
      height: '100%',
    },
  })
);

export default useStyles;
