const USED_ROUTES = [
  {
    menu: false,
    name: 'Selección tipo de negocio',
    order: 0,
    pathselected: '',
    path: '/negocio',
    component: 'BusinessTypePage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Detalles de líneas',
    order: 1,
    pathselected: '/usados',
    path: '/usados',
    component: 'DetallesLineasUsadosPage',
    icon: 'list',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Cursadas',
    order: 2,
    pathselected: '/usados/cursadas',
    path: '/usados/cursadas',
    component: 'OperacionesUsadasCursadasPage',
    icon: 'up-to-top',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Pendientes de giro',
    order: 3,
    pathselected: '/usados/pendientes-giro',
    path: '/usados/pendientes-giro',
    component: 'PendienteGiroUsadosPage',
    icon: 'time',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Otorgadas',
    order: 4,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas',
    component: 'OtorgadasUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Aplicar pago',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/pendientes-pago',
    component: 'AplicarPagosUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: undefined,
        name: 'Pendientes de Pago',
      },
    ],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/pendientes-pago/historial',
    component: 'HistorialPagoUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: '/usados/otorgadas/pendientes-pago',
        name: 'Pendientes de Pago',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle de pagos',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/pendientes-pago/historial/:id',
    component: 'DetalleHistorialPagoUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: '/usados/otorgadas/pendientes-pago',
        name: 'Pendientes de Pago',
      },
      {
        path: '/usados/otorgadas/pendientes-pago/historial',
        name: 'Historial',
      },
    ],
  },
  {
    menu: false,
    name: 'Pendientes de confirmación',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/pendientes-confirmacion',
    component: 'PendientesConfirmacionUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle aplicar pago',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/pendientes-pago/:id',
    component: 'DetalleAplicarPagoUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: undefined,
        name: 'Pendientes de Pago',
      },
    ],
  },

  {
    menu: false,
    name: 'Detalle otorgadas',
    order: 0,
    pathselected: '/usados/otorgadas',
    path: '/usados/otorgadas/:id',
    component: 'DetalleOtorgadasUsadosPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/usados/otorgadas',
        name: 'Operaciones otorgadas',
      },
    ],
  },
  {
    menu: true,
    name: 'Carga de operaciones',
    order: 5,
    pathselected: '/usados/carga-operaciones',
    path: '/usados/carga-operaciones',
    component: 'CargaOperacionesUsadosPage',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/usados/carga-operaciones',
    path: '/usados/carga-operaciones/historial',
    component: 'HistorialCargaOperacionesUsadosPage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/usados/carga-operaciones',
        name: 'Carga de operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle',
    order: 0,
    pathselected: '/usados/carga-operaciones',
    path: '/usados/carga-operaciones/:id',
    component: 'DetalleCargaOperacionesUsadosPage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/usados/carga-operaciones',
        name: 'Carga de operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/usados/pendientes-giro',
    path: '/usados/pendientes-giro/historial',
    component: 'HistorialPendienteGiroUsadosPage',
    icon: 'time',
    breadcrumb: [
      {
        path: '/usados/pendientes-giro',
        name: 'Pendientes de giro',
      },
    ],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/exception-page',
    component: 'ExceptionPage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/pagenotfound',
    component: 'PageNotFound',
    icon: '',
    breadcrumb: [],
  },
];

export default USED_ROUTES;
