import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import useStyles from './DetailGrantedFreeZoneOperationPage.style';
import DetailGrantedFreeZoneOperation from '../../../../components/freeZone/granted/detail';
import TitleDetail from '../../../../components/common/titleDetail';

type Params = { id: string };

const DetailGrantedFreeZoneOperationPage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState('');

  const changeTitle = (t: string) => {
    setTitle(t);
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <TitleDetail title={title} />
          </Grid>
        </Grid>
      </Grid>

      <DetailGrantedFreeZoneOperation id={match.params.id} changeTitle={changeTitle} />
    </MainContainer>
  );
};

export default DetailGrantedFreeZoneOperationPage;
