import React from 'react';
import { BUSINESSTYPE, COLORS } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import {
  FreeZoneChargeOperationDetail,
  FreeZoneChargeOperationDetailItem,
} from '../../../../models/freeZone';
import useStyles from './DetailChargeOperationFreeZone.style';
import Error from '../../../common/Error';
import { Grid } from '@material-ui/core';
import { Loading } from '../../../common/loading';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { numberToMiles } from '../../../../utilities/string-functions';
import DownloadDetailChargeOperationsFreeZone from './DownloadDetailChargeOperationsFreeZone';

type Props = {
  id: string;
};

const DetailChargeOperationFreeZone: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const styleCss = {
    paddingLeft: 20,
  };

  const url = `/api/charges/${id}`;
  const { status, data, error } = useFetch<
    FreeZoneChargeOperationDetail<FreeZoneChargeOperationDetailItem>
  >(url, undefined, BUSINESSTYPE.FREEZONE);

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.title}
              >
                <Grid item className={classes.mainTitle}>
                  {getObjDateLocal(data?.UploadAt, true, 'dd-LL-yyyy').date}
                </Grid>
                <Grid item>
                  <DownloadDetailChargeOperationsFreeZone />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.headerContainer}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={1} className={classes.headerTitleContainer}>
                  Hora de Carga
                </Grid>
                <Grid item md={2} className={classes.headerTitleContainer}>
                  VIN
                </Grid>
                <Grid item md={3} className={classes.headerTitleContainer}>
                  Estado
                </Grid>
                <Grid item md={1} className={classes.headerTitleContainer}>
                  Cliente
                </Grid>
                <Grid item md={1} className={classes.headerTitleContainer}>
                  N° Factura
                </Grid>
                <Grid item md={2} className={classes.headerTitleContainer} style={styleCss}>
                  Monto
                </Grid>
                <Grid item md={2} className={classes.headerTitleContainer}>
                  Dealer
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              {data &&
                data.Details &&
                data.Details.map((detail: FreeZoneChargeOperationDetailItem, index: number) => {
                  return (
                    <Grid
                      key={index}
                      id={'grandtedDetail_' + index}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        borderBottom: `1px solid ${COLORS.quintinary}`,
                      }}
                    >
                      <Grid
                        item
                        sm={1}
                        className={classes.detailContainerCell}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getObjDateLocal(data.UploadAt, true, 'dd-LL-yyyy', 'HH:mm:ss').time}
                      </Grid>
                      <Grid item sm={2} className={classes.detailContainerCell}>
                        {detail.Vin}
                      </Grid>
                      <Grid item sm={3} className={classes.detailContainerCell}>
                        {detail.Status ? detail.Status : 'Rechazada - ' + detail.Description}
                      </Grid>
                      <Grid item sm={1} className={classes.detailContainerCell}>
                        {detail.ClientName}
                      </Grid>
                      <Grid item sm={1} className={classes.detailContainerCell}>
                        {detail.InvoiceNumber}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        className={classes.detailContainerCell}
                        style={styleCss}
                      >{`$ ${numberToMiles(detail.Amount)}`}</Grid>
                      <Grid item sm={2} className={classes.detailContainerCell}>
                        {detail.DealerName ? detail.DealerName : '-'}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailChargeOperationFreeZone;
