import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Title from '../../../../components/common/title';
import MainContainer from '../../../../components/maincontainer';
import HistorialCargaOperacionesUsados from '../../../../components/used/charge/HistorialCargaOperacionesUsados';
import useStyles from '../CargaOperacionesUsadosPage/CargaOperacionesUsadosPage.style';

const HistorialCargaOperacionesUsadosPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'historial') props.history.push('/usados/carga-operaciones/historial');
    else props.history.push('/usados/carga-operaciones');
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item sm={12}>
            <Title title="Carga de operaciones" />
          </Grid>
        </Grid>

        <Grid container className={classes.tabNavigation}>
          <Grid item sm={2} className={classes.tab} onClick={handleClickOnChangeTab}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
            Historial
          </Grid>
        </Grid>
      </Grid>

      <HistorialCargaOperacionesUsados />
    </MainContainer>
  );
};

export default HistorialCargaOperacionesUsadosPage;
