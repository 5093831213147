import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';

import { IlastGrantedOperations, GrantedOperations } from '../../../../interfaces';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { numberToMiles } from '../../../../utilities/string-functions';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { setPaymentRequestOperationsHistory } from '../../../../services/file-manager-service';
import { downloadFiles } from '../../../../utilities/download-files';
import NotFound from '../../../common/notfound';
import Notification from '../../../common/notification';
import { COLORS } from '../../../../constants';

/** region style */

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cajaContainer: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 72,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    titleContainer: {
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 10,
      paddingTop: 10,
      borderBottom: '1px solid #979797',
    },
    grantedDate: {
      fontFamily: 'Nunito Sans',
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
    },
    detailsContainer: {
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E0E0E0',
      fontWeight: 550,
    },
    info: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      color: COLORS.sextenary,
      display: 'flex',
    },
    labelDate: {
      fontSize: 16,
      color: COLORS.primary,
    },
    IdSolicitud: {
      color: COLORS.secondary,
      textTransform: 'uppercase',
      fontSize: 16,
    },
    TotalPrice: {
      textAlign: 'right',
      paddingRight: 40,
      fontSize: 16,
      color: COLORS.primary,
    },
    botonDescargar: {
      width: '100%',
      height: 'auto',
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
    },
    botonIcon: {
      marginRight: 10,
    },
  })
);

/** endregion */

interface IProps {
  data: IlastGrantedOperations;
}

export const GiroHistorico = (props: IProps) => {
  const classes = useStyle();
  const { data } = props;

  const [showSuccessDownloadMsg, setShowSuccessDownloadMsg] = useState(false);
  const [indexButton, setIndexButton] = useState<number | null>();
  const [messageNotification, setMessageNotification] = useState('');
  const [variantNotification, setVariantNotification] = useState('');
  const [buttonDisabled, setButtonDisables] = useState(false);

  const handleDownloadClick = async (MoneyOrderRequestId: string, index: number) => {
    setIndexButton(index);
    setButtonDisables(true);
    setPaymentRequestOperationsHistory({
      MoneyOrderRequestId: MoneyOrderRequestId,
      IsHistory: true,
    })
      .then((response) => {
        downloadFiles(response.data, MoneyOrderRequestId, 'xlsx');
        setMessageNotification('Archivo descargado correctamente');
        setVariantNotification('success');
        setShowSuccessDownloadMsg(true);
        setButtonDisables(false);
        setIndexButton(null);
      })
      .catch((error) => {
        setMessageNotification('No se encontró el archivo');
        setVariantNotification('warning');
        setShowSuccessDownloadMsg(true);
        setButtonDisables(false);
        setIndexButton(null);
      });
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.cajaContainer}
      >
        <Grid item className={classes.titleContainer}>
          <Grid item className={classes.grantedDate}>
            {getObjDateLocal(data.GrantedDate).date}
          </Grid>
        </Grid>
        {data.GrantedOperations.length > 0 && (
          <Grid item className={classes.info}>
            <Grid container alignItems="center" direction="row">
              <Grid item sm={2}>
                Hora de Otorgado
              </Grid>
              <Grid item sm={5}>
                ID Solicitud de Giro
              </Grid>
              <Grid item sm={3} className={classes.TotalPrice}>
                Monto a Girar
              </Grid>
            </Grid>
          </Grid>
        )}
        {data.GrantedOperations.length <= 0 && (
          <NotFound message="No contiene información de giros asociados." />
        )}
        {data.GrantedOperations.length > 0 &&
          data.GrantedOperations.map((operacion: GrantedOperations, index: number) => {
            return (
              <Grid item key={index} className={classes.detailsContainer}>
                <Grid container justifyContent="center" alignItems="center" direction="row">
                  <Grid item sm={2} className={classes.labelDate}>
                    {getObjDateLocal(operacion.GrantedTime, true, 'dd-LL-yyyy', 'HH:mm:ss').time}
                  </Grid>
                  <Grid item sm={5} className={classes.IdSolicitud}>
                    {operacion.MoneyOrderRequestId}
                  </Grid>
                  <Grid item sm={3} className={classes.TotalPrice}>
                    ${numberToMiles(operacion.TotalPrice)}
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.botonDescargar}
                      disabled={indexButton === index ? buttonDisabled : false}
                      onClick={() => handleDownloadClick(operacion.MoneyOrderRequestId, index)}
                    >
                      <SaveAltRoundedIcon className={classes.botonIcon} />
                      Descargar Excel
                    </Button>
                  </Grid>
                </Grid>
                <Notification
                  variant={variantNotification}
                  message={messageNotification}
                  show={showSuccessDownloadMsg}
                  onClose={() => setShowSuccessDownloadMsg(false)}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default GiroHistorico;
