import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { rutFormater } from '../../../../utilities/string-functions';
import { UsedDetailLine } from '../../../../models/used';
import useStyles from './DetallesLineasUsados.style';

type Props = {
  usedDetailLine: UsedDetailLine;
};

export const ItemDetalleLineaUsados: React.FC<Props> = ({ usedDetailLine }) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <div className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item xs={5} sm={5} md={6} className={classes.columnDetail}>
            <div className={classes.dealerName}>{usedDetailLine.DealerName}</div>
            <div>
              <span className={classes.dealerRut}>
                {rutFormater(
                  usedDetailLine.DealerRut.toString() + usedDetailLine.VerificationNumber
                )}
              </span>
              <span className={classes.vins}>VIN Otorgados {usedDetailLine.Vins}</span>
              <Tooltip
                title="VINs que se encuentran consumiendo línea de crédito (pendientes de giro hacia delante)."
                arrow
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={7} sm={7} md={6} className={classes.columnDetail}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={3} sm={3}>
                <div className={classes.titleDetail}>Línea de crédito</div>
                <div>
                  <span style={{ marginRight: 5 }}>$</span>
                  {usedDetailLine.CreditLine.toLocaleString()}
                </div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <div className={classes.titleDetail}>Sobregiro aprobado</div>
                <div>
                  <span style={{ marginRight: 5 }}>$</span>
                  {usedDetailLine.CreditOverdraftApproved.toLocaleString()}
                </div>
              </Grid>
              <Grid item xs={3} sm={3} className={classes.rightFirstDetail}>
                <div className={classes.titleDetail}>Línea utilizada</div>
                <div>
                  <span style={{ marginRight: 5 }}>$</span>
                  {usedDetailLine.CreditLineUsed.toLocaleString()}
                </div>
              </Grid>
              <Grid item xs={3} sm={3} className={classes.rightSecondDetail}>
                <div className={classes.titleDetail}>Sobregiro utilizado</div>
                <div>
                  <span style={{ marginRight: 5 }}>$</span>
                  {usedDetailLine.CreditOverdraft.toLocaleString()}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
