import React from 'react';
import { useStyles } from './NotFoundSpareparts.style';

type NotFoundProps = {
  message: string;
};

// TODO: Change references to this component to use the new one (common)
const NotFoundSpareparts: React.FC<NotFoundProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default NotFoundSpareparts;
