import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Checkbox, Grid } from '@material-ui/core';
import { find } from 'lodash';
import { useFetch } from '../../../../hooks/useFetch';
import {
  SparepartsCreateMoneyTransferPendingRequest,
  SparepartsOperationsTakenResponse,
  DetailCreateMoneyTransferPending,
} from '../../../../models/spareParts';
import { SuccessApiResponse } from '../../../../models/SuccessApiResponse';
import { Loading } from '../../../common/loading';
import ErrorSpareparts from '../../commons/ErrorSpareparts';
import Notification from '../../../common/notification';
import NotFoundSpareparts from '../../commons/NotFoundSpareparts';
import FooterOperationsSparepartsTaken from './FooterOperationsSparepartsTaken';
import ItemOperationsSparepartsTaken from './ItemOperationsSparepartsTaken';
import useStyles from './operationsSparepartsTaken.style';
import { post } from '../../../../utilities/requestsSpareparts';
import { TakenOperationStateEnum } from '../../../../enums';
import config from '../../../../config/api';
import { BUSINESSTYPE, COLORS } from '../../../../constants';

function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: number[], b: number[]) {
  return [...a, ...not(b, a)];
}

type NotificationState = {
  show: boolean;
  variant: 'success' | 'error';
  message: string;
};

// TODO: mejorar el check all cuando hay disabled

const OperacionesUsadasCursadas: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [requesting, setRequesting] = React.useState(false);
  const [checked, setChecked] = React.useState<number[]>([]);
  const [positions, setPositions] = React.useState<number[]>([]);
  const [anyDisabled, setAnyDisabled] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [showNotificacion, setShowNotification] = React.useState<NotificationState>({
    show: false,
    variant: 'success',
    message: 'Solicitud de giro creada correctamente',
  });

  const url = `${config.sparePartSettings.apiUri}/api/taken`;
  const {
    status,
    data: takenOperationSpareparts,
    error,
  } = useFetch<SparepartsOperationsTakenResponse[]>(url, undefined, BUSINESSTYPE.PARTS);

  useEffect(() => {
    if (status === 'fetched') {
      setPositions(takenOperationSpareparts?.map((t) => t.Position) ?? []);
    }
  }, [takenOperationSpareparts, status]);

  useEffect(() => {
    let totAmount = 0;
    checked.map((ch) => {
      const amount =
        find(takenOperationSpareparts, {
          Position: ch,
        })?.AmountDraft ?? 0;
      totAmount += amount;
      return true;
    });

    setTotalAmount(totAmount);
  }, [checked, takenOperationSpareparts]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: number[]) => intersection(checked, items).length;

  const handleToggleAll = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleClickCreateRequest = async () => {
    setRequesting(true);
    const sparepartsOperations: DetailCreateMoneyTransferPending[] = [];

    checked.map((ch) => {
      const opsDealer = find(takenOperationSpareparts, {
        Position: ch,
      });
      if (opsDealer) {
        opsDealer.ResponseTakenDetail.forEach((op) => {
          sparepartsOperations.push({
            SpareOperationId: op.SpareOperationId,
            InOverdraft: op.InOverdraft !== TakenOperationStateEnum.CreditLine,
          });
        });
      }
      return true;
    });

    const createRequest: SparepartsCreateMoneyTransferPendingRequest = {
      TotalAmountMoneyTransfer: totalAmount,
      TotalOperationSpareParts: sparepartsOperations.length,
      SpareOperations: sparepartsOperations,
    };

    const response = await post<SuccessApiResponse>('/api/moneyTransfer', createRequest);
    if (response.status === 'success') {
      setShowNotification({
        show: true,
        variant: 'success',
        message: 'Solicitud creada correctamente.',
      });
    } else {
      setShowNotification({
        show: true,
        variant: 'error',
        message: 'No se pudo crear la solicitud de giro.',
      });
    }
    setRequesting(false);
    props.history.push('/repuestos/pendientes-giro');
  };

  return status === 'error' && error ? (
    <ErrorSpareparts
      StatusCode={error.StatusCode}
      Message={error.Message}
      ErrorId={error.ErrorId}
    />
  ) : (
    <div className={classes.body}>
      {status === 'fetching' && <Loading />}
      {requesting && <Loading />}
      {status === 'fetched' && !requesting && (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          {takenOperationSpareparts?.length === 0 ? (
            <NotFoundSpareparts message="No existen operaciones cursadas para este cliente." />
          ) : (
            <>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="flex-start"
                className={classes.header}
              >
                <Grid item xs={1}>
                  <Checkbox
                    style={{ color: COLORS.secondary }}
                    onClick={handleToggleAll(positions)}
                    checked={
                      numberOfChecked(positions) === positions.length && positions.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(positions) !== positions.length &&
                      numberOfChecked(positions) !== 0
                    }
                    disabled={positions.length === 0 || anyDisabled}
                    inputProps={{ 'aria-label': 'all items selected' }}
                  />
                </Grid>
                <Grid item xs={5}>
                  Operación
                </Grid>
                <Grid item xs={3}>
                  Resumen del giro
                </Grid>
                <Grid item xs={3}>
                  Distribución del giro
                </Grid>
              </Grid>
              {takenOperationSpareparts?.map((operationByDealer) => {
                return (
                  <ItemOperationsSparepartsTaken
                    key={operationByDealer.ClientDealerId}
                    operation={operationByDealer}
                    checked={checked}
                    handleToggle={handleToggle}
                    setAnyDisabled={setAnyDisabled}
                  />
                );
              })}
            </>
          )}
        </Grid>
      )}
      <FooterOperationsSparepartsTaken
        amount={totalAmount}
        handleClickCreateRequest={handleClickCreateRequest}
        requesting={requesting}
      />

      {showNotificacion.show && (
        <Notification
          variant={showNotificacion.variant}
          message={showNotificacion.message}
          show={showNotificacion.show}
          onClose={() =>
            setShowNotification((p) => {
              return { ...p, show: false };
            })
          }
        />
      )}
    </div>
  );
};

export default withRouter(OperacionesUsadasCursadas);
