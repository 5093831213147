import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import useStyles from './AlertCommon.style';

type AlertProps = {
  show?: boolean;
  type?: 'success' | 'info' | 'warning' | 'error';
  text?: string;
};

const AlertCommon: React.FC<AlertProps | Partial<AlertProps>> = (props) => {
  const { show = false, type = 'success', text = '' } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(show);

  return (
    <Collapse className={classes.alert} in={open}>
      <Alert
        severity={type}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
};

export default AlertCommon;
