import { createStyles, makeStyles, TableCell, withStyles } from '@material-ui/core';

export const StyledTableCellHeader = withStyles(() => ({
  head: {
    color: '#666666',
    fontSize: '16px',
    padding: '10px',
    fontWeight: 'bold',
  },
}))(TableCell);

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: '25px 20px 0px 20px',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
      marginBottom: '10px',
      borderBottom: 'none',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold',
      color: 'red',
    },
  })
);

export default useStyles;
