import apiConfig from '../config/api';
import { get } from '../utilities/request';
import { CancelRequest } from '../utilities/cancelRequest';

class MasterServices {
  private baseUrl = `${apiConfig.apiUri}/api/master`;

  // Obtiene listado de cuentas de bancos
  async getBankAccounts() {
    const url = `${this.baseUrl}/bankAccounts`;
    return get(url, {});
  }

  // Obtiene listado de bancos
  async getBanks() {
    const url = `${this.baseUrl}/banks`;
    return get(url);
  }

  // Obtiene listado de clientes
  async getClients(cancelRequest?: CancelRequest) {
    const url = `${this.baseUrl}/clients`;
    return get(url, {}, {}, true, cancelRequest);
  }

  // Obtiene tipos de línea de crédito
  async getCreditLineTypes() {
    const url = `${this.baseUrl}/creditlinetypes`;
    return get(url);
  }
}

export const masterServices = new MasterServices();
