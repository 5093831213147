import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      padding: '25px 20px 0px 20px',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
    },
    headerTable: {
      color: '#666666',
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '20px',

      '& > *': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    itemContainer: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '77px',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0,0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '20px',
    },
    errorContainer: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow:
        '0 2px 4px 0 rgba(255,0,0,0.46), 0px 6px 6px 6px rgba(255,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
      color: 'red',
      fontWeight: 'bold',
      fontSize: '16px',
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '77px',
      flexWrap: 'nowrap',
      marginBottom: '20px',
    },
    columnId: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
      paddingLeft: '8px',
    },
    columnAmount: {
      color: '#B2002B',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    columnTotal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    columnDownload: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    btnDownload: {
      color: 'white',
      fontSize: '14px',
      background: '#B2002B',
      lineHeight: '0px',
      minWidth: '132px',
      textTransform: 'none',
    },
    btnOtorgar: {
      color: 'white',
      fontSize: '14px',
      background: '#B2002B',
      minWidth: '132px',
      lineHeight: '0px',
      textTransform: 'none',
    },
    btnError: {
      color: 'white',
      fontSize: '14px',
      background: '#cb0227',
      minWidth: '132px',
      lineHeight: '0px',
      textTransform: 'none',
    },
    formSelect: {
      margin: theme.spacing(1),
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default useStyles;
