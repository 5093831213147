import React, { useState, useCallback, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Button, Container, LinearProgress } from '@material-ui/core';

import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Icons from './icons';

import Loadpayroll from './uploadfile/loadpayroll';
import LoadpayrollEthernet from './uploadfile/loadpayrollethernet';
import LoadICAR from './uploadfile/loadicar';
import LoadNotasCredito from './uploadfile/LoadNotasCredito';

import { convertDateReadableFormat } from '../../utilities/dates-functions';
import { COLORS } from '../../constants';

/** REGION Styles */
const dropzoneStyleObj = {
  display: 'flex',
  //flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 72,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
    },
    header: {
      color: '#666666',
      height: '90px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '90px',

      color: COLORS.secondary,
      fontFamily: 'Nunito Sans',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '27px',
    },
    headerContainerBoton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    headerBoton: {
      width: '257px',
      height: '36px',
      backgroundColor: '#FF8C00',
      fontFamily: 'Nunito Sans ',
      fontSize: '15px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      marginTop: '-12px',
    },
    headerBotonImg: {
      marginRight: '10px',
      width: 24,
      height: 'auto',
      backgroundColor: '#FFFFF',
    },
    body: {
      height: '100%',
    },
    button: {
      width: '257px',
      height: '36px',
      backgroundColor: COLORS.secondary,
      fontFamily: 'Nunito Sans ',
      fontSize: '15px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      marginTop: '-12px',
    },
    imgButton: {
      marginRight: '10px',
      width: 24,
      height: 'auto',
      backgroundColor: '#FFFFF',
    },
    dropzonecontainer: {
      backgroundColor: 'white',
      border: '2px dashed #ADADAD',
      textAlign: 'center',
      maxWidth: '3000px',
      height: '150px',
      ...dropzoneStyleObj,
    },
    info: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: 30,
    },
    infoUpload: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      paddingBottom: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    nominaCSV: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      lineHeight: '22px',
    },
    loading: {
      height: 4,
      width: 549,
      borderRadius: 4,
      margin: '0px auto',
    },
    footer: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#666666',
      //height: '50px',
      paddingLeft: '20px',
      paddingRight: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    footerIcon: {
      fontSize: 25,
      marginRight: 5,
      color: COLORS.secondary,
    },
  })
);

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: COLORS.secondary,
  },
  barColorPrimary: {
    backgroundColor: COLORS.primary,
  },
})(LinearProgress);
/** ENDREGION Styles */

const COMPONENT_RESULT = {
  LOADPAYROLL: 'Loadpayroll',
  LOADPAYROLLETHERNET: 'LoadpayrollEthernet',
  LOADICAR: 'LoadICAR',
  LOADNOTASCREDITO: 'LoadNotasCredito',
};

interface IPros extends RouteComponentProps {
  key: any;
  formato: string;
  showfooter: boolean;
  uploadFile: (params: FormData, key: any) => any;
  loadComponent: string;
  ids?: string;
  dateDownload?: string;
  buttonDisabled?: boolean;
  creditLineType?: string;
}

export const UploadFile = (props: IPros) => {
  const classes = useStyles();

  const {
    formato,
    showfooter,
    uploadFile,
    loadComponent,
    ids,
    dateDownload,
    buttonDisabled,
    creditLineType,
  } = props;

  const [completed, setCompleted] = React.useState(0);

  // let _loading = true,
  //     _showUpload = false,
  //     _showUploadResult = false

  // const [loading, setLoading] = useState(_loading);
  // const [showUpload, setShowUpload] = useState(_showUpload);
  // const [showUploadResult, setShowUploadResult] = useState(_showUploadResult);

  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(true);
  const [showUploadResult, setShowUploadResult] = useState(false);

  const [countRowsError, SetcountRowsError] = useState(0);
  const [countExits, SetcountExits] = useState(0);
  const [countRowsOk, SetcountRowsOk] = useState(0);

  const [fileName, setFileName] = useState('');
  const [uploadResult, setUploadResult] = useState({} as any);

  const [keyId, setKeyId] = useState(ids);

  const [creditLine, setCreditLine] = useState(creditLineType);

  const onDrop = useCallback(async (acceptedFiles) => {
    let data = new FormData();
    data.append('file', acceptedFiles[0]);
    //data.append('companyRut', '96667560-8');
    setFileName(acceptedFiles[0].name);
    setLoading(true);
    setShowUpload(false);
    const result = await uploadFile(data, keyId);

    if (loadComponent === COMPONENT_RESULT.LOADPAYROLL) {
      SetcountRowsError(parseInt(result.data.TotalOperationsRejected));
      SetcountExits(parseInt(result.data.TotalOperations));
      SetcountRowsOk(parseInt(result.data.TotalOperationsSuccessful));
    }

    if (loadComponent === COMPONENT_RESULT.LOADICAR) {
      SetcountRowsError(parseInt(result.data.TotalOperationsRejected));
      SetcountExits(parseInt(result.data.TotalOperations));
      SetcountRowsOk(parseInt(result.data.TotalOperationsSuccessful));
    }

    if (loadComponent === COMPONENT_RESULT.LOADNOTASCREDITO) {
      SetcountRowsError(parseInt(result.data.TotalOperationsRejected));
      SetcountExits(parseInt(result.data.TotalOperations));
      SetcountRowsOk(parseInt(result.data.TotalOperationsSuccessful));
    }

    setUploadResult(result.data);
    setLoading(false);
    setShowUploadResult(true);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: formato,
    onDrop,
  });

  const handleSelectedNewFileClick = () => {
    setFileName('');
    setShowUpload(true);
    SetcountRowsError(0);
    SetcountExits(0);
    SetcountRowsOk(0);
    setUploadResult({});
    setShowUploadResult(false);
  };

  var date = new Date();
  var dia =
    date.getDate() +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    date.getFullYear() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes();

  var datetimeDownloadSolicitud = convertDateReadableFormat(
    dateDownload,
    true,
    'a las',
    'dd-LL-yyyy'
  );

  const files2 = acceptedFiles.map((file: any) => (
    <div key={file.path} className={classes.infoUpload}>
      <Icons name="document" size="20px" />
      {file.name}
    </div>
  ));

  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      {!showUploadResult && (
        <div className={classes.body}>
          {showUpload && (
            <Container hidden className={classes.dropzonecontainer} {...getRootProps()}>
              <div>
                <div className={classes.info}>
                  Seleccione el archivo a cargar
                  <input className={classes.info} {...getInputProps()} />
                </div>

                <Button
                  variant="contained"
                  size="small"
                  className={classes.button}
                  disabled={buttonDisabled}
                >
                  <NoteAddOutlinedIcon className={classes.imgButton}>NoteAdd</NoteAddOutlinedIcon>
                  Seleccionar archivo
                </Button>
              </div>
              {/* {
                                !loading &&
                                <div>
                                    <p className={classes.info} >Carga el archivo acá</p>
                                    <input className={classes.info} {...getInputProps()} />
                                    <br />
                                </div>
                            } */}
            </Container>
          )}
          {!showUpload && (
            <Container className={classes.dropzonecontainer}>
              <div style={{ padding: '20px 0' }}>
                {files2}
                {loading && <ColorLinearProgress className={classes.loading} />}
              </div>
            </Container>
          )}
        </div>
      )}
      {showUploadResult && loadComponent === COMPONENT_RESULT.LOADPAYROLL && (
        <Loadpayroll
          countRowsError={countRowsError}
          countExits={countExits}
          countRowsOk={countRowsOk}
          FileName={fileName}
          FechaCarga={dia}
          onClear={handleSelectedNewFileClick}
        />
      )}
      {showUploadResult && loadComponent === COMPONENT_RESULT.LOADPAYROLLETHERNET && (
        <LoadpayrollEthernet
          response={uploadResult}
          FileName={fileName}
          FechaCarga={dia}
          onClear={handleSelectedNewFileClick}
        />
      )}
      {showUploadResult && loadComponent === COMPONENT_RESULT.LOADICAR && (
        <LoadICAR
          countRowsError={countRowsError}
          countExits={countExits}
          countRowsOk={countRowsOk}
          FileName={fileName}
          FechaCarga={dia}
          onClear={handleSelectedNewFileClick}
        />
      )}
      {showUploadResult && loadComponent === COMPONENT_RESULT.LOADNOTASCREDITO && (
        <LoadNotasCredito
          countRowsError={countRowsError}
          countExits={countExits}
          countRowsOk={countRowsOk}
          FileName={fileName}
          FechaCarga={dia}
          onClear={handleSelectedNewFileClick}
        />
      )}

      <div className={classes.footer} style={{ height: showfooter ? 50 : 30 }}>
        {showfooter && (
          <>
            <CheckCircleIcon className={classes.footerIcon} />
            Descarga de archivo realizada el {datetimeDownloadSolicitud}
          </>
        )}
      </div>
    </div>
  );
};

UploadFile.defaultProps = {
  showfooter: true,
  ids: '',
  dateDownload: undefined,
  buttonDisabled: false,
  creditLineType: '',
};

export default withRouter(UploadFile);
