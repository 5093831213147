import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Icons from '../icons';
import { getDiffBetweenDates, convertDateReadableFormat } from '../../../utilities/dates-functions';
import { chargeCreditNotesServices } from '../../../services/chargeCreditNotesServices';
import { chargeOperationsServices } from '../../../services/chargeOperationsServices';
import { chargeIcarServices } from '../../../services/chargeIcarServices';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../utilities/storageHelper';
import { BusinessTypeEnum } from '../../../enums';

interface IProps {
  isHeader: boolean;
}

const BarInfoUpload = (props: IProps) => {
  const { isHeader } = props;
  const [loading, setLoading] = useState(true);
  const [lastUpload, setlastUpload] = useState<string>();
  const [dateUpload, setDateUpload] = useState<string>();
  const [data, setData] = useState();
  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  const handleDateChange = (date: string) => {
    setDateUpload(convertDateReadableFormat(date, true));
    const between = getDiffBetweenDates(date);
    if (between !== '') {
      setlastUpload(`Hace ${between}`);
    }
  };

  const handleGetLastRecordUploadFiles = () => {
    const witnessUrl = window.location.href;

    if (witnessUrl.includes('icar')) {
      chargeIcarServices
        .getLastRecordUploadFilesIcar(businessTypeStorage as BusinessTypeEnum)
        .then((response) => {
          if (response && response.data) {
            setLoading(false);
            setData(response.data);
            handleDateChange(response.data.CreationDate);
          }
        });
    } else if (witnessUrl.includes('notas-de-credito')) {
      chargeCreditNotesServices
        .getLastRecordUploadFilesNc(businessTypeStorage as BusinessTypeEnum)
        .then((response) => {
          if (response && response.data) {
            setLoading(false);
            setData(response.data);
            handleDateChange(response.data.CreationDate);
          }
        });
    } else {
      chargeOperationsServices
        .getLastRecordUploadFiles(businessTypeStorage as BusinessTypeEnum)
        .then((response) => {
          if (response && response.data) {
            setLoading(false);
            setData(response.data);
            handleDateChange(response.data.CreationDate);
          }
        });
    }
  };

  useEffect(() => {
    if (loading) {
      handleGetLastRecordUploadFiles();
    }
  }, []);

  return loading ? (
    <div style={{ height: 45 }}></div>
  ) : (
    <Grid
      container
      justifyContent={isHeader ? 'flex-end' : 'flex-start'}
      className="bar-info-upload"
    >
      <Grid
        item
        md={12}
        style={
          isHeader
            ? {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }
        }
      >
        {!data && (
          <span
            style={{ fontWeight: 'bold', paddingRight: 5, color: isHeader ? '#FFFFFF' : '#000000' }}
          >
            No existe carga inicial
          </span>
        )}
        {data && (
          <>
            <span
              style={{
                fontWeight: 'bold',
                paddingRight: 5,
                color: isHeader ? '#FFFFFF' : '#000000',
              }}
            >
              Última Carga:
            </span>
            <span style={{ paddingLeft: 20, color: isHeader ? '#FFFFFF' : '#000000' }}>
              {lastUpload}
            </span>
            <span style={{ paddingLeft: 20, color: isHeader ? '#FFFFFF' : '#000000' }}>
              {dateUpload}
            </span>
            <span
              style={{
                paddingLeft: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: isHeader ? '#FFFFFF' : '#000000',
              }}
            >
              <Icons name="document" size="26px" />
              archivo-carga.xls
            </span>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BarInfoUpload;
