import React from 'react';
import { useStyles } from './NotFoundBox.style';

type NotFoundProps = {
  message: string;
};

const NotFoundBox: React.FC<NotFoundProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="notfound-box">
      <div className={classes.message}>
        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default NotFoundBox;
