import React from 'react';
import { BUSINESSTYPE, CHARGETYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { FreeZoneLastChargeOperation } from '../../../../models/freeZone';
import {
  convertDateReadableFormat,
  getDiffBetweenDates,
} from '../../../../utilities/dates-functions';
import useStyles from './LastChargeOperations.style';

type Props = {
  isHeader?: boolean;
  chargeType?: string;
};

const LastChargeOperations: React.FC<Props> = ({
  isHeader = false,
  chargeType = CHARGETYPE.OPERATION,
}) => {
  const classes = useStyles({ isHeader });
  const url =
    chargeType === CHARGETYPE.OPERATION ? '/api/charges/last' : '/api/chargeCreditNotes/last';

  let lastUpload = '';
  let dateUpload = '';

  const handleDateChange = (date: string | undefined) => {
    if (date === undefined) {
      lastUpload = 'No existe carga inicial para operaciones de zona franca';
      dateUpload = '';
    } else {
      dateUpload = convertDateReadableFormat(date.replace('Z', ''), true);
      const between = getDiffBetweenDates(date);
      if (between !== '') {
        lastUpload = `Hace ${between}`;
      }
    }
  };

  const { status, data, error } = useFetch<FreeZoneLastChargeOperation>(
    url,
    undefined,
    BUSINESSTYPE.FREEZONE
  );

  if (status === 'fetched' || status === 'error') {
    if (!data || error) {
      handleDateChange('');
    } else {
      handleDateChange(data.CreationDate);
    }
  }

  return (
    <div className={classes.root}>
      {status === 'fetched' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            {lastUpload}
          </div>
          <div>{dateUpload}</div>
        </>
      )}
      {status === 'error' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            Ocurrió un error obteniendo la última carga.
          </div>
        </>
      )}
    </div>
  );
};

export default LastChargeOperations;
