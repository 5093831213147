import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { IObjCheked } from '../../../../../../interfaces';
import { COLORS } from '../../../../../../constants';

/* #region estilos */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    contenttitle: {
      height: 95,
    },
    title: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      width: 536,
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    contentbody: {
      height: 95,
    },
    formulario: {
      width: '100%',
      padding: '20px 0 0 0',
    },
    select: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    selectItem: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    contentbotones: {
      height: 60,
    },
    botonCancelar: {
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
    botonDescargar: {
      width: 224,
      height: 36,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 15,
      fontWeight: 'bold',
      marginLeft: 30,
    },
    botonIcon: {
      fontSize: 24,
      marginRight: 10,
    },
  })
);
/* #endregion */

export interface IDialogProps {
  open: boolean;
  checkedSelected: IObjCheked[];
  onClose: () => void;
  onUpdate: () => void;
}

function ModalSolicitud(props: IDialogProps) {
  const classes = useStyles();

  const { open, checkedSelected, onClose, onUpdate } = props;

  const [boton, setBoton] = useState(true);
  const [countOperationsAlert, setCountOperationsAlert] = useState(0);
  const [dataCheckedSelected, setDataCheckedSelected] = useState<IObjCheked[]>([]);

  const handleClose = () => {
    onClose();
  };
  const handleUpdate = () => {
    onUpdate();
  };

  const handleCheckedSelected = () => {
    let countOperationsAlert = 0;
    const objDataDealerSelected = [] as IObjCheked[];

    checkedSelected.map((data: IObjCheked) => {
      const objOperations = data.Operations.filter((op) => op.Checked === true);
      const objOperationsAlert = objOperations.filter((op) => op.HasAlert === true);

      if (objOperations.length > 0) {
        objDataDealerSelected.push(data);
        countOperationsAlert = countOperationsAlert + objOperationsAlert.length;
      }
    });

    setDataCheckedSelected(objDataDealerSelected);
    setCountOperationsAlert(countOperationsAlert);
    setBoton(objDataDealerSelected.length === 0);
  };

  return (
    <Dialog
      open={open}
      onEntering={handleCheckedSelected}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.container}
    >
      <DialogTitle id="form-dialog-title" className={classes.contenttitle}>
        {countOperationsAlert > 0 && (
          <div className={classes.title}>Creando Solicitud con indicador</div>
        )}
        {countOperationsAlert === 0 && <div className={classes.title}>Creando Solicitud</div>}
      </DialogTitle>
      <DialogContent className={classes.contentbody}>
        {!boton && (
          <Grid container className={classes.subtitle}>
            {dataCheckedSelected.length > 1 && (
              <Grid item md={12}>
                Se crearán <span style={{ fontWeight: 'bold' }}>{dataCheckedSelected.length}</span>{' '}
                solicitudes
              </Grid>
            )}
            {dataCheckedSelected.length < 2 && (
              <Grid item md={12}>
                Se creará una solicitud para el dealer{' '}
                <span style={{ fontWeight: 'bold' }}>{dataCheckedSelected[0].DealerName}</span>
              </Grid>
            )}
            {!boton && countOperationsAlert > 0 && (
              <Grid item md={12} className={classes.botonCancelar}>
                Conteniendo {countOperationsAlert} VIN con indicador de alerta
              </Grid>
            )}
          </Grid>
        )}
        {boton && (
          <Grid container className={classes.subtitle}>
            <Grid item sm={12}>
              Para crear una solicitud selecciona al menos un VIN
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.contentbotones}>
        <Button color="primary" className={classes.botonCancelar} onClick={handleClose}>
          Volver
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={boton}
          className={classes.botonDescargar}
          onClick={handleUpdate}
        >
          <CheckCircleRoundedIcon className={classes.botonIcon} />
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalSolicitud;
