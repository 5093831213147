import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const titleContainerStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 240px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: 'white',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
    },
    title: {
      borderBottom: '1px solid #666666',
      color: COLORS.secondary,
      fontSize: '16px',
    },

    mainTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    headerContainer: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    headerTitleContainer: {
      height: 60,
      fontWeight: 'bold',
      fontSize: 16,
      ...titleContainerStyleObj,
    },
    detailContainerCell: {
      height: 'auto',
      minHeight: 40,
      ...titleContainerStyleObj,
    },
  })
);

export default useStyles;
