import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
  DialogTitle,
} from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import MdlItemFlota from './modalFacturadoItems/mdlItemFlota';
import MdlItemTanner from './modalFacturadoItems/mdlItemTanner';
import MdlItemTransferencia from './modalFacturadoItems/mdlItemTransferencia';
import UploadImage from './uploadimage';

import { masterServices } from '../../../../services/masterServices';
import { paymentServices } from '../../../../services/paymentServices';

import { COLORS, PAYMENT_METHOD_TYPE } from '../../../../constants';

import {
  IPayment,
  ICheckPaymentDetail,
  IVoucherPaymentDetail,
  ITransferPaymentDetail,
  ITannerCreditPaymentDetail,
  IFleetPaymentDetail,
  IAttachmentPaymentDetail,
} from '../../../../interfaces';
import { numberToMiles } from '../../../../utilities/string-functions';

import { getObjDateLocal } from '../../../../utilities/dates-functions';

/** #region estilos */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    contenttitle: {
      height: 95,
    },
    title: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      width: 536,
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    contentbody: {
      height: '463px !important',
      paddingTop: 15,
      paddingBottom: 15,
    },
    formulario: {
      width: '100%',
      padding: '20px 0 0 0',
    },
    select: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    selectItem: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    contentbotones: {
      height: 60,
      paddingRight: 20,
    },
    botonCancelar: {
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
    botonDescargar: {
      width: 224,
      height: 36,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 15,
      fontWeight: 'bold',
      marginLeft: 30,
    },
    botonIcon: {
      fontSize: 24,
      marginRight: 10,
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dialogPaper: {
      height: '321px !important',
    },
    notVisible: {
      display: 'none',
    },
  })
);
/** #endregion */

export interface IDialogProps {
  open: boolean;
  chkSelected: number;
  onClose: (isPayment: boolean) => void;
  clientDealerIdSelected: string;
  operations: Array<any>;
  totalMount: number;
}

const noSelected = 'none';
const hoy = getObjDateLocal(undefined, undefined, 'yyyy-LL-dd').date;

function ModalSolicitud(props: IDialogProps) {
  const classes = useStyles();

  const { onClose, open, chkSelected, clientDealerIdSelected, operations, totalMount } = props;

  const [paymentMethod, setPaymentMethod] = useState([] as Array<any>);
  const [banks, setBanks] = useState([] as Array<any>);
  const [confirmButton, setDisableConfirmButton] = useState(true);
  const [banco, setBanco] = useState(noSelected);
  const [numberPayment, setNumberPayment] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(noSelected);
  const [creditTannerData, setCreditTannerData] = useState({} as ITannerCreditPaymentDetail);
  const [datePaymentFlota, setDatePaymentFlota] = useState('');
  const [expirationDatePaymentFlota, setExpirationDatePaymentFlota] = useState('');
  const [numberPaymentFlota, setNumberPaymentFlota] = useState('');
  const [dataCuentas, setDataCuentas] = useState([] as Array<any>);
  const [cuenta, setCuenta] = useState(noSelected);
  const [witnessMedioPago, setWitnessMedioPago] = useState(false);
  const [adjuntarFile, setAdjuntarFile] = useState(false);
  const [fileAttach, setFileAttach] = useState({});

  const [dateApplyPayment, setDateApplyPayment] = useState(hoy);

  const handleConfirm = async () => {
    setDisableConfirmButton(true);
    const newIPayment = setIPayment();
    paymentServices.createPayment(newIPayment).then((response) => {
      handleClose(true);
    });
  };

  const handleClose = (isPayment: boolean = false) => {
    setBanco(noSelected);
    setCuenta(noSelected);
    setSelectedPaymentMethod(noSelected);
    setFileAttach({});
    onClose(isPayment);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const _paymentMethod = event.target.value as string;

    setSelectedPaymentMethod(_paymentMethod);
    validateDataIPayment(_paymentMethod);
  };

  const handleChangeSlcBanco = (event: any) => {
    setBanco(event.target.value);
  };

  const handleChangeSlcCuenta = (event: any) => {
    setCuenta(event.target.value);
  };

  const validateDataIPayment = (paymentMethod: string) => {
    let existsPaymentMethod = true;
    let _witnessMedioPago = false;

    switch (paymentMethod) {
      case PAYMENT_METHOD_TYPE.CHEQUE.DB_ID: {
        _witnessMedioPago = true;
        setAdjuntarFile(PAYMENT_METHOD_TYPE.CHEQUE.ADJUNTAR);
        break;
      }
      case PAYMENT_METHOD_TYPE.CREDITO_TANNER.DB_ID: {
        setAdjuntarFile(PAYMENT_METHOD_TYPE.CREDITO_TANNER.ADJUNTAR);
        break;
      }
      case PAYMENT_METHOD_TYPE.FLOTA.DB_ID: {
        setAdjuntarFile(PAYMENT_METHOD_TYPE.FLOTA.ADJUNTAR);
        break;
      }
      case PAYMENT_METHOD_TYPE.TRANSFERENCIA.DB_ID: {
        setAdjuntarFile(PAYMENT_METHOD_TYPE.TRANSFERENCIA.ADJUNTAR);
        break;
      }
      case PAYMENT_METHOD_TYPE.VALE_VISTA.DB_ID: {
        _witnessMedioPago = true;
        setAdjuntarFile(PAYMENT_METHOD_TYPE.VALE_VISTA.ADJUNTAR);
        break;
      }
      default: {
        existsPaymentMethod = false;
        setAdjuntarFile(false);
        break;
      }
    }

    if (existsPaymentMethod) {
      setBanco(noSelected);
      setCuenta(noSelected);
      setFileAttach({});
    }

    setWitnessMedioPago(_witnessMedioPago);
    setDisableConfirmButton(false);
  };

  const setIPayment = () => {
    const IPaymentNew = {
      PaymentMethodId: selectedPaymentMethod,
      ClientDealerId: clientDealerIdSelected,
      Operations: operations,
      CheckPaymentDetail: {},
      TannerCreditPaymentDetail: {},
      FleetPaymentDetail: {},
      TransferPaymentDetail: {},
      VoucherPaymentDetail: {},
      AttachmentPaymentDetail: fileAttach,
      DateApplyPayment: dateApplyPayment,
    } as IPayment;

    switch (IPaymentNew.PaymentMethodId) {
      case PAYMENT_METHOD_TYPE.CHEQUE.DB_ID: {
        IPaymentNew.CheckPaymentDetail = setICheckPaymentDetail();
        break;
      }
      case PAYMENT_METHOD_TYPE.CREDITO_TANNER.DB_ID: {
        IPaymentNew.TannerCreditPaymentDetail = creditTannerData;
        break;
      }
      case PAYMENT_METHOD_TYPE.FLOTA.DB_ID: {
        IPaymentNew.FleetPaymentDetail = setIFleetPaymentDetail();
        break;
      }
      case PAYMENT_METHOD_TYPE.TRANSFERENCIA.DB_ID: {
        IPaymentNew.TransferPaymentDetail = setITransferPaymentDetail();
        break;
      }
      case PAYMENT_METHOD_TYPE.VALE_VISTA.DB_ID: {
        IPaymentNew.VoucherPaymentDetail = setIVoucherPaymentDetail();
        break;
      }
      default: {
        break;
      }
    }
    return IPaymentNew;
  };

  const setIFleetPaymentDetail = () => {
    const IFleetPaymentDetailNew = {
      ApplicationDate: datePaymentFlota,
      ExpirationDate: expirationDatePaymentFlota,
      GuaranteeNumber: numberPaymentFlota,
      GuaranteeBankId: banco,
    } as IFleetPaymentDetail;

    return IFleetPaymentDetailNew;
  };

  const setICheckPaymentDetail = () => {
    const ICheckPaymentDetailNew = {
      BankId: banco,
      DocumentNumber: numberPayment,
    } as ICheckPaymentDetail;

    return ICheckPaymentDetailNew;
  };

  const setIVoucherPaymentDetail = () => {
    const IVoucherPaymentDetailNew = {
      BankId: banco,
      DocumentNumber: numberPayment,
    } as IVoucherPaymentDetail;

    return IVoucherPaymentDetailNew;
  };

  const setITransferPaymentDetail = () => {
    const ITransferPaymentDetailNew = {
      BankAccountId: cuenta,
    } as ITransferPaymentDetail;

    return ITransferPaymentDetailNew;
  };

  const handleSetFileAttach = (attachmentPaymentDetail: IAttachmentPaymentDetail) => {
    setFileAttach(attachmentPaymentDetail);
  };

  const handleGetPaymentMethod = async () => {
    await paymentServices.getPaymentMethod().then((response) => {
      setPaymentMethod(response.data);
    });

    await masterServices.getBanks().then((response) => {
      setBanks(response.data);
    });
  };

  const handleGetBankAccounts = async () => {
    masterServices.getBankAccounts().then((response) => {
      setDataCuentas(response.data);
    });
  };

  useEffect(() => {
    handleGetPaymentMethod();
    handleGetBankAccounts();
  }, []);

  return (
    <Dialog
      id="mdlFacturaPayment"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.contenttitle}>
        <div
          className={classes.title}
        >{`Aplicar medio de pago a ${chkSelected} VIN seleccionados`}</div>
        <Grid item className={classes.subtitle} sm={12}>
          El monto total a cancelar es de{' '}
          <span style={{ fontWeight: 'bold' }}>{`$ ${numberToMiles(totalMount)}`}</span>
        </Grid>
        <Grid item className={classes.subtitle} sm={12}>
          Se creará una solicitud de pago aplicado.
        </Grid>
      </DialogTitle>

      <DialogContent id="mdlDialogContentFactura" className={classes.contentbody}>
        <form autoComplete="off">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid container>
              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="select-multiple">Selecciona el medio de pago.</InputLabel> */}
                <Select
                  value={selectedPaymentMethod}
                  onChange={handleChange}
                  displayEmpty
                  style={{ minWidth: '404px' }}
                  id="slcMedioPago"
                >
                  <MenuItem disabled value={noSelected}>
                    <em>Selecciona el medio de pago</em>
                  </MenuItem>

                  {paymentMethod.map((medioPago: any, index: number) => {
                    return (
                      <MenuItem value={medioPago.PaymentMethodId} key={`medioPagoOpt${index}`}>
                        {medioPago.PaymentMethodName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {selectedPaymentMethod !== noSelected && (
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.formControl}
              >
                <Grid
                  item
                  style={{
                    color: '#666666',
                    fontFamily: 'Nunito Sans',
                    fontSize: 16,
                    height: 35,
                  }}
                >
                  fecha de pago
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={12}>
                      <TextField
                        id="dateApplyPayment"
                        type="date"
                        margin="normal"
                        variant="filled"
                        defaultValue={hoy}
                        onChange={(e) => setDateApplyPayment(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {selectedPaymentMethod !== noSelected && witnessMedioPago && (
              <Grid container>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  className={classes.formControl}
                >
                  <Grid
                    item
                    style={{
                      color: '#666666',
                      fontFamily: 'Nunito Sans',
                      fontSize: 16,
                      height: 35,
                    }}
                  >
                    Banco
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item md={12}>
                        <Select
                          value={banco}
                          onChange={handleChangeSlcBanco}
                          style={{
                            minWidth: '404px',
                          }}
                          displayEmpty
                          id={'slcBancoMedPago'}
                        >
                          <MenuItem disabled value={noSelected}>
                            <em>Seleccione Banco</em>
                          </MenuItem>

                          {banks.map((banco: any, index: number) => {
                            return (
                              <MenuItem value={banco.BankId} key={`medioPagoOptBanco${index}`}>
                                {banco.BankName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedPaymentMethod !== noSelected && witnessMedioPago && (
              <Grid container>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  className={classes.formControl}
                >
                  <Grid
                    item
                    style={{
                      color: '#666666',
                      fontFamily: 'Nunito Sans',
                      fontSize: 16,
                      height: 35,
                    }}
                  >
                    Número de Documento
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item md={12}>
                        <TextField
                          id="numberPayment"
                          type="number"
                          margin="normal"
                          variant="filled"
                          onChange={(e) => setNumberPayment(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedPaymentMethod !== noSelected &&
              selectedPaymentMethod === PAYMENT_METHOD_TYPE.TRANSFERENCIA.DB_ID && (
                <Grid container>
                  <MdlItemTransferencia
                    banks={dataCuentas}
                    handleChangeSlcBanco={handleChangeSlcCuenta}
                    classes={classes}
                    banco={cuenta}
                    noSelected={noSelected}
                  />
                </Grid>
              )}
            {selectedPaymentMethod !== noSelected &&
              selectedPaymentMethod === PAYMENT_METHOD_TYPE.CREDITO_TANNER.DB_ID && (
                <Grid container>
                  <MdlItemTanner classes={classes} setCreditTannerData={setCreditTannerData} />
                </Grid>
              )}
            {selectedPaymentMethod !== noSelected &&
              selectedPaymentMethod === PAYMENT_METHOD_TYPE.FLOTA.DB_ID && (
                <Grid container direction="row">
                  <MdlItemFlota
                    banks={banks}
                    handleChangeSlcBanco={handleChangeSlcBanco}
                    classes={classes}
                    banco={banco}
                    noSelected={noSelected}
                    setDatePaymentFlota={setDatePaymentFlota}
                    setExpirationDatePaymentFlota={setExpirationDatePaymentFlota}
                    setNumberPaymentFlota={setNumberPaymentFlota}
                  />
                </Grid>
              )}
            {adjuntarFile && (
              <UploadImage
                onSetFileAttach={handleSetFileAttach}
                existsFileAttach={Object.entries(fileAttach).length > 0}
              />
            )}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions className={classes.contentbotones}>
        <Button
          id="btnCancelPayment"
          title="Cancelar pago."
          color="primary"
          className={classes.botonCancelar}
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          title="Confirmar pago."
          id="btnConfirmPayment"
          size="small"
          disabled={confirmButton}
          className={classes.botonDescargar}
          onClick={handleConfirm}
        >
          <CheckCircleRoundedIcon className={classes.botonIcon} />
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalSolicitud;
