import React, { useState, useEffect } from 'react';
import { Grid, Link, makeStyles, createStyles, Theme } from '@material-ui/core';
import NotFound from '../../../common/notfound';
import { getGranted } from '../../../../services/line-detail-service';
import { IGrandtedDetail, IParamsTitleDetails } from '../../../../interfaces';
import { rutFormater } from '../../../../utilities/string-functions';
import { IPropsOperaciones, IOtorgadasDetail } from '../../../../interfaces';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

const flexStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const celdaStyleObj = {
  height: 30,
  fontFamily: 'Nunito Sans',
  ...flexStyleObj,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 60,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      fontFamily: 'Nunito Sans',
    },
    dealer: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
    },
    cantidad: {
      color: '#666666',
      fontSize: 14,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    link: {
      color: '#666666',
      fontSize: 16,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    delaerRut: {
      fontWeight: 'bold',
      color: COLORS.secondary,
      fontSize: 16,
    },
    celdaUnoDetalle: {
      ...celdaStyleObj,
    },
    celdaUnoTitulo: {
      color: COLORS.secondary,
      fontSize: 18,
      fontWeight: 'bold',
      ...celdaStyleObj,
    },
    enfasis: {
      marginRight: 5,
      color: COLORS.quintinary,
      fontSize: 16,
    },
  })
);

const Operaciones = (props: IPropsOperaciones) => {
  const classes = useStyles();

  const { onShowDetails } = props;

  const [dataDetails, setDataDetails] = useState([] as Array<IGrandtedDetail>);
  const [existData, setExistData] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleGetDataDetails = async () => {
    getGranted().then((response) => {
      setLoading(false);
      setDataDetails(response.data);
      setExistData(response.data.length > 0);
    });
  };

  const handlerShowDetails = (name: string, clientDealerId: string) => {
    const params = {
      visible: true,
      id: clientDealerId,
      text: name,
    } as IParamsTitleDetails;

    onShowDetails(params);
  };

  useEffect(() => {
    handleGetDataDetails();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {!loading && (
          <div>
            {existData &&
              dataDetails.map((detail: IOtorgadasDetail, numberDetail: number) => {
                const { ClientDealerId, DealerId, DealerName, VINCount, VerificationNumber } =
                  detail;
                const rutformat = rutFormater(DealerId.toString() + VerificationNumber);
                return (
                  <div
                    key={numberDetail}
                    id={`containerDetailOtorg'${numberDetail}`}
                    className={classes.contenedor}
                  >
                    <Grid
                      container
                      id={`otorgadasDetaildealerName${numberDetail}`}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.body}
                    >
                      <Grid item xs={11} md={11} lg={11}>
                        <label
                          id={`otorgadasDetaildealerName${numberDetail}`}
                          className={classes.celdaUnoTitulo}
                        >
                          {DealerName}
                        </label>
                        <div>
                          <span
                            id={`otorgadasDetailRut${numberDetail}`}
                            className={classes.delaerRut}
                          >
                            {rutformat}
                          </span>
                          <span
                            id={`otorgadasVINCount${numberDetail}`}
                            className={classes.enfasis}
                          >{` ${VINCount} VIN otorgados`}</span>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        id={`otorgadasBtn${numberDetail}`}
                        md={1}
                        lg={1}
                        className={classes.link}
                      >
                        <Link
                          href="#"
                          key={'linkDetail' + numberDetail}
                          onClick={() => handlerShowDetails(DealerName, ClientDealerId)}
                          style={{
                            textDecoration: 'none',
                            color: COLORS.secondary,
                            fontWeight: 'bold',
                          }}
                        >
                          Revisar detalle
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            {!existData && <NotFound message="No se encontraron operaciones otorgadas." />}
          </div>
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Operaciones;
