import React from 'react';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './OperacionesUsadasCursadasPage.style';
import OperacionesUsadasCursadas from '../../../../components/used/taken/OperacionesUsadasCursadas';
import DownloadOperacionesUsadasCursadas from '../../../../components/used/taken/OperacionesUsadasCursadas/DownloadOperacionesUsadasCursadas';

const OperacionesUsadasCursadasPage: React.FC = () => {
  const classes = useStyles();

  return (
    <MainContainer>
      <div className={classes.root}>
        <Grid container id="title" className={classes.header}>
          <Grid item sm={12}>
            <Title title="Cursadas">
              <DownloadOperacionesUsadasCursadas />
            </Title>
          </Grid>
        </Grid>
        <OperacionesUsadasCursadas />
      </div>
    </MainContainer>
  );
};

export default OperacionesUsadasCursadasPage;
