import { createStyles, makeStyles, styled, TextField } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: 'white',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '5px 20px',
    },
    detalles: {
      color: '#666666',
      fontSize: 16,
      height: 72,
      marginTop: 40,
    },
    select: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    selectItem: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
  })
);

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.primary,
    },
    '&:hover fieldset': {
      borderColor: COLORS.primary,
      borderWidth: 2,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primary,
    },
  },
});

export default useStyles;
