import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getGrantedDetail } from '../../../../services/line-detail-service';
import { IDealerGrantedDetail } from '../../../../interfaces';

import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

/** region style */
const tituloContainerStyleObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: COLORS.sextenary,
      fontSize: 14,
      paddingBottom: 20,
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: 4,
      width: '100%',
      height: 'auto',
      maxWidth: 4000,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: '0 20px',
      paddingBottom: 20,
    },
    cabeceraContainer: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    cabeceraContainerTitulo: {
      height: 60,
      fontWeight: 600,
      color: COLORS.sextenary,
      ...tituloContainerStyleObj,
    },
    detalleContainer: {
      height: 'auto',
      marginBottom: 20,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    detalleContainerCelda: {
      height: 40,
      ...tituloContainerStyleObj,
    },
  })
);
/** endregion */

interface IProps {
  clientDealerId: string;
}

const Detalles = (props: IProps) => {
  const classes = useStyles();
  const { clientDealerId } = props;
  const [loading, setLoading] = useState(true);
  const [grandtedDetail, setGrandtedDetail] = useState([] as Array<IDealerGrantedDetail>);

  const handleGetDataDetails = async (clientDealerId: string) => {
    getGrantedDetail(clientDealerId).then((response) => {
      setLoading(false);
      setGrandtedDetail(response.data.Value);
    });
  };

  useEffect(() => {
    handleGetDataDetails(clientDealerId);
  }, [clientDealerId]);

  return (
    <>
      <div className={classes.root}>
        {!loading && (
          <div className={classes.contenedor}>
            <Grid container direction="column">
              <Grid item className={classes.cabeceraContainer}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Grid
                    item
                    sm={2}
                    className={classes.cabeceraContainerTitulo}
                    style={{ color: COLORS.secondary }}
                  >
                    Días Transcurridos
                  </Grid>
                  <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                    VIN
                  </Grid>
                  <Grid item sm={3} className={classes.cabeceraContainerTitulo}>
                    Número de Factura
                  </Grid>
                  <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                    Fecha Emisión
                  </Grid>
                  <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                    Estado de Inventario
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {grandtedDetail.map((detail: any, detailNumber: number) => {
                  const { DaysPassed, InventoryStatus, Invoice, InvoiceDate, VIN } = detail;

                  return (
                    <Grid
                      key={detailNumber}
                      id={`grandtedDetail ${detailNumber}`}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        borderBottom: `1px solid ${COLORS.quintinary}`,
                      }}
                    >
                      <Grid
                        item
                        sm={2}
                        id={`DaysPassed ${detailNumber}`}
                        className={classes.detalleContainerCelda}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {DaysPassed}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        id={`VIN ${detailNumber}`}
                        className={classes.detalleContainerCelda}
                      >
                        {VIN}
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        id={`Invoice ${detailNumber}`}
                        className={classes.detalleContainerCelda}
                      >
                        {Invoice}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        id={`InvoiceDate ${detailNumber}`}
                        className={classes.detalleContainerCelda}
                      >
                        {getObjDateLocal(InvoiceDate, false, 'dd/LL/yyyy').date}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        id={`InventoryStatus ${detailNumber}`}
                        className={classes.detalleContainerCelda}
                      >
                        {InventoryStatus}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Detalles;
