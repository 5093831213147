import React, { FunctionComponent, useEffect } from 'react';
import { History } from 'history';
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { matchPath } from 'react-router';
import { createTheme } from '@material-ui/core/styles';

import Login from './components/login';
import { AuthProvider } from './components/common/auth';
import PrivateRoute from './components/private';
import Cursadas from './components/floorplanning/cursadas';
import DetallesLineas from './components/floorplanning/detalleslineas';
import ViewOtorgadas from './components/floorplanning/otorgadas/viewotorgadas';
import ViewPendientes from './components/floorplanning/otorgadas/viewpendientesdefacturacion/viewpendientes';
import ViewSolicitadas from './components/floorplanning/otorgadas/viewpendientesdefacturacion/viewsolicitadas';
import ViewFacturado from './components/floorplanning/otorgadas/viewfacturado';
import ViewPendientesDeConfirmacion from './components/floorplanning/otorgadas/viewpendientesdeconfirmacion';
import PendientesGiro from './components/floorplanning/pendientesgiro';
import ViewHistoricoGiros from './components/floorplanning/pendientesgiro/viewhistoricogiros';
import ViewCargaOperaciones from './components/floorplanning/subirexcel/viewcargaoperaciones/viewcarga';
import ViewHistoricoOperaciones from './components/floorplanning/subirexcel/viewcargaoperaciones/viewhistorico';
import ViewChargeICAR from './components/floorplanning/subirexcel/viewcargaicar';
import ViewCargaNotasCredito from './components/floorplanning/subirexcel/viewcarganotascreditos/viewcarga';
import ViewHistoricoNotasDeCredito from './components/floorplanning/subirexcel/viewcarganotascreditos/viewhistorico';
import ExceptionPage from './components/common/exceptionpage';
import PageNotFound from './components/common/pagenotfound';

import newRoutes from './components/menusettings';
import usedRoutes from './components/usedMenuSettings';
import sparePartsRoutes from './components/sparePartMenuSettings';
import freeZoneRoutes from './components/freeZoneMenuSettings';

import BusinessTypePage from './pages/BusinessTypePage';

import { useLocalStorage } from './hooks/useLocalStorage';
import { StorageKeys } from './utilities/storageHelper';
import { BusinessTypeEnum } from './enums';

import DetallesLineasUsadosPage from './pages/usedPages/creditLine/DetalleLineaUsadosPage';
import CargaOperacionesUsadosPage from './pages/usedPages/charge/CargaOperacionesUsadosPage';
import HistorialCargaOperacionesUsadosPage from './pages/usedPages/charge/HistorialCargaOperacionesUsadosPage';
import DetalleCargaOperacionesUsadosPage from './pages/usedPages/charge/DetalleCargaOperacionesUsadosPage';
import OperacionesUsadasCursadasPage from './pages/usedPages/taken/OperacionesUsadasCursadasPage';
import PendienteGiroUsadosPage from './pages/usedPages/moneyTransferPending/PendienteGiroUsadosPage';
import HistorialPendienteGiroUsadosPage from './pages/usedPages/moneyTransferPending/HistorialPendienteGiroUsadosPage';
import OtorgadasUsadosPage from './pages/usedPages/granted/OtorgadasUsadosPage';
import DetalleOtorgadasUsadosPage from './pages/usedPages/granted/DetalleOtorgadasUsadosPage';
import AplicarPagosUsadosPage from './pages/usedPages/paymentPending/AplicarPagoUsadosPage';
import HistorialPagoUsadosPage from './pages/usedPages/paymentPending/HistorialPagoUsadosPage';
import DetalleAplicarPagoUsadosPage from './pages/usedPages/paymentPending/DetalleAplicarPagoUsadosPage';
import PendientesConfirmacionUsadosPage from './pages/usedPages/confirmPending/PendientesConfirmacionUsadosPage';
import DetalleHistorialPagoUsadosPage from './pages/usedPages/paymentPending/DetalleHistorialPagoUsadosPage';
import CreditLineDetail from './components/spareParts/creditLine/creditLineDetails';

import HistoricChargeOperationsSparePartsPage from './pages/sparePartsPages/charge/historicChargeOperationsSparePartsPage';
import DetailChargeOperationsSparePartsPage from './pages/sparePartsPages/charge/detailChargeOperationsSparePartsPage';
import ChargeOperationsSparePartsPage from './pages/sparePartsPages/charge/chargeOperations';
import OperationsSparepartsTakenPage from './pages/sparePartsPages/taken/operationsSparepartsTakenPage';
import MoneyTransferPendingPage from './pages/sparePartsPages/moneyTransferPending/MoneyTransferPendingPage';
import HistoricMoneyTransferPendingSparePartsPage from './pages/sparePartsPages/moneyTransferPending/historicMoneyTransferPendingSparePartsPage';
import GrantedSparePartsPage from './pages/sparePartsPages/granted/GrantedSparePartsPage/GrantedSparePartsPage';
import DetailGrantedSparePartsPage from './pages/sparePartsPages/granted/DetailGrantedPage/DetailGrantedSparePartsPage';
import DetailApplyPaymentSparePartsPage from './pages/sparePartsPages/paymentPending/detailApplyPaymentSparePartsPage/DetailApplyPaymentSparePartsPage';
import ApplyPaymentSparePartPage from './pages/sparePartsPages/paymentPending/applyPaymentSparePartPage';
import ConfirmPendingSparePartsPage from './pages/sparePartsPages/confirmPending/confirmPendingSparePartsPage';

import ChargeOperationsFreeZonePage from './pages/freeZonePages/charge/chargeOperations';
import HistoricChargeOperationsFreeZonePage from './pages/freeZonePages/charge/historyChargeOperationsFreeZonePage';
import DetailLineFreeZonePage from './pages/freeZonePages/creditLine/detailLineFreeZonePage';
import ChargeCreditNotesPage from './pages/freeZonePages/charge/chargeCreditNotes';
import OperationsFreeZoneTakenPage from './pages/freeZonePages/taken/operationsFreeZoneTakenPage';
import DetailChargeOperationFreeZonePage from './pages/freeZonePages/charge/detailChargeOperationFreeZonePage';
import MoneyTransferPendingFreeZonePage from './pages/freeZonePages/moneyTransferPending';
import ListGrantedFreeZoneOperationsPage from './pages/freeZonePages/granted/listGrantedFreeZoneOperationsPage';
import DetailGrantedFreeZoneOperationPage from './pages/freeZonePages/granted/detailGrantedFreeZoneOperationPage';
import ApplyPaymentFreeZonePage from './pages/freeZonePages/paymentPending/applyPaymentFreeZonePage';
import HistoricMoneyTransferPendingFreeZonePage from './pages/freeZonePages/moneyTransferPending/historicMoneyTransferPendingFreeZonePage';
import DetailApplyPaymentFreeZonePage from './pages/freeZonePages/paymentPending/detailApplyPaymentFreeZonePage/DetailApplyPaymentFreeZonePage';
import ConfirmationPendingFreeZonePage from './pages/freeZonePages/confirmationPending/confirmationPendingFreeZonePage/ConfirmationPendingFreeZonePage';
interface AppProps {
  history: History;
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    credinissanBackground: Palette['background'];
    credinissanHeaderBackground: Palette['background'];
  }

  interface PaletteOptions {
    credinissanBackground: PaletteOptions['background'];
    credinissanHeaderBackground: PaletteOptions['background'];
  }
}

const theme = createTheme({
  palette: {
    credinissanBackground: {
      default: 'rgb(255,255,255)',
    },
    credinissanHeaderBackground: {
      default:
        'linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 350px, rgba(247,247,247,1) 350px,rgba(247,247,247, 1) 1500px)',
    },
  },
});

const App = ({ history }: AppProps) => {
  const routesComponent = {
    Cursadas,
    DetallesDeLineas: DetallesLineas,
    Otorgadas: ViewOtorgadas,
    ViewPendientes,
    ViewSolicitadas,
    Facturado: ViewFacturado,
    PendientesDeConfirmacion: ViewPendientesDeConfirmacion,
    PendientesDeGiro: PendientesGiro,
    HistoricoGiros: ViewHistoricoGiros,

    CargaOperaciones: ViewCargaOperaciones,
    HistoricoOperaciones: ViewHistoricoOperaciones,

    CargaICAR: ViewChargeICAR,

    CargaNotasCredito: ViewCargaNotasCredito,
    HistoricoNotasDeCredito: ViewHistoricoNotasDeCredito,

    BusinessTypePage,

    ExceptionPage,
    PageNotFound,
  };

  const usedRoutesComponent = {
    DetallesLineasUsadosPage,
    OperacionesUsadasCursadasPage,
    PendienteGiroUsadosPage,
    HistorialPendienteGiroUsadosPage,
    OtorgadasUsadosPage,
    DetalleOtorgadasUsadosPage,
    AplicarPagosUsadosPage,
    DetalleAplicarPagoUsadosPage,
    HistorialPagoUsadosPage,
    DetalleHistorialPagoUsadosPage,
    CargaOperacionesUsadosPage,
    HistorialCargaOperacionesUsadosPage,
    DetalleCargaOperacionesUsadosPage,
    PendientesConfirmacionUsadosPage,
    BusinessTypePage,
    ExceptionPage,
    PageNotFound,
  };

  const sparePartsRoutesComponent = {
    CreditLineDetail,
    ChargeOperationsSparePartsPage,
    OperationsSparepartsTakenPage,
    HistoricChargeOperationsSparePartsPage,
    DetailChargeOperationsSparePartsPage,
    MoneyTransferPendingPage,
    GrantedSparePartsPage,
    HistoricMoneyTransferPendingSparePartsPage,
    DetailGrantedSparePartsPage,
    DetailApplyPaymentSparePartsPage,
    ApplyPaymentSparePartPage,
    ConfirmPendingSparePartsPage,
    BusinessTypePage,
    ExceptionPage,
    PageNotFound,
  };

  const freeZoneRoutesComponent = {
    ChargeOperationsFreeZonePage,
    HistoricChargeOperationsFreeZonePage,
    DetailLineFreeZonePage,
    ChargeICAR: ViewChargeICAR,
    ChargeCreditNotesPage,
    HistoryChargeCreditNotes: ViewHistoricoNotasDeCredito,
    OperationsFreeZoneTakenPage,
    DetailChargeOperationFreeZonePage,
    MoneyTransferPendingFreeZonePage,
    ListGrantedFreeZoneOperationsPage,
    DetailGrantedFreeZoneOperationPage,
    ApplyPaymentFreeZonePage,
    HistoricMoneyTransferPendingFreeZonePage,
    BusinessTypePage,
    ExceptionPage,
    PageNotFound,
    DetailApplyPaymentFreeZonePage,
    ConfirmationPendingFreeZonePage,
  };

  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);
  const [hasError] = useLocalStorage('isExceptionError');

  let routes = newRoutes;

  if (businessTypeStorage === BusinessTypeEnum.Usados) {
    routes = usedRoutes;
  } else if (businessTypeStorage === BusinessTypeEnum.Repuestos) {
    routes = sparePartsRoutes;
  } else if (businessTypeStorage === BusinessTypeEnum.ZonaFranca) {
    routes = freeZoneRoutes;
  }

  interface ParamsRoute {
    id: string;
  }

  interface MatchParams {
    isExact: boolean;
    params: ParamsRoute;
  }

  useEffect(() => {
    const run = () => {
      let isUrlValid = false;

      routes.map((route: any) => {
        const match: MatchParams | null = matchPath(window.location.pathname, {
          path: route.path,
          exact: true,
          strict: false,
        });
        if (route.path.toLowerCase() === window.location.pathname.toLowerCase()) {
          isUrlValid = true;
        } else if (match) {
          isUrlValid = match?.isExact === true;
        }
      });

      if (!isUrlValid) {
        if (window.location.pathname.toLowerCase() !== '/login') {
          history.push('/pagenotfound');
          window.location.reload();
        }
      }
    };

    if (hasError) {
      if (window.location.pathname.toLowerCase() !== '/exception-page') {
        history.push('/exception-page');
        window.location.reload();
      }
    }
    run();
  }, []);

  const selectBusinessComponent = (
    businessType: BusinessTypeEnum | unknown,
    route: any
  ): FunctionComponent<any> => {
    switch (businessType) {
      case BusinessTypeEnum.Nuevos:
        return routesComponent[route.component as keyof typeof routesComponent];
      case BusinessTypeEnum.Usados:
        return usedRoutesComponent[route.component as keyof typeof usedRoutesComponent];
      case BusinessTypeEnum.Repuestos:
        return sparePartsRoutesComponent[route.component as keyof typeof sparePartsRoutesComponent];
      case BusinessTypeEnum.ZonaFranca:
        return freeZoneRoutesComponent[route.component as keyof typeof freeZoneRoutesComponent];
      default:
        return routesComponent[route.component as keyof typeof routesComponent];
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            {routes.map((route: any) => (
              <PrivateRoute
                key={route.order}
                exact
                path={route.path}
                component={selectBusinessComponent(businessTypeStorage, route)}
                history={history}
              />
            ))}
            <Redirect
              exact
              path="/"
              to={businessTypeStorage ? `${businessTypeStorage}` : '/negocio'}
            />
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
