const ROUTES = [
  {
    menu: false,
    name: 'Selección tipo de negocio',
    order: 0,
    pathselected: '',
    path: '/negocio',
    component: 'BusinessTypePage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Detalles de líneas',
    order: 1,
    pathselected: '/repuestos',
    path: '/repuestos',
    component: 'CreditLineDetail',
    icon: 'list',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Cursadas',
    order: 2,
    pathselected: '/repuestos/cursadas',
    path: '/repuestos/cursadas',
    component: 'OperationsSparepartsTakenPage',
    icon: 'up-to-top',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Pendientes de giro',
    order: 3,
    pathselected: '/repuestos/pendientes-giro',
    path: '/repuestos/pendientes-giro',
    component: 'MoneyTransferPendingPage',
    icon: 'time',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Otorgadas',
    order: 4,
    pathselected: '/repuestos/otorgadas',
    path: '/repuestos/otorgadas',
    component: 'GrantedSparePartsPage',
    icon: 'thumbsup',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Carga de operaciones',
    order: 5,
    pathselected: '/repuestos/carga-operaciones',
    path: '/repuestos/carga-operaciones',
    component: 'ChargeOperationsSparePartsPage',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/repuestos/carga-operaciones',
    path: '/repuestos/carga-operaciones/historial',
    component: 'HistoricChargeOperationsSparePartsPage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/repuestos/carga-operaciones',
        name: 'Carga de operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle',
    order: 0,
    pathselected: '/repuestos/carga-operaciones',
    path: '/repuestos/carga-operaciones/:id/:UploadAt',
    component: 'DetailChargeOperationsSparePartsPage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/repuestos/carga-operaciones',
        name: 'Carga de operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/repuestos/pendientes-giro',
    path: '/repuestos/pendientes-giro/historial',
    component: 'HistoricMoneyTransferPendingSparePartsPage',
    icon: 'time',
    breadcrumb: [
      {
        path: '/repuestos/pendientes-giro',
        name: 'Pendientes de giro',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle otorgadas',
    order: 0,
    pathselected: '/repuestos/otorgadas',
    path: '/repuestos/otorgadas/detalle-otorgadas/:id',
    component: 'DetailGrantedSparePartsPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/repuestos/otorgadas',
        name: 'Operaciones otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Aplicar pago',
    order: 0,
    pathselected: '/repuestos/otorgadas',
    path: '/repuestos/otorgadas/aplicacion-pago',
    component: 'ApplyPaymentSparePartPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/repuestos/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle aplicar pago',
    order: 0,
    pathselected: '/repuestos/otorgadas',
    path: '/repuestos/otorgadas/aplicacion-pago/:id',
    component: 'DetailApplyPaymentSparePartsPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/repuestos/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Pendientes de confirmación',
    order: 0,
    pathselected: '/repuestos/otorgadas',
    path: '/repuestos/otorgadas/pendientes-confirmacion',
    component: 'ConfirmPendingSparePartsPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/repuestos/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  /* {
      menu: false,
      name: 'Carga de I-CAR',
      order: 0,
      pathselected: '/nuevos/carga-operaciones',
      path: '/nuevos/carga-icar',
      component: 'CargaICAR',
      icon: 'document-add',
      breadcrumb: [],
    },
    {
      menu: false,
      name: 'Selección tipo de negocio',
      order: 0,
      pathselected: '',
      path: '/negocio',
      component: 'BusinessTypePage',
      icon: '',
      breadcrumb: [],
    },
    {
      menu: true,
      name: 'Detalles de líneas',
      order: 1,
      pathselected: '/repuestos',
      path: '/repuestos',
      component: 'CreditLineDetail',
      icon: 'list',
      breadcrumb: [],
    },
    {
      menu: true,
      name: 'Carga de Operaciones',
      order: 5,
      pathselected: '/repuestos/carga-operaciones',
      path: '/repuestos/carga-operaciones',
      component: 'ChargeOperationsPage',
      icon: 'document-add',
      breadcrumb: [],
    },
    {
      menu: false,
      name: 'Historial',
      order: 0,
      pathselected: '/repuestos/carga-operaciones',
      path: '/repuestos/carga-operaciones/historial',
      component: 'HistoricChargeOperationsSparePartsPage',
      icon: 'document-add',
      breadcrumb: [
        {
          path: '/repuestos/carga-operaciones',
          name: 'Carga de Operaciones',
        },
      ],
    },
*/
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/exception-page',
    component: 'ExceptionPage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/pagenotfound',
    component: 'PageNotFound',
    icon: '',
    breadcrumb: [],
  },
];

export default ROUTES;
