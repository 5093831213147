import { Grid } from '@material-ui/core';
import React from 'react';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { SparePartsPaymentPending } from '../../../../models/spareParts';
import { Loading } from '../../../common/loading';
import ErrorSpareparts from '../../commons/ErrorSpareparts';
import NotFoundSpareparts from '../../commons/NotFoundSpareparts';
import useStyles from './ApplyPaymentSparePart.style';
import ItemApplyPaymentSparePart from './ItemApplyPaymentSparePart';

const ApplyPaymentSparePart = () => {
  const classes = useStyles();
  const url = `/api/payments/pendings`;
  const {
    status,
    data: pendingPayments,
    error,
  } = useFetch<SparePartsPaymentPending[]>(url, undefined, BUSINESSTYPE.PARTS);

  if (status === 'error' && error) {
    return (
      <ErrorSpareparts
        StatusCode={error.StatusCode}
        Message={error.Message}
        ErrorId={error.ErrorId}
      />
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          pendingPayments?.map((pending) => {
            return <ItemApplyPaymentSparePart key={pending.ClientDealerId} pending={pending} />;
          })}
        {status === 'fetched' && pendingPayments?.length === 0 && (
          <NotFoundSpareparts message="No existen operaciones pendientes de pago para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default ApplyPaymentSparePart;
