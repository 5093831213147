import React, { FunctionComponent } from 'react';
import { History } from 'history';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAuth } from './common/auth';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { StorageKeys } from '../utilities/storageHelper';

interface IPrivateRouteProps {
  component: FunctionComponent<any>;
  history: History;
}

const PrivateRoute = (props: IPrivateRouteProps & RouteProps) => {
  const { path, component, exact } = props;

  const { isAuthenticated, setIsAuthenticated, setBusinessType } = useAuth();
  const [businessTypeStorage] = useLocalStorage<string>(StorageKeys.BusinessType);

  const handleIsAutenticated = async () => {
    let isAuth = false;

    if (isAuthenticated === null) {
      if (localStorage.getItem('userIsLogged') !== null) {
        if (localStorage.getItem('userIsLogged') === 'true') {
          isAuth = true;
        } else {
          props.history.push('/login');
          window.location.reload();
        }
      }
      setIsAuthenticated(isAuth);
    } else {
      isAuth = isAuthenticated;
    }

    return isAuth;
  };

  const handleBusinessType = () => {
    if (businessTypeStorage) {
      setBusinessType(businessTypeStorage);
      return true;
    }
    if (!window.location.pathname.endsWith('/negocio')) {
      return false;
    }
    return true;
  };

  if (handleIsAutenticated()) {
    if (handleBusinessType()) {
      return <Route path={path} component={component} exact={exact} />;
    }
    return <Redirect to="/negocio" />;
  }

  return <Redirect to="/login" />;
};

export default PrivateRoute;
