import { AxiosResponse } from 'axios';
import apiConfig from '../config/api';
import { get, post, postFormDataChargeOperation } from '../utilities/request';
import { IPagRequest, IChargeOperations } from '../interfaces';
import { BusinessTypeEnum } from '../enums';

class ChargeOperationsServices {
  private readonly baseUrlNew = `${apiConfig.apiUri}/api/chargeOperations`;
  private readonly baseUrlUsed = `${apiConfig.usedSettings.apiUri}/api/charge`;
  private readonly baseUrlSpareParts = `${apiConfig.sparePartSettings.apiUri}/api/charges`;
  private readonly baseUrlFreeZone = `${apiConfig.freeZoneSettings.apiUri}/api/charges`;

  async getLastRecordUploadFiles(businessType: BusinessTypeEnum): Promise<AxiosResponse | null> {
    switch (businessType) {
      case BusinessTypeEnum.Nuevos:
        return this.getLastRecordUploadFilesNew();
      case BusinessTypeEnum.Usados:
        return this.getLastRecordUploadFilesUsed();
      case BusinessTypeEnum.Repuestos:
        return this.getLastRecordUploadFilesSpareParts();
      case BusinessTypeEnum.ZonaFranca:
        return this.getLastRecordUploadFilesFreeZone();
      default:
        return null;
    }
  }

  private async getLastRecordUploadFilesNew(): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/last`;
    return get(url);
  }

  private async getLastRecordUploadFilesUsed(): Promise<AxiosResponse> {
    const url = `${this.baseUrlUsed}/last`;
    return get(url);
  }

  private async getLastRecordUploadFilesSpareParts(): Promise<AxiosResponse> {
    const url = `${this.baseUrlSpareParts}/last`;
    return get(url);
  }

  private async getLastRecordUploadFilesFreeZone(): Promise<AxiosResponse> {
    const url = `${this.baseUrlFreeZone}/last`;
    return get(url);
  }

  async getChargesOperationsHistory(pagRequest: IPagRequest): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/history`;
    return post(url, pagRequest);
  }

  async getChargeOperationsDetail(chargeid: string): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/${chargeid}`;
    return get(url);
  }

  async processOperationFile(
    params: IChargeOperations,
    options: RequestInit = {}
  ): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/upload`;
    return postFormDataChargeOperation(url, options, params);
  }
}

export const chargeOperationsServices = new ChargeOperationsServices();
