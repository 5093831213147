import { DateTime, DateTimeFormatOptions } from 'luxon';

export const dateHandlers = {
  convertTimeSpanToDate: (timeSpan: number) => DateTime.fromSeconds(timeSpan),
  convertTimeSpanToDateFormatted: (
    timeSpan: number,
    format: DateTimeFormatOptions = DateTime.DATE_FULL
  ) => DateTime.fromSeconds(timeSpan).toLocaleString(format),
  getDateTimeNow: (format: DateTimeFormatOptions = DateTime.DATETIME_SHORT) =>
    DateTime.local().toLocaleString(format),
  convertStringToDate: (stringDate: string, format: string = 'yyyy-MM-ddThh:mm:ss') =>
    stringDate ? DateTime.fromString(stringDate, format).toLocaleString() : stringDate,
  convertStringToDateSSS: (stringDate: string, format: string = 'yyyy-MM-ddThh:mm:ss.SSS') =>
    stringDate ? DateTime.fromString(stringDate, format).toLocaleString() : stringDate,
  convertStringToDateSS: (stringDate: string, format: string = 'yyyy-MM-ddThh:mm:ss.SS') =>
    stringDate ? DateTime.fromString(stringDate, format).toLocaleString() : stringDate,
};

export const getObjDateLocal = (
  objFecha: string | undefined,
  isUtc = true,
  formatdate = 'yyyy-LL-dd',
  formattime = 'HH:mm:ss.SSS'
) => {
  let _objFecha = DateTime.utc().toISO();

  if (objFecha) {
    _objFecha = isUtc ? `${objFecha}Z` : `${objFecha}.000`;
  }

  const dt = DateTime.fromISO(_objFecha);

  return {
    fulldate: new Date(dt.toString()),
    fulldatestring: _objFecha,
    date: dt.toFormat(formatdate),
    time: dt.toFormat(formattime),
    offset: dt.toFormat('ZZ'),
  };
};

export const getDiffBetweenDates = (
  value: string,
  datenow: string = DateTime.utc().toISO().replace('Z', '')
) => {
  const fecha1 = getObjDateLocal(datenow);
  const fecha2 = getObjDateLocal(value);

  const diff = Math.floor(fecha1.fulldate.getTime() - fecha2.fulldate.getTime());
  const day = 1000 * 60 * 60 * 24;

  const days = Math.floor(diff / day);
  const months = Math.floor(days / 31);
  const years = Math.floor(months / 12);

  if (years >= 1) {
    return years + (years > 1 ? ' años' : ' año');
  } else if (months >= 1) {
    return months + (months > 1 ? ' meses' : ' mes');
  } else if (days >= 1) {
    return days + (days > 1 ? ' días' : ' día');
  } else {
    return getTimeBetweenHours(value);
  }
};

export const getTimeBetweenHours = (
  value: string,
  datenow: string = DateTime.utc().toISO().replace('Z', '')
) => {
  const fecha1 = getObjDateLocal(datenow);
  const fecha2 = getObjDateLocal(value);

  const now = fecha1.time.split(':');
  const date = fecha2.time.split(':');

  let t1 = new Date();
  let t2 = new Date();

  t1.setHours(parseInt(now[0]), parseInt(now[1]), parseInt(now[2]));
  t2.setHours(parseInt(date[0]), parseInt(date[1]), parseInt(date[2]));

  t1.setHours(
    t1.getHours() - t2.getHours(),
    t1.getMinutes() - t2.getMinutes(),
    t1.getSeconds() - t2.getSeconds()
  );

  if (t1.getHours() >= 1) {
    return t1.getHours() + (t1.getHours() > 1 ? ' horas' : ' hora');
  } else if (t1.getMinutes() >= 1) {
    return t1.getMinutes() + (t1.getMinutes() > 1 ? ' minutos' : ' minuto');
  } else if (t1.getSeconds() >= 1) {
    return t1.getSeconds() + (t1.getSeconds() > 1 ? ' segundos' : ' segundo');
  } else {
    return '0 segundos';
  }
};

export const convertDateReadableFormat = (
  value: string = DateTime.utc().toISO().replace('Z', ''),
  ampm: boolean = false,
  separator: string = '',
  formatdate: string = 'dd/LL/yyyy'
) => {
  const fecha = getObjDateLocal(value, true, formatdate, 'HH:mm:ss');
  let hora = fecha.time;

  if (ampm) {
    hora = getTextAmOrPm(fecha.time);
  }

  separator = separator.trim().length > 0 ? ` ${separator.trim()} ` : ' ';

  return fecha.date + separator + hora;
};

export const getTextAmOrPm = (time: string) => {
  const partes = time.split(':');

  const hour = parseInt(partes[0]);
  const minute = parseInt(partes[1]);

  let _hour = hour;
  let ampm = 'am';

  if (hour > 12) {
    _hour = hour - 12;
    ampm = 'pm';
  }

  const _hours = _hour < 10 ? `0${_hour}` : _hour;
  const _minutes = minute < 10 ? `0${minute}` : minute;

  return `${_hours}:${_minutes} ${ampm}`;
};

export function getDateWithoutTime(date: string) {
  return date.split('T')[0];
}
