import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { Button, Grid, Typography } from '@material-ui/core/';
import { makeStyles, Theme } from '@material-ui/core/styles';
import authorizationServices from '../../services/authorizationServices';

import { ErrorBase } from '../../interfaces';
import { COLORS } from '../../constants';

/** region style */
const styleCajaObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  header: {
    background: COLORS.primary,
    color: '#666666',
    height: '200px',
  },
  paper: {
    height: 'auto',
    width: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  relleno: {
    height: '200px',
    width: 820,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    marginTop: '100px',
    boxShadow:
      '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
    ...styleCajaObj,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boton: {
    height: '36px',
    width: '149px',
    borderRadius: '4px',
    backgroundColor: COLORS.secondary,
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: 20,
  },
  boton401: {
    height: '36px',
    width: 250,
    borderRadius: '4px',
    backgroundColor: COLORS.secondary,
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: 20,
  },
  msgExError: {
    paddingTop: 15,
    paddingBottom: 10,
    ...styleCajaObj,
  },
}));
/** endregion */

interface IProps {
  history: History;
}

const errorBase: ErrorBase = {
  code: 500,
  statusText: 'Se ha producido un error inesperado, comuníquese con el administrador del sistema.',
  traceId: '',
};

const ExceptionPage = (props: IProps) => {
  const classes = useStyles();

  const [codeExceptionError, setCodeExceptionError] = useState<number>();
  const [traceIdExceptionError, setTraceIdExceptionError] = useState<string>();
  const [messageExceptionError, setMessageExceptionError] = useState<string>();
  const [urlToReturn, setUrlToReturn] = useState('/');

  const navigateToUrl = (path: string) => {
    props.history.push(path);
    window.location.reload();
  };

  const handleSetUriNavegate = () => {
    let _urlToReturn = localStorage.getItem('urlToReturn');

    if (_urlToReturn) {
      if (window.location.pathname.toLowerCase() !== _urlToReturn.toLowerCase()) {
        setUrlToReturn(_urlToReturn);
      }

      localStorage.removeItem('urlToReturn');
    }
  };

  const handleLocalstorageExceptionError = () => {
    handleSetUriNavegate();

    let _codeExceptionError = errorBase.code;
    const codeExceptionError = localStorage.getItem('codeExceptionError');
    if (codeExceptionError) {
      _codeExceptionError = parseInt(codeExceptionError);
      localStorage.removeItem('codeExceptionError');
    }
    setCodeExceptionError(_codeExceptionError);

    const isExceptionError = localStorage.getItem('isExceptionError');
    if (isExceptionError) {
      let _traceIdExceptionError = errorBase.traceId;
      const traceIdExceptionError = localStorage.getItem('traceIdExceptionError');
      if (traceIdExceptionError && traceIdExceptionError !== 'undefined') {
        _traceIdExceptionError = traceIdExceptionError;
        localStorage.removeItem('traceIdExceptionError');
      }
      setTraceIdExceptionError(_traceIdExceptionError);

      let _messageExceptionError = errorBase.statusText;
      const messageExceptionError = localStorage.getItem('messageExceptionError');
      if (messageExceptionError && messageExceptionError !== 'undefined') {
        _messageExceptionError = messageExceptionError;
        localStorage.removeItem('messageExceptionError');
      }
      setMessageExceptionError(_messageExceptionError);

      if (_codeExceptionError === 401) {
        authorizationServices.logout();
      }

      localStorage.removeItem('isExceptionError');
    } else {
      const userIsLogged = localStorage.getItem('userIsLogged');
      const _userIsLogged = userIsLogged ? JSON.parse(userIsLogged) : false;

      if (_userIsLogged) {
        if (_codeExceptionError !== 503) {
          navigateToUrl(urlToReturn);
        }
      } else {
        navigateToUrl('/login');
      }
    }
  };

  useEffect(() => {
    handleLocalstorageExceptionError();
  }, []);

  return (
    <div>
      <div className={classes.header}>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item md={12} className={classes.container}>
            <div className={classes.relleno}>
              <div className={classes.paper}>
                {codeExceptionError === 400 && (
                  <div className={classes.form}>
                    <Typography variant="h6" className={classes.msgExError}>
                      {messageExceptionError}
                    </Typography>
                    <Typography variant="h5">{traceIdExceptionError}</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.boton}
                      onClick={() => navigateToUrl(urlToReturn)}
                    >
                      Volver
                    </Button>
                  </div>
                )}
                {codeExceptionError === 401 && (
                  <div className={classes.form}>
                    <Typography variant="h6" className={classes.msgExError}>
                      El token ha expirado
                    </Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.boton401}
                      onClick={() => navigateToUrl('/login')}
                    >
                      Volver a iniciar sesión
                    </Button>
                  </div>
                )}
                {codeExceptionError === 404 && (
                  <div className={classes.form}>
                    <Typography variant="h6" className={classes.msgExError}>
                      {messageExceptionError}
                    </Typography>
                    <Typography variant="h5">{traceIdExceptionError}</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.boton}
                      onClick={() => navigateToUrl(urlToReturn)}
                    >
                      Volver
                    </Button>
                  </div>
                )}
                {codeExceptionError === 500 && (
                  <div className={classes.form}>
                    <Typography variant="h6" className={classes.msgExError}>
                      {messageExceptionError}
                    </Typography>
                    <Typography variant="h5">{traceIdExceptionError}</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.boton}
                      onClick={() => navigateToUrl(urlToReturn)}
                    >
                      Volver
                    </Button>
                  </div>
                )}
                {codeExceptionError === 503 && (
                  <div className={classes.form}>
                    <Typography variant="h6" className={classes.msgExError}>
                      Ha ocurrido un error inesperado.
                    </Typography>
                    <Typography variant="h5">El servidor no se encuentra disponible.</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.boton}
                      onClick={() => navigateToUrl(urlToReturn)}
                    >
                      Volver
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ExceptionPage;
