import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  createContext,
} from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ClientInfo } from '../../interfaces';
import { CancelRequest } from '../../utilities/cancelRequest';
import { setClientInfoHeader } from '../../utilities/request';
import { masterServices } from '../../services/masterServices';
import { StorageKeys } from '../../utilities/storageHelper';
import Notification from './notification';
import NotificationModel from '../../models/NotificationModel';

interface IAuthContext {
  isAuthenticated: boolean | null;
  loading: boolean;
  clientInfo: ClientInfo;
  clients: ClientInfo[];
  businessType: string;
  titlePaymentDetail: string;
  showNotification: NotificationModel;
  setIsAuthenticated: Dispatch<SetStateAction<boolean | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setClientInfo: Dispatch<SetStateAction<ClientInfo>>;
  setClients: Dispatch<SetStateAction<ClientInfo[]>>;
  setBusinessType: Dispatch<SetStateAction<string>>;
  setTitlePaymentDetail: Dispatch<SetStateAction<string>>;
  setShowNotification: Dispatch<SetStateAction<NotificationModel>>;
}

const initialContext: IAuthContext = {
  isAuthenticated: false,
  loading: true,
  clientInfo: {} as ClientInfo,
  clients: [] as ClientInfo[],
  businessType: '',
  titlePaymentDetail: '',
  showNotification: {
    show: false,
    variant: 'success',
    message: '',
  } as NotificationModel,
  setIsAuthenticated: () => {},
  setLoading: () => {},
  setClientInfo: () => {},
  setClients: () => {},
  setBusinessType: () => {},
  setTitlePaymentDetail: () => {},
  setShowNotification: () => {},
};

interface IAuthProps {
  children: React.ReactNode;
}

export const AuthContext = createContext(initialContext);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props: IAuthProps) => {
  const { children } = props;

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [businessType, setBusinessType] = useState<string>('');
  const [titlePaymentDetail, setTitlePaymentDetail] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [clientInfo, setClientInfo] = useState<ClientInfo>(initialContext.clientInfo);
  const [clients, setClients] = useState<ClientInfo[]>(initialContext.clients);
  const [reload, setReload] = useState<boolean>(true);
  const [showNotification, setShowNotification] = React.useState<NotificationModel>(
    initialContext.showNotification
  );
  const history = useHistory();

  useEffect(() => {
    const cancelRequest = new CancelRequest();

    const getClient = () => {
      if (isAuthenticated) {
        setLoading(true);

        masterServices
          .getClients()
          .then((response) => {
            if (cancelRequest.isCancel) {
              return;
            }

            setLoading(false);

            if (response && response.data) {
              const clientsApi: ClientInfo[] = response.data;

              setClients(clientsApi);

              const client = JSON.parse(localStorage.getItem(StorageKeys.Client) as string);
              if (client && client.rut !== '') {
                setClientInfoHeader(client);
                setClientInfo(client);
              } else {
                setClientInfoHeader(clients[0]);
                setClientInfo(clients[0]);
              }

              const publicRoutes = ['login'];

              const path = window.location.pathname.split('/')[1];

              if (publicRoutes.includes(path)) {
                history.replace('/');
              }
            }
          })
          .catch((err) => {
            if (Axios.isCancel(err)) return;
            setReload(false);
            setLoading(false);
          });
      }
    };
    if (reload) {
      getClient();
    } else {
      setLoading(false);
    }

    return () => {
      cancelRequest.cancel();
      setLoading(false);
    };
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        clientInfo,
        clients,
        businessType,
        titlePaymentDetail,
        showNotification,
        setIsAuthenticated,
        setLoading,
        setClientInfo,
        setClients,
        setBusinessType,
        setTitlePaymentDetail,
        setShowNotification,
      }}
    >
      {children}
      {showNotification.show && (
        <Notification
          variant={showNotification.variant}
          message={showNotification.message}
          show={showNotification.show}
          onClose={() =>
            setShowNotification((p) => {
              return { ...p, show: false };
            })
          }
        />
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
