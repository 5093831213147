const hostname = window && window.location && window.location.hostname;

let apiUri = 'https://wholesale-credinissan-api.tanner.cl';
let usedApiUri = '';
let subscriptionKey = 'bb887605d1ab49858f43abdd560e68b3';
let usedSubscriptionKey = '';
const usedApiVersion = 'v1';
const apiVersion = '1.0';
let featureFlagUsed = false;
let featureFlagParts = true;
let featureFlagFreeZone = true;
let sparePartUri = 'https://wholesale-credinissan-api.tanner.cl/spareparts';
let sparePartSubscriptionKey = '';
const sparePartApiVersion = '1.0';
let freeZoneUri = 'https://wholesale-credinissan-api.tanner.cl/freezone';
let freeZoneSubscriptionKey = '';
const freeZoneApiVersion = '1.0';

export type UsedSettings = {
  apiUri: string;
  subscriptionKey: string;
  apiVersion: string;
};

export type BusinessSettings = {
  apiUri: string;
  subscriptionKey: string;
  apiVersion: string;
};

export type Settings = {
  apiUri: string;
  subscriptionKey: string;
  apiVersion: string;
  usedSettings: UsedSettings;
  sparePartSettings: BusinessSettings;
  freeZoneSettings: BusinessSettings;
  featureFlagUsed: boolean;
  featureFlagParts: boolean;
  featureFlagFreeZone: boolean;
};

switch (true) {
  case hostname.includes('-dev'): {
    apiUri = 'https://floorplanning-credinissan-api-dev.tanner.cl';
    subscriptionKey = 'e3d4d16234c54fbbb8e4c3e804924fc9';
    usedApiUri = 'https://apimtanner-dev.azure-api.net/floorplanning/used';
    usedSubscriptionKey = 'd56aa4a44f0b4ddeadd8f6c945303a14';
    sparePartUri = 'https://floorplanning-credinissan-api-dev.tanner.cl/spareparts';
    freeZoneUri = 'https://floorplanning-credinissan-api-dev.tanner.cl/freezone';
    freeZoneSubscriptionKey = 'd56aa4a44f0b4ddeadd8f6c945303a14';
    featureFlagUsed = true;
    featureFlagParts = true;
    featureFlagFreeZone = true;
    break;
  }
  case hostname.includes('-qa'): {
    apiUri = 'https://floorplanning-credinissan-api-qa.tanner.cl';
    subscriptionKey = '12aaad2217484268a0a513d7f3553dd5';
    usedApiUri = 'https://apimtanner-qa.azure-api.net/floorplanning/used';
    usedSubscriptionKey = '12aaad2217484268a0a513d7f3553dd5';
    sparePartUri = 'https://floorplanning-credinissan-api-qa.tanner.cl/spareparts';
    freeZoneUri = 'https://floorplanning-credinissan-api-qa.tanner.cl/freezone';
    freeZoneSubscriptionKey = '12aaad2217484268a0a513d7f3553dd5';
    featureFlagUsed = false;
    featureFlagParts = true;
    featureFlagFreeZone = true;
    break;
  }
  case hostname.includes('localhost'): {
    const apiLocal = true;
    if (apiLocal) {
      apiUri = 'https://localhost:44341';
      usedApiUri = 'https://localhost:44301/used';
      sparePartUri = 'https://localhost:7285/spareparts';
      freeZoneUri = 'https://localhost:7222/freezone';
    } else {
      apiUri = 'https://floorplanning-credinissan-api-dev.tanner.cl';
      usedApiUri = 'https://apimtanner-dev.azure-api.net/floorplanning/used';
      sparePartUri = 'https://floorplanning-credinissan-api-dev.tanner.cl/spareparts';
      freeZoneUri = 'https://floorplanning-credinissan-api-dev.tanner.cl/freezone';
    }
    subscriptionKey = 'e3d4d16234c54fbbb8e4c3e804924fc9';
    usedSubscriptionKey = 'd56aa4a44f0b4ddeadd8f6c945303a14';
    sparePartSubscriptionKey = 'd56aa4a44f0b4ddeadd8f6c945303a14';
    freeZoneSubscriptionKey = 'd56aa4a44f0b4ddeadd8f6c945303a14';

    featureFlagUsed = true;
    featureFlagParts = true;
    featureFlagFreeZone = true;
    break;
  }
  default: {
    break;
  }
}

const config: Settings = {
  apiUri,
  subscriptionKey,
  apiVersion,
  usedSettings: {
    apiUri: usedApiUri,
    subscriptionKey: usedSubscriptionKey,
    apiVersion: usedApiVersion,
  },
  sparePartSettings: {
    apiUri: sparePartUri,
    subscriptionKey: sparePartSubscriptionKey,
    apiVersion: sparePartApiVersion,
  },
  freeZoneSettings: {
    apiUri: freeZoneUri,
    subscriptionKey: freeZoneSubscriptionKey,
    apiVersion: freeZoneApiVersion,
  },
  featureFlagUsed,
  featureFlagParts,
  featureFlagFreeZone,
};

export default config;
