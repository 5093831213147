import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme, makeStyles, createStyles, Grid, Button } from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { COLORS } from '../../../constants';

/** START Styles */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      height: '100%',
    },
    contenedorDetalle: {
      display: 'column',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      height: 'auto',
    },
    barraEstado: {
      height: '60px',
      borderRadius: '4px',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    barraEstadoIcon: {
      fontSize: 36,
      marginRight: 10,
      marginLeft: 10,
    },
    detalle: {
      height: 'auto',
      padding: '40px 0 0 0',
    },
    detalleLeft: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: '24px 0 0 0',
    },
    detalleLeftIconContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    detalleLeftIcon: {
      fontSize: 36,
      color: '#666666',
    },
    detalleLeftFile: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
    detalleLeftFileName: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 16,
      fontWeight: 600,
    },
    detalleLeftFileDate: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 14,
    },
    detalleRight: {
      borderLeft: `1px solid ${COLORS.secondary}`,
      padding: '0 0 0 10px',
    },
    detalleRightUp: {
      color: COLORS.secondary,
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
    },
    detalleRightCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: '#666666',
      fontSize: 14,
      fontWeight: 'bold',
    },
    detalleRightCenterHeader: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    detalleRightCenterBody: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    detalleRightCenterBodyText: {
      fontWeight: 'normal',
    },
    dot: {
      height: 13,
      width: 13,
      backgroundColor: COLORS.secondary,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 10,
    },
    dotOK: {
      height: 13,
      width: 13,
      backgroundColor: COLORS.secondary,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 10,
    },
    detalleRightDown: {
      padding: '20px 0 0 0',
    },
    detalleRightDownBoton: {
      width: 336,
      height: 36,
      backgroundColor: COLORS.secondary,
      fontFamily: 'Nunito Sans ',
      fontSize: '15px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      marginLeft: 10,
    },
    detalleRightDownBotonError: {
      width: 257,
      height: 36,
      backgroundColor: COLORS.secondary,
      fontFamily: 'Nunito Sans ',
      fontSize: '15px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
    },
    detalleRightDownBotonIcon: {
      fontSize: 24,
      marginRight: 10,
    },
  })
);
/** FINISH Styles */

interface IProps extends RouteComponentProps {
  countRowsError: number;
  countExits: number;
  countRowsOk: number;
  FileName: string;
  FechaCarga: string;
  onClear: () => void;
}

const LoadICAR = (props: IProps) => {
  const classes = useStyles();

  const { countRowsError, countExits, countRowsOk, FileName, FechaCarga, onClear } = props;

  const handleSelectedNewFileClick = () => {
    onClear();
  };

  return (
    <div className={classes.body}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.contenedorDetalle}
      >
        {countRowsError > 0 || countExits === 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.barraEstado}
            style={{ backgroundColor: COLORS.secondary }}
          >
            <HighlightOffIcon className={classes.barraEstadoIcon} />
            El archivo Excel presenta errores.
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.barraEstado}
            style={{ backgroundColor: COLORS.secondary }}
          >
            <CheckCircleOutlineIcon className={classes.barraEstadoIcon} />
            Archivo cargado con éxito
          </Grid>
        )}
        <Grid item xs={12} sm={12} className={classes.detalle}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={4} className={classes.detalleLeft}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} sm={2} className={classes.detalleLeftIconContainer}>
                  <DescriptionOutlinedIcon className={classes.detalleLeftIcon} />
                </Grid>
                <Grid item xs={10} sm={10} className={classes.detalleLeftFile}>
                  <div className={classes.detalleLeftFileName}>{FileName}</div>
                  <div className={classes.detalleLeftFileDate}>{FechaCarga.toString()}</div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8} className={classes.detalleRight}>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item xs={12} sm={12} className={classes.detalleRightUp}>
                  Detalle del archivo cargado
                </Grid>
                <Grid item xs={12} sm={12} className={classes.detalleRightCenter}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.detalleRightCenterHeader}
                  >
                    <Grid item xs={5} sm={5}>
                      Estado
                    </Grid>
                    <Grid item xs={7} sm={7}>
                      Acción a realizar
                    </Grid>
                  </Grid>
                  {countExits > 0 && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.detalleRightCenterBody}
                    >
                      <Grid item xs={5} sm={5}>
                        <span className={classes.dotOK}></span>
                        {countExits} filas procesadas
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sm={7}
                        className={classes.detalleRightCenterBodyText}
                      ></Grid>
                    </Grid>
                  )}
                  {countRowsOk > 0 && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.detalleRightCenterBody}
                    >
                      <Grid item xs={5} sm={5}>
                        <span className={classes.dotOK}></span>
                        {countRowsOk} filas cargadas
                      </Grid>
                    </Grid>
                  )}
                  {countRowsError > 0 && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.detalleRightCenterBody}
                    >
                      <Grid item xs={5} sm={5}>
                        <span className={classes.dot}></span>
                        {countRowsError} filas con errores
                      </Grid>
                      <Grid item xs={7} sm={7} className={classes.detalleRightCenterBodyText}>
                        Estas filas NO se pueden cargar
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} className={classes.detalleRightDown}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSelectedNewFileClick}
                    className={classes.detalleRightDownBotonError}
                  >
                    <ArrowForwardIosIcon className={classes.detalleRightDownBotonIcon} />
                    Seleccionar nuevo archivo
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(LoadICAR);
