const ROUTES = [
  {
    menu: false,
    name: 'Selección tipo de negocio',
    order: 0,
    pathselected: '',
    path: '/negocio',
    component: 'BusinessTypePage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Detalles de líneas',
    order: 1,
    pathselected: '/nuevos',
    path: '/nuevos',
    component: 'DetallesDeLineas',
    icon: 'list',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Cursadas',
    order: 2,
    pathselected: '/nuevos/cursadas',
    path: '/nuevos/cursadas',
    component: 'Cursadas',
    icon: 'up-to-top',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Pendientes de giro',
    order: 3,
    pathselected: '/nuevos/pendientes-giro',
    path: '/nuevos/pendientes-giro',
    component: 'PendientesDeGiro',
    icon: 'time',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Otorgadas',
    order: 4,
    pathselected: '/nuevos/otorgadas',
    path: '/nuevos/otorgadas',
    component: 'Otorgadas',
    icon: 'thumbsup',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Carga de Operaciones',
    order: 5,
    pathselected: '/nuevos/carga-operaciones',
    path: '/nuevos/carga-operaciones',
    component: 'CargaOperaciones',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/nuevos/carga-operaciones',
    path: '/nuevos/carga-operaciones/historial',
    component: 'HistoricoOperaciones',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/nuevos/carga-operaciones',
        name: 'Carga de Operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Carga de I-CAR',
    order: 0,
    pathselected: '/nuevos/carga-operaciones',
    path: '/nuevos/carga-icar',
    component: 'CargaICAR',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Carga de Notas de Crédito',
    order: 0,
    pathselected: '/nuevos/carga-operaciones',
    path: '/nuevos/carga-notas-de-credito',
    component: 'CargaNotasCredito',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/nuevos/carga-operaciones',
    path: '/nuevos/carga-notas-de-credito/historial',
    component: 'HistoricoNotasDeCredito',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/nuevos/carga-notas-de-credito',
        name: 'Carga de Notas de Crédito',
      },
    ],
  },
  {
    menu: false,
    name: 'Pendientes',
    order: 0,
    pathselected: '/nuevos/otorgadas',
    path: '/nuevos/otorgadas/pedientes-de-facturacion',
    component: 'ViewPendientes',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/nuevos/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: undefined,
        name: 'Pendientes de facturación',
      },
    ],
  },
  {
    menu: false,
    name: 'Solicitadas',
    order: 0,
    pathselected: '/nuevos/otorgadas',
    path: '/nuevos/otorgadas/pedientes-de-facturacion/solicitadas',
    component: 'ViewSolicitadas',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/nuevos/otorgadas',
        name: 'Otorgadas',
      },
      {
        path: '/nuevos/otorgadas/pedientes-de-facturacion',
        name: 'Pendientes de facturación',
      },
    ],
  },
  {
    menu: false,
    name: 'Facturado',
    order: 0,
    pathselected: '/nuevos/otorgadas',
    path: '/nuevos/otorgadas/facturado',
    component: 'Facturado',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/nuevos/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Pendientes de confirmación',
    order: 0,
    pathselected: '/nuevos/otorgadas',
    path: '/nuevos/otorgadas/pedientes-de-confirmacion',
    component: 'PendientesDeConfirmacion',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/nuevos/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Historial de giros',
    order: 0,
    pathselected: '/nuevos/pendientes-giro',
    path: '/nuevos/pendientes-giro/historico-giros',
    component: 'HistoricoGiros',
    icon: 'time',
    breadcrumb: [
      {
        path: '/nuevos/pendientes-giro',
        name: 'Pendientes de giro',
      },
    ],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/exception-page',
    component: 'ExceptionPage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/pagenotfound',
    component: 'PageNotFound',
    icon: '',
    breadcrumb: [],
  },
];

export default ROUTES;
