import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: 'white',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '5px 20px',
    },
  })
);

export default useStyles;
