import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { numberToMiles } from '../../../../utilities/string-functions';
import { ErrorModel } from '../../../../models/ErrorModel';
import { NotificationModel, UsedMoneyTransferPendingsResponse } from '../../../../models/used';
import { SuccessApiResponse } from '../../../../models/SuccessApiResponse';
import useStyles from './PendienteGiroUsados.style';
import DialogSeleccionCuentaGiro from './DialogSeleccionCuentaGiro';
import Notification from '../../../common/notification';
import { convertDateReadableFormat } from '../../../../utilities/dates-functions';
import { put } from '../../../../utilities/requestsUsed';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

type Props = {
  pending: UsedMoneyTransferPendingsResponse;
} & RouteComponentProps;

const ItemPendienteGiroUsados: React.FC<Props> = (props) => {
  const { pending } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ErrorModel | undefined>(undefined);
  const [downloadedAt, setDownloadedAt] = React.useState<string | null>(pending.DownloadRequestAt);

  const [showNotificacion, setShowNotification] = React.useState<NotificationModel>({
    show: false,
    variant: 'success',
    message: 'Archivo descargado correctamente',
  });
  const handleClickDownload = () => {
    handleOpen();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotification = (notification: NotificationModel) => {
    setShowNotification(notification);
  };

  const handleOtorgar = async () => {
    setLoading(true);
    const response = await put<SuccessApiResponse>(`/api/moneyTransfer/${pending.Id}`, {});
    setLoading(false);
    if (response.status === 'success') {
      props.history.push('/usados/otorgadas');
    } else {
      setShowNotification({
        show: true,
        variant: 'error',
        message: 'No se pudo otorgar la solicitud de giro.',
      });
      setError(response.error);
    }
  };

  const handleClickBack = () => {
    setError(undefined);
  };
  const handleDownloadDate = (date: string) => {
    setDownloadedAt(date);
  };

  const classes = useStyles();

  if (error) {
    return (
      <Grid container item xs={12} className={classes.errorContainer}>
        <Grid item xs={10}>
          {error.ErrorId === undefined
            ? 'Error 500: Ha ocurrido un error en el servidor.'
            : `Error ${error.StatusCode}: ErrorId ${error.ErrorId} - ${error.Message}`}
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            className={classes.btnError}
            onClick={handleClickBack}
            startIcon={<ArrowBackIosIcon />}
          >
            Volver
          </Button>
        </Grid>
        {showNotificacion.show && (
          <Notification
            variant={showNotificacion.variant}
            message={showNotificacion.message}
            show={showNotificacion.show}
            onClose={() =>
              setShowNotification((p) => {
                return { ...p, show: false };
              })
            }
          />
        )}
      </Grid>
    );
  }

  return loading ? (
    <Grid container item xs={12} className={classes.itemContainer}>
      <Loading />
    </Grid>
  ) : (
    <Grid container item xs={12} className={classes.itemContainer}>
      <Grid item xs={3} className={classes.columnId}>
        {pending.Id}
      </Grid>
      <Grid item xs={2} className={classes.columnAmount}>
        $ {numberToMiles(pending.TotalAmount)}
      </Grid>
      <Grid item xs={1} className={classes.columnVins}>
        {pending.UsedVinsTotal}
      </Grid>
      <Grid container item xs={6} className={classes.columnDownload}>
        <Grid container item xs={6} justifyContent="center" alignItems="center">
          {downloadedAt !== null ? (
            <>
              <CheckCircleIcon style={{ fontSize: 15, marginRight: 3, color: COLORS.secondary }} />
              <p>{convertDateReadableFormat(downloadedAt)}</p>
            </>
          ) : (
            <p>No se ha descargado aún</p>
          )}
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            className={classes.btnDownload}
            startIcon={<GetAppIcon />}
            onClick={handleClickDownload}
            style={{ backgroundColor: COLORS.secondary }}
          >
            Descargar excel
          </Button>
        </Grid>
        {downloadedAt !== null && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              className={classes.btnOtorgar}
              onClick={handleOtorgar}
              startIcon={<CheckCircleOutlineIcon />}
            >
              Otorgar
            </Button>
          </Grid>
        )}
      </Grid>

      <DialogSeleccionCuentaGiro
        open={open}
        handleClose={handleClose}
        requestId={pending.Id}
        handleNotification={handleNotification}
        handleDownloadDate={handleDownloadDate}
      />
      {showNotificacion.show && (
        <Notification
          variant={showNotificacion.variant}
          message={showNotificacion.message}
          show={showNotificacion.show}
          onClose={() =>
            setShowNotification((p) => {
              return { ...p, show: false };
            })
          }
        />
      )}
    </Grid>
  );
};

export default withRouter(ItemPendienteGiroUsados);
