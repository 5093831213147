import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { numberToMiles } from '../../../../utilities/string-functions';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { COLORS } from '../../../../constants';
import { SparePartsDetailPaymentPending } from '../../../../models/spareParts/SparePartsDetailPaymentPending';
import useStyles from './DetailApplyPaymentSpareParts.style';

type Props = {
  pendingPayment: SparePartsDetailPaymentPending;
  checked: number[];
  handleToggle: (value: number) => () => void;
};

const ItemApplyPaymentSpareParts: React.FC<Props> = ({ pendingPayment, checked, handleToggle }) => {
  const [disabledChecked, setDisabledChecked] = useState(false);
  const classes = useStyles();

  return (
    <TableRow key={pendingPayment.OperationId}>
      <TableCell className={classes.itemApplyPayment}>
        <Checkbox
          style={{ color: COLORS.secondary }}
          disabled={disabledChecked}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          checked={checked.indexOf(pendingPayment.Position) !== -1}
          onClick={handleToggle(pendingPayment.Position)}
          disableRipple
        />
      </TableCell>
      <TableCell className={classes.itemApplyPayment}>{pendingPayment.PassedDays}</TableCell>
      <TableCell className={classes.itemApplyPayment}>{`$ ${numberToMiles(
        pendingPayment.Amount
      )}`}</TableCell>
      <TableCell className={classes.itemApplyPayment}>{pendingPayment.InvoiceNumber}</TableCell>
      <TableCell className={classes.itemApplyPayment}>
        {getObjDateLocal(pendingPayment.InvoiceDate, true, 'dd-LL-yyyy').date}
      </TableCell>
    </TableRow>
  );
};

export default ItemApplyPaymentSpareParts;
