import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);

export default useStyles;
