import React, { useState } from 'react';
import { Menu, MenuItem, Grid, IconButton, Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Icons from '../icons';
import { ClientInfo } from '../../../interfaces';
import { useAuth } from '../auth';
import { setClientInfoHeader } from '../../../utilities/request';
import useStyles from './clientSelect.style';

const ClientSelect = (props: RouteComponentProps) => {
  const classes = useStyles();
  const { clientInfo, setClientInfo, clients } = useAuth();
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorRef);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorRef(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorRef(null);
  };

  const handlePick = (client: ClientInfo) => {
    if (client.rut && client.name) {
      setClientInfoHeader(client);
      setClientInfo(client);
    }

    const path = window.location.pathname.split('/');
    let urlReturn = `/${path[1]}`;

    for (let i = 2; i < path.length; i++) {
      urlReturn += `/${path[i]}`;
    }
    props.history.replace(urlReturn);
    window.location.reload();
    handleClose();
  };

  return (
    <div className={classes.clientSelect}>
      <Button
        onClick={handleClick}
        className={classes.wrapper}
        variant="contained"
        aria-hidden="true"
        disabled={clients.length === 1}
        endIcon={<Icons name="exchange" cssClassName={classes.icon} size="24px" />}
      >
        <div className={classes.wrapperText}>
          <div className={classes.nameClient}>{clientInfo.name}</div>
          <div className={classes.rutClient}>{clientInfo.rut}</div>
        </div>
      </Button>
      <Menu
        id="client-menu"
        open={open}
        anchorEl={anchorRef}
        keepMounted
        onClose={handleClose}
        classes={{ paper: `switch-client-paper` }}
        PaperProps={{
          style: {
            maxHeight: '400px',
          },
        }}
      >
        {clients.length > 1 &&
          clients.map((row: ClientInfo) => {
            return (
              row.rut !== clientInfo.rut && (
                <MenuItem className="pick-menuitem" key={row.rut} onClick={() => handlePick(row)}>
                  <Grid container>
                    <Grid className="pick-menuitem-content" item xs>
                      <p className="badge-name-menu">{row.name} </p>
                      <p className="badge-rut-menu">{row.rut}</p>
                    </Grid>
                  </Grid>
                </MenuItem>
              )
            );
          })}
      </Menu>
    </div>
  );
};

export default withRouter(ClientSelect);
