import apiConfig from '../config/api';
import { getFromUrl } from '../utilities/request';

export const getGranted = async () => {
  const url = `${apiConfig.apiUri}/api/granted`;
  return getFromUrl(url);
};

export const getGrantedDetail = async (clientDealerId: string) => {
  const url = `${apiConfig.apiUri}/api/granted/detail/${clientDealerId}`;
  return getFromUrl(url);
};
