import { createStyles, makeStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants';

const displayCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: `${COLORS.secondary} !important`,
      },
    },
  },
});

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      overflowY: 'auto',
    },
    box: {
      ...displayCenter,
      width: '100%',
      minHeight: '40vh',
      fontFamily: 'Nunito Sans',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '5px 20px',
    },
    boxError: {
      width: '100%',
      height: '50vh',
      fontFamily: 'Nunito Sans',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    textUpload: {
      color: '#666666',
      fontSize: '24px',
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
    },
    progressBar: {
      height: '4px',
      width: '70%',
      borderRadius: '4px',
      margin: '0px auto',
      backgroundColor: 'rgba(178, 0, 43, 0.17)',
    },
    MuiDropzoneArea: {
      icon: {
        color: `${COLORS.secondary} !important`,
      },
    },
  })
);
