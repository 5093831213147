import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },

    boxDetail: {
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '87px',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0,0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: '20px',
    },

    body: {
      padding: '25px 20px 0px 20px',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
    },

    item: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
    },

    emptyMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },

    bodyDetail: {
      height: '100%',
      padding: '20px',
    },

    dealerName: {
      color: COLORS.secondary,
      fontSize: '20px',
      fontWeight: 'bold',
    },

    dealerRut: {
      fontWeight: 'bold',
      marginRight: '5px',
      color: COLORS.secondary,
    },

    vins: {
      fontWeight: 'bold',
      marginRight: '5px',
    },

    columnDetail: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: '16px',
    },

    titleDetail: {
      fontWeight: 'bold',
    },

    rightFirstDetail: {
      alignItems: 'flex-end',
      borderLeft: '1px solid #979797',
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },

    rightSecondDetail: {
      alignItems: 'flex-end',
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default useStyles;
