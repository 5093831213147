import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Button, Hidden } from '@material-ui/core/';

import { NoteAddOutlined } from '@material-ui/icons';

import CompanyLogo from './companylogo';
import Breadcrumbs from './breadcrumbs';
import BarInfoUpload from './barinfoupload';
import ClientSelect from './clientSelect';

import { BusinessTypeEnum } from '../../../enums';
import { useAuth } from '../auth';
import UltimaCargaUsados from '../../used/commons/UltimaCargaUsados';
import DivisionSelect from './divisionSelect';
import config from '../../../config/api';
import { COLORS } from '../../../constants';
import LastChargeOperations from '../../spareParts/commons/lastChargeOperations';

const useStyles = makeStyles(() =>
  createStyles({
    body: {},
    breadcrumbs: {},
    button: {
      backgroundColor: COLORS.secondary,
      color: '#fff',
      fontSize: '16px',
      textTransform: 'capitalize',
      width: '177px',
      height: '50px',
    },
    contenedor: {
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.tertiary,
      color: '#ffffff',
    },
    contenedorUsados: {
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.tertiary,
      color: '#ffffff',
    },
    containerSpareParts: {
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.tertiary,
      color: '#ffffff',
    },
    excel: {
      fontSize: '12px',
      marginLeft: '20px',
      paddingTop: '10px',
    },
    information: {
      fontSize: '12px',
      paddingTop: '10px',
    },
    imgButton: {
      marginRight: '10px',
      height: 28,
      width: 28,
      backgroundColor: '#FFFFF',
    },
    height: {
      height: 30,
    },
    file: {
      display: 'flex',
      alignItems: 'center',
    },
    contentTop: {
      backgroundColor: COLORS.tertiary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '80px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    contentBottom: {
      backgroundColor: COLORS.primary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '40px',
      paddingTop: '10px',
      paddingLeft: '20px',
      paddingRight: '28px',
    },
  })
);

interface PropHeader extends RouteComponentProps {
  heightheder: number;
  paddingleftheader: number;
  paddingrightheader: number;
}

const Headers = (props: PropHeader) => {
  const classes = useStyles();

  const { heightheder, paddingleftheader, paddingrightheader } = props;

  const { businessType } = useAuth();

  // TODO: mejorar esto
  const showClientSelect = !window.location.pathname.includes('carga');

  const headerstyles = {
    bold: {
      fontWeight: 550,
    },
    height: {
      height: heightheder,
      paddingLeft: paddingleftheader,
      paddingRight: paddingrightheader,
    },
  };

  const handleLoadExcelClick = () => {
    switch (businessType) {
      case BusinessTypeEnum.Repuestos:
        props.history.push('/repuestos/carga-operaciones');
        break;
      case BusinessTypeEnum.Usados:
        props.history.push('/usados/carga-operaciones');
        break;
      case BusinessTypeEnum.ZonaFranca:
        props.history.push('/zona-franca/carga-operaciones');
        break;
      default:
        props.history.push('/nuevos/carga-operaciones');
        break;
    }
  };

  const showDivisionSelect = () => {
    if (!config.featureFlagUsed && !config.featureFlagParts && !config.featureFlagFreeZone)
      return <></>;

    return <DivisionSelect />;
  };

  const renderLastOperationCharge = () => {
    switch (businessType) {
      case BusinessTypeEnum.Usados:
        return <UltimaCargaUsados isHeader />;
      case BusinessTypeEnum.Repuestos:
        return <LastChargeOperations isHeader />;
      default:
        return <BarInfoUpload isHeader />;
    }
  };

  return (
    <AppBar
      id="navBarHeader"
      elevation={0}
      style={headerstyles.height}
      className={
        businessType === BusinessTypeEnum.Usados
          ? classes.contenedorUsados
          : businessType === BusinessTypeEnum.Repuestos
          ? classes.containerSpareParts
          : classes.contenedor
      }
    >
      <Grid
        id="navBarHeaderContainer"
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.body}
      >
        <Grid
          item
          className={classes.contentTop}
          id="containerdownload"
          data-hidden="/nuevos/carga-operaciones"
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CompanyLogo />
          </Grid>
          {showClientSelect && (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Hidden mdDown>
                <Button
                  id="navHeaderBarBtnDowload"
                  variant="contained"
                  className={classes.button}
                  onClick={handleLoadExcelClick}
                >
                  <NoteAddOutlined className={classes.imgButton}>NoteAdd</NoteAddOutlined>
                  Cargar excel
                </Button>
              </Hidden>
              {showDivisionSelect()}
              <ClientSelect />
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" className={classes.contentBottom}>
          <Grid>
            <Breadcrumbs />
          </Grid>
          <Grid>{showClientSelect && renderLastOperationCharge()}</Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default withRouter(Headers);
