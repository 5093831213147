import { Button, LinearProgress } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { COLORS } from '../../../../constants';
import { ErrorModel } from '../../../../models/ErrorModel';
import { ApiResponse, OperationsChargeResponse } from '../../../../models/commons';
import Error from '../../Error';
import { useStyles } from './UploadFile.style';
import { DateTime } from 'luxon';

type Props = {
  uploadFile: (formData: FormData) => Promise<ApiResponse<OperationsChargeResponse>>;
  handleViews: (response: OperationsChargeResponse) => void;
} & RouteComponentProps;

type States = {
  loading: boolean;
  fileName: string;
  showUpload: boolean;
  showUploadResult: boolean;
  uploadResult?: OperationsChargeResponse;
  error?: ErrorModel;
} & typeof defaultStates;

const defaultStates = {
  loading: true,
  fileName: '',
  showUpload: true,
  showUploadResult: false,
};

const UploadFile: React.FC<Props> = ({ uploadFile, handleViews }) => {
  const classes = useStyles();
  const [states, setStates] = useState<States>(defaultStates);
  const [completed, setCompleted] = useState<number>(0);

  const onDrop = async (acceptedFiles: File[]) => {
    const data = new FormData();
    data.append('file', acceptedFiles[0]);
    setStates({
      ...states,
      loading: true,
      showUpload: false,
      fileName: acceptedFiles[0].name,
    });

    const result = await uploadFile(data);

    if (result.error) {
      setStates({
        ...states,
        loading: false,
        showUpload: false,
        showUploadResult: false,
        fileName: acceptedFiles[0].name,
        error: result.error,
      });
    } else {
      setStates({
        ...states,
        loading: false,
        showUpload: true,
        showUploadResult: true,
        fileName: acceptedFiles[0].name,
        uploadResult: result.data,
      });
    }
  };

  useEffect(() => {
    if (states.showUploadResult) {
      handleViews({
        ...states.uploadResult,
        FileName: states.fileName,
        UploadAt: DateTime.utc().toISO().replace('Z', ''),
      } as OperationsChargeResponse);
    }
  }, [states.showUploadResult, handleViews, states.uploadResult, states.fileName]);

  useEffect(() => {
    function progress() {
      setCompleted((pCompleted) => {
        if (pCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(pCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 500);

    return () => {
      clearInterval(timer);
    };
  }, [states.loading]);

  return (
    <div className={classes.root}>
      {states.showUpload && (
        <DropzoneArea
          onDrop={onDrop}
          filesLimit={1}
          dropzoneText="Seleccione el archivo a cargar"
          showPreviewsInDropzone={false}
          showAlerts={false}
          dropzoneClass={classes.box}
          dropzoneParagraphClass={classes.textUpload}
        />
      )}
      {states.loading && !states.showUpload && (
        <div className={classes.box}>
          <LinearProgress className={classes.progressBar} color="secondary" />
        </div>
      )}
      {states.error && (
        <>
          <div className={classes.boxError}>
            <Button
              variant="contained"
              startIcon={<ArrowForwardIosIcon />}
              name="newFile"
              onClick={() => {
                setStates(defaultStates);
              }}
              style={{ color: '#FFFFFF', backgroundColor: COLORS.secondary }}
            >
              Cargar archivo de nuevo
            </Button>
            <Error
              ErrorId={states.error?.ErrorId}
              Message={states.error?.Message}
              StatusCode={states.error?.StatusCode}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(UploadFile);
