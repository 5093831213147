export enum PaymentMethodEnum {
  CREDITO_TANNER = '799b216a-9920-4d28-96e3-783f94281768',
  TRANSFERENCIA = 'd6b8b2f5-c0c2-40cb-81ed-861a73cbd49a',
  VALE_VISTA = '92c911b1-2899-4a98-b896-4b7d72314ed0',
  CHEQUE = 'fcd5cacb-f794-4b1c-9f70-8a5dee1a08eb',
  FLOTA = 'fcaaf623-340c-42dd-a4db-d89ce6f4a444',
  EFECTIVO = '734f5eee-74a3-46f7-982f-63f3561db30a',
}

export const PAYMENT_METHOD_TYPE = {
  CREDITO_TANNER: {
    DB_ID: PaymentMethodEnum.CREDITO_TANNER,
    ADJUNTAR: true,
  },
  TRANSFERENCIA: {
    DB_ID: PaymentMethodEnum.TRANSFERENCIA,
    ADJUNTAR: true,
  },
  VALE_VISTA: {
    DB_ID: PaymentMethodEnum.VALE_VISTA,
    ADJUNTAR: true,
  },
  CHEQUE: {
    DB_ID: PaymentMethodEnum.CHEQUE,
    ADJUNTAR: true,
  },
  FLOTA: {
    DB_ID: PaymentMethodEnum.FLOTA,
    ADJUNTAR: true,
  },
  EFECTIVO: {
    DB_ID: PaymentMethodEnum.EFECTIVO,
    ADJUNTAR: false,
  },
};

export const COLORS = {
  primary: '#000000',
  secondary: '#B2002B',
  tertiary: '#EFEFEF',
  quaternary: '#7F0B12',
  quintinary: '#8B8B8B',
  sextenary: '#595959',
  sidebar: '#DDDDDD',
};

export const BUSINESSTYPE = {
  NEW: 'nuevos',
  USED: 'usados',
  PARTS: 'repuestos',
  FREEZONE: 'zona-franca',
};

export const TABS = {
  CHARGE_OPERATIONS: 'carga_de_archivo',
  HISTORY_CHARGE: 'historial_de_carga',
  CHARGE_ICAR: 'carga_icar',
  CHARGE_CREDIT_NOTES: 'carga_notas_credito',
};

export const CHARGETYPE = {
  OPERATION: 'operaciones',
  CREDIT_NOTES: 'notas_credito',
};

export const OPERATION_TAKEN = {
  CREDIT_LINE: 0, // NORMAL
  OVERDRAFT: 1, // SOBREGIRO
  OUTDRAFT: 2, // EXCESO
};

export const OPERATION_TAKEN_TRANSFORM = {
  CREDIT_LINE: false,
  OVERDRAFT: true,
};
