import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import ErrorUsados from '../../commons/ErrorUsados';
import useStyles from './OtorgadasUsados.style';
import NotFoundUsados from '../../commons/NotFoundUsados';
import { Loading } from '../../../common/loading';
import ItemOtorgadosUsados from './ItemOtorgadosUsados';
import { UsedGrantedByDealer } from '../../../../models/used';

const OtorgadasUsados: React.FC = () => {
  const classes = useStyles();

  const url = `/api/granteds`;
  const { status, data: usedGrantedByDealers, error } = useFetch<UsedGrantedByDealer[]>(url);

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          usedGrantedByDealers?.map((usedGrantedByDealer) => {
            return (
              <ItemOtorgadosUsados
                key={usedGrantedByDealer.ClientDealerId}
                usedGrantedByDealer={usedGrantedByDealer}
              />
            );
          })}
        {status === 'fetched' && usedGrantedByDealers?.length === 0 && (
          <NotFoundUsados message="No existen operaciones otorgadas para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default OtorgadasUsados;
