import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    businessTypeScreen: {
      position: 'fixed',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      overflow: 'auto',
    },
    mainContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      padding: '40px',
    },
    header: {
      background: theme.palette.credinissanHeaderBackground.default,
    },
    selectBox: {
      height: '630px',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0, 0, 0.16),0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '800px',
      padding: '10px',
    },
    btn: {
      height: '44px',
      textTransform: 'initial',
      backgroundColor: COLORS.secondary,
      color: '#ffffff',
      cursor: 'pointer',
    },
    imgCar: {
      width: 'auto',
      height: '90px',
    },
    divider: {
      width: '87%',
      height: '2px',
    },
    selectLogo: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: '30px',
    },

    selectTitle: {
      color: '#ffffff',
      marginBottom: '35px',
    },
    title: {
      display: 'block',
      color: COLORS.primary,
      fontSize: '24px',
      fontWeight: 'bold',
    },
    subtitle: {
      display: 'block',
      color: COLORS.quintinary,
      fontSize: '16px',
    },
    titleCar: {
      margin: '30px',
    },
    wrapperTitleImg: {
      display: 'flex',
      width: '70%',
      alignItems: 'center',
    },
    select: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '150px',
      width: '100%',
      padding: '0px 50px 0px 50px',
    },
  })
);

export default useStyles;
