import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import DetailGrantedSpareParts from '../../../../components/spareParts/granted/DetailGrantedSpareParts';
import useStyles from './DetailGrantedSparePartsPage.style';

type Params = { id: string };

const DetailGrantedSparePartsPage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState('');

  const changeTitle = (t: string) => {
    setTitle(t);
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Title title={title} goBackButton />
          </Grid>
        </Grid>
      </Grid>

      <DetailGrantedSpareParts id={match.params.id} changeTitle={changeTitle} />
    </MainContainer>
  );
};

export default DetailGrantedSparePartsPage;
