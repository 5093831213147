import React, { Fragment } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const MdlItemTransferencia = (props: any) => {
  const { classes, banco, handleChangeSlcBanco, banks, noSelected } = props;

  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <label style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 17 }}>
          Elija cuenta con la cual fue registrada la transferencia
        </label>
        <Select
          value={banco}
          onChange={handleChangeSlcBanco}
          style={{ minWidth: '233px' }}
          displayEmpty
          id="slcBancoTransferencia"
        >
          <MenuItem disabled value={noSelected}>
            <em>{'Seleccione Cuenta'}</em>
          </MenuItem>
          {banks.map((banco: any, index: number) => {
            return (
              <MenuItem value={banco.BankAccountId} key={`medioPagoOptBanco${index}`}>
                {`${banco.BankName} - ${banco.BankAccountNumber}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default MdlItemTransferencia;
