import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Title from '../../../common/title';
import TitleDetails from '../../../common/titledetails';

import Operaciones from './operaciones';
import Detalles from './detalles';

import { IParamsTitleDetails } from '../../../../interfaces';

import MainContainer from '../../../maincontainer';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps {}

const ViewOtorgadas = (props: IProps) => {
  const classes = useStyles();
  const [changeToDetails, setChangeToDetails] = useState(false);
  const [dealer, setDealer] = useState('');
  const [clientDealerID, setClientDealerID] = useState('');

  const handleShowDetailsOtorgadas = (params: IParamsTitleDetails) => {
    setChangeToDetails(params.visible);
    setDealer(params.text);
    setClientDealerID(params.id);
  };

  const onShowTitle = () => {
    if (!changeToDetails) {
      return <Title title="Otorgadas" mostrarTabOperaciones={true} tabSelected="otorgadas" />;
    }

    return <TitleDetails title={dealer} onShowDetails={handleShowDetailsOtorgadas} />;
  };

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!changeToDetails && <Operaciones onShowDetails={handleShowDetailsOtorgadas} />}
            {changeToDetails && <Detalles clientDealerId={clientDealerID} />}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default ViewOtorgadas;
