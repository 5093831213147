import { Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import TitleDetails from '../../../../components/common/titledetails';
import DetailChargeOperationFreeZone from '../../../../components/freeZone/charge/detailChargeOperationFreeZone';
import MainContainer from '../../../../components/maincontainer';
import useStyles from './DetailChargeOperationFreeZonePage.style';

type Params = { id: string; UploadAt: string };

const DetailChargeOperationFreeZonePage: React.FC<RouteComponentProps<Params>> = (props) => {
  const {
    match: {
      params: { id, UploadAt },
    },
  } = props;

  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'historial') {
      props.history.push('/zona-franca/carga-operaciones/historial');
    } else {
      props.history.push('/zona-franca/carga-operaciones');
    }
  };

  const handleGoBack = () => {
    props.history.goBack();
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <TitleDetails title={UploadAt} onShowDetails={handleGoBack} />
          </Grid>
        </Grid>

        <Grid container className={classes.tabNavigation}>
          <Grid item sm={2} className={classes.tab} onClick={handleClickOnChangeTab}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
            Historial
          </Grid>
        </Grid>
      </Grid>

      <DetailChargeOperationFreeZone id={id} />
    </MainContainer>
  );
};

export default DetailChargeOperationFreeZonePage;
