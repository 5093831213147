import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
      overflowWrap: 'anywhere',
    },
    box: {
      backgroundColor: 'white',
      fontFamily: 'Nunito Sans',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      boxShadow:
        '0 2px 4px 0 rgba(255,0,0,0.46), 0px 6px 6px 6px rgba(255,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
      color: 'black',

      '& h1': {
        color: 'red',
        margin: '0px',
      },
    },
  })
);

export default useStyles;
