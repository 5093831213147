import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '20px 20px 0px 20px',
      height: 'calc(100vh - 248px)',
      overflowY: 'auto',
    },
    table: {
      height: '100%',
    },
    contenedor: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#666666',
      fontSize: '12px',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'calc((100vh - 248px)/7.5)',
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      flexWrap: 'nowrap',
      marginBottom: '1.5em',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    },
    celda: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: 16,
    },
    celdaUnoTitulo: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
      height: 30,
      fontFamily: 'Nunito Sans',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    titulo: {
      fontWeight: 'bold',
    },
    link: {
      color: '#666666',
      fontSize: 16,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

export default useStyles;
