import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Icons from '../../../../../common/icons';
import NotFound from '../../../../../common/notfound';
import UploadFile from '../../../../../common/uploadfile';

import { IDataRequestedOperations, IRequestedBillingRequest } from '../../../../../../interfaces';

import {
  uploadSetTannerBilling,
  downloadEnthernetFile,
} from '../../../../../../services/file-manager-service';

import { downloadFiles } from '../../../../../../utilities/download-files';
import { Loading } from '../../../../../common/loading';
import { COLORS } from '../../../../../../constants';

/** region style */
const botonSolicitudStylesObj = {
  background: COLORS.secondary,
  lineHeight: '22px',
  color: '#FFFFFF',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sinDatosContainer: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 101,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    cajaContainer: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 72,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    titleContainer: {
      height: 72,
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #979797',
    },
    textContainer: {
      textAlign: 'center',
    },
    text: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 20,
    },
    dealer: {
      fontFamily: 'Nunito Sans',
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
    },
    detailsContainer: {
      height: 'auto',
      minHeight: 62,
      display: 'flex',
      alignItems: 'center',
    },
    detailsBoxContainer: {
      height: 62,
    },
    botonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    botonDescargaEnternet: {
      fontFamily: 'Nunito Sans',
      width: 246,
      height: 36,
      textTransform: 'none',
      fontSize: 16,
      textAlign: 'center',
      ...botonSolicitudStylesObj,
    },
    botonIconDescargaEnternet: {
      marginRight: 10,
    },
    info: {
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      color: '#666666',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    infoResult: {
      fontWeight: 'bold',
      marginLeft: 2,
      textTransform: 'uppercase',
    },
  })
);
/** endregion */

interface IProps {
  dataOperaciones: Array<IDataRequestedOperations>;
  loading: boolean;
  onRefresh: () => void;
}

const Solicitadas = (props: IProps) => {
  const classes = useStyles();

  const { dataOperaciones, loading, onRefresh } = props;

  const [dataSolicitadas, setDataSolicitadas] = useState([] as Array<IDataRequestedOperations>);
  const [existeDataSolicitadas, setExisteDataSolicitada] = useState(false);

  const handleExpandedContainerClick = async (billingRequestId: string) => {
    downloadEnthernetFile(billingRequestId).then((response) => {
      var date = new Date();
      var dia = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();

      downloadFiles(response.data, `${billingRequestId}_${dia}`, 'csv');

      onRefresh();
    });
  };

  const handleFunctionLoadFile = async (params: FormData, key: any) => {
    return await uploadSetTannerBilling(params, { billingRequestId: key });
  };

  useEffect(() => {
    setDataSolicitadas(dataOperaciones);
    setExisteDataSolicitada(dataOperaciones.length > 0);
  });

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        {!loading && (
          <div>
            {!existeDataSolicitadas && (
              <NotFound message="Selecciona al menos un dealer para crear una solicitud" />
            )}
            {existeDataSolicitadas && (
              <div>
                <Grid item>
                  {dataSolicitadas.map((data: IDataRequestedOperations, index: number) => {
                    return (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        key={index}
                        className={classes.cajaContainer}
                      >
                        <Grid item className={classes.titleContainer}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                          >
                            <Grid item className={classes.dealer}>
                              {data.DealerName}
                            </Grid>
                          </Grid>
                        </Grid>
                        {data.BillingRequests.map(
                          (solicitud: IRequestedBillingRequest, counter: number) => {
                            return (
                              <Grid
                                item
                                key={counter}
                                className={classes.detailsContainer}
                                style={
                                  counter !== 0
                                    ? {
                                        borderTop: '1px solid #E0E0E0',
                                      }
                                    : {}
                                }
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  className={classes.info}
                                >
                                  <Grid item md={12}>
                                    <Grid
                                      container
                                      direction="column"
                                      justifyContent="flex-start"
                                      alignItems="stretch"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="flex-start"
                                          alignItems="center"
                                          className={classes.detailsBoxContainer}
                                        >
                                          <Grid item sm={5}>
                                            Número solicitud:{' '}
                                            <span className={classes.infoResult}>
                                              {solicitud.BillingRequestId}
                                            </span>
                                          </Grid>
                                          <Grid item sm={4}>
                                            Facturas asociadas:{' '}
                                            <span className={classes.infoResult}>
                                              {solicitud.PendingOperationQuantity}
                                            </span>
                                          </Grid>
                                          <Grid item sm={3} className={classes.botonContainer}>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              onClick={() => {
                                                handleExpandedContainerClick(
                                                  solicitud.BillingRequestId
                                                );
                                              }}
                                              className={classes.botonDescargaEnternet}
                                            >
                                              <Icons
                                                name="document"
                                                size="24px"
                                                cssClassName={classes.botonIconDescargaEnternet}
                                              />
                                              Descargar Excel Enternet
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {solicitud.DownloadRequestDate !== null && (
                                        <Grid item>
                                          <UploadFile
                                            key={solicitud.BillingRequestId}
                                            formato=".csv"
                                            showfooter={true}
                                            uploadFile={handleFunctionLoadFile}
                                            loadComponent="LoadpayrollEthernet"
                                            ids={solicitud.BillingRequestId}
                                            dateDownload={solicitud.DownloadRequestDate}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
          </div>
        )}
      </Grid>
      {loading && <Loading />}
    </>
  );
};

export default Solicitadas;
