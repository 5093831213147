import { Button, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { PaymentsPendingConfirmation } from '../../../../models/commons';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './ConfirmPendingSpareParts.style';
import ModalConfirmPendingSpareParts from './ModalConfirmPendingSpareParts';

type Props = {
  pending: PaymentsPendingConfirmation;
  reload: () => void;
};

const ItemConfirmPendingSpareParts: React.FC<Props> = (props) => {
  const { pending, reload } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isRejected, setIsRejected] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectedClick = (isRej: boolean) => {
    setIsRejected(isRej);
    setOpen(true);
  };

  return (
    <>
      <Grid container item xs={12} className={classes.itemContainer}>
        <Grid container alignItems="flex-start" item xs={3} className={classes.columnDealer}>
          <div style={{ fontSize: '1.4rem' }}>{pending.DealerName}</div>
          <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
            {rutFormater(`${pending.DealerRut}-${pending.VerificationNumber}`)}
          </div>
        </Grid>
        <Grid item xs={4} className={classes.coluymnPaymentId}>
          {pending.PaymentId}
        </Grid>
        <Grid item xs={2} className={classes.columnInvoices}>
          {pending.Invoices}
        </Grid>
        <Grid container item xs={3}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              className={classes.btnPagado}
              startIcon={<CheckCircleIcon />}
              onClick={() => handleRejectedClick(false)}
            >
              PAGADO
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button
              variant="contained"
              className={classes.btnRechazar}
              startIcon={<CancelIcon />}
              onClick={() => handleRejectedClick(true)}
            >
              RECHAZAR PAGO
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ModalConfirmPendingSpareParts
        paymentId={pending.PaymentId}
        isRejected={isRejected}
        open={open}
        handleClose={handleClose}
        reload={reload}
      />
    </>
  );
};

export default ItemConfirmPendingSpareParts;
