import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants';

/** region style */
const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      borderBottom: '1px solid #FFFFFF',
    },
    tab: {
      height: 48,
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      height: 48,
      textTransform: 'uppercase',
      cursor: 'default',
      background: '#FFFFFF',
      color: COLORS.primary,
      ...tabStylesObj,
    },
  })
);
/** endregion */

const TABS = {
  OTORGADAS: 'otorgadas',
  PENDIENTES_DE_FACTURACION: 'pendientes_de_facturacion',
  FACTURADO: 'facturado',
  PENDIENTES_DE_CONFIRMACION: 'pendientes_de_confirmacion',
};

interface IProps extends RouteComponentProps {
  tabSelected: string;
}

const TabMenuOtorgadas = (props: IProps) => {
  const classes = useStyles();

  const { tabSelected } = props;

  const handleTabOtorgadasClick = () => {
    props.history.push('/nuevos/otorgadas');
  };

  const handleTabPendientesDeFacturacionClick = () => {
    props.history.push('/nuevos/otorgadas/pedientes-de-facturacion');
  };

  const handleTabFacturadoClick = () => {
    props.history.push('/nuevos/otorgadas/facturado');
  };

  const handleTabPendientesDeConfirmacionClick = () => {
    props.history.push('/nuevos/otorgadas/pedientes-de-confirmacion');
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.tabContainer}
    >
      <Grid
        item
        sm={2}
        onClick={handleTabOtorgadasClick}
        className={tabSelected === TABS.OTORGADAS ? classes.tabSelected : classes.tab}
      >
        Otorgadas
      </Grid>
      <Grid
        item
        sm={4}
        onClick={handleTabPendientesDeFacturacionClick}
        className={
          tabSelected === TABS.PENDIENTES_DE_FACTURACION ? classes.tabSelected : classes.tab
        }
      >
        Pendientes de Facturación
      </Grid>
      <Grid
        item
        sm={2}
        onClick={handleTabFacturadoClick}
        className={tabSelected === TABS.FACTURADO ? classes.tabSelected : classes.tab}
      >
        Facturado
      </Grid>
      <Grid
        item
        sm={4}
        onClick={handleTabPendientesDeConfirmacionClick}
        className={
          tabSelected === TABS.PENDIENTES_DE_CONFIRMACION ? classes.tabSelected : classes.tab
        }
      >
        Pendientes de ConfirmacIón
      </Grid>
    </Grid>
  );
};

export default withRouter(TabMenuOtorgadas);
