import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import { Loading } from '../../../common/loading';
import Error from '../../../common/Error';
import useStyles, { StyledTableCell } from './DetailGrantedFreeZoneOperation.style';
import config from '../../../../config/api';
import { FreeZoneGrantedOperationDetail } from '../../../../models/freeZone/FreeZoneGrantedOperationDetail';

type Props = {
  id: string;
  changeTitle: (t: string) => void;
};

const DetailGrantedFreeZoneOperation: React.FC<Props> = (props) => {
  const { id, changeTitle } = props;
  const classes = useStyles();

  const url = `${config.freeZoneSettings.apiUri}/api/granteds/clientDealer/${id}`;
  const {
    status,
    data: grantedOperationsDetails,
    error,
  } = useFetch<FreeZoneGrantedOperationDetail[]>(url);

  React.useEffect(() => {
    if (status === 'fetched') {
      changeTitle(grantedOperationsDetails?.[0].DealerName ?? '');
    }
  }, [status, grantedOperationsDetails, changeTitle]);

  if (status === 'error' && error)
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell align="center">Días transcurridos</StyledTableCell>
                      <StyledTableCell align="center">VIN</StyledTableCell>
                      <StyledTableCell align="center">Número de factura</StyledTableCell>
                      <StyledTableCell align="center">Fecha de emisión</StyledTableCell>
                      <StyledTableCell align="center">Estado de inventario</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {grantedOperationsDetails?.map((row) => (
                      <TableRow key={`${row.ClientDealerId}-${row.InvoiceNumber}`}>
                        <TableCell align="center">{row.PassedDays}</TableCell>
                        <TableCell align="center">{row.Vin}</TableCell>
                        <TableCell align="center">{row.InvoiceNumber}</TableCell>
                        <TableCell align="center">{row.InvoiceDate}</TableCell>
                        <TableCell align="center">{row.StockState}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailGrantedFreeZoneOperation;
