import React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import useStyles from './GrantedSpareParts.style';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import GrantedSpareParts from '../../../../components/spareParts/granted/GrantedSpareParts/GrantedSpareParts';

const GrantedSparePartsPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'otorgadas') props.history.push('/repuestos/otorgadas');
    else if (valueClicked === 'aplicación de pago')
      props.history.push('/repuestos/otorgadas/aplicacion-pago');
    else props.history.push('/repuestos/otorgadas/pendientes-confirmacion');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Otorgadas">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tabSelected}>
                OTORGADAS
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickOnChangeTab}>
                APLICACIÓN DE PAGO
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickOnChangeTab}>
                PENDIENTES CONFIRMACIÓN
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>

      <GrantedSpareParts />
    </MainContainer>
  );
};

export default GrantedSparePartsPage;
