import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ButtonIconText from '../../common/bottonIconText';

import { getOperationTakenDealer } from '../../../services/file-manager-service';
import { getObjDateLocal } from '../../../utilities/dates-functions';

import { downloadFiles } from '../../../utilities/download-files';

/** region style */
const useStyles = makeStyles((theme: Theme) => createStyles({}));
/** endregion */

interface IProps {}

const DownloadCursadas = (props: IProps) => {
  const classes = useStyles();

  const handleGetOperationTakenDealer = async () => {
    getOperationTakenDealer().then((response) => {
      const fecha = getObjDateLocal(undefined, undefined, 'yyyyLLdd', 'HHmm');
      downloadFiles(response.data, `OperacionesCursadas_${fecha.date}_${fecha.time}`, 'xlsx');
    });
  };

  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
      <Grid
        item
        md={12}
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <span style={{ fontSize: 12, fontWeight: 'normal' }}>Descarga operaciones cursadas</span>

        <ButtonIconText
          // buttonBackgroundColor={""}
          // buttonColor={""}
          // buttonHeight={0}
          // buttonWidth={0}
          buttonKey={'botondescargarcursadas'}
          buttonMarginLeft={10}
          // fontFamily={""}
          // fontSize={0}
          iconName={'file-download'}
          iconSize={14}
          label={'Descargar Excel'}
          objHandleEvent={{
            execute: handleGetOperationTakenDealer,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DownloadCursadas;
