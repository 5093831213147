export type FetchConfig = {
  method?:
    | 'get'
    | 'GET'
    | 'delete'
    | 'DELETE'
    | 'options'
    | 'OPTIONS'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH';
  withFatalError?: boolean;
  withAuth?: boolean;
  contentType?: 'application/json' | 'multipart/form-data';
  fileName?: string;
} & (
  | typeof defaultFetchConfig
  | typeof defaultPostFileConfig
  | typeof defaultPostConfig
  | typeof defaultPutConfig
);

export const defaultFetchConfig = {
  method: 'GET' as const,
  withFatalError: true,
  withAuth: true,
  contentType: 'application/json' as const,
  fileName: '',
};

export const defaultPostFileConfig = {
  contentType: 'multipart/form-data' as const,
  method: 'POST' as const,
  withAuth: true,
  withFatalError: true,
  fileName: '',
};

export const defaultPostConfig = {
  contentType: 'application/json' as const,
  method: 'POST' as const,
  withAuth: true,
  withFatalError: true,
  fileName: '',
};

export const defaultPutConfig = {
  contentType: 'application/json' as const,
  method: 'PUT' as const,
  withAuth: true,
  withFatalError: true,
  fileName: '',
};
