import React from 'react';
import { useStyles } from './NotFoundUsados.style';

type NotFoundProps = {
  message: string;
};

const NotFoundUsados: React.FC<NotFoundProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default NotFoundUsados;
