import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import Icons from '../../../common/icons';
import { paymentServices } from '../../../../services/paymentServices';
import { withRouter } from 'react-router-dom';
import { COLORS } from '../../../../constants';
/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenttitle: {
      height: 75,
    },
    title: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      width: 536,
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    contentbody: {
      height: 69,
    },
    contentbotones: {
      height: 60,
      padding: '0 24px',
    },
    botonCancelar: {
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
    botonAceptar: {
      width: 224,
      height: 36,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 16,
    },
    Icon: {
      marginRight: 10,
    },
  })
);
/** endregion */

interface IProps {
  open: boolean;
  paymentId: string;
  onClose: (value: boolean) => void;
  onHandleAceptedPayment: (Id: string) => void;
}

const ModalAceptarPago = (props: any) => {
  const classes = useStyles();
  const { open, paymentId, onClose, acceptPending } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleAccept = (paymentId: string) => {
    onClose(false);
    acceptPending(paymentId);
  };

  return (
    <Dialog
      id={'mdlToRefusePayment'}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.contenttitle}>
        <div className={classes.title}>¿Desea realizar el pago?</div>
      </DialogTitle>
      <DialogContent className={classes.contentbody}>
        <Grid container className={classes.subtitle}>
          <Grid item sm={12}>
            Los VIN asociados a la solicitud <span style={{ fontWeight: 'bold' }}>{paymentId}</span>{' '}
            quedarán en estado Pagado.
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.contentbotones}>
        <Button
          color="primary"
          id={'btnMdlCancelPayment'}
          className={classes.botonCancelar}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          id={'btnMdlToRefusePayment'}
          size="small"
          className={classes.botonAceptar}
          onClick={() => {
            handleAccept(paymentId);
          }}
        >
          <Icons name="subtract1" size="26px" cssClassName={classes.Icon} />
          Sí, deseo aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(ModalAceptarPago);
