import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';

import useStyles from './DetalleHistorialPagoUsadosPage.style';
import MainContainer from '../../../../components/maincontainer';
import TituloDetallesUsados from '../../../../components/used/commons/TituloDetallesUsados';
import DetalleHistorialPagoUsados from '../../../../components/used/paymentPending/DetalleHistorialPagoUsados';
import { useAuth } from '../../../../components/common/auth';

type Params = { id: string };

const DetalleHistorialPagoUsadosPage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;
  const classes = useStyles();

  const { titlePaymentDetail } = useAuth();
  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <TituloDetallesUsados title={titlePaymentDetail} />
          </Grid>
        </Grid>
      </Grid>

      <DetalleHistorialPagoUsados id={match.params.id} />
    </MainContainer>
  );
};

export default DetalleHistorialPagoUsadosPage;
