import React from 'react';
import { Grid } from '@material-ui/core';
import { Loading } from '../../../common/loading';
import { ItemDetalleLineaUsados } from './ItemDetalleLineaUsados';
import { UsedDetailLine } from '../../../../models/used';
import { useFetch } from '../../../../hooks/useFetch';
import ErrorUsados from '../../commons/ErrorUsados';
import useStyles from './DetallesLineasUsados.style';
import NotFoundUsados from '../../commons/NotFoundUsados';

export const DetallesLineasUsados: React.FC = () => {
  const classes = useStyles();

  const url = `/api/dealer/creditLine`;
  const { status, data: usedDetailLines, error } = useFetch<UsedDetailLine[]>(url);

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          usedDetailLines?.map((line) => {
            return <ItemDetalleLineaUsados key={line.CreditLineId} usedDetailLine={line} />;
          })}
        {status === 'fetched' && usedDetailLines?.length === 0 && (
          <NotFoundUsados message="No existen líneas de créditos para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};
