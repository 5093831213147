import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Formik, Form, FormikProps, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useStyles from './DetalleAplicarPagoUsados.style';
import { useFetch } from '../../../../hooks/useFetch';
import AlertUsados from '../../commons/AlertUsados';
import {
  CheckPaymentDetail,
  CreatePaymentRequest,
  FleetPaymentDetail,
  TannerCreditPaymentDetail,
  TransferPaymentDetail,
  VoucherPaymentDetail,
} from '../../../../models/used';
import { SuccessApiResponse } from '../../../../models/SuccessApiResponse';
import { PaymentMethodTypes } from '../../../../enums';
import { post } from '../../../../utilities/requestsUsed';
import { fileToBase64 } from '../../../../utilities/fileHelpers';
import { useAuth } from '../../../common/auth';
import { BankAccountsByClientResponse } from '../../../../models/commons';

type Props = {
  open: boolean;
  handleClose: () => void;
  usedOperationIds: string[];
  clientDealerId: string;
  reload: () => void;
};

type PaymentMethod = {
  Id: string;
  Name: string;
};

type Bank = {
  Id: string;
  Name: string;
};

type FormValues = {
  method: string;
  paymentDate?: Date | null;
  requestDate: Date | null;
  expirationDate: Date | null;
  documentNumber: number;
  warrantyNumber: number;
  bank: string;
  warrantyBank: string;
  creditNumber: number;
  transferAccount: string;
  file?: File | null;
};

const ModalDetalleAplicarPagoUsados: React.FC<Props> = ({
  open,
  handleClose,
  usedOperationIds,
  clientDealerId,
  reload,
}) => {
  const classes = useStyles();

  const { setShowNotification } = useAuth();

  const urlPayments = `/api/payments/methods`;
  const {
    status: statusPayments,
    data: paymentMethods,
    error,
  } = useFetch<PaymentMethod[]>(urlPayments);

  const urlBanks = `/api/payments/banks`;
  const { status: statusBanks, data: banks } = useFetch<Bank[]>(urlBanks);

  const urlAccounts = `/api/client/bankAccounts`;
  const { status: statusAccounts, data: bankAccounts } =
    useFetch<BankAccountsByClientResponse[]>(urlAccounts);

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(true);

    const createPaymentRequest: CreatePaymentRequest = {
      PaymentMethodId: values.method,
      ClientDealerId: clientDealerId.split(':')[1],
      PaymentDate: values.paymentDate ?? new Date(),
      UsedOperationIds: [...usedOperationIds],
    };
    if (values.file) {
      const file = await fileToBase64(values.file);
      createPaymentRequest.AttachmentFile = {
        ContentType: values.file.type,
        FileContent: file.split('base64,')[1],
      };
    }
    if (values.method === PaymentMethodTypes.ValeVista) {
      createPaymentRequest.VoucherPaymentDetail = {
        BankId: values.bank,
        DocumentNumber: values.documentNumber,
      } as VoucherPaymentDetail;
    } else if (values.method === PaymentMethodTypes.CreditoTanner) {
      createPaymentRequest.TannerCreditPaymentDetail = {
        TannerCreditNumber: values.creditNumber,
      } as TannerCreditPaymentDetail;
    } else if (values.method === PaymentMethodTypes.Transferencia) {
      createPaymentRequest.TransferPaymentDetail = {
        BankAccountId: values.transferAccount,
      } as TransferPaymentDetail;
    } else if (values.method === PaymentMethodTypes.Cheque) {
      createPaymentRequest.CheckPaymentDetail = {
        BankId: values.bank,
        DocumentNumber: values.documentNumber,
      } as CheckPaymentDetail;
    } else if (values.method === PaymentMethodTypes.Flota) {
      createPaymentRequest.FleetPaymentDetail = {
        RequestDate: values.requestDate,
        ExpirationDate: values.expirationDate,
        WarrantyBankId: values.warrantyBank,
        WarrantyNumber: values.warrantyNumber,
      } as FleetPaymentDetail;
    }

    const response = await post<SuccessApiResponse>('/api/payments', createPaymentRequest);
    let message = '';
    if (response.status === 'success') {
      message = response.data?.message ?? 'Pago aplicado correctamente.';
      reload();
    } else {
      message =
        response.error?.Message !== undefined
          ? `Error ${response.error?.ErrorId}: ${response.error?.Message}`
          : 'Ha ocurrido un error realizando el pago.';
    }
    setShowNotification({
      show: true,
      variant: response.status === 'success' ? 'success' : 'error',
      message,
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <>
        <DialogTitle id="responsive-dialog-title">{`Aplicar medio de pago a ${usedOperationIds.length} VIN seleccionados`}</DialogTitle>
        <DialogContent>
          {statusPayments === 'error' && <AlertUsados show type="error" text={error?.Message} />}
          <Formik
            initialValues={
              {
                method: '',
                paymentDate: null,
                requestDate: null,
                expirationDate: null,
                documentNumber: 0,
                warrantyNumber: 0,
                bank: '',
                warrantyBank: '',
                creditNumber: 0,
                transferAccount: '',
                file: null,
              } as FormValues
            }
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              method: Yup.mixed<PaymentMethodTypes>()
                .oneOf(Object.values(PaymentMethodTypes), 'Debe seleccionar un medio de pago.')
                .required('Debe seleccionar un medio de pago.'),
              paymentDate: Yup.date().required('Debe seleccionar una fecha de pago.'),
              requestDate: Yup.date().when('method', (method: string) => {
                if (method === PaymentMethodTypes.Flota)
                  return Yup.date().required('Debe introducir la fecha de la solicitud.');
                return Yup.mixed();
              }),
              expirationDate: Yup.date().when(
                ['method', 'requestDate'],
                (method: string, requestDate: unknown) => {
                  if (
                    method === PaymentMethodTypes.Flota &&
                    Number.isNaN((requestDate as Date).getTime())
                  ) {
                    return Yup.mixed().required('Debe introducir la fecha de vencimiento.');
                  }
                  if (method === PaymentMethodTypes.Flota && requestDate != null) {
                    return Yup.date()
                      .required('Debe introducir la fecha de vencimiento.')
                      .min(
                        requestDate,
                        'La fecha de vencimiento no puede ser anterior a la de solicitud'
                      );
                  }
                  return Yup.mixed();
                }
              ),
              creditNumber: Yup.number().when('method', (method: string) => {
                if (method === PaymentMethodTypes.CreditoTanner) {
                  return Yup.number()
                    .integer()
                    .min(1, 'Debe introducir un número de crédito Tanner.')
                    .required('Debe introducir el número de crédito Tanner.');
                }
                return Yup.mixed();
              }),
              transferAccount: Yup.string().when('method', (method: string) => {
                if (method === PaymentMethodTypes.Transferencia) {
                  return Yup.string().required('Debe seleccionar una cuenta de transferencia.');
                }
                return Yup.mixed();
              }),
              bank: Yup.string().when('method', (method: string) => {
                if (
                  method === PaymentMethodTypes.ValeVista ||
                  method === PaymentMethodTypes.Cheque
                ) {
                  return Yup.string().required('Debe seleccionar un banco.');
                }
                return Yup.mixed();
              }),
              warrantyBank: Yup.string().when('method', (method: string) => {
                if (method === PaymentMethodTypes.Flota) {
                  return Yup.string().required('Debe seleccionar un banco de garantía.');
                }
                return Yup.mixed();
              }),
              warrantyNumber: Yup.number().when('method', (method: string) => {
                if (method === PaymentMethodTypes.Flota) {
                  return Yup.number()
                    .integer()
                    .min(1, 'Debe introducir un número de garantía.')
                    .required('Debe introducir el número de garantía.');
                }
                return Yup.mixed();
              }),
              documentNumber: Yup.number().when('method', (method: string) => {
                if (
                  method === PaymentMethodTypes.ValeVista ||
                  method === PaymentMethodTypes.Cheque
                ) {
                  return Yup.number()
                    .integer()
                    .min(1, 'Debe introducir un número de documento.')
                    .required('Debe introducir el número de documento.');
                }
                return Yup.mixed();
              }),
              file: Yup.mixed().when('method', (method: string) => {
                if (method === PaymentMethodTypes.Efectivo) {
                  return Yup.mixed();
                }
                // TODO: Preguntar si el documento adjunto es requerido
                return Yup.mixed();
              }),
            })}
          >
            {(props: FormikProps<FormValues>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <Grid container spacing={3} direction="column">
                    <Grid item>
                      <Field
                        component={TextField}
                        type="text"
                        name="method"
                        error={errors.method && touched.method}
                        variant="standard"
                        value={values.method}
                        select
                        label="Medio de pago"
                        onChange={handleChange('method')}
                        className={classes.formSelect}
                        helperText={errors.method && touched.method ? errors.method : ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      >
                        <MenuItem value="" disabled>
                          {statusPayments === 'fetched' && paymentMethods?.length === 0
                            ? 'No existen medios de pago disponibles'
                            : 'Seleccione el medio de pago'}
                        </MenuItem>
                        {paymentMethods?.map((option) => (
                          <MenuItem key={option.Id} value={option.Id.toUpperCase()}>
                            {option.Name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item xs={12} md={6}>
                        {Object.values(PaymentMethodTypes).includes(
                          values.method as PaymentMethodTypes
                        ) && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              placeholder=""
                              fullWidth
                              format="dd/MM/yyyy"
                              error={!!(errors.paymentDate && touched.paymentDate)}
                              name="paymentDate"
                              onChange={(value) => setFieldValue('paymentDate', value)}
                              value={values.paymentDate}
                              label="Fecha de pago"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {values.method === PaymentMethodTypes.CreditoTanner && (
                          <Field
                            component={TextField}
                            name="creditNumber"
                            type="number"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            error={errors.creditNumber && touched.creditNumber}
                            label="Número de crédito Tanner"
                            value={values.creditNumber}
                            onChange={handleChange('creditNumber')}
                            onBlur={handleBlur('creditNumber')}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {values.method === PaymentMethodTypes.Transferencia && (
                      <Grid container item alignItems="flex-end" spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            name="transferAccount"
                            select
                            error={errors.transferAccount && touched.transferAccount}
                            label="Cuenta transferencia"
                            fullWidth
                            value={values.transferAccount}
                            onChange={handleChange('transferAccount')}
                            className={clsx(classes.margin, classes.textField)}
                          >
                            <MenuItem value="" disabled>
                              {statusAccounts === 'fetched' && bankAccounts?.length === 0
                                ? 'No existen cuentas bancarias disponibles'
                                : 'Seleccione la cuenta bancaria'}
                            </MenuItem>
                            {statusAccounts === 'fetched' &&
                              bankAccounts?.map((account) => (
                                <MenuItem
                                  key={account.BankAccountId}
                                  value={account.BankAccountId.toUpperCase()}
                                >
                                  {`${account.BankName} - ${account.BankAccoutNumber}`}
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                      </Grid>
                    )}
                    {values.method === PaymentMethodTypes.Flota && (
                      <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container item spacing={2}>
                            <Grid item xs={12} md={6}>
                              <KeyboardDatePicker
                                placeholder=""
                                fullWidth
                                format="dd/MM/yyyy"
                                name="requestDate"
                                error={!!(errors.requestDate && touched.requestDate)}
                                onChange={(value) => setFieldValue('requestDate', value)}
                                value={values.requestDate}
                                label="Fecha de la solicitud"
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <KeyboardDatePicker
                                placeholder=""
                                fullWidth
                                format="dd/MM/yyyy"
                                error={!!(errors.expirationDate && touched.expirationDate)}
                                name="expirationDate"
                                onChange={(value) => setFieldValue('expirationDate', value)}
                                value={values.expirationDate}
                                label="Fecha de vencimiento"
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid container item alignItems="flex-end" spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              name="warrantyBank"
                              select
                              label="Banco de la garantía"
                              error={errors.warrantyBank && touched.warrantyBank}
                              fullWidth
                              value={values.warrantyBank}
                              onChange={handleChange('warrantyBank')}
                              className={clsx(classes.margin, classes.textField)}
                            >
                              <MenuItem value="" disabled>
                                {statusBanks === 'fetched' && banks?.length === 0
                                  ? 'No existen bancos disponibles'
                                  : 'Seleccione el banco'}
                              </MenuItem>
                              {statusBanks === 'fetched' &&
                                banks?.map((bank) => (
                                  <MenuItem key={bank.Id} value={bank.Id.toUpperCase()}>
                                    {bank.Name}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={TextField}
                              name="warrantyNumber"
                              autoComplete="off"
                              type="number"
                              fullWidth
                              className={clsx(classes.margin, classes.textField)}
                              error={errors.warrantyNumber && touched.warrantyNumber}
                              label="Número de la garantía"
                              value={values.warrantyNumber}
                              onChange={handleChange('warrantyNumber')}
                              onBlur={handleBlur('warrantyNumber')}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container item alignItems="flex-end" spacing={2}>
                      <Grid item xs={12} md={6}>
                        {(values.method === PaymentMethodTypes.Cheque ||
                          values.method === PaymentMethodTypes.ValeVista) && (
                          <Field
                            component={TextField}
                            name="bank"
                            select
                            error={errors.bank && touched.bank}
                            label="Banco"
                            fullWidth
                            value={values.bank}
                            onChange={handleChange('bank')}
                            className={clsx(classes.margin, classes.textField)}
                          >
                            <MenuItem value="" disabled>
                              {statusBanks === 'fetched' && banks?.length === 0
                                ? 'No existen bancos disponibles'
                                : 'Seleccione el banco'}
                            </MenuItem>
                            {statusBanks === 'fetched' &&
                              banks?.map((bank) => (
                                <MenuItem key={bank.Id} value={bank.Id.toUpperCase()}>
                                  {bank.Name}
                                </MenuItem>
                              ))}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {(values.method === PaymentMethodTypes.ValeVista ||
                          values.method === PaymentMethodTypes.Cheque) && (
                          <Field
                            component={TextField}
                            name="documentNumber"
                            type="number"
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            error={errors.documentNumber && touched.documentNumber}
                            label="Número de documento"
                            value={values.documentNumber}
                            onChange={handleChange('documentNumber')}
                            onBlur={handleBlur('documentNumber')}
                          />
                        )}
                      </Grid>
                    </Grid>

                    {(values.method === PaymentMethodTypes.ValeVista ||
                      values.method === PaymentMethodTypes.CreditoTanner ||
                      values.method === PaymentMethodTypes.Transferencia ||
                      values.method === PaymentMethodTypes.Flota ||
                      values.method === PaymentMethodTypes.Cheque) && (
                      <Grid container item spacing={1} alignItems="center">
                        <Hidden xsDown>
                          <Grid item>Documento adjunto</Grid>
                        </Hidden>
                        <Grid item>
                          <label htmlFor="icon-button-file">
                            <input
                              accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                              hidden
                              id="icon-button-file"
                              type="file"
                              name="file"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue('file', event?.currentTarget?.files?.[0])
                              }
                            />
                            <Button
                              variant="outlined"
                              component="span"
                              startIcon={<AttachFileIcon />}
                              className={classes.btnUploadDoc}
                            >
                              Subir documento
                            </Button>
                          </label>
                        </Grid>
                        <Grid item>
                          <span>{values.file?.name ?? ''}</span>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<CheckCircleOutlineIcon />}
                      classes={{
                        root: classes.btnAplicarPago,
                        disabled: classes.disabled,
                      }}
                      disabled={isSubmitting}
                    >
                      Realizar pago
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default ModalDetalleAplicarPagoUsados;
