import React from 'react';
import { Button, Grid } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import useStyles from './DetalleAplicarPagoUsados.style';
import { numberToMiles } from '../../../../utilities/string-functions';
import ModalDetalleAplicarPagoUsados from './ModalDetalleAplicarPagoUsados';

type Props = {
  amount: number;
  usedOperationIds: string[];
  clientDealerId: string;
  reload: () => void;
};

const FooterDetalleAplicarPagoUsados: React.FC<Props> = ({
  amount,
  usedOperationIds,
  clientDealerId,
  reload,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container className={classes.footer} justifyContent="center" alignItems="center">
      <Grid container direction="column" item xs={4} justifyContent="center" alignItems="center">
        <Grid style={{ fontSize: '18px', fontWeight: 'bold' }}>VINs a pagar</Grid>
        <Grid style={{ fontSize: '24px', fontWeight: 'bold' }}>{usedOperationIds.length}</Grid>
      </Grid>
      <Grid container direction="column" item xs={4} justifyContent="center" alignItems="center">
        <Grid style={{ fontSize: '18px', fontWeight: 'bold' }}>Monto total a pagar</Grid>
        <Grid style={{ fontSize: '24px', fontWeight: 'bold' }}>$ {numberToMiles(amount)}</Grid>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          disabled={usedOperationIds.length === 0}
          startIcon={<PaymentIcon />}
          classes={{
            root: classes.btnAplicarPago,
            disabled: classes.disabled,
          }}
          onClick={() => setOpen(true)}
        >
          Aplicar medio de pago
        </Button>
      </Grid>
      <ModalDetalleAplicarPagoUsados
        open={open}
        handleClose={handleClose}
        usedOperationIds={usedOperationIds}
        clientDealerId={clientDealerId}
        reload={reload}
      />
    </Grid>
  );
};

export default FooterDetalleAplicarPagoUsados;
