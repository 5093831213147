export enum StorageKeys {
  BusinessType = 'BusinessType',
  ExceptionError = 'ExceptionError',
  Client = 'Client',
  UserIsLogged = 'userIsLogged',
  Token = 'token',
}

export class StorageHelper<T> {
  constructor(protected key: string, protected defaultValue?: T) {}

  protected get storage() {
    return window.localStorage;
  }

  get(): T {
    const strValue = this.storage.getItem(this.key);
    if (strValue != null) {
      try {
        return JSON.parse(strValue);
      } catch (e) {
        // continue regardless of error
      }
    }
    return this.defaultValue as T;
  }

  set(value: T) {
    this.storage.setItem(this.key, JSON.stringify(value));
    return this;
  }

  merge(value: Partial<T>) {
    const currentValue = this.get();
    return this.set(Object.assign(currentValue, value));
  }

  clear() {
    this.storage.removeItem(this.key);
    return this;
  }

  getDefaultValue() {
    return this.defaultValue;
  }

  restoreDefaultValue() {
    return this.set(this.defaultValue as T);
  }
}

export function createStorage<T>(key: string, defaultValue?: T) {
  return new StorageHelper(key, defaultValue);
}
