import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Checkbox, Button, Tooltip, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ModalCreditNote from '../../../../../common/paymentPending/detailApplyPayment/modalCreditNote';
import ModalSolicitud from './modalsolicitud';

import NotFound from '../../../../../common/notfound';
import Icons from '../../../../../common/icons';
import { numberToMiles } from '../../../../../../utilities/string-functions';
import { setRequestedOperations } from '../../../../../../services/pending-billing-operation-service';
import {
  IObjCheked,
  IOperationsChecked,
  IPendingBilling,
  IPendingBillingOperation,
  ICreditNoteDetailsByOperation,
  IBillingRequest,
  IBillingReqEntity,
} from '../../../../../../interfaces';
import Notification from '../../../../../common/notification';
import { Loading } from '../../../../../common/loading';
import { COLORS } from '../../../../../../constants';

/** region style */
const botonSolicitudStylesObj = {
  background: COLORS.secondary,
  lineHeight: '16px',
  color: '#FFFFFF',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
    },
    cabecera: {
      width: '100%',
      height: 66,
      maxWidth: '4000px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginTop: -15,
    },
    cabeceraContainer: {
      height: '100%',
      padding: '0 20px',
      fontSize: 16,
    },
    detalleContainer: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 72,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
    },
    botonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    botonSolicitud: {
      width: 220,
      height: 36,
      textTransform: 'none',
      fontSize: 16,
      textAlign: 'center',
      fontWeight: 'bold',
      ...botonSolicitudStylesObj,
    },
    botonIconSolicitud: {
      marginRight: 20,
    },
    botonSolicitudindividual: {
      width: 162,
      height: 24,
      textTransform: 'none',
      fontSize: 12,
      textAlign: 'center',
      ...botonSolicitudStylesObj,
    },
    botonIconSolicitudindividual: {
      marginRight: 10,
    },
    iconRed: {
      color: COLORS.secondary,
    },
    iconGrey: {
      color: '#979797',
    },
    paidDivider: {
      color: '#979797',
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
    },
    creditnoteenable: {
      color: COLORS.secondary,
      cursor: 'pointer',
    },
    creditnotedisable: {
      color: '#ADADAD',
    },
  })
);
/** endregion */

interface IPending {
  dataOperaciones: Array<IPendingBilling>;
  onClickTabSolicitadas: () => void;
  loading: boolean;
}

const Pendientes = (props: IPending) => {
  const classes = useStyles();

  const { dataOperaciones, onClickTabSolicitadas, loading } = props;

  const [dataPending, setDataPending] = useState<IPendingBilling[]>([]);
  const [existsDataPending, setExistsDataPending] = useState(false);

  const [objChecked, setObjCheked] = useState<IObjCheked[]>([]);

  const [dealerChecked, setDealerChecked] = useState<number[]>([]);
  const [operationChecked, setOperationChecked] = useState<string[]>([]);

  const [checkedGlobal, setCheckedGlobal] = useState(false);

  const [open, setOpen] = useState(false);
  const [dealerIdSelected, setDealerIdSelected] = useState(0);

  const [errorMsg, setErrorMsg] = useState('Existe VIN con indicador de alerta');
  const [showNotificationErrorMsg, setShowNotificationErrorMsg] = useState(false);

  const [modalNC, setModalNC] = useState(false);
  const [vin, setVin] = useState('');
  const [creditNote, setCreditNote] = useState<ICreditNoteDetailsByOperation[]>([]);

  const handleOpenModalNC = (item: IPendingBillingOperation) => {
    setVin(item.Vin);
    setCreditNote(item.CreditNote);
    setModalNC(true);
  };
  const handleCloseModalNC = () => {
    setVin('');
    setCreditNote([]);
    setModalNC(false);
  };

  const handleOpenModalClick = (dealerid: number = 0) => {
    setDealerIdSelected(dealerid);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setDealerIdSelected(0);
    setOpen(false);
  };

  const handleUpdateDataSelectedClick = async () => {
    const dataBillingRequest = await mapBillingRequest();
    await handleSetRequestedOperations(dataBillingRequest);
  };

  const mapBillingRequest = async () => {
    const billingRequest = {
      ListBillingReqEntity: await mapBillingReqEntity(),
    } as IBillingRequest;

    return billingRequest;
  };

  const mapBillingReqEntity = async () => {
    const listBillingReqEntity = [] as Array<IBillingReqEntity>;

    objChecked.map((data: IObjCheked) => {
      const objOperations = data.Operations.filter((op) => op.Checked === true);

      if (objOperations.length > 0) {
        const billingReqEntity = {
          ClientDealerId: data.ClientDealerId,
          ListOperations: objOperations.map((item) => {
            return {
              OperationId: item.OperationsID,
              HasAlert: item.HasAlert,
            };
          }),
        } as IBillingReqEntity;

        listBillingReqEntity.push(billingReqEntity);
      }
    });
    return listBillingReqEntity;
  };

  const handleSetRequestedOperations = async (billingRequest: IBillingRequest) => {
    await setRequestedOperations(billingRequest).then((response) => {
      handleCloseModal();
      onClickTabSolicitadas();
    });

    // console.log(billingRequest)
    // handleCloseModal()
  };

  const handleCheckedGlobal = () => {
    let countOperationsWithAlerts = 0;
    const newDealerChecked = [] as number[];
    const newOperationChecked = [] as string[];

    if (!checkedGlobal) {
      objChecked.map((data: IObjCheked) => {
        const objOperationsAlert = data.Operations.filter((op) => op.HasAlert === true);
        countOperationsWithAlerts = countOperationsWithAlerts + objOperationsAlert.length;
        if (objOperationsAlert.length < 1) {
          newDealerChecked.push(data.DealerID);
        }

        data.Operations.map((op: IOperationsChecked) => {
          if (!op.HasAlert) {
            newOperationChecked.push(op.OperationsID);
          }
        });
      });
    }

    if (countOperationsWithAlerts > 0) {
      setCheckedGlobal(false);
      setShowNotificationErrorMsg(true);
      setErrorMsg(`Existen ${countOperationsWithAlerts} VIN con indicador de alerta`);
    } else {
      setCheckedGlobal(checkedGlobal ? false : true);
    }

    setDealerChecked(newDealerChecked);
    setOperationChecked(newOperationChecked);
    handleCreateObjChecked(undefined, newDealerChecked, newOperationChecked);
  };

  const handleCheckedDealer = (dealerid: number) => {
    const currentDealerIndex = dealerChecked.indexOf(dealerid);
    const newDealerChecked = [...dealerChecked];

    const objDealer = objChecked.filter((dealer) => dealer.DealerID === dealerid)[0];
    const newOperationChecked = [...operationChecked];

    if (currentDealerIndex === -1) {
      const objOperationsAlert = objDealer.Operations.filter((op) => op.HasAlert === true);
      if (objOperationsAlert.length < 1) {
        newDealerChecked.push(objDealer.DealerID);
      } else {
        setShowNotificationErrorMsg(true);
        setErrorMsg(`Existen ${objOperationsAlert.length} VIN con indicador de alerta`);
      }

      objDealer.Operations.map((op: IOperationsChecked) => {
        const indexOperation = newOperationChecked.indexOf(op.OperationsID);
        if (indexOperation < 0 && !op.HasAlert) {
          newOperationChecked.push(op.OperationsID);
        }
      });
    } else {
      newDealerChecked.splice(currentDealerIndex, 1);
      objDealer.Operations.map((op: IOperationsChecked) => {
        const indexOperation = newOperationChecked.indexOf(op.OperationsID);
        if (indexOperation > -1) {
          newOperationChecked.splice(indexOperation, 1);
        }
      });
    }

    /** Validacion check global */
    let checkedGlobal = true;
    objChecked.map((dealer: IObjCheked) => {
      if (newDealerChecked.indexOf(dealer.DealerID) < 0) {
        checkedGlobal = false;
      }
    });
    setCheckedGlobal(checkedGlobal);

    setDealerChecked(newDealerChecked);
    setOperationChecked(newOperationChecked);
    handleCreateObjChecked(undefined, newDealerChecked, newOperationChecked);
  };

  const handleCheckedOperation = (dealerid: number, operationid: string) => {
    const currentOperationIndex = operationChecked.indexOf(operationid);
    const newOperationChecked = [...operationChecked];

    if (currentOperationIndex === -1) {
      newOperationChecked.push(operationid);
    } else {
      newOperationChecked.splice(currentOperationIndex, 1);
    }

    /** Validacion checked por grupo */
    const objDealer = objChecked.filter((dealer) => dealer.DealerID === dealerid)[0];
    const currentDealerIndex = dealerChecked.indexOf(objDealer.DealerID);
    const newDealerChecked = [...dealerChecked];
    const objOperations = [];

    objDealer.Operations.map((op: IOperationsChecked) => {
      if (newOperationChecked.indexOf(op.OperationsID) > -1) {
        objOperations.push(op);
      }
    });

    if (objDealer.CountOperations === objOperations.length) {
      if (currentDealerIndex < 0) {
        newDealerChecked.push(objDealer.DealerID);
      }
    } else {
      if (currentDealerIndex > -1) {
        newDealerChecked.splice(currentDealerIndex, 1);
      }
    }

    /** Validacion checked global */
    let checkedGlobal = true;
    objChecked.map((dealer: IObjCheked) => {
      dealer.Operations.map((op: IOperationsChecked) => {
        if (newOperationChecked.indexOf(op.OperationsID) < 0) {
          checkedGlobal = false;
        }
      });
    });
    setCheckedGlobal(checkedGlobal);

    setDealerChecked(newDealerChecked);
    setOperationChecked(newOperationChecked);
    handleCreateObjChecked(undefined, newDealerChecked, newOperationChecked);
  };

  const handleCreateObjChecked = (
    data: IPendingBilling[] = dataPending,
    currentDealerChecked: number[] = dealerChecked,
    currentOperationChecked: string[] = operationChecked
  ) => {
    const objChecked = [] as IObjCheked[];

    data.map((item: IPendingBilling) => {
      const operations = [] as IOperationsChecked[];
      item.Operations.map((operation: IPendingBillingOperation) => {
        let checkedOperations =
          currentOperationChecked.indexOf(operation.OperationId) < 0 ? false : true;
        operations.push({
          OperationsID: operation.OperationId,
          HasAlert: operation.Icar ? !operation.Icar.Status : true,
          Checked: checkedOperations,
        });
      });

      let checkedDealer = currentDealerChecked.indexOf(item.DealerId) < 0 ? false : true;
      objChecked.push({
        ClientDealerId: item.ClientDealerID,
        DealerID: item.DealerId,
        DealerName: item.DealerName,
        CountOperations: operations.length,
        Operations: operations,
        Checked: checkedDealer,
      });
    });

    setObjCheked(objChecked);
  };

  const handleLoadData = (data: IPendingBilling[]) => {
    setDataPending(data);
    setExistsDataPending(data.length > 0);
    handleCreateObjChecked(data);
  };

  useEffect(() => {
    handleLoadData(dataOperaciones);
  }, [dataOperaciones]);

  return (
    <>
      {loading && <Loading />}
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        {!loading && (
          <div>
            {!existsDataPending && <NotFound message="No tienes Pendientes de Facturación" />}
            {existsDataPending && (
              <div className={classes.root}>
                <Grid item className={classes.cabecera}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.cabeceraContainer}
                  >
                    <Grid item md={4}>
                      <Checkbox
                        key={Math.random()}
                        checked={checkedGlobal}
                        style={{ color: COLORS.secondary }}
                        onChange={handleCheckedGlobal}
                      />
                      <span
                        style={{
                          color: COLORS.secondary,
                          fontSize: 14,
                          paddingLeft: 20,
                          fontWeight: 400,
                        }}
                      >
                        Seleccionar VIN correctos
                      </span>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      style={{
                        color: '#666666',
                        fontSize: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icons name="paste" size="16px" />
                      <span style={{ paddingLeft: 3 }}>Notas de Crédito</span>
                      <span className={classes.paidDivider}>|</span>
                      <Icons name="error-filled" size="16px" cssClassName={classes.iconGrey} />
                      <span style={{ paddingLeft: 3 }}>No encontrado</span>
                      <span className={classes.paidDivider}>|</span>
                      <Icons name="checkmark-filled" size="16px" cssClassName={classes.iconRed} />
                      <span style={{ paddingLeft: 3 }}>OK</span>
                      <span className={classes.paidDivider}>|</span>
                      <Icons name="warning-alt-filled" size="16px" cssClassName={classes.iconRed} />
                      <span style={{ paddingLeft: 3 }}>Monto menor</span>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleOpenModalClick()}
                        className={classes.botonSolicitud}
                      >
                        <Icons name="pin" size="26px" cssClassName={classes.botonIconSolicitud} />
                        Crear solicitud
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  {dataPending.map((data: IPendingBilling, index: number) => {
                    const objDealer = objChecked.filter((obj) => obj.DealerID === data.DealerId)[0];
                    return (
                      <Grid
                        key={index}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.detalleContainer}
                      >
                        <Grid
                          item
                          md={12}
                          style={{
                            height: 50,
                            display: 'flex',
                            borderBottom: 'solid 1px #979797',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid
                              item
                              md={6}
                              style={{
                                color: COLORS.secondary,
                                fontSize: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              {data.DealerName}
                              <span
                                style={{
                                  color: COLORS.sextenary,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  paddingLeft: 20,
                                }}
                              >
                                {`${data.Operations.length} VIN asociados`}
                              </span>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => handleOpenModalClick(objDealer.DealerID)}
                                className={classes.botonSolicitudindividual}
                              >
                                <Icons
                                  name="pin"
                                  size="16px"
                                  cssClassName={classes.botonIconSolicitudindividual}
                                />
                                solicitud individual
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          md={12}
                          style={{
                            height: 50,
                            display: 'flex',
                            color: COLORS.quintinary,
                            fontSize: 16,
                            fontWeight: 550,
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item sm={1}>
                              <Checkbox
                                key={index}
                                checked={objDealer.Checked}
                                onChange={() => handleCheckedDealer(data.DealerId)}
                                style={{ color: COLORS.secondary }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              style={{
                                color: COLORS.secondary,
                                fontSize: 14,
                              }}
                            >
                              Días Transcurridos
                            </Grid>
                            <Grid item md={2}>
                              VIN
                            </Grid>
                            <Grid item md={1}>
                              NC
                            </Grid>
                            <Grid item md={3}>
                              Monto Neto
                            </Grid>
                            <Grid item md={3}>
                              Monto Original
                            </Grid>
                          </Grid>
                        </Grid>

                        {data.Operations.map((item: IPendingBillingOperation, indx: number) => {
                          const objOperation = objDealer.Operations.filter(
                            (operation) => operation.OperationsID === item.OperationId
                          )[0];
                          return (
                            <Grid
                              item
                              md={12}
                              style={{
                                height: 42,
                                display: 'flex',
                                color: '#666666',
                                fontSize: 14,
                                borderTop: `solid 1px ${COLORS.quintinary}`,
                              }}
                              key={index}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item sm={1}>
                                  <Checkbox
                                    key={index}
                                    checked={objOperation.Checked}
                                    onChange={() =>
                                      handleCheckedOperation(
                                        objDealer.DealerID,
                                        objOperation.OperationsID
                                      )
                                    }
                                    style={{ color: COLORS.secondary }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={2}
                                  style={{
                                    fontWeight: 550,
                                    paddingLeft: 50,
                                  }}
                                >
                                  {item.DayPassed}
                                </Grid>
                                <Grid item md={2}>
                                  {item.Vin}
                                </Grid>
                                <Grid item md={1}>
                                  <IconButton
                                    disabled={item.CreditNote.length < 1}
                                    style={{
                                      fontFamily: 'Nunito Sans',
                                      fontSize: 14,
                                    }}
                                    onClick={() => handleOpenModalNC(item)}
                                  >
                                    <span
                                      className={
                                        item.CreditNote.length > 0
                                          ? classes.creditnoteenable
                                          : classes.creditnotedisable
                                      }
                                    >
                                      <Icons name="paste" size="16px" />
                                      <span
                                        style={{
                                          paddingLeft: 2,
                                        }}
                                      >
                                        {item.CreditNote.length}
                                      </span>
                                    </span>
                                  </IconButton>
                                </Grid>
                                <Grid item md={3}>
                                  {item.Icar !== null && (
                                    <Tooltip
                                      title={`Monto I-Car $${numberToMiles(item.Icar.Amount)}`}
                                    >
                                      <IconButton>
                                        {item.Icar.Status ? (
                                          <Icons
                                            name="checkmark-filled"
                                            size="18px"
                                            cssClassName={classes.iconRed}
                                          />
                                        ) : (
                                          <Icons
                                            name="warning-alt-filled"
                                            size="18px"
                                            cssClassName={classes.iconRed}
                                          />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {item.Icar === null && (
                                    <Tooltip title="No encontrado">
                                      <IconButton>
                                        <Icons
                                          name="error-filled"
                                          size="18px"
                                          cssClassName={classes.iconGrey}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <span
                                    style={{
                                      paddingLeft: 5,
                                    }}
                                  >{`$ ${numberToMiles(item.TotalPrice)}`}</span>
                                </Grid>
                                <Grid item md={3}>{`$ ${numberToMiles(
                                  item.WholesaleAmount
                                )}`}</Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
            <ModalCreditNote
              open={modalNC}
              vin={vin}
              creditNote={creditNote}
              onClose={handleCloseModalNC}
            />

            <ModalSolicitud
              open={open}
              checkedSelected={
                dealerIdSelected > 0
                  ? objChecked.filter((data) => data.DealerID === dealerIdSelected)
                  : objChecked
              }
              onClose={handleCloseModal}
              onUpdate={handleUpdateDataSelectedClick}
            />

            <Notification
              variant="error"
              message={errorMsg}
              show={showNotificationErrorMsg}
              onClose={() => setShowNotificationErrorMsg(false)}
            />
          </div>
        )}
      </Grid>
    </>
  );
};

export default Pendientes;
