import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Grid,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAuth } from '../auth';
import { BUSINESSTYPE, COLORS } from '../../../constants';
import { DIVISIONS, DivisionType } from '../../../pages/BusinessTypePage/BusinessTypeSettings';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../utilities/storageHelper';
import Icons from '../icons';
import config from '../../../config/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWhite: {
      color: '#FFFFFF',
    },
    iconBlack: {
      color: COLORS.primary,
    },
  })
);

const DivisionSelect = (props: RouteComponentProps) => {
  const { businessType, setBusinessType } = useAuth();
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorRef);
  const classes = useStyles();

  const [, setBusinessTypeStorage] = useLocalStorage(StorageKeys.BusinessType, '');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorRef(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorRef(null);
  };

  const handlePick = (business: DivisionType) => {
    const businessTypeEvent = business.name;
    if (businessTypeEvent !== businessType) {
      setBusinessTypeStorage(businessTypeEvent);
      setBusinessType(businessTypeEvent);
      props.history.replace(`/${businessTypeEvent}`);
      window.location.reload();
    }
  };
  return (
    <div className="client-select">
      <div onClick={handleClick} className="wrapper" aria-hidden="true">
        <div className="wrapper-text">
          <p className="title-division">Cambiar de división</p>
          <p className="name-division">Vehículos {businessType.replace('-', ' ')}</p>
        </div>
        <IconButton
          aria-controls="client-menu"
          aria-haspopup="true"
          aria-label="Cambiar cliente"
          className="icon"
        >
          <Icons name="repeat" size="26px" cssClassName={classes.iconWhite} />
        </IconButton>
      </div>
      <Menu
        id="client-menu"
        open={open}
        anchorEl={anchorRef}
        keepMounted
        onClose={handleClose}
        classes={{ paper: `switch-division-paper` }}
        PaperProps={{
          style: {
            maxHeight: '400px',
          },
        }}
      >
        <Grid className="pick-header">
          <Icons name="repeat" size="26px" cssClassName={classes.iconBlack} />
          <span className="pick-header-title">Cambio de división</span>
        </Grid>
        {DIVISIONS.length > 1 &&
          DIVISIONS.map((row: DivisionType) => {
            switch (true) {
              case !config.featureFlagUsed && row.name === BUSINESSTYPE.USED:
              case !config.featureFlagParts && row.name === BUSINESSTYPE.PARTS:
              case !config.featureFlagFreeZone && row.name === BUSINESSTYPE.FREEZONE:
                return false;
              default:
                return (
                  <MenuItem
                    className="pick-menuitem"
                    key={row.name}
                    onClick={() => handlePick(row)}
                  >
                    <Grid className="pick-menuItem-content">
                      <Grid>
                        <span className="pick-menuItem-content-title">{row.title}</span>
                        <span className="pick-menuItem-content-subtitle">{row.subtitle}</span>
                      </Grid>
                      <NavigateNextIcon fontSize="large" htmlColor={COLORS.sextenary} />
                    </Grid>
                  </MenuItem>
                );
            }
          })}
      </Menu>
    </div>
  );
};

export default withRouter(DivisionSelect);
