import React from 'react';
import useStyles from './OperationsFreeZoneTakenPage.style';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import { Grid } from '@material-ui/core';
import OperationsFreeZoneTaken from '../../../../components/freeZone/taken/operationsFreeZoneTaken';
import DownloadOperationsFreeZoneTaken from '../../../../components/freeZone/taken/operationsFreeZoneTaken/DownloadOperationsFreeZoneTaken';

const OperationsFreeZoneTakenPage: React.FC = () => {
  const classes = useStyles();

  return (
    <MainContainer>
      <div className={classes.root}>
        <Grid container id="title" className={classes.header}>
          <Grid item sm={12}>
            <Title title="Cursadas">
              <DownloadOperationsFreeZoneTaken />
            </Title>
          </Grid>
        </Grid>
        <OperationsFreeZoneTaken />
      </div>
    </MainContainer>
  );
};

export default OperationsFreeZoneTakenPage;
