import React from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getObjDateLocal } from '../../../../../utilities/dates-functions';
import { numberToMiles } from '../../../../../utilities/string-functions';
import { ICreditNoteDetailsByOperation } from '../../../../../interfaces';
import { COLORS } from '../../../../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalcontainer: {},
    contenttitle: {
      height: 95,
    },
    title: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      width: 536,
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    contentbody: {
      height: 160,
    },
    contentbotones: {
      height: 60,
      paddingLeft: 20,
      paddingRight: 20,
    },
    botonCancelar: {
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
    cabeceraDetais: {
      height: 35,
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 18,
      fontWeight: 'bold',
      borderBottom: 'solid 1px #979797',
    },
    bodyDetails: {
      height: 35,
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
  })
);
/** endregion */

interface IProps {
  open: boolean;
  vin: string;
  creditNote: ICreditNoteDetailsByOperation[];
  onClose: () => void;
}

const ModalCreditNote = (props: IProps) => {
  const classes = useStyles();

  const { open, vin, creditNote, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modalcontainer}
    >
      <DialogTitle id="form-dialog-title" className={classes.contenttitle}>
        <div className={classes.title}>Notas de crédito</div>
        <div className={classes.subtitle}>VIN {vin}</div>
      </DialogTitle>
      <DialogContent className={classes.contentbody}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.cabeceraDetais}
        >
          <Grid item md={4}>
            Fecha
          </Grid>
          <Grid item md={4}>
            Monto
          </Grid>
          <Grid item md={4}>
            N° Nota Crédito
          </Grid>
        </Grid>

        {creditNote.map((data: ICreditNoteDetailsByOperation, index: number) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.bodyDetails}
              style={index !== 0 ? { borderTop: 'solid 1px rgba(151, 151, 151, .3)' } : {}}
              key={index}
            >
              <Grid item md={4}>
                {getObjDateLocal(data.CreditNoteDate, false, 'dd-LL-yyyy').date}
              </Grid>
              <Grid item md={4}>
                ${numberToMiles(data.Amount)}
              </Grid>
              <Grid item md={4}>
                {data.CreditNoteNumber}
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
      <DialogActions className={classes.contentbotones}>
        <Button color="primary" className={classes.botonCancelar} onClick={handleClose}>
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCreditNote;
