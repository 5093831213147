import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useFetch } from '../../../../hooks/useFetch';
import { PaginationResponse, OperationsChargeHistory } from '../../../../models/commons';
import { Loading } from '../../../common/loading';
import useStyles from './HistoricChargeOperationsSpareParts.style';
import ItemHistoricChargeOperations from '../../../common/charge/historicChargeOperations/ItemHistoricChargeOperations';
import NotFoundSpareparts from '../../commons/NotFoundSpareparts';
import config from '../../../../config/api';
import ErrorSpareParts from '../../commons/ErrorSpareparts';
import { BUSINESSTYPE } from '../../../../constants';
import TablePaginationActions from '../../../common/table/tablePaginationActions';

const HistoricChargeOperationsSpareParts: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const url = `${config.sparePartSettings.apiUri}/api/charges?pageSize=${rowsPerPage}&pageNumber=${
    page + 1
  }`;
  const {
    status,
    data: paginationResponse,
    error,
  } = useFetch<PaginationResponse<OperationsChargeHistory>>(url, undefined, BUSINESSTYPE.PARTS);

  let charges: OperationsChargeHistory[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    charges = paginationResponse?.Result ?? charges;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailClick = (id = '', UploadAt = '') => {
    props.history.push(`${id}/${UploadAt}`);
  };

  return status === 'error' && error ? (
    <ErrorSpareParts
      StatusCode={error.StatusCode}
      Message={error.Message}
      ErrorId={error.ErrorId}
    />
  ) : (
    <TableContainer className={classes.root}>
      {status === 'fetching' && <Loading />}
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableBody>
          {status === 'fetched' &&
            charges?.map((charge) => {
              return (
                <ItemHistoricChargeOperations
                  key={charge.ChargeId}
                  charge={charge}
                  handleDetailClick={handleDetailClick}
                />
              );
            })}
          {status === 'fetched' && charges?.length === 0 && (
            <NotFoundSpareparts key={0} message="No existen cargas anteriores." />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {status === 'fetched' && charges?.length > 0 && (
              <TablePagination
                labelRowsPerPage="Filas por página:"
                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                colSpan={3}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default withRouter(HistoricChargeOperationsSpareParts);
