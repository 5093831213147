import { AxiosResponse } from 'axios';
import apiConfig from '../config/api';
import { BusinessTypeEnum } from '../enums';
import { get, postFormData } from '../utilities/request';

class ChargeIcarServices {
  private readonly baseUrlNew = `${apiConfig.apiUri}/api/chargeIcar`;
  private readonly baseUrlFreeZone = `${apiConfig.freeZoneSettings.apiUri}/api/chargeIcar`;

  async getLastRecordUploadFilesIcar(
    businessType: BusinessTypeEnum
  ): Promise<AxiosResponse | null> {
    switch (businessType) {
      case BusinessTypeEnum.Nuevos:
        return this.getLastRecordUploadFilesIcarNew();
      case BusinessTypeEnum.ZonaFranca:
        return this.getLastRecordUploadFilesIcarFreeZone();
      default:
        return null;
    }
  }

  private async getLastRecordUploadFilesIcarNew(): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/last`;
    return get(url);
  }

  private async getLastRecordUploadFilesIcarFreeZone(): Promise<AxiosResponse> {
    const url = `${this.baseUrlFreeZone}/last`;
    return get(url);
  }

  async processIcarFile(params: FormData, options: RequestInit = {}): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/upload`;
    return postFormData(url, options, params);
  }
}

export const chargeIcarServices = new ChargeIcarServices();
