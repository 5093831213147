import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { FetchConfig } from '../../../../config/fetchConfig';
import { COLORS } from '../../../../constants';
import Notification from '../../../common/notification';
import { downloadFreeZone } from '../../../../utilities/downloadFileFreeZone';
import { useParams } from 'react-router-dom';

type Params = {
  id: string;
};

type NotificationState = {
  show: boolean;
  variant: 'success' | 'error';
  message: string;
};

const DownloadDetailChargeOperationsFreeZone: React.FC = () => {
  const { id } = useParams<Params>();
  const [showNotificacion, setShowNotification] = useState<NotificationState>({
    show: false,
    variant: 'success',
    message: 'Archivo descargado correctamente',
  });
  const urlDownload = `/api/charges/:${id}/download`;

  const handleDownloadFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadFreeZone(urlDownload, {
      fileName: `Carga_${id}.xlsx`,
    } as FetchConfig).then((state) => {
      if (state?.status === 'error') {
        setShowNotification({
          show: true,
          variant: 'error',
          message: 'Ocurrió un problema descargando el archivo.',
        });
      } else {
        setShowNotification({
          show: true,
          variant: 'success',
          message: 'Archivo descargado correctamente',
        });
      }
    });
  };

  return (
    <>
      <Button style={{ color: COLORS.secondary }} onClick={handleDownloadFile}>
        {`Carga_${id}.xlsx`}
      </Button>
      {showNotificacion.show && (
        <Notification
          variant={showNotificacion.variant}
          message={showNotificacion.message}
          show={showNotificacion.show}
          onClose={() =>
            setShowNotification((p) => {
              return { ...p, show: false };
            })
          }
        />
      )}
    </>
  );
};

export default DownloadDetailChargeOperationsFreeZone;
