import { createStyles, makeStyles, Theme, createTheme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

export const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        color: '#666666 !important',
      },
      head: {
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: 'rgba(121, 185, 0)',
        color: 'white',
        fontSize: '15px',
        marginTop: '10px',
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '20px 0',

      '& > *': {
        color: '#666666',
      },
    },

    container: {
      padding: '20px 0px',
    },

    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '44px',
    },

    titleFile: {
      '& > *': {
        margin: '0px',
      },
    },

    containerRight: {
      borderLeft: `1px solid ${COLORS.secondary}`,
      paddingLeft: '8px',
      '& h2': {
        margin: '0px',
        color: COLORS.secondary,
      },
    },

    tableContainer: {
      flexGrow: 1,

      color: '#666666',
    },

    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      buttonContainer: {},
    },

    greenCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '&::before': {
        content: '""',
        minWidth: '12px',
        minHeight: '12px',
        backgroundColor: COLORS.secondary,
        borderRadius: '50%',
        marginRight: '8px',
      },
    },

    greenRed: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '&::before': {
        content: '""',
        minWidth: '12px',
        minHeight: '12px',
        backgroundColor: COLORS.secondary,
        borderRadius: '50%',
        marginRight: '8px',
      },
    },

    buttonContainer: {
      flexWrap: 'wrap',
      minWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  })
);

export default useStyles;
