import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#ffffff',
    },

    header: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      background: theme.palette.credinissanHeaderBackground.default,
      color: '#ffffff',
      height: '200px',
      padding: '10px 20px 0px 20px',
      width: '100%',
    },

    tabNavigation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      background: theme.palette.credinissanHeaderBackground.default,
      height: '48px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },

    tab: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },

    tabSelected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#ffffff',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
    },
  })
);

export default useStyles;
