import { TableCell, Grid, TableRow } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { PaymentPending } from '../../../../models/commons';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './ApplyPaymentFreeZone.style';

type Props = {
  pending: PaymentPending;
} & RouteComponentProps;

const ItemApplyPaymentFreeZone: React.FC<Props> = (props) => {
  const { pending } = props;
  const classes = useStyles();

  return (
    <TableRow data-testid="DealerId">
      <TableCell className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item xs={5} sm={5} md={6} className={classes.columnDetail}>
            <div className={classes.dealerName} data-testid="DealerName">
              {pending.DealerName}
            </div>
            <div>
              <span className={classes.dealerRut} data-testid="RutFormated">
                {rutFormater(`${pending.DealerRut.toString()}${pending.DealerVerificationNumber}`)}
              </span>
              <span className={classes.vins}> {pending.PendingVins} VIN otorgados </span>
            </div>
          </Grid>
          <Grid item xs={7} sm={7} md={6} className={classes.watchDetail}>
            <Link
              to={`/zona-franca/otorgadas/aplicacion-pago/:${pending.ClientDealerId}`}
              className={classes.buttonApplyPayment}
            >
              Aplicar pago
            </Link>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default withRouter(ItemApplyPaymentFreeZone);
