import React from 'react';
import { RouteComponentProps } from 'react-router';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './HistoricMoneyTransferPendingFreeZonePage.style';
import { Grid } from '@material-ui/core';
import HistoricMoneyTransferPendingFreeZone from '../../../../components/freeZone/moneyTransferPending/historicMoneyTransferPendingFreeZone';

const HistoricMoneyTransferPendingFreeZonePage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickPending = () => {
    props.history.push('/zona-franca/pendientes-giro');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Historial de giros">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tab} onClick={handleClickPending}>
                PENDIENTES DE GIRO
              </Grid>
              <Grid item sm={4} className={classes.tabSelected}>
                HISTÓRICO DE GIROS
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>
      <HistoricMoneyTransferPendingFreeZone />
    </MainContainer>
  );
};

export default HistoricMoneyTransferPendingFreeZonePage;
