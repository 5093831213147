import React, { useState, Fragment } from 'react';
import { Grid, Link, makeStyles, createStyles, Theme } from '@material-ui/core';
import NotFound from '../../../common/notfound';
import { rutFormater } from '../../../../utilities/string-functions';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

/** #region estilos */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 60,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      fontFamily: 'Nunito Sans',
    },
    dealer: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
    },
    cantidad: {
      color: '#666666',
      fontSize: 14,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    link: {
      color: '#666666',
      fontSize: 16,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    delaerRut: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
    enfasis: {
      fontWeight: 'bold',
      marginRight: 5,
    },
  })
);
/** #endregion */

interface IParamsChangeView {
  visible: boolean;
  dealer: string;
}

interface IProps {
  onShowDetailsFacturadas: (paramsChangeView: IParamsChangeView) => void;
}

const Operaciones = (props: any) => {
  const classes = useStyles();
  const {
    onShowDetailsFacturadas,
    dataOperationBilling,
    setLoading,
    loading,
    getOperationBillingDetailData,
    existData,
  } = props;

  const handlerShowDetails = (name: string, clientDealerId: string) => {
    setLoading(true);
    getOperationBillingDetailData(clientDealerId);
    onShowDetailsFacturadas(true, name);
  };

  return (
    <div className={classes.root} id={`containerBillingRoot`}>
      {!loading &&
        existData &&
        dataOperationBilling.map((operationBilling: any, index: number) => {
          const {
            BillingOperationQuantity,
            ClientDealerId,
            DealerName,
            DealerRut,
            VerificationNumber,
          } = operationBilling;
          const rutFormat = rutFormater(DealerRut.toString() + VerificationNumber);
          return (
            <div id={`containerBilling${index}`} className={classes.contenedor} key={index}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.body}
              >
                <Grid item xs={9} md={9} lg={10}>
                  <label id={`containerBillingDealerName${index}`} className={classes.dealer}>
                    {DealerName}
                  </label>
                  <div>
                    <span id={`containerBillingDealerRut${index}`} className={classes.delaerRut}>
                      {rutFormat}
                    </span>
                    <span
                      id={`containerBillingOperationQuantity${index}`}
                      className={classes.enfasis}
                    >{` ${BillingOperationQuantity} VIN otorgados`}</span>
                  </div>
                </Grid>
                <Grid item xs={3} md={3} lg={2} className={classes.link}>
                  <Link
                    href="#"
                    id={`containerBillingBtnPayment${index}`}
                    onClick={() => handlerShowDetails(DealerName, ClientDealerId)}
                    style={{ textDecoration: 'none' }}
                  >
                    Aplicar Pagos
                  </Link>
                </Grid>
              </Grid>
            </div>
          );
        })}
      {!loading && !existData && (
        <NotFound message="No se encontraron operaciones en estado Facturado." />
      )}
      {loading && <Loading />}
    </div>
  );
};

export default Operaciones;
