export interface DivisionType {
  title: string;
  subtitle: string;
  name: string;
  btnName: string;
}

export const DIVISIONS: DivisionType[] = [
  {
    title: 'Vehículos Nuevos',
    subtitle: 'Gestiona la compra de vehículos nuevos',
    name: 'nuevos',
    btnName: 'NUEVOS',
  },
  {
    title: 'Vehículos Usados',
    subtitle: 'Gestiona la compra de vehículos usados',
    name: 'usados',
    btnName: 'USADOS',
  },
  {
    title: 'Vehículos Zona Franca',
    subtitle: 'Gestiona la compra de vehículos en zona franca',
    name: 'zona-franca',
    btnName: 'ZONA FRANCA',
  },
  {
    title: 'Repuestos Para Vehículos',
    subtitle: 'Gestiona la compra de repuestos',
    name: 'repuestos',
    btnName: 'REPUESTOS',
  },
];
