import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Title from '../../../../common/title';
import Paginator from '../../../../common/paginator/paginator';
import TitleDetails from '../../../../common/titledetails';
import NotFound from '../../../../common/notfound';

import Charge from './subcomponentes/charge';
import ChargeDetails from './subcomponentes/chargedetails';

import {
  ILoadedFiles,
  IPagRequest,
  IPagResponse,
  IParamsTitleDetails,
} from '../../../../../interfaces';
import { chargeOperationsServices } from '../../../../../services/chargeOperationsServices';
import { convertDateReadableFormat } from '../../../../../utilities/dates-functions';

import MainContainer from '../../../../maincontainer';
import { Loading } from '../../../../common/loading';
import { COLORS, TABS } from '../../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;
const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    tabContainer: {
      backgroundColor: COLORS.primary,
      height: 48,
      paddingLeft: 20,
      paddingRight: 20,
    },
    tab: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      background: '#FFFFFF',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
      ...tabStylesObj,
    },
  })
);
/** endregion */

interface IProps extends RouteComponentProps {}

const initialRequest = {
  pageNumber: 1,
  pageSize: 10,
};

const ViewHistorico = (props: IProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [pageRequest, setPageRequest] = useState(initialRequest);
  const [currentDataLoadedFiles, setcurrentDataLoadedFiles] = useState(
    {} as IPagResponse<ILoadedFiles>
  );

  const [changeToDetails, setChangeToDetails] = useState(false);
  const [chargeId, setChargeId] = useState('');
  const [title, setTitle] = useState('');
  const [titleDetails, setTitleDetails] = useState('');

  const [pageSize, setPageSize] = useState(initialRequest.pageSize);

  const [existDataLoadedFiles, setExistDataLoadedFiles] = useState(false);

  const handleShowDetailsUpload = (params: IParamsTitleDetails) => {
    setChangeToDetails(params.visible);
    setChargeId(params.id);
    setTitle(convertDateReadableFormat(params.text, false, '-', 'dd-LL-yyyy'));
    setTitleDetails(params.text);
  };

  const handleGetDataLoadedFiles = async (objPagRequest: IPagRequest) => {
    chargeOperationsServices.getChargesOperationsHistory(objPagRequest).then((response) => {
      setcurrentDataLoadedFiles(response.data);
      setExistDataLoadedFiles(response.data.Result.length > 0);
      setLoading(false);
    });
  };

  const handleRefreshData = (pagRequest: IPagRequest, size: number) => {
    setLoading(true);
    setPageSize(size);
    //handleGetDataLoadedFiles(pagRequest)
    setPageRequest(pagRequest);
  };

  const onShowTitle = () => {
    if (!changeToDetails) {
      return (
        <Title
          title="Carga de archivo"
          mostrarTabSubirExcel={true}
          tabSelected={TABS.CHARGE_OPERATIONS}
        />
      );
    }

    return <TitleDetails title={title} onShowDetails={handleShowDetailsUpload} />;
  };

  const handleHiddenDownload = () => {
    const headerContainerDownload = document.getElementById('containerdownload');
    if (headerContainerDownload) {
      headerContainerDownload.style.visibility = 'hidden';
    }
  };

  const hadleSeeTabCargaClick = async () => {
    props.history.push('/nuevos/carga-operaciones');
  };

  useEffect(() => {
    if (loading) {
      handleGetDataLoadedFiles(pageRequest);
      handleHiddenDownload();
    }
  }, [pageRequest]);

  return (
    <MainContainer>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.tabContainer}
        >
          <Grid item sm={2} className={classes.tab} onClick={hadleSeeTabCargaClick}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tabSelected}>
            Historial
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!loading && !existDataLoadedFiles && (
              <NotFound message="No tienes historial de carga" />
            )}
            {!loading &&
              !changeToDetails &&
              existDataLoadedFiles &&
              currentDataLoadedFiles.Result.map((data: ILoadedFiles, index: number) => {
                return <Charge key={index} data={data} onShowDetails={handleShowDetailsUpload} />;
              })}
            {!loading && !changeToDetails && existDataLoadedFiles && (
              <Grid
                item
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Paginator
                  data={currentDataLoadedFiles}
                  onUpdate={handleRefreshData}
                  size={pageSize}
                />
              </Grid>
            )}
            {!loading && changeToDetails && (
              <ChargeDetails chargeId={chargeId} title={titleDetails} />
            )}
            {loading && <Loading />}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default withRouter(ViewHistorico);
