import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import ModalDescarga from './modaldescarga';

import { IPendingRequest } from '../../../interfaces';

import { setPaymentRequestOperations } from '../../../services/file-manager-service';
import { putGrantTurnRequest } from '../../../services/money-order-request-service';

import { getObjDateLocal, convertDateReadableFormat } from '../../../utilities/dates-functions';
import { numberToMiles } from '../../../utilities/string-functions';

import ButtonIconText from '../../common/bottonIconText';

import { downloadFiles } from '../../../utilities/download-files';
import { COLORS } from '../../../constants';

/** #region estilos */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
      fontWeight: 550,
    },
    cabecera: {
      width: '100%',
      height: 66,
      maxWidth: '4000px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginTop: -15,
      color: COLORS.quintinary,
    },
    cabeceraBody: {
      height: '100%',
      padding: '0 20px',
      fontSize: 16,
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 66,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    celdaIdSolicitudGiro: {
      textTransform: 'uppercase',
      fontSize: 16,
      color: COLORS.sextenary,
    },
    celdaMonto: {
      color: COLORS.secondary,
      fontSize: 24,
    },
    celdaVins: {
      fontSize: 18,
    },
    celdaFechaDescarga: {
      display: 'flex',
      fontSize: 18,
      alignItems: 'center',
    },
    celdaFechaDescargaIcon: {
      fontSize: 25,
      marginRight: 5,
      justifyContent: 'center',
      color: COLORS.secondary,
    },
    celdaBotones: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);
/** #endregion */

interface IProps extends RouteComponentProps {
  data: Array<IPendingRequest>;
  onRefresh: (value: number) => void;
}

export const Tupla = (props: IProps) => {
  const classes = useStyles();
  const clientRut = 76390989;
  const { data, onRefresh } = props;
  const [dataPendingRequest, setDataPendingRequest] = useState(data);
  const [open, setOpen] = React.useState(false);

  const [moneyOrderRequestIdSelected, setMoneyOrderRequestIdSelected] = useState('');

  const handleOpenModalClick = (value: string) => {
    setMoneyOrderRequestIdSelected(value);
    setOpen(true);
  };

  const handleCloseModalClick = () => {
    setMoneyOrderRequestIdSelected('');
    setOpen(false);
  };

  const handleDownloadClick = async (bankAccountId: string) => {
    setPaymentRequestOperations({
      MoneyOrderRequestId: moneyOrderRequestIdSelected,
      BankAccountId: bankAccountId,
    }).then((response) => {
      downloadFiles(response.data, moneyOrderRequestIdSelected, 'xlsx');

      const opGiro = _.find(dataPendingRequest, {
        MoneyOrderRequestId: moneyOrderRequestIdSelected,
      });
      if (opGiro) {
        const dia = new Date().toLocaleDateString();
        opGiro.DownloadRequestDate = getObjDateLocal(undefined).fulldatestring.replace('Z', '');
      }

      handleCloseModalClick();
    });
  };

  const handlePutGrantTurnRequest = async (moneyOrderRequestId: string) => {
    putGrantTurnRequest(moneyOrderRequestId).then((response) => {
      onRefresh(clientRut);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.cabecera}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.cabeceraBody}
        >
          <Grid item md={3}>
            ID solicitud giro
          </Grid>
          <Grid item md={3} style={{ paddingLeft: 20 }}>
            Monto total a girar
          </Grid>
          <Grid item md={1}>
            Total VIN
          </Grid>
          <Grid item md={2}>
            Última descarga
          </Grid>
        </Grid>
      </div>

      {/* #COMPONENT REGISTRO CON DESCARGA */}
      {dataPendingRequest.map((data: IPendingRequest, index: number) => {
        const keyBtnOtorgada = `btnotorgarpendientesgiro_${index}`;
        return (
          <div className={classes.contenedor} key={index}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.body}
            >
              <Grid item md={3} className={classes.celdaIdSolicitudGiro}>
                {data.MoneyOrderRequestId}
              </Grid>
              <Grid item md={3} className={classes.celdaMonto} style={{ paddingLeft: 20 }}>
                {`$ ${numberToMiles(data.TotalAmount)}`}
              </Grid>
              <Grid item md={1} className={classes.celdaVins}>{`${data.VinsCount}`}</Grid>
              {data.DownloadRequestDate !== null ? (
                <Grid item md={2} className={classes.celdaFechaDescarga}>
                  <CheckCircleRoundedIcon className={classes.celdaFechaDescargaIcon} />
                  {convertDateReadableFormat(data.DownloadRequestDate, true)}
                </Grid>
              ) : (
                <Grid item md={2} className={classes.celdaFechaDescarga}>
                  No descargado aún
                </Grid>
              )}
              <Grid item md={3} className={classes.celdaBotones}>
                <ButtonIconText
                  objHandleEvent={{
                    params: {
                      id: data.MoneyOrderRequestId,
                    },
                    execute: (params: any) => {
                      handleOpenModalClick(params);
                    },
                  }}
                  buttonBackgroundColor={COLORS.secondary}
                  buttonColor={'#FFFFFF'}
                  buttonHeight={28}
                  buttonWidth={140}
                  buttonKey={`btndescargarpendientesgiro_${index}`}
                  fontSize={12}
                  iconName={'file-download'}
                  iconSize={14}
                  label={'Descargar Excel'}
                />

                {data.DownloadRequestDate !== null && (
                  <ButtonIconText
                    objHandleEvent={{
                      params: {
                        id: data.MoneyOrderRequestId,
                        key: true,
                      },
                      execute: (params: any) => {
                        handlePutGrantTurnRequest(params);
                      },
                    }}
                    buttonBackgroundColor={COLORS.secondary}
                    buttonColor={'#FFFFFF'}
                    buttonHeight={28}
                    buttonWidth={140}
                    buttonKey={`btnotorgarpendientesgiro_${index}`}
                    buttonMarginLeft={30}
                    fontSize={12}
                    iconName={'checkmark'}
                    iconSize={14}
                    label={'Otorgar'}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {/* #ENDCOMPONENT */}

      {/* #COMPONENT MODAL */}
      <ModalDescarga
        //selectedValue={selectedValue}
        open={open}
        onClose={handleCloseModalClick}
        onDownload={handleDownloadClick}
      />
      {/* #ENDCOMPONENT */}
    </div>
  );
};

export default withRouter(Tupla);
