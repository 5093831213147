export const numberToMiles = (num: number): string => {
  if (!num) return '0';

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const clean = (rut: string) => {
  return rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
};

export const rutFormater = (rutParam: string): string => {
  const rut = clean(rutParam);

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
};

export const rutToNumberWithoutDv = (rut: string): number => {
  if (!rut) return 0;
  return parseInt(rut.replace(/(\d{1,3})(\d{3})(\d{3})-(\w{1})/g, '$1$2$3'), 10);
};
