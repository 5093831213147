import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import useStyles from './TituloDetallesUsados.style';

/** region style */

/** endregion */

type Props = {
  title: string;
} & RouteComponentProps;

const TituloDetallesUsados: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { title } = props;

  const handlerShowDetails = () => {
    props.history.goBack();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.header}
    >
      <Grid item sm={12} className={classes.tituloContainer}>
        <ArrowBackIosOutlinedIcon className={classes.boton} onClick={handlerShowDetails} />
        <Typography variant="h4" className={classes.text}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withRouter(TituloDetallesUsados);
