import React, { useState } from 'react';
import { Checkbox, IconButton, TableCell, TableRow } from '@material-ui/core';
import { numberToMiles } from '../../../../../utilities/string-functions';
import { getObjDateLocal } from '../../../../../utilities/dates-functions';
import { COLORS } from '../../../../../constants';
import useStyles from '../DetailApplyPaymentFreeZone.style';
import {
  FreeZoneCreditNotesDetailByOperation,
  FreeZoneDetailPaymentPending,
} from '../../../../../models/freeZone/FreeZoneDetailPaymentPeding';
import Icons from '../../../../common/icons';
import ModalCreditNote from '../../../../common/paymentPending/detailApplyPayment/modalCreditNote';
import { ICreditNoteDetailsByOperation } from '../../../../../interfaces';

type Props = {
  pendingPayment: FreeZoneDetailPaymentPending;
  checked: string[];
  handleToggle: (value: string) => () => void;
};

const ItemApplyPaymentFreeZone: React.FC<Props> = ({ pendingPayment, checked, handleToggle }) => {
  const classes = useStyles();
  const [disabledChecked, setDisabledChecked] = useState(false);
  const [modalNC, setModalNC] = React.useState<boolean>(false);
  const [vin, setVin] = React.useState<string>('');
  const [creditNote, setCreditNote] = React.useState<ICreditNoteDetailsByOperation[]>([]);

  const handleOpenModalNC = (item: FreeZoneDetailPaymentPending) => {
    const formated = formatCreditNote(item.FreeZoneCreditNotes);
    setVin(item.VIN);
    setCreditNote(formated);
    setModalNC(true);
  };

  const handleCloseModalNC = () => {
    setVin('');
    setCreditNote([]);
    setModalNC(false);
  };

  const formatCreditNote = (creditNotes: FreeZoneCreditNotesDetailByOperation[]) => {
    const creditNoteFormated: ICreditNoteDetailsByOperation[] = creditNotes.map(
      (item: FreeZoneCreditNotesDetailByOperation) => {
        return {
          CreditNoteDate: item.CreditNoteDate,
          CreditNoteId: item.FreeZoneCreditNoteId,
          Amount: item.Amount,
          OperationId: item.FreeZoneOperationId,
          CreditNoteNumber: item.CreditNoteNumber,
        };
      }
    );
    return creditNoteFormated;
  };

  return (
    <TableRow key={pendingPayment.FreeZoneOperationId} data-testid="OperationId">
      <TableCell className={classes.itemApplyPayment}>
        <Checkbox
          disabled={disabledChecked}
          inputProps={{ 'aria-label': 'checkboxItem' }}
          checked={checked.indexOf(pendingPayment.VIN) !== -1}
          onClick={handleToggle(pendingPayment.VIN)}
          disableRipple
          style={{ color: COLORS.secondary }}
          data-testid="checkboxItem"
        />
      </TableCell>
      <TableCell className={classes.itemApplyPayment}>{pendingPayment.PassedDays}</TableCell>
      <TableCell className={classes.itemApplyPayment}>{pendingPayment.VIN}</TableCell>
      <TableCell className={classes.itemApplyPayment}>{`$ ${numberToMiles(
        pendingPayment.Amount
      )}`}</TableCell>
      <TableCell className={classes.itemApplyPayment}>
        <IconButton
          disabled={pendingPayment.FreeZoneCreditNotes.length < 1}
          onClick={() => handleOpenModalNC(pendingPayment)}
          style={{
            fontFamily: 'Nunito Sans',
            fontSize: 14,
          }}
        >
          <span
            className={
              pendingPayment.FreeZoneCreditNotes.length > 0
                ? classes.iconCreditNotesEnabled
                : classes.iconCreditNotesDisabled
            }
          >
            <Icons name="paste" size="16px" />
            <span
              style={{
                paddingLeft: 2,
              }}
            >
              {pendingPayment.FreeZoneCreditNotes.length}
            </span>
          </span>
        </IconButton>
        <ModalCreditNote
          open={modalNC}
          vin={vin}
          creditNote={creditNote}
          onClose={handleCloseModalNC}
        />
      </TableCell>
      <TableCell className={classes.itemApplyPayment}>{pendingPayment.InvoiceNumber}</TableCell>
      <TableCell className={classes.itemApplyPayment}>
        {getObjDateLocal(pendingPayment.InvoiceDate, true, 'dd-LL-yyyy').date}
      </TableCell>
    </TableRow>
  );
};

export default ItemApplyPaymentFreeZone;
