import { createStyles, makeStyles, TableCell, Theme, withStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

export const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 'bold',
    color: COLORS.secondary,
    padding: '0px',
  },
}))(TableCell);

const heightFooter = '8vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: theme.palette.credinissanBackground.default,
      borderRadius: '4px',
      width: '100%',
      minHeight: '100%',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
    },
    title: {
      borderBottom: '1px solid #666666',
      color: COLORS.secondary,
      fontSize: '16px',
    },

    mainTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },

    table: {
      marginTop: '10px',
      boxShadow: '0px 0px 0px 0px',
    },

    headTable: {
      '& > *': {
        color: '#666666',
      },
    },

    btnApplyPayment: {
      backgroundColor: COLORS.secondary,
      color: 'white',

      '&$disabled': {
        background: '#EFEFEF',
      },
    },

    btnUploadDoc: {
      borderColor: COLORS.secondary,
      color: COLORS.secondary,
    },

    formSelect: {
      margin: theme.spacing(1),
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },

    dialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rootForm: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      flexBasis: 200,
    },
    dialogBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: '100%',
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    searchInput: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0.5rem 1.5rem 0.5rem 0rem !important',
      fontWeight: 'bold',
    },
    searchForm: {
      display: 'flex',
    },
    btnFilter: {
      marginTop: '2rem !important',
      marginBottom: '0.5rem !important',
      backgroundColor: COLORS.secondary,
      color: 'white',
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
      background: COLORS.sidebar,
      height: heightFooter,
      padding: '5px',
    },
    itemApplyPayment: {
      padding: '0px !important',
    },
    btnDownload: {
      color: 'white',
      background: COLORS.secondary,
    },
  })
);

export default useStyles;
