import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Loading } from '../../common/loading';
import Title from '../../common/title';
import OperacionDetalleLinea from './operaciondetallelinea';
import { IDataDealer } from '../../../interfaces';
import { dealerServices } from '../../../services/dealerServices';

import MainContainer from '../../maincontainer';
import { COLORS } from '../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps {}

function DetallesLineas(props: IProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [dataDetails, setDataDetails] = useState([] as Array<IDataDealer>);

  const handleGetDataDetails = async () => {
    dealerServices.getLineDetails().then((response) => {
      setLoading(false);
      setDataDetails(response.data);
    });
  };

  useEffect(() => {
    handleGetDataDetails();
  }, []);
  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          id={'title'}
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title title={'Detalle de líneas'} />
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!loading && <OperacionDetalleLinea data={dataDetails} />}
            {loading && <Loading />}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}

export default DetallesLineas;
