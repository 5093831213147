import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import ErrorSpareParts from '../../commons/ErrorSpareparts/ErrorSpareparts';
import NotFountSpareParts from '../../commons/NotFoundSpareparts/NotFoundSpareparts';
import { Loading } from '../../../common/loading';
import useStyles from './GrantedSpareParts.style';
import { SparePartsGrantedByDealer } from '../../../../models/spareParts/SparePartsGrantedByDealer';
import config from '../../../../config/api';
import ItemGrantedSpareParts from './ItemGrantedSpareParts';
import { BUSINESSTYPE } from '../../../../constants';

const GrantedSpareParts: React.FC = () => {
  const classes = useStyles();

  const url = `${config.sparePartSettings.apiUri}/api/granteds`;
  const {
    status,
    data: sparePartsGrantedByDealers,
    error,
  } = useFetch<SparePartsGrantedByDealer[]>(url, undefined, BUSINESSTYPE.PARTS);

  return status === 'error' && error ? (
    <ErrorSpareParts
      StatusCode={error.StatusCode}
      Message={error.Message}
      ErrorId={error.ErrorId}
    />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          sparePartsGrantedByDealers?.map((sparePartsGrantedByDealer) => {
            return (
              <ItemGrantedSpareParts
                key={sparePartsGrantedByDealer.ClientDealerId}
                sparePartsGrantedByDealer={sparePartsGrantedByDealer}
              />
            );
          })}
        {status === 'fetched' && sparePartsGrantedByDealers?.length === 0 && (
          <NotFountSpareParts message="No existen operaciones otorgadas para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default GrantedSpareParts;
