import React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import useStyles from './ListGrantedFreeZoneOperationsPage.style';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import ListGrantedFreeZoneOperations from '../../../../components/freeZone/granted/list';

const ListGrantedFreeZoneOperationsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const handleClickChangeTabs = (e: React.MouseEvent<HTMLDivElement>) => {
    const value = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (value === 'otorgadas') {
      history.push('zona-franca/otorgadas');
    } else if (value === 'aplicación de pagos') {
      history.push('/zona-franca/otorgadas/aplicacion-pago');
    } else if (value === 'pendientes de confirmación') {
      history.push('/zona-franca/otorgadas/pendientes-confirmacion');
    }
  };

  return (
    <MainContainer>
      <Grid className={classes.header}>
        <Grid item sm={12}>
          <Title title="Otorgadas">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tabSelected} onClick={handleClickChangeTabs}>
                OTORGADAS
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickChangeTabs}>
                APLICACIÓN DE PAGOS
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickChangeTabs}>
                PENDIENTES DE CONFIRMACIÓN
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>
      <ListGrantedFreeZoneOperations />
    </MainContainer>
  );
};

export default ListGrantedFreeZoneOperationsPage;
