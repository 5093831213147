import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

import TabMenuOtorgadas from './tabmenu/otorgadas';
import TabMenuSubirExcel from './tabmenu/subirexcel';
import TabMenuPendientes from './tabmenu/pendientes';
import { COLORS } from '../../constants';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

/** region style */
const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: 120,
      paddingTop: 140,
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
    },
    titleContainer: {
      height: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontFamily: 'Nunito Sans',
    },
    button: {
      color: '#FFFFFF',
      padding: 5,
      background: COLORS.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      borderRadius: 4,
      width: 27,
      height: 27,
      cursor: 'pointer',
    },
  })
);
/** endregion */

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  },
});

interface IProps extends RouteComponentProps {
  title: string;
  mostrarTabOperaciones?: boolean;
  mostrarTabSubirExcel?: boolean;
  mostrarTabPendientes?: boolean;
  objDownloadCursadas?: {
    mostrarBtnDownload: boolean;
    component: any;
  };
  tabSelected?: string;
  children?: React.ReactNode;
  goBackButton?: boolean;
}

const Title: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const {
    title,
    mostrarTabOperaciones,
    mostrarTabSubirExcel,
    mostrarTabPendientes,
    objDownloadCursadas,
    tabSelected = '',
    children = <></>,
    goBackButton,
    history,
  } = props;

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.header}
    >
      <Grid item sm={4} className={classes.titleContainer}>
        <ThemeProvider theme={theme}>
          {goBackButton && (
            <ArrowBackIosOutlinedIcon className={classes.button} onClick={handleGoBack} />
          )}
          <Typography variant="h4">{title}</Typography>
        </ThemeProvider>
      </Grid>

      <Grid item sm={8}>
        {mostrarTabOperaciones && <TabMenuOtorgadas tabSelected={tabSelected} />}
        {mostrarTabSubirExcel && <TabMenuSubirExcel tabSelected={tabSelected} />}
        {mostrarTabPendientes && <TabMenuPendientes tabSelected={tabSelected} />}
        {objDownloadCursadas && objDownloadCursadas.mostrarBtnDownload && (
          <objDownloadCursadas.component />
        )}
        {children}
      </Grid>
    </Grid>
  );
};

Title.defaultProps = {
  mostrarTabOperaciones: false,
  mostrarTabSubirExcel: false,
  mostrarTabPendientes: false,
  objDownloadCursadas: undefined,
  tabSelected: '',
  goBackButton: false,
};

export default withRouter(Title);
