import { Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './UnExpectedError.style';

type Props = {
  message: string;
};

const UnExpectedError: React.FC<Props> = ({ message }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <h1>Error</h1>
        <h2>{message}</h2>
      </Grid>
    </Grid>
  );
};

export default UnExpectedError;
