import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import MainContainer from '../../../../maincontainer';
import Title from '../../../../common/title';

import Pendientes from './subcomponentes/pendientes';

import {
  getPendingOperations,
  getRequestedOperation,
} from '../../../../../services/pending-billing-operation-service';
import { IPendingBilling } from '../../../../../interfaces';
import { COLORS } from '../../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = 250;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    tabContainer: {
      backgroundColor: COLORS.primary,
      height: 48,
      paddingLeft: 20,
      paddingRight: 20,
    },
    tab: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      background: '#FFFFFF',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
      ...tabStylesObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps extends RouteComponentProps {}

const ViewPendientes = (props: IProps) => {
  const classes = useStyles();

  const [loadingPending, setLoadingPending] = useState(true);
  const [dataPending, setDataPending] = useState<IPendingBilling[]>([]);

  const [countPendientes, setCountPendientes] = useState(0);
  const [countSolicitadas, setCountSolicitadas] = useState(0);

  const hadleSeeTabSolicitadasClick = async () => {
    props.history.push('/nuevos/otorgadas/pedientes-de-facturacion/solicitadas');
  };

  const handleGetPendingOperations = async () => {
    setLoadingPending(true);
    getPendingOperations().then((response) => {
      handleGetRequestedOperation();
      setDataPending(response.data);
      setCountPendientes(response.data.length);
      setLoadingPending(false);
    });
  };

  const handleGetRequestedOperation = async () => {
    getRequestedOperation().then((response) => {
      setCountSolicitadas(response.data.length);
    });
  };

  useEffect(() => {
    handleGetPendingOperations();
  }, []);

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title
              title={'Otorgadas'}
              mostrarTabOperaciones={true}
              tabSelected={'pendientes_de_facturacion'}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.tabContainer}
        >
          <Grid item sm={2} className={classes.tabSelected}>
            Pendientes ({countPendientes})
          </Grid>
          <Grid item sm={2} className={classes.tab} onClick={hadleSeeTabSolicitadasClick}>
            Solicitadas ({countSolicitadas})
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            <Pendientes
              dataOperaciones={dataPending}
              onClickTabSolicitadas={hadleSeeTabSolicitadasClick}
              loading={loadingPending}
            />
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default withRouter(ViewPendientes);
