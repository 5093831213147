import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { numberToMiles } from '../../../../utilities/string-functions';
import { COLORS } from '../../../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.sidebar,
      color: COLORS.primary,
      // paddingLeft: '33px',
      paddingRight: '80px',
      fontWeight: 'bold',
    },
    labelAmount: {
      textAlign: 'center',
    },
    footerTotalMount: {
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: 'bold',
    },
    contentbutton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    button: {
      backgroundColor: COLORS.secondary,
      color: '#FFFFFF',
      textTransform: 'capitalize',
      width: '198px',
    },
    buttonDisabled: {
      backgroundColor: COLORS.tertiary,
      color: COLORS.sextenary,
      textTransform: 'capitalize',
      width: '198px',
    },
  })
);
/** endregion */

interface IProps {
  countVins: number;
  totalMount: number;
  disabled: boolean;
  onApplyPayment: () => void;
  heightFooter: number;
}

const Footer = (props: IProps) => {
  const classes = useStyles();

  const { countVins, totalMount, disabled, onApplyPayment, heightFooter } = props;

  const handleApplyPayment = () => {
    //setMensaje(DICTIONARY.mensajeProcessState as string)
    onApplyPayment();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.contenedor}
      style={{ height: heightFooter }}
    >
      <Grid item sm={2} md={2} className={classes.labelAmount}>
        <Grid container direction="column" justifyContent="center">
          <Grid item md={12}>
            <label id="h4">VIN seleccionados</label>
          </Grid>
          <Grid item md={12}>
            <span className={classes.footerTotalMount} id={'footerTotalMount'}>
              {numberToMiles(countVins)}
            </span>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={2} md={2} className={classes.labelAmount}>
        <Grid container direction="column" justifyContent="center">
          <Grid item md={12}>
            <label id="h4">Monto total</label>
          </Grid>
          <Grid item md={12}>
            <span className={classes.footerTotalMount} id={'footerTotalMount'}>
              ${numberToMiles(totalMount)}
            </span>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3} sm={3} className={classes.contentbutton}>
        <Button
          id={'footerRequestBnt'}
          variant="contained"
          disabled={disabled} /*startIcon={<ArrowUpwardIcon />}*/
          className={!disabled ? classes.button : classes.buttonDisabled}
          style={disabled ? { background: COLORS.tertiary } : {}}
          onClick={handleApplyPayment}
        >
          <span id="footerRequestTurnBtn">Aplicar medio de pago</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
