import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AlertUsados from '../../commons/AlertUsados/AlertUsados';
import useStyles, { theme } from './ResultCargaOperacionesUsados.style';
import { UsedOperationsChargeResponse } from '../../../../models/used';

type Props = {
  handleUploadAgain: () => void;
} & UsedOperationsChargeResponse &
  RouteComponentProps;

const ResultCargaOperacionesUsados: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { ChargeId, Total, TotalSuccessful, TotalRejected, FileName, UploadAt, handleUploadAgain } =
    props;

  const handleViewDetails = () => {
    props.history.push(`carga-operaciones/:${ChargeId}`);
  };

  const goToTakenOperations = () => {
    props.history.push(`cursadas`);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {TotalRejected === Total && (
          <AlertUsados
            show
            type="error"
            text="El archivo subido presenta errores. No se pudo cargar ninguna operación."
          />
        )}
        {TotalRejected !== Total && TotalRejected > 0 && (
          <AlertUsados
            show
            type="warning"
            text="El archivo subido presenta algunos errores. Solo se cargaron las operaciones sin problemas."
          />
        )}
        {TotalRejected === 0 && (
          <AlertUsados show type="success" text="El archivo subido se procesó correctamente." />
        )}
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={5} md={5}>
            <Grid container justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={2} className={classes.icon}>
                <DescriptionOutlinedIcon fontSize="inherit" />
              </Grid>
              <Grid item xs={10} className={classes.titleFile}>
                <h4>{FileName}</h4>
                <p>{UploadAt}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '80%' }}>
              <Button
                variant="contained"
                startIcon={<ArrowForwardIosIcon />}
                name="goDetails"
                onClick={handleViewDetails}
              >
                Ver detalles de la carga
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} md={7} className={classes.containerRight}>
            <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
              <h2>Detalle del archivo cargado</h2>
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Estado</TableCell>
                      <TableCell>Acción a realizar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell>
                        <span
                          className={classes.greenCell}
                        >{`${TotalSuccessful} filas procesadas`}</span>
                      </TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell>
                        <span
                          className={classes.greenRed}
                        >{`${TotalRejected} filas con errores`}</span>
                      </TableCell>
                      <TableCell>
                        {TotalRejected > 0 && 'Estas filas NO se pueden cargar'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  startIcon={<ArrowForwardIosIcon />}
                  name="newFile"
                  onClick={handleUploadAgain}
                >
                  Seleccionar nuevo archivo
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ArrowForwardIosIcon />}
                  name="continue"
                  onClick={goToTakenOperations}
                >
                  Continuar a operaciones cursadas
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default withRouter(ResultCargaOperacionesUsados);
