import { AxiosResponse } from 'axios';
import apiConfig from '../config/api';
import { get, post } from '../utilities/request';
import { IPagRequest } from '../interfaces';
import { BusinessTypeEnum } from '../enums';

class ChargeCreditNotesServices {
  private readonly baseUrlNew = `${apiConfig.apiUri}/api/chargeCreditNotes`;
  private readonly baseUrlFreeZone = `${apiConfig.freeZoneSettings.apiUri}/api/chargeCreditNotes`;

  async getLastRecordUploadFilesNc(businessType: BusinessTypeEnum): Promise<AxiosResponse | null> {
    switch (businessType) {
      case BusinessTypeEnum.Nuevos:
        return this.getLastRecordUploadFilesNcNew();
      case BusinessTypeEnum.ZonaFranca:
        return this.getLastRecordUploadFilesNcFreeZone();
      default:
        return null;
    }
  }

  private async getLastRecordUploadFilesNcNew(): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/last`;
    return get(url);
  }

  private async getLastRecordUploadFilesNcFreeZone(): Promise<AxiosResponse> {
    const url = `${this.baseUrlFreeZone}/last`;
    return get(url);
  }

  async getChargeCreditNotesHistory(
    businessType: BusinessTypeEnum,
    pagRequest: IPagRequest
  ): Promise<AxiosResponse | null> {
    switch (businessType) {
      case BusinessTypeEnum.Nuevos:
        return this.getChargeCreditNotesHistoryNew(pagRequest);
      case BusinessTypeEnum.ZonaFranca:
        return this.getChargeCreditNotesHistoryFreeZone(pagRequest);
      default:
        return null;
    }
  }

  private async getChargeCreditNotesHistoryNew(pagRequest: IPagRequest): Promise<AxiosResponse> {
    const url = `${this.baseUrlNew}/history`;
    return post(url, pagRequest);
  }

  private async getChargeCreditNotesHistoryFreeZone(
    pagRequest: IPagRequest
  ): Promise<AxiosResponse> {
    const url = `${this.baseUrlFreeZone}/history`;
    return get(url, pagRequest);
  }

  async getChargeCreditNoteDetails(
    chargeid: string,
    businessType: BusinessTypeEnum
  ): Promise<AxiosResponse> {
    const url =
      businessType === BusinessTypeEnum.ZonaFranca
        ? `${this.baseUrlFreeZone}/${chargeid}`
        : `${this.baseUrlNew}/${chargeid}`;
    return get(url);
  }
}

export const chargeCreditNotesServices = new ChargeCreditNotesServices();
