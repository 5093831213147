import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IFooter } from '../../../interfaces';
import { COLORS } from '../../../constants';

/** region style */
const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: COLORS.quintinary,
      color: '#fff',
      textTransform: 'capitalize',
      width: '198px',
    },
    buttonDisabled: {
      color: '#A6A6A6',
      textTransform: 'capitalize',
      width: '198px',
    },
    contenedor: {
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.sidebar,
      color: '#ffffff',
      paddingLeft: '33px',
      paddingRight: '80px',
      fontWeight: 'bold',
    },
    contentbutton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    contentinfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    labelCreditNotes: {
      textAlign: 'center',
      color: COLORS.primary,
    },
    labelAmount: {
      textAlign: 'center',
      paddingLeft: '68px',
      color: COLORS.primary,
    },
    totalAmount: {
      textAlign: 'right',
      fontSize: '25px',
    },
    verticalLine: {
      borderLeft: '1px solid #d2cccc',
      paddingLeft: '18px',
    },
    footerTotalMount: {
      fontSize: '25px',
      lineHeight: '38px',
      fontWeight: 'bold',
    },
  })
);
/** endregion */

const DICTIONARY = {
  mensajeInitialState: 'Solicitar giro.',
  mensajeProcessState: 'Solicitando giro.',
  h1: 'Total notas de crédito por cobrar',
  h2: 'Monto solicitud',
  h3: 'Notas de crédito',
  h4: 'Monto total a girar',
};

const Footer = (props: IFooter) => {
  const classes = useStyles();
  const [mensaje, setMensaje] = useState(DICTIONARY.mensajeInitialState as string);
  const {
    heightFooter,
    onUpdate,
    disabled,
    data: {
      debtsCreditNote,
      requestAmount,
      creditNote,
      totalMount,
      totalCrediNote,
      totalCreditNoteAvailable,
    },
  } = props;

  const handleUpdateClick = () => {
    setMensaje(DICTIONARY.mensajeProcessState as string);
    onUpdate();
  };
  const creditNoteMsje = `$${creditNote} (${totalCrediNote} notas)`;
  const creditNoteAvailable = `${debtsCreditNote} (${totalCreditNoteAvailable} notas)`;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.contenedor}
      style={{ height: heightFooter }}
    >
      <Grid item id={`gridFooterDebtsCreditNote`} xs={3} sm={3}>
        <Grid item xs={12} sm={12}>
          <label id="h1" className={classes.labelCreditNotes}>
            {DICTIONARY.h1}
          </label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span id={`footerDebtsCreditNote`} className={classes.labelCreditNotes}>
            ${creditNoteAvailable}
          </span>
        </Grid>
      </Grid>
      <Grid item id={`gridFooterRequestAmount`} xs={2} sm={2} className={classes.verticalLine}>
        <Grid item xs={12} style={{ opacity: '60%' }} sm={12}>
          <label id="h2" className={classes.labelCreditNotes}>
            {DICTIONARY.h2}
          </label>
        </Grid>
        <Grid item xs={12} style={{ opacity: '60%' }} sm={12}>
          <span id={`footerRequestAmount`} className={classes.labelCreditNotes}>
            ${requestAmount}
          </span>
        </Grid>
      </Grid>
      <Grid id={`gridFooterCreditNote`} style={{ opacity: '60%' }} item xs={2} sm={2}>
        <Grid item xs={12} sm={12}>
          <label id="h3 " className={classes.labelCreditNotes}>
            {DICTIONARY.h3}
          </label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span id={'footerCreditNote'} className={classes.labelCreditNotes}>
            {creditNoteMsje}
          </span>
        </Grid>
      </Grid>
      <Grid id={`gridFooterTotalMount`} item xs={2} sm={2} className={classes.labelAmount}>
        <Grid item xs={12} sm={12}>
          <label id="h4">{DICTIONARY.h4}</label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span className={classes.footerTotalMount} id={'footerTotalMount'}>
            ${totalMount.toLocaleString()}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={3} sm={3} className={classes.contentbutton}>
        <Button
          id={'footerRequestBnt'}
          variant="contained"
          disabled={disabled}
          startIcon={<ArrowUpwardIcon />}
          className={!disabled ? classes.button : classes.buttonDisabled}
          // style={disabled ? { background: '#E0E0E0' } : {}}
          onClick={handleUpdateClick}
        >
          <span id="footerRequestTurnBtn">{mensaje}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
