import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../../constants';
const heightFooter = '8vh';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
      background: COLORS.sidebar,
      height: heightFooter,
      padding: '5px',
    },
    btnApplyPayment: {
      backgroundColor: COLORS.secondary,
      color: 'white',

      '&$disabled': {
        background: '#EFEFEF',
      },
    },
    disabled: {},
    titleItem: { fontSize: '16px', fontWeight: 'bold' },
    numberItem: { fontSize: '18px', fontWeight: 'bold' },
  })
);

export default useStyles;
