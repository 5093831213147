import { Grid } from '@material-ui/core';
import React from 'react';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { PaymentsPendingConfirmation } from '../../../../models/commons';
import { Loading } from '../../../common/loading';
import ErrorSpareparts from '../../commons/ErrorSpareparts';
import NotFoundSpareparts from '../../commons/NotFoundSpareparts';
import useStyles from './ConfirmPendingSpareParts.style';
import ItemConfirmPendingSpareParts from './ItemConfirmPendingSpareParts';

const ConfirmPendingSpareParts = () => {
  const classes = useStyles();

  const url = `/api/payments/confirm`;
  const {
    status,
    data: confirmPendings,
    error,
    reload,
  } = useFetch<PaymentsPendingConfirmation[]>(url, undefined, BUSINESSTYPE.PARTS);

  if (status === 'error' && error) {
    return (
      <ErrorSpareparts
        StatusCode={error.StatusCode}
        Message={error.Message}
        ErrorId={error.ErrorId}
      />
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && confirmPendings?.length === 0 && (
          <NotFoundSpareparts message="No existen pagos pendientes de confirmación para este cliente." />
        )}
        {status === 'fetched' && confirmPendings && confirmPendings?.length > 0 && (
          <>
            <Grid container item xs={12} className={classes.headerTable}>
              <Grid item xs={3}>
                &nbsp;
              </Grid>
              <Grid item xs={4}>
                ID del pago
              </Grid>
              <Grid item xs={2}>
                Facturas asociadas
              </Grid>
              <Grid item xs={3}>
                Método de pago
              </Grid>
              <Grid item xs={3}>
                &nbsp;
              </Grid>
            </Grid>
            {confirmPendings?.map((pending) => {
              return (
                <ItemConfirmPendingSpareParts
                  key={pending.PaymentId}
                  pending={pending}
                  reload={reload}
                />
              );
            })}
          </>
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default ConfirmPendingSpareParts;
