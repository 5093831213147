import {
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { COLORS } from '../../../../constants';

export const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 'bold',
  },
}))(TableCell);

export const FailedTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(178, 0, 43, 0.17)',
    },
  })
)(TableRow);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 240px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: 'white',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
    },
    title: {
      borderBottom: '1px solid #666666',
      color: COLORS.secondary,
      fontSize: '16px',
    },

    mainTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },

    table: {
      marginTop: '10px',
      boxShadow: '0px 0px 0px 0px',
    },

    headTable: {
      '& > *': {
        color: '#666666',
      },
    },
  })
);

export default useStyles;
