import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ErrorUsados from '../../commons/ErrorUsados';
import { useFetch } from '../../../../hooks/useFetch';
import { Loading } from '../../../common/loading';
import { UsedOperationsChargeDetail } from '../../../../models/used';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import useStyles, { FailedTableRow, StyledTableCell } from './DetalleCargaOperacionesUsados.style';
import DownloadDetalleCargaOperacionesUsados from './DownloadDetalleCargaOperacionesUsados';

type Props = {
  id: string;
};

const DetalleCargaOperacionesUsados: React.FC<Props> = ({ id }) => {
  const classes = useStyles();

  const url = `/api/charge/${id}`;
  const { status, data, error } = useFetch<UsedOperationsChargeDetail>(url);

  if (status === 'error' && error)
    return (
      <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
    );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.title}
              >
                <Grid item className={classes.mainTitle}>
                  {getObjDateLocal(data?.UploadAt, true, 'dd-LL-yyyy').date}
                </Grid>
                <Grid item>
                  <DownloadDetalleCargaOperacionesUsados />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell>Hora de carga</StyledTableCell>
                      <StyledTableCell align="center">VIN Usado</StyledTableCell>
                      <StyledTableCell align="center">VIN Nuevo</StyledTableCell>
                      <StyledTableCell align="center">Estado</StyledTableCell>
                      <StyledTableCell align="center">Cliente</StyledTableCell>
                      <StyledTableCell align="center">N° Factura</StyledTableCell>
                      <StyledTableCell align="center">Monto</StyledTableCell>
                      <StyledTableCell align="center">Dealer</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.Details.map((row) => {
                      return row.IsValid ? (
                        <TableRow key={row.RowId}>
                          <TableCell align="center">
                            {getObjDateLocal(row?.ProcessAt, true, 'dd-LL-yyyy').time.split('.')[0]}
                          </TableCell>
                          <TableCell align="center">{row.UsedVin}</TableCell>
                          <TableCell align="center">{row.NewVin}</TableCell>
                          <TableCell align="center">
                            {row.IsValid ? row.StatusUsedOperation : row.Description}
                          </TableCell>
                          <TableCell align="center">{row.ClientName}</TableCell>
                          <TableCell align="center">{row.InvoiceNumber}</TableCell>
                          <TableCell align="center">{row.Amount}</TableCell>
                          <TableCell align="center">{row.DealerName}</TableCell>
                        </TableRow>
                      ) : (
                        <FailedTableRow key={row.RowId}>
                          <TableCell align="center">
                            {getObjDateLocal(row?.ProcessAt, true, 'dd-LL-yyyy').time.split('.')[0]}
                          </TableCell>
                          <TableCell align="center">{row.UsedVin}</TableCell>
                          <TableCell align="center">{row.NewVin}</TableCell>
                          <TableCell align="center">
                            {row.IsValid ? row.StatusUsedOperation : row.Description}
                          </TableCell>
                          <TableCell align="center">{row.ClientName}</TableCell>
                          <TableCell align="center">{row.InvoiceNumber}</TableCell>
                          <TableCell align="center">{row.Amount}</TableCell>
                          <TableCell align="center">{row.DealerName}</TableCell>
                        </FailedTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetalleCargaOperacionesUsados;
