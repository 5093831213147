import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import ErrorUsados from '../../commons/ErrorUsados';
import { Loading } from '../../../common/loading';
import useStyles from './HistorialPendienteGiroUsados.style';
import { useFetch } from '../../../../hooks/useFetch';
import { UsedMoneyTransferHistoryResponse, UsedPaginationResponse } from '../../../../models/used';
import ItemHistorialPendienteGiroUsados from './ItemHistorialPendienteGiroUsados';
import NotFoundUsados from '../../commons/NotFoundUsados';
import TablePaginationActions from '../../../common/table/tablePaginationActions';

const HistorialPendienteGiroUsados = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const url = `/api/moneyTransfer/history?pageSize=${rowsPerPage}&pageNumber=${page + 1}`;

  const {
    status,
    data: paginationResponse,
    error,
  } = useFetch<UsedPaginationResponse<UsedMoneyTransferHistoryResponse>>(url);

  let moneyTransfers: UsedMoneyTransferHistoryResponse[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    moneyTransfers = paginationResponse?.Result ?? moneyTransfers;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <TableContainer className={classes.root}>
      {status === 'fetching' && <Loading />}
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableBody>
          {status === 'fetched' &&
            moneyTransfers?.map((transfer) => {
              return <ItemHistorialPendienteGiroUsados key={transfer.Date} transfer={transfer} />;
            })}
          {status === 'fetched' && moneyTransfers?.length === 0 && (
            <TableRow className={classes.rowNotFound}>
              <TableCell width="100%" style={{ borderBottom: 'none' }}>
                <NotFoundUsados key={0} message="No existen solicitudes de giros anteriores." />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {status === 'fetched' && moneyTransfers?.length > 0 && (
              <TablePagination
                labelRowsPerPage={() => 'Filas por página:'}
                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                colSpan={3}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default HistorialPendienteGiroUsados;
