import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './CargaOperacionesUsados.style';
import UltimaCargaUsados from '../../commons/UltimaCargaUsados';
import SubirArchivoCargaUsados from '../../commons/SubirArchivoCargaUsados';
import ResultCargaOperacionesUsados from '../ResultCargaOperacionesUsados';
import { postFile } from '../../../../utilities/requestsUsed';
import { UsedApiResponse, UsedOperationsChargeResponse } from '../../../../models/used';

const CargaOperacionesUsados: React.FC = () => {
  const classes = useStyles();

  const [showResult, setShowResult] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<UsedOperationsChargeResponse>(
    {} as UsedOperationsChargeResponse
  );

  const handleUploadFile = async (
    formData: FormData
  ): Promise<UsedApiResponse<UsedOperationsChargeResponse>> => {
    const dataResponse = await postFile<UsedOperationsChargeResponse>(
      '/api/charge/upload',
      formData
    );
    return dataResponse;
  };

  const handleViews = (response: UsedOperationsChargeResponse) => {
    setUploadResponse(response);
    setShowResult(true);
  };

  const handleUploadAgain = () => {
    setShowResult(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <UltimaCargaUsados />

        {!showResult ? (
          <SubirArchivoCargaUsados uploadFile={handleUploadFile} handleViews={handleViews} />
        ) : (
          <ResultCargaOperacionesUsados
            ChargeId={uploadResponse.ChargeId}
            Total={uploadResponse.Total}
            TotalSuccessful={uploadResponse.TotalSuccessful}
            TotalRejected={uploadResponse.TotalRejected}
            FileName={uploadResponse.FileName}
            UploadAt={uploadResponse.UploadAt}
            handleUploadAgain={handleUploadAgain}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CargaOperacionesUsados;
