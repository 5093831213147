import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { UsedGrantedByDealer } from '../../../../models/used';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './OtorgadasUsados.style';

type Props = {
  usedGrantedByDealer: UsedGrantedByDealer;
} & RouteComponentProps;
const ItemOtorgadosUsados: React.FC<Props> = (props) => {
  const { usedGrantedByDealer } = props;
  const classes = useStyles();

  const handleDetailClick = (id = '') => {
    props.history.push(`/usados/otorgadas/:${id}`);
  };

  return (
    <div className={classes.item}>
      <div className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item xs={5} sm={5} md={6} className={classes.columnDetail}>
            <div className={classes.dealerName}>{usedGrantedByDealer.DealerName}</div>
            <div>
              <span className={classes.dealerRut}>
                {rutFormater(
                  usedGrantedByDealer.DealerRut.toString() +
                    usedGrantedByDealer.DealerVerificationNumber
                )}
              </span>
              <span className={classes.vins}>
                | VIN Otorgados {usedGrantedByDealer.GrantedVins}
              </span>
            </div>
          </Grid>
          <Grid item xs={7} sm={7} md={6} className={classes.watchDetail}>
            <Button
              color="primary"
              onClick={() => handleDetailClick(usedGrantedByDealer.ClientDealerId)}
            >
              Revisar detalle
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(ItemOtorgadosUsados);
