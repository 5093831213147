import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { FormControl, TextField } from '@material-ui/core';
import { ITannerCreditPaymentDetail } from '../../../../../interfaces';

const MdlItemTanner = (props: any) => {
  const { classes, setCreditTannerData } = props;

  const setITannerCreditPaymentDetail = (tannerCreditNumber: string) => {
    const ITannerCreditPaymentDetailNew = {
      TannerCreditNumber: tannerCreditNumber,
    } as ITannerCreditPaymentDetail;

    setCreditTannerData(ITannerCreditPaymentDetailNew);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.formControl}
    >
      <Grid item style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}>
        Número de crédito Tanner
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item md={12}>
            <TextField
              id="txtNumberCreditTanner"
              type="number"
              margin="normal"
              variant="filled"
              onChange={(e) => setITannerCreditPaymentDetail(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MdlItemTanner;
