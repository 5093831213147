import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

import { IParamsTitleDetails } from '../../interfaces';
import { COLORS } from '../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: 120,
      paddingTop: '120px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    boton: {
      color: '#FFFFFF',
      padding: 10,
      background: COLORS.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      borderRadius: 4,
      width: 44,
      height: 44,
      cursor: 'pointer',
    },
    tituloContainer: {
      height: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    text: {
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      fontSize: 24,
    },
  })
);
/** endregion */

interface IProps {
  title: string;
  onShowDetails: (params: IParamsTitleDetails) => void;
}

const TitleDetails = (props: IProps) => {
  const classes = useStyles();
  const { title, onShowDetails } = props;

  const handlerShowDetails = () => {
    const params = {
      visible: false,
      id: '',
      text: '',
    } as IParamsTitleDetails;

    onShowDetails(params);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.header}
    >
      <Grid item sm={12} className={classes.tituloContainer}>
        <ArrowBackIosOutlinedIcon className={classes.boton} onClick={handlerShowDetails} />
        <Typography variant="h4" className={classes.text}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleDetails;
