import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './HistorialPagoUsadosPage.style';
import HistorialPagosUsados from '../../../../components/used/paymentPending/HistorialPagosUsados';
import clsx from 'clsx';

const HistorialPagoUsadosPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'otorgadas') props.history.push('/usados/otorgadas');
    else if (valueClicked === 'pendientes pago')
      props.history.push('/usados/otorgadas/pendientes-pago');
    else props.history.push('/usados/otorgadas/pendientes-confirmacion');
  };

  const handleClickSubTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'aplicar pago') props.history.push('/usados/otorgadas/pendientes-pago');
    else props.history.push('/usados/otorgadas/pendientes-pago/historial');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Pendientes de pago">
            <Grid container className={classes.tabNavigation}>
              <Grid
                item
                sm={4}
                className={clsx(classes.tab, classes.borderBottom)}
                onClick={handleClickOnChangeTab}
              >
                OTORGADAS
              </Grid>
              <Grid item sm={4} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
                PENDIENTES PAGO
              </Grid>
              <Grid
                item
                sm={4}
                className={clsx(classes.tab, classes.borderBottom)}
                onClick={handleClickOnChangeTab}
              >
                PENDIENTES CONFIRMACIón
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>
      <Grid container className={classes.subTabNavigation}>
        <Grid item sm={2} className={classes.tab} onClick={handleClickSubTab}>
          Aplicar pago
        </Grid>
        <Grid item sm={2} className={classes.tabSelected} onClick={handleClickSubTab}>
          Historial de pagos
        </Grid>
      </Grid>

      <HistorialPagosUsados />
    </MainContainer>
  );
};

export default HistorialPagoUsadosPage;
