import React, { useEffect, useState, ChangeEvent } from 'react';
import { Grid, Select, MenuItem, TextField } from '@material-ui/core';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Title from '../../../../common/title';
import UploadFile from '../../../../common/uploadfile';
import BarInfoUpload from '../../../../common/header/barinfoupload';

import { chargeOperationsServices } from '../../../../../services/chargeOperationsServices';

import MainContainer from '../../../../maincontainer';
import { IChargeOperations } from '../../../../../interfaces';
import { masterServices } from '../../../../../services/masterServices';
import { COLORS, TABS } from '../../../../../constants';
import { ICreditLines } from '../../../../../models/commons/CreditLines';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cajaContainer: {
      fontFamily: 'Nunito Sans',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      padding: '0 20px',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    detalles: {
      color: '#666666',
      fontSize: 16,
      height: 72,
    },
    file: {
      display: 'flex',
      alignItems: 'center',
    },
    tabContainer: {
      backgroundColor: COLORS.primary,
      height: 48,
      paddingLeft: 20,
      paddingRight: 20,
    },
    tab: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      background: '#FFFFFF',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
      ...tabStylesObj,
    },
    select: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    selectItem: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
  })
);

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.primary,
    },
    '&:hover fieldset': {
      borderColor: COLORS.primary,
      borderWidth: 2,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primary,
    },
  },
});
/** endregion */

interface IProps extends RouteComponentProps {}

const noSelected = 'none';

const ViewCargaOperaciones = (props: IProps) => {
  const classes = useStyles();

  const [creditLines, setCreditLines] = useState([] as Array<ICreditLines>);
  const [creditLineType, setCreditLineType] = useState(noSelected);
  const [botonDisabled, setBotonDisabled] = useState(true);

  const handleGetCreditLines = async () => {
    masterServices.getCreditLineTypes().then((response) => {
      setCreditLines(response.data);
    });
  };

  useEffect(() => {
    if (!(creditLines.length > 0)) {
      handleGetCreditLines();
    }
  }, []);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setBotonDisabled(false);
    setCreditLineType(event.target.value as string);
  };

  const handleHiddenDownload = () => {
    const headerContainerDownload = document.getElementById('containerdownload');
    if (headerContainerDownload) {
      headerContainerDownload.style.visibility = 'hidden';
    }
  };

  const handleFunctionLoadFile = async (params: FormData, key: any) => {
    let creditLineType = document.getElementsByName('creditLineType')[0] as HTMLInputElement;
    const request = {
      CreditLineType: creditLineType.value,
      File: params,
    } as IChargeOperations;
    return await chargeOperationsServices.processOperationFile(request);
  };

  const hadleSeeTabHistorialClick = async () => {
    props.history.push('/nuevos/carga-operaciones/historial');
  };

  useEffect(() => {
    handleHiddenDownload();
  });

  const onShowTitle = () => {
    return (
      <Title
        title="Carga de archivo"
        mostrarTabSubirExcel={true}
        tabSelected={TABS.CHARGE_OPERATIONS}
      />
    );
  };

  return (
    <MainContainer>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.tabContainer}
        >
          <Grid item sm={2} className={classes.tabSelected}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tab} onClick={hadleSeeTabHistorialClick}>
            Historial
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item className={classes.cajaContainer}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              <Grid item md={6} className={classes.detalles}>
                <BarInfoUpload isHeader={false} />
                <Grid item md={6} className={classes.detalles}>
                  <StyledTextField
                    value={creditLineType}
                    onChange={handleChange}
                    name="creditLineType"
                    className={classes.select}
                    variant="outlined"
                    select
                  >
                    <MenuItem disabled value={noSelected}>
                      <em>Seleccione el tipo de línea de crédito</em>
                    </MenuItem>

                    {creditLines.map((linea: ICreditLines, index: number) => {
                      return (
                        <MenuItem
                          key={index}
                          value={linea.CreditLineTypeId}
                          className={classes.selectItem}
                        >
                          {linea.Name}
                        </MenuItem>
                      );
                    })}
                  </StyledTextField>
                </Grid>
              </Grid>

              <Grid>
                <UploadFile
                  key="csv"
                  formato=".csv"
                  showfooter={false}
                  uploadFile={handleFunctionLoadFile}
                  loadComponent="Loadpayroll"
                  buttonDisabled={botonDisabled}
                  creditLineType={creditLineType}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default withRouter(ViewCargaOperaciones);
