import React from 'react';
import config from '../../../../config/api';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { LastChargeOperation } from '../../../../models/commons';
import {
  convertDateReadableFormat,
  getDiffBetweenDates,
} from '../../../../utilities/dates-functions';
import useStyles from './LastChargeOperations.style';

type Props = {
  isHeader?: boolean;
};

const LastChargeOperations: React.FC<Props> = ({ isHeader = false }) => {
  const classes = useStyles({ isHeader });
  const url = `${config.sparePartSettings.apiUri}/api/charges/last`;

  let lastUpload = '';
  let dateUpload = '';

  const handleDateChange = (date: string | undefined) => {
    if (date === undefined) {
      lastUpload = 'No existe carga inicial para operaciones de repuestos';
      dateUpload = '';
    } else {
      dateUpload = convertDateReadableFormat(date.replace('Z', ''), true);
      const between = getDiffBetweenDates(date);
      if (between !== '') {
        lastUpload = `Hace ${between}`;
      }
    }
  };

  const { status, data, error } = useFetch<LastChargeOperation>(url, undefined, BUSINESSTYPE.PARTS);

  if (status === 'fetched' || status === 'error') {
    if (!data || error) {
      handleDateChange('');
    } else {
      handleDateChange(data.UploadAt);
    }
  }

  return (
    <div className={classes.root}>
      {status === 'fetched' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            {lastUpload}
          </div>
          <div>{dateUpload}</div>
        </>
      )}
      {status === 'error' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            Ocurrió un error obteniendo la última carga.
          </div>
        </>
      )}
    </div>
  );
};

export default LastChargeOperations;
