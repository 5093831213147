import { createStyles, makeStyles, TableCell, Theme, withStyles } from '@material-ui/core';
import { COLORS } from '../../../../../constants';

export const StyledTableCell = withStyles(() => ({
  head: {
    color: '#666666',
    fontSize: '16px',
    padding: '10px',
  },
  body: {
    padding: '10px',
    fontSize: '14px',
    border: 'none',
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#666666',
      fontSize: '12px',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      flexWrap: 'nowrap',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
      marginBottom: '20px',
      borderBottom: 'none',
    },
    errorContainer: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow:
        '0 2px 4px 0 rgba(255,0,0,0.46), 0px 6px 6px 6px rgba(255,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
      color: 'red',
      fontWeight: 'bold',
      fontSize: '16px',
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '77px',
      flexWrap: 'nowrap',
      marginBottom: '20px',
    },
    columnPaymentId: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    columnDealer: {
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '290px',
    },
    column: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actions: {
      width: '450px',
    },
    btnReject: {
      color: 'white',
      fontSize: '1rem',
      background: COLORS.secondary,
      lineHeight: '0px',
      minWidth: '132px',
      textTransform: 'none',
    },
    btnPaid: {
      color: 'white',
      fontSize: '1rem',
      background: COLORS.secondary,
      minWidth: '132px',
      lineHeight: '0px',
      textTransform: 'none',
    },
    btnConfirm: {
      color: 'white',
      fontSize: '1rem',
      background: COLORS.secondary,
      textTransform: 'none',
    },
  })
);

export default useStyles;
