import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import Icons from './icons';

import newRoutes from '../menusettings';
import usedRoutes from '../usedMenuSettings';
import sparePartRoutes from '../sparePartMenuSettings';
import freeZoneRoutes from '../freeZoneMenuSettings';

import LoadIcon from './loadIcon';

import { IRoute } from '../../interfaces';
import authorizationServices from '../../services/authorizationServices';
import { useAuth } from './auth';

import { createStorage, StorageKeys } from '../../utilities/storageHelper';
import { BusinessTypeEnum } from '../../enums';
import config from '../../config/api';
import { COLORS } from '../../constants';

// #region Style
const drawerWidth = 240;
const objIconCajaStyles = {
  width: 35,
  height: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: 'pink',
    },
    drawer: {
      backgroundColor: COLORS.sidebar,
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: COLORS.tertiary,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: COLORS.sidebar,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) - 4, // en esta linea se maneja el ancho cuando el menu se colapsa (se cierra)
      },
    },
    toolbar: {
      backgroundColor: COLORS.sidebar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      backgroundColor: 'yellow',
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    Principaltext: {
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      fontSize: 17,
      color: COLORS.primary,
    },
    List: {
      height: 750,
    },
    iconNavbar: {
      ...objIconCajaStyles,
    },
    iconNavbarActive: {
      backgroundColor: COLORS.quaternary,
      borderRadius: 3,
      ...objIconCajaStyles,
    },
    menu: {
      paddingLeft: 12,
    },
    menuExit: {
      fontFamily: 'Nunito Sans',
      color: COLORS.primary,
    },
    textExit: {
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      fontSize: 17,
      color: COLORS.primary,
    },
    icon: {
      color: COLORS.primary,
    },

    botomSideBar: {
      position: 'absolute',
      bottom: '0px',
    },
  })
);

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'red',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&$selected:hover': {
      backgroundColor: 'purple',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: COLORS.secondary,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
  selected: {},
})(MuiListItem);
// #endregion

const Menu = (props: RouteComponentProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [routeSelected, setRouteSelected] = useState({} as IRoute);

  const { setIsAuthenticated, businessType, setBusinessType } = useAuth();

  let routes = newRoutes;

  switch (true) {
    case businessType === BusinessTypeEnum.Usados:
      routes = usedRoutes;
      break;
    case businessType === BusinessTypeEnum.Repuestos:
      routes = sparePartRoutes;
      break;
    case businessType === BusinessTypeEnum.ZonaFranca:
      routes = freeZoneRoutes;
      break;
    default:
      routes = newRoutes;
      break;
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const handleChangeView = (path: string) => {
    const headerContainerDownload = document.getElementById('containerdownload');
    if (headerContainerDownload) {
      headerContainerDownload.style.visibility = 'visible';
      history.push(path);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setBusinessType('');
    authorizationServices.logout();
    props.history.push('/login');
  };

  const handleChangeBusinessType = () => {
    createStorage(StorageKeys.BusinessType).clear();
    setBusinessType('');
    props.history.replace('/negocio');
  };

  useEffect(() => {
    const run = () => {
      routes.map((route: any) => {
        if (route.path.toLowerCase() === window.location.pathname.toLowerCase()) {
          setRouteSelected(route);
        }
      });
    };

    run();
  }, []);

  return (
    <div className={classes.root} onMouseLeave={handleDrawerClose}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar} onMouseOver={handleDrawerOpen}>
          <IconButton edge="start">
            {open ? (
              <CloseRoundedIcon color="inherit" htmlColor={COLORS.primary} />
            ) : (
              <MenuOutlinedIcon color="inherit" htmlColor={COLORS.primary} />
            )}
          </IconButton>
        </div>
        <Divider />
        <>
          <List className={classes.List}>
            {routes
              .filter((route) => route.menu)
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((route: any) => (
                <ListItem
                  key={route.order}
                  id={route.icon}
                  button
                  className={classes.menu}
                  onClick={() => handleChangeView(route.path)}
                >
                  <Tooltip title={route.name}>
                    <ListItemIcon>
                      <LoadIcon
                        componente={route.icon}
                        dataCss={
                          routeSelected.pathselected === route.path
                            ? classes.iconNavbarActive
                            : classes.iconNavbar
                        }
                        isActive={routeSelected.pathselected === route.path}
                      />
                    </ListItemIcon>
                  </Tooltip>

                  <ListItemText className={classes.Principaltext} primary={route.name} />
                </ListItem>
              ))}
          </List>

          <div className={classes.botomSideBar}>
            <Divider />

            <List className={classes.menuExit}>
              <ListItem button onClick={handleChangeBusinessType}>
                <Tooltip title="Cambiar de negocio">
                  <ListItemIcon>
                    <Icons name="repeat" size="26px" cssClassName={classes.icon} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText className={classes.textExit} primary="Cambiar de negocio" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <Tooltip title="Cerrar sesión">
                  <ListItemIcon>
                    <Icons name="exit" size="26px" cssClassName={classes.icon} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText className={classes.textExit} primary="Cerrar sesión" />
              </ListItem>
            </List>
          </div>
        </>
      </Drawer>
    </div>
  );
};

export default withRouter(Menu);
