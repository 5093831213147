import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },

    boxDetail: {
      fontFamily: 'Nunito Sans',
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '87px',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0,0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: '20px',
    },

    body: {
      padding: '25px 20px 0px 20px',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
    },

    bodyDetail: {
      height: '100%',
    },

    dealerName: {
      color: COLORS.secondary,
      fontSize: '18px',
      fontWeight: 'bold',
    },

    dealerRut: {
      fontWeight: 'bold',
      marginRight: '5px',
      color: COLORS.secondary,
    },

    vins: {
      fontWeight: 'bold',
      marginRight: '5px',
    },

    columnDetail: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: '14px',
    },

    watchDetail: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '16px',
    },
    table: {
      height: '100%',
    },
    buttonApplyPayment: {
      color: COLORS.secondary,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })
);

export default useStyles;
