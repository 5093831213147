import React from 'react';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../components/maincontainer';
import Title from '../../../components/common/title';
import MoneyTransferPending from '../../../components/spareParts/moneyTransferPending/MoneyTransferPending';
import useStyles from './MoneyTransferPending.style';
import { RouteComponentProps } from 'react-router';

const MoneyTransferPendingPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickHistoricPending = () => {
    props.history.push('/repuestos/pendientes-giro/historial');
  };

  return (
    <MainContainer>
      <Grid container id="title" className={classes.header}>
        <Grid item sm={12}>
          <Title title="Pendientes de giro">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tabSelected}>
                PENDIENTES DE GIRO
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickHistoricPending}>
                HISTÓRICO DE GIROS
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>
      <MoneyTransferPending />
    </MainContainer>
  );
};

export default MoneyTransferPendingPage;
