import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

export type CompanyLogoStyleProps = {
  isHeader: boolean;
};

const useStyles = makeStyles(() => ({
  texto: (props: CompanyLogoStyleProps) => ({
    fontFamily: 'Nunito Sans',
    marginLeft: '20px',
    textTransform: 'uppercase',
    fontWeight: 700,
    color: !props.isHeader ? COLORS.primary : '#FFFFFF',
    fontSize: '18px',
  }),
  logo: {
    width: '160px',
    height: 'auto',
  },
}));

export default useStyles;
