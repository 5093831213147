import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      background: theme.palette.credinissanHeaderBackground.default,
      color: '#ffffff',
      height: '200px',
      padding: '10px 20px 0px 20px',
    },
  })
);

export default useStyles;
