import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Checkbox,
  Button,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import useStyles, { StyledTableCell } from './DetailApplyPaymentFreeZone.style';
import { Loading } from '../../../common/loading';
import { BUSINESSTYPE, COLORS } from '../../../../constants';
import config from '../../../../config/api';
import Error from '../../../common/Error';
import FooterDetailApplyPaymentFreeZone from './footerDetailApplyPaymentFreeZone/FooterDetailApplyPaymentFreeZone';
import ItemApplyPaymentFreeZone from './itemApplyPaymentFreeZone/ItemApplyPaymentFreeZone';
import { FreeZoneDetailPaymentPending } from '../../../../models/freeZone/FreeZoneDetailPaymentPeding';

type Props = {
  clientDealerId: string;
  changeTitle: (t: string) => void;
} & RouteComponentProps;

const DetailApplyPaymentFreeZone: React.FC<Props> = (props) => {
  const { clientDealerId, changeTitle } = props;
  const classes = useStyles();

  const [checked, setChecked] = React.useState<string[]>([]);
  const [checkedAll, setCheckedAll] = React.useState<boolean>(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [invoiceNumber, setInvoiceNumber] = React.useState<number | string>('');
  const [vinFilter, setVinFilter] = React.useState<string>('');
  const [pendingPayments, setPendingPayments] = React.useState<FreeZoneDetailPaymentPending[]>([]);
  const [selectedVIN, setSelectedVIN] = React.useState<number>(0);
  const [operationIds, setOperationIds] = React.useState<string[]>([]);

  const url = `${config.freeZoneSettings.apiUri}/api/payments/pendings/${clientDealerId}`;
  const {
    status,
    data: pendingPaymentsAll,
    error,
    reload,
  } = useFetch<FreeZoneDetailPaymentPending[]>(url, undefined, BUSINESSTYPE.FREEZONE);

  React.useEffect(() => {
    if (pendingPaymentsAll && pendingPaymentsAll?.length > 0) {
      setPendingPayments(pendingPaymentsAll);
    }
  }, [pendingPaymentsAll]);

  // Función para actualizar el listado de facturas por VIN y/o número de factura
  const handleFilter = () => {
    if (pendingPaymentsAll) {
      if (invoiceNumber && vinFilter) {
        setPendingPayments(
          pendingPaymentsAll.filter((p) => p.InvoiceNumber === invoiceNumber && p.VIN === vinFilter)
        );
      } else if (invoiceNumber) {
        setPendingPayments(pendingPaymentsAll.filter((p) => p.InvoiceNumber === invoiceNumber));
      } else if (vinFilter) {
        setPendingPayments(pendingPaymentsAll.filter((p) => p.VIN === vinFilter));
      } else {
        setPendingPayments(pendingPaymentsAll);
      }
    }
  };

  // Función para el onChange de los checkbox de ItemApplyPayment
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    handleIsAllCheckedChange(newChecked);
    setChecked(newChecked);
  };

  React.useEffect(() => {
    if (status === 'fetched' && pendingPaymentsAll?.length === 0) {
      props.history.replace(`/zona-franca/otorgadas/aplicacion-pago/`);
    } else if (status === 'fetched') {
      changeTitle(pendingPaymentsAll?.[0].DealerName ?? '');
      setTotalAmount(0);
      setChecked([]);
      setOperationIds([]);
    }
  }, [status]);

  React.useEffect(() => {
    let totAmount: number = 0;
    const ids: string[] = [];

    checked.map((ch: string) => {
      if (pendingPaymentsAll) {
        const operation = pendingPaymentsAll.find((payment) => payment.VIN === ch);
        const amount =
          operation !== undefined ? (operation as FreeZoneDetailPaymentPending).Amount : 0;
        totAmount += amount;

        if (operation !== undefined) {
          ids.push(operation.FreeZoneOperationId);
        }
      }
      return true;
    });

    setTotalAmount(totAmount);
    setSelectedVIN(checked.length);
    setOperationIds(ids);
  }, [checked, pendingPaymentsAll]);

  /**
   * verifica si todas las operaciones estan checkeadas
   * @param newChecked array con el rut dealer
   */
  const handleIsAllCheckedChange = (newChecked: string[]) => {
    let dataNotChecked = pendingPaymentsAll?.filter((data) => newChecked.indexOf(data.VIN) < 0);
    let existDataNotChecked = dataNotChecked && dataNotChecked.length > 0 ? true : false;
    setCheckedAll(!existDataNotChecked);
  };

  // Check de todas las operaciones
  const handleToggleAll = () => {
    setCheckedAll(checkedAll ? false : true);
    const newChecked: string[] = [];

    if (!checkedAll) {
      pendingPaymentsAll?.map((data) => {
        if (data.Amount > 0) {
          newChecked.push(data.VIN);
        }
      });
    }
    setChecked(newChecked);
  };

  if (status === 'error' && error)
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <div className={classes.searchForm}>
                <div className={classes.searchInput}>
                  <label htmlFor="vinVehicle">VIN</label>
                  <TextField
                    placeholder="Ingrese VIN del vehiculo"
                    id="vinVehicle"
                    style={{ minWidth: '25%' }}
                    value={vinFilter || ''}
                    variant="outlined"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVinFilter(e.target.value);
                    }}
                  />
                </div>
                <div className={classes.searchInput}>
                  <label htmlFor="invoiceNumber">N° factura cliente</label>
                  <TextField
                    placeholder="Ingrese N° factura cliente"
                    id="invoiceNumber"
                    style={{ minWidth: '25%' }}
                    value={invoiceNumber || ''}
                    variant="outlined"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInvoiceNumber(Number(e.target.value));
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  className={classes.btnFilter}
                  onClick={() => handleFilter()}
                >
                  <SearchIcon /> Filtrar
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell>
                        <Checkbox
                          style={{ color: COLORS.secondary }}
                          onClick={handleToggleAll}
                          checked={checkedAll}
                          inputProps={{ 'aria-label': 'Todos los items seleccionados' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>Días Transcurridos</StyledTableCell>
                      <StyledTableCell>VIN</StyledTableCell>
                      <StyledTableCell>Monto Neto</StyledTableCell>
                      <StyledTableCell>NC</StyledTableCell>
                      <StyledTableCell>N°Factura Cliente</StyledTableCell>
                      <StyledTableCell>Fecha Emisión</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingPayments?.map((item) => {
                      return (
                        <ItemApplyPaymentFreeZone
                          key={item.FreeZoneOperationId}
                          checked={checked}
                          pendingPayment={item}
                          handleToggle={handleToggle}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
      <FooterDetailApplyPaymentFreeZone
        amount={totalAmount}
        selectedVIN={selectedVIN}
        operationIds={operationIds}
        clientDealerId={clientDealerId}
        reload={reload}
      />
    </Grid>
  );
};

export default withRouter(DetailApplyPaymentFreeZone);
