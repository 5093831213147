import React, { ChangeEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';

import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';

import { IBankAccounts } from '../../../interfaces';

import { masterServices } from '../../../services/masterServices';
import { COLORS } from '../../../constants';

/** #region estilos */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    contenttitle: {
      height: 95,
    },
    title: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 24,
      fontWeight: 'bold',
      width: 536,
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 16,
    },
    contentbody: {
      height: 95,
    },
    formulario: {
      width: '100%',
      padding: '20px 0 0 0',
    },
    select: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    selectItem: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    contentbotones: {
      height: 60,
    },
    botonCancelar: {
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
    botonDescargar: {
      width: 224,
      height: 36,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 15,
      fontWeight: 'bold',
      marginLeft: 30,
    },
    botonIcon: {
      fontSize: 24,
      marginRight: 10,
    },
  })
);
/** #endregion */

interface IDialogProps {
  open: boolean;
  //selectedValue: string
  onClose: () => void;
  onDownload: (value: string) => void;
}

interface ICuenta {
  key: string;
  bankName: string;
  bankAccountNumber: string;
}

const noSelected = 'none';

function ModalDescarga(props: IDialogProps) {
  const classes = useStyles();

  const { open, onClose, onDownload } = props;

  const [cuentas, setCuentas] = useState([] as Array<ICuenta>);
  const [cuenta, setCuenta] = useState(noSelected);
  const [selectedCuenta, setSelectedCuenta] = useState<string>('');
  const [botonDisabled, setBotonDisabled] = useState(true);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setBotonDisabled(false);
    setCuenta(event.target.value as string);
    setSelectedCuenta(event.target.value as string);
  };

  // const handleCuentaChange = () => {
  //     let value = noSelected

  //     if (cuentas.filter(cuenta => cuenta.key === selectedValue).length > 0) {
  //         value = selectedValue
  //     }

  //     setCuenta(value)
  // }

  const handleCloseClick = () => {
    setCuenta(noSelected);
    setBotonDisabled(true);
    onClose();
  };

  const handleDownloadClick = () => {
    setCuenta(noSelected);
    setBotonDisabled(true);
    onDownload(selectedCuenta);
  };

  const handleDataCuentasTransform = (dataBankAccounts: Array<IBankAccounts>) => {
    const currentDataTransform = [] as Array<ICuenta>;

    dataBankAccounts.map((bankAccount: IBankAccounts, index: number) => {
      currentDataTransform.push({
        key: bankAccount.BankAccountId,
        bankName: bankAccount.BankName,
        bankAccountNumber: bankAccount.BankAccountNumber,
      } as ICuenta);
    });

    setCuentas(currentDataTransform);
  };

  const handleGetBankAccounts = async () => {
    masterServices.getBankAccounts().then((response) => {
      handleDataCuentasTransform(response.data);
    });
  };

  useEffect(() => {
    if (!(cuentas.length > 0)) {
      handleGetBankAccounts();
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      aria-labelledby="form-dialog-title"
      className={classes.container}
    >
      <DialogTitle id="form-dialog-title" className={classes.contenttitle}>
        <div className={classes.title}>Configurar descarga</div>
        <div className={classes.subtitle}>Seleccione la cuenta a girar</div>
      </DialogTitle>
      <DialogContent className={classes.contentbody}>
        <FormControl className={classes.formulario}>
          <Select
            value={cuenta}
            onChange={handleChange}
            displayEmpty
            name="cuenta"
            className={classes.select}
          >
            <MenuItem disabled value={noSelected}>
              <em>Seleccione la cuenta a girar</em>
            </MenuItem>

            {cuentas.map((cuenta: any, index: number) => {
              return (
                <MenuItem key={index} value={cuenta.key} className={classes.selectItem}>
                  {cuenta.bankName} - {cuenta.bankAccountNumber}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.contentbotones}>
        <Button color="primary" className={classes.botonCancelar} onClick={handleCloseClick}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={botonDisabled}
          className={classes.botonDescargar}
          onClick={handleDownloadClick}
        >
          <SaveAltRoundedIcon className={classes.botonIcon} />
          Descargar Excel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDescarga;
