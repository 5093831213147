import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { chargeCreditNotesServices } from '../../../../../../services/chargeCreditNotesServices';
import { downloadChargeDetails } from '../../../../../../services/file-manager-service';
import { getObjDateLocal } from '../../../../../../utilities/dates-functions';
import { numberToMiles } from '../../../../../../utilities/string-functions';
import { downloadFiles } from '../../../../../../utilities/download-files';
import { Loading } from '../../../../../common/loading';
import { COLORS } from '../../../../../../constants';
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../../../utilities/storageHelper';
import { BusinessTypeEnum } from '../../../../../../enums';

/** region style */
const containerTitleStyleObj = {
  height: 60,
  color: COLORS.secondary,
  fontFamily: 'Nunito Sans',
  display: 'flex',
  alignItems: 'center',
};
const tituloContainerStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 14,
      paddingBottom: 20,
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: 4,
      width: '100%',
      height: 'auto',
      maxWidth: 4000,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: '0 20px',
      paddingBottom: 20,
      marginBottom: 50,
    },
    containerTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      justifyContent: 'flex-start',
      ...containerTitleStyleObj,
    },
    containerFile: {
      fontSize: 16,
      justifyContent: 'flex-end',
      ...containerTitleStyleObj,
    },
    cabeceraContainer: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    cabeceraContainerTitulo: {
      height: 60,
      fontWeight: 'bold',
      fontSize: 16,
      ...tituloContainerStyleObj,
    },
    detalleContainer: {
      height: 'auto',
      marginBottom: 20,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    detalleContainerCelda: {
      height: 'auto',
      minHeight: 40,
      ...tituloContainerStyleObj,
    },
  })
);
/** endregion */

interface IProps {
  chargeId: string;
  title: string;
}

const ChargeDetails = (props: IProps) => {
  const classes = useStyles();
  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  const styleCss = {
    paddingLeft: 20,
  };

  const { chargeId, title } = props;

  const [loading, setLoading] = useState(true);
  const [dataChargeDetails, setDataChargeDetails] = useState<unknown[]>();
  const [showSuccessDownloadMsg, setShowSuccessDownloadMsg] = useState(false);

  const handleGetChargeDetails = async (chargeId: string) => {
    chargeCreditNotesServices
      .getChargeCreditNoteDetails(chargeId, businessTypeStorage as BusinessTypeEnum)
      .then((response) => {
        setDataChargeDetails(response.data);
        setLoading(false);
      });
  };

  const handlerDownloadExcel = async () => {
    downloadChargeDetails(chargeId).then((response) => {
      downloadFiles(response.data, chargeId, 'xlsx');
      setShowSuccessDownloadMsg(true);
    });
  };

  useEffect(() => {
    handleGetChargeDetails(chargeId);
  }, [chargeId]);

  return (
    <>
      <div className={classes.root}>
        {!loading && (
          <div className={classes.contenedor}>
            <Grid container direction="column">
              <Grid item style={{ borderBottom: '1px solid #979797' }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Grid item md={9} className={classes.containerTitle}>
                    {getObjDateLocal(title, true, 'dd-LL-yyyy').date}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.cabeceraContainer}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Grid item md={1} className={classes.cabeceraContainerTitulo}>
                    Hora de Carga
                  </Grid>
                  <Grid item md={2} className={classes.cabeceraContainerTitulo} style={styleCss}>
                    VIN
                  </Grid>
                  <Grid item md={3} className={classes.cabeceraContainerTitulo}>
                    Estado
                  </Grid>
                  <Grid item md={1} className={classes.cabeceraContainerTitulo}>
                    Cliente
                  </Grid>
                  <Grid item md={1} className={classes.cabeceraContainerTitulo}>
                    N° Nota de Crédito
                  </Grid>
                  <Grid item md={2} className={classes.cabeceraContainerTitulo} style={styleCss}>
                    Monto
                  </Grid>
                  <Grid item md={2} className={classes.cabeceraContainerTitulo}>
                    Dealer
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item>
                {(dataChargeDetails as unknown[]).map((detail: any, index: number) => {
                  return (
                    <Grid
                      key={index}
                      id={'grandtedDetail_' + index}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        borderBottom: `1px solid ${COLORS.quintinary}`,
                      }}
                    >
                      <Grid
                        item
                        sm={1}
                        className={classes.detalleContainerCelda}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {getObjDateLocal(title, true, 'dd-LL-yyyy', 'HH:mm:ss').time}
                      </Grid>
                      <Grid item sm={2} className={classes.detalleContainerCelda} style={styleCss}>
                        {detail.VIN}
                      </Grid>
                      <Grid item sm={3} className={classes.detalleContainerCelda}>
                        {detail.Status
                          ? 'Aceptada - ' + (detail.Paied ? 'Cobrada' : 'Por Cobrar')
                          : 'Rechazada - ' + detail.StatusDescription}
                      </Grid>
                      <Grid item sm={1} className={classes.detalleContainerCelda}>
                        {detail.ClientName}
                      </Grid>
                      <Grid item sm={1} className={classes.detalleContainerCelda}>
                        {detail.CreditNoteNumber}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        className={classes.detalleContainerCelda}
                        style={styleCss}
                      >{`$ ${numberToMiles(detail.CreditNoteAmount)}`}</Grid>
                      <Grid item sm={2} className={classes.detalleContainerCelda}>
                        {detail.DealerName ? detail.DealerName : '-'}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ChargeDetails;
