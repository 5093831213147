import React, { useState, ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, FormControl, Select, MenuItem } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { IPagRequest, IPagResponse } from '../../../interfaces';
import { COLORS } from '../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    contenedor: {
      color: COLORS.sextenary,
      fontFamily: 'Nunito Sans',
      padding: 12,
    },
    formControl: {
      padding: 10,
    },
    select: {
      color: COLORS.sextenary,
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
  })
);

interface IProps {
  data: IPagResponse<any>;
  onUpdate: (pagRequest: IPagRequest, size: number) => void;
  size: number;
}

const options = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
];

const Paginator = (props: IProps) => {
  const classes = useStyles();

  const { data, onUpdate, size } = props;

  const [pageNumber, setPageNumber] = useState(data.CurrentPage);
  const [pageSize, setPageSize] = useState(size);
  const [totalPages, setTotalPages] = useState(data.TotalPages);
  const [totalItems, setTotalItems] = useState(data.TotalItems);

  const handleOnChangeTotalPage = (event: ChangeEvent<{ value: unknown }>) => {
    const size = event.target.value as number;
    setPageSize(size);
    updatePageState(1, size);
  };

  const handleFirstPageButtonClick = () => {
    setPageNumber(1);
    updatePageState(1);
  };

  const handleBackButtonClick = () => {
    const pageBack = pageNumber - 1;
    setPageNumber(pageBack);
    updatePageState(pageBack);
  };

  const handleNextButtonClick = () => {
    const pageNext = pageNumber + 1;
    setPageNumber(pageNext);
    updatePageState(pageNext);
  };

  const handleLastPageButtonClick = () => {
    setPageNumber(totalPages);
    updatePageState(totalPages);
  };

  const updatePageState = (page: number, size: number = pageSize) => {
    const obj = {
      pageNumber: page,
      pageSize: size,
    };
    onUpdate(obj, size);
  };

  return (
    <>
      <div className={classes.contenedor}>
        <span>Filas por página: </span>
        <FormControl className={classes.formControl}>
          <Select
            disableUnderline
            value={pageSize}
            onChange={handleOnChangeTotalPage}
            className={classes.select}
            displayEmpty
          >
            {options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <IconButton onClick={() => handleFirstPageButtonClick()} disabled={pageNumber <= 1}>
          <FirstPageIcon style={{ color: COLORS.secondary }} />
        </IconButton>
        <IconButton onClick={() => handleBackButtonClick()} disabled={pageNumber <= 1}>
          <KeyboardArrowLeftIcon style={{ color: COLORS.secondary }} />
        </IconButton>
        <span>
          {pageNumber}-{totalPages} de {totalItems}
        </span>
        <IconButton onClick={() => handleNextButtonClick()} disabled={pageNumber === totalPages}>
          <KeyboardArrowRightIcon style={{ color: COLORS.secondary }} />
        </IconButton>
        <IconButton
          onClick={() => handleLastPageButtonClick()}
          disabled={pageNumber === totalPages}
        >
          <LastPageIcon style={{ color: COLORS.secondary }} />
        </IconButton>
      </div>
    </>
  );
};

export default Paginator;
