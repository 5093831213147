import axios, { AxiosResponse, AxiosError } from 'axios';
import {} from 'history';
import { ClientInfo, IChargeOperations } from '../interfaces';
import config from '../config/api';
import { rutToNumberWithoutDv } from './string-functions';
import { CancelRequest } from './cancelRequest';
import { StorageKeys } from './storageHelper';

const axiosNoInterceptors = axios.create();

const axiosWithInterceptors = axios.create();

axiosWithInterceptors.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    localStorage.setItem('urlToReturn', window.location.pathname);

    if (error.response) {
      localStorage.setItem('isExceptionError', 'true');
      localStorage.setItem('codeExceptionError', error.response.status.toString());
      localStorage.setItem('traceIdExceptionError', error.response.data.id);
      localStorage.setItem('messageExceptionError', error.response.data.message);
      window.location.reload();
    }
  }
);

const getAuthorizationHeaders = () => {
  const reqHeaders: { [k: string]: any } = {};
  if (localStorage.getItem('userIsLogged') !== null) {
    if (localStorage.getItem('userIsLogged') === 'true') {
      reqHeaders.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
  }
  return reqHeaders;
};

const getClientInfoHeaders = () => {
  const reqHeaders: { [k: string]: any } = {};
  if (localStorage.getItem('userIsLogged') !== null) {
    if (localStorage.getItem('userIsLogged') === 'true') {
      const client = JSON.parse(localStorage.getItem(StorageKeys.Client) as string) as ClientInfo;
      reqHeaders.ClientRut = rutToNumberWithoutDv((client && client.rut) || '0');
    }
  }
  return reqHeaders;
};

export const defaultHeaders = (contentType = 'application/json', withAuthentication = true) => {
  const reqHeaders: { [k: string]: any } = {};
  reqHeaders['Content-Type'] = contentType;
  reqHeaders['api-version'] = config.apiVersion;
  reqHeaders['Ocp-Apim-Subscription-Key'] = config.subscriptionKey;
  reqHeaders['Ocp-Apim-Trace'] = 'true';

  if (withAuthentication) {
    return {
      ...reqHeaders,
      ...getAuthorizationHeaders(),
      ...getClientInfoHeaders(),
    };
  }

  return reqHeaders;
};

const defaultHeadersChargeOperation = (
  contentType = 'application/json',
  creditLineType = '',
  withAuthentication = true
) => {
  const reqHeaders: { [k: string]: any } = {};
  reqHeaders['Content-Type'] = contentType;
  reqHeaders['api-version'] = config.apiVersion;
  reqHeaders['Ocp-Apim-Subscription-Key'] = config.subscriptionKey;
  reqHeaders['Ocp-Apim-Trace'] = 'true';
  reqHeaders.CreditLineTypeId = creditLineType;

  if (withAuthentication) {
    return {
      ...reqHeaders,
      ...getAuthorizationHeaders(),
      ...getClientInfoHeaders(),
    };
  }

  return reqHeaders;
};

export const setHeader = (key: string, value: string) => {
  axiosWithInterceptors.defaults.headers[key] = value;
  axiosNoInterceptors.defaults.headers[key] = value;
};

export const setClientInfoHeader = (clientInfo: ClientInfo) => {
  setHeader('ClientRut', rutToNumberWithoutDv(clientInfo.rut).toString());
  if (clientInfo) {
    localStorage.setItem(StorageKeys.Client, JSON.stringify(clientInfo));
  } else {
    localStorage.removeItem(StorageKeys.Client);
  }
};

export const getParamsFormat = (filter: Object) => {
  let params = '';
  Object.keys(filter).forEach((key) => {
    if (params !== '') {
      params = params + '&';
    }

    if (Array.isArray(filter[key as keyof Object])) {
      const arr: any = filter[key as keyof Object];

      arr.forEach((element: number) => {
        params = params + key + '[]=' + element;
      });
    } else {
      params = params + key + '=' + filter[key as keyof Object];
    }
  });
  return params;
};

export const getUrlFormat = (url: string, params: Object) => {
  if (params) {
    return `${url}?${getParamsFormat(params)}`;
  }
  return url;
};

export const parseJSON = (response: any) => {
  return response.json();
};

export const checkStatus = (response: any) => {
  if (response && response.status >= 200 && response.status < 300) {
    return response;
  }
  return false;
};

export const get = (
  url: string,
  params: Object = {},
  options: RequestInit = {},
  catchGlobalErr = false,
  cancelRequest?: CancelRequest
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.get(`${getUrlFormat(url, params)}`, {
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.get(`${getUrlFormat(url, params)}`, {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const getFromUrl = (
  url: string,
  params: Object = {},
  options: RequestInit = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.get(url, {
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.get(url, {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const getDownloadFile = (
  url: string,
  params: Object = {},
  options: RequestInit = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.get(`${getUrlFormat(url, params)}`, {
      responseType: 'blob',
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.get(`${getUrlFormat(url, params)}`, {
    responseType: 'blob',
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const post = (
  url: string,
  params = {},
  options: RequestInit = {},
  catchGlobalErr = false,
  withAuthentication = true
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.post(url, JSON.stringify(params), {
      headers: {
        ...defaultHeaders('application/json', withAuthentication),
        ...options.headers,
      },
    });
  }
  return axiosWithInterceptors.post(url, JSON.stringify(params), {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const postFormData = (
  url: string,
  options: RequestInit,
  data: FormData,
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.post(url, data, {
      headers: { ...defaultHeaders('multipart/form-data') },
    });
  }
  return axiosWithInterceptors.post(url, data, {
    headers: { ...defaultHeaders('multipart/form-data') },
  });
};

export const postFormDataChargeOperation = (
  url: string,
  options: RequestInit,
  data: IChargeOperations,
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.post(url, data.File, {
      headers: {
        ...defaultHeadersChargeOperation('multipart/form-data', data.CreditLineType),
      },
    });
  }
  return axiosWithInterceptors.post(url, data.File, {
    headers: {
      ...defaultHeadersChargeOperation('multipart/form-data', data.CreditLineType),
    },
  });
};

export const postDownloadFile = (
  url: string,
  params: Object = {},
  options: RequestInit = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.post(url, JSON.stringify(params), {
      responseType: 'blob',
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.post(url, JSON.stringify(params), {
    responseType: 'blob',
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const put = (
  url: string,
  options: RequestInit,
  params: Object = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.put(`${getUrlFormat(url, params)}`, JSON.stringify(options.body), {
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.put(`${getUrlFormat(url, params)}`, JSON.stringify(options.body), {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const putFormData = (
  url: string,
  options: RequestInit,
  data: FormData,
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.put(url, data, {
      headers: { ...options.headers },
    });
  }
  return axiosWithInterceptors.put(url, data, { headers: { ...options.headers } });
};

export const putFromUrl = (
  url: string,
  params: Object = {},
  options: RequestInit = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.put(`${url}`, JSON.stringify(options.body), {
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.put(`${url}`, JSON.stringify(options.body), {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};

export const del = (
  url: string,
  options: RequestInit,
  params: Object = {},
  catchGlobalErr = false
) => {
  if (catchGlobalErr) {
    return axiosNoInterceptors.delete(`${getUrlFormat(url, params)}`, {
      headers: { ...defaultHeaders(), ...options.headers },
    });
  }
  return axiosWithInterceptors.delete(`${getUrlFormat(url, params)}`, {
    headers: { ...defaultHeaders(), ...options.headers },
  });
};
