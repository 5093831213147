import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import ErrorUsados from '../../commons/ErrorUsados';
import useStyles from './AplicarPagosUsados.style';
import NotFoundUsados from '../../commons/NotFoundUsados';
import { Loading } from '../../../common/loading';
import ItemAplicarPagosUsados from './ItemAplicarPagosUsados';
import { PaymentPending } from '../../../../models/commons';

const AplicarPagoUsados = () => {
  const classes = useStyles();

  const url = `/api/payments/pendings`;
  const { status, data: pendingPayments, error } = useFetch<PaymentPending[]>(url);

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          pendingPayments?.map((pending) => {
            return <ItemAplicarPagosUsados key={pending.ClientDealerId} pending={pending} />;
          })}
        {status === 'fetched' && pendingPayments?.length === 0 && (
          <NotFoundUsados message="No existen operaciones pendientes de pago para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default AplicarPagoUsados;
