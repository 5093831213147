import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  BUSINESSTYPE,
  COLORS,
  OPERATION_TAKEN,
  OPERATION_TAKEN_TRANSFORM,
} from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import {
  FreeZoneOperationTakenDetailResponse,
  FreeZoneOperationTakenResponse,
} from '../../../../models/freeZone';
import useStyles from './OperationsFreeZoneTaken.style';
import Error from '../../../common/Error';
import { Loading } from '../../../common/loading';
import { Checkbox, Grid } from '@material-ui/core';
import NotFound from '../../../common/notfound';
import ItemOperationsFreeZoneTaken from './ItemOperationsFreeZoneTaken';
import {
  IFooterDataObject,
  IFreeZoneCreditNotesDetail,
  IFreeZoneCreditNotesAvailableResponse,
  IFreeZoneMoneyOrderRequest,
  IFreeZoneOperationsMoneyOrderRequest,
  IFreeZoneCreditNotesToPayResponse,
  IFreeZoneCreditNotesList,
} from '../../../../interfaces';
import { numberToMiles } from '../../../../utilities/string-functions';
import FooterTaken from './FooterOperationsFreeZoneTaken/FooterOperationsFreeZoneTaken';
import {
  createMoneyOrderRequest,
  getCreditNote,
  getCreditNoteWithOutPay,
} from '../../../../services/money-order-request-service';
import { useAuth } from '../../../common/auth';
import { BusinessTypeEnum } from '../../../../enums';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../utilities/storageHelper';

const OperationsFreeZoneTaken: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<number[]>([]);
  const [anyDisabled, setAnyDisabled] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [dataOperations, setDataOperations] = useState<
    FreeZoneOperationTakenResponse[] | undefined
  >([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [dataSelected, setDataSelected] = useState<IFreeZoneOperationsMoneyOrderRequest[]>([]);
  const [mountDataSelected, setMountDataSelected] = useState(0);
  const [bankAccountIdSelected, setBankAccountIdSelected] = useState<string>('');
  const [notificationDisabled, setNotificationDisabled] = useState(false);
  const [creditNotesAvailable, setCreditNotesAvailable] =
    useState<IFreeZoneCreditNotesAvailableResponse>({} as IFreeZoneCreditNotesAvailableResponse);
  const [sumAmountOperationTaken, setsumAmountOperationTaken] = useState(0 as number);
  const [creditNoteToPay, setCreditNoteToPay] = useState<IFreeZoneCreditNotesToPayResponse>(
    {} as IFreeZoneCreditNotesToPayResponse
  );
  const [creditNoteTotalAmount, setCreditNoteTotalAmount] = useState<number>(0);
  const [countCrediNote, setCountCreditNote] = useState<number>(0);
  const [countCreditNoteAvailable, setCountCreditNoteAvailable] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [businessType] = useLocalStorage(StorageKeys.BusinessType);

  useEffect(() => {
    if (creditNoteToPay && creditNoteToPay.CreditNotes) {
      setCreditNoteTotalAmount(creditNoteToPay.CreditNotes.TotalPrice);
      setCountCreditNote(creditNoteToPay.CreditNotes?.CreditNotes?.length);
      creditNotesAvailable.CreditNotes === undefined
        ? 0
        : setCountCreditNoteAvailable(creditNotesAvailable.CreditNotes.length);
      creditNoteToPay.CreditNotes?.CreditNotes?.length > 0
        ? setTotalAmount(mountDataSelected - creditNoteTotalAmount)
        : setTotalAmount(mountDataSelected);
    }
  }, [
    creditNoteToPay,
    creditNotesAvailable,
    mountDataSelected,
    creditNoteTotalAmount,
    totalAmount,
  ]);

  const url = '/api/taken';
  const {
    status,
    data: takenOperationFreeZone,
    error,
  } = useFetch<FreeZoneOperationTakenResponse[]>(url, undefined, BUSINESSTYPE.FREEZONE);

  useEffect(() => {
    if (status === 'fetched') {
      setDataOperations(takenOperationFreeZone);
    }
  }, [takenOperationFreeZone, status]);

  // handleCheck de cada dealer
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    handleIsAllCheckedChange(newChecked);
    handleMarkOperationsCheckedChange(newChecked);
    setChecked(newChecked);
  };

  /**
   * verifica si todas las operaciones estan checkeadas
   * @param newChecked array con el rut dealer
   */
  const handleIsAllCheckedChange = (newChecked: number[]) => {
    let dataNotChecked = dataOperations?.filter((data) => newChecked.indexOf(data.DealerRut) < 0);
    let existDataNotChecked = dataNotChecked && dataNotChecked.length > 0 ? true : false;
    setCheckedAll(!existDataNotChecked);
  };

  // Check de todos los dealers
  const handleToggleAll = () => {
    setCheckedAll(checkedAll ? false : true);
    const newChecked: number[] = [];

    if (!checkedAll) {
      dataOperations?.map((data) => {
        if (data.AmountDraft > 0) {
          newChecked.push(data.DealerRut);
        }
      });
    }
    handleMarkOperationsCheckedChange(newChecked);
    setChecked(newChecked);
  };

  /**
   * Crear solicitud de giro
   * */
  const handleCreateMoneyOrderRequest = async () => {
    setButtonDisabled(true);
    let moneyRequestOrder = setMoneyOrderRequest();
    createMoneyOrderRequest(moneyRequestOrder, businessType as BusinessTypeEnum).then(
      (response) => {
        props.history.push('/zona-franca/pendientes-giro');
      }
    );
  };

  const setMoneyOrderRequest = (): IFreeZoneMoneyOrderRequest => {
    const creditNotesUsed: IFreeZoneCreditNotesDetail[] =
      creditNoteToPay.CreditNotes === undefined
        ? []
        : mapFreeZoneCreditNotes(creditNoteToPay.CreditNotes.CreditNotes);
    const moneyOrderRequest: IFreeZoneMoneyOrderRequest = {
      FreeZoneBankAccountId: bankAccountIdSelected,
      TotalAmountMoneyTransfer: mountDataSelected,
      TotalOperationFreeZone: sumAmountOperationTaken,
      TotalCreditNotes: creditNotesAvailable.TotalPrice,
      FreeZoneOperations: dataSelected,
      FreeZoneCreditNotes: creditNotesUsed,
    };
    return moneyOrderRequest;
  };

  /**
   * Formatea el array de notas de crédito que serán usadas
   * @param creditNotes Notas de crédito para usar
   * @returns
   */
  const mapFreeZoneCreditNotes = (creditNotes: IFreeZoneCreditNotesList[]) => {
    const creditNotesUsed: IFreeZoneCreditNotesDetail[] = [];
    creditNotes.map((creditNote: IFreeZoneCreditNotesList) => {
      creditNotesUsed.push({ FreeZoneCreditNoteId: creditNote.CreditNoteId });
    });
    return creditNotesUsed;
  };

  // Objeto con información para cargar en el footer
  const footerDataObject: IFooterDataObject = {
    debtsCreditNote: numberToMiles(creditNotesAvailable.TotalPrice),
    requestAmount: numberToMiles(mountDataSelected),
    creditNote: numberToMiles(creditNoteTotalAmount),
    totalMount: numberToMiles(totalAmount),
    totalCrediNote: countCrediNote,
    totalCreditNoteAvailable: countCreditNoteAvailable,
  };

  /**
   * obtener operaciones seleccionadas
   */
  const handleMarkOperationsCheckedChange = (checked: Array<number>) => {
    let currentOperationsSelected = [] as Array<IFreeZoneOperationsMoneyOrderRequest>;
    let currentMountDataSelected = 0;
    let sumAmountOperationTaken = 0;

    checked.map((value: number) => {
      let objOperationSelected: FreeZoneOperationTakenResponse | undefined = dataOperations?.find(
        (data) => data.DealerRut === value
      );

      if (objOperationSelected && objOperationSelected.AmountDraft > 0) {
        mapCursadasDetails(currentOperationsSelected, objOperationSelected.ResponseTakenDetail);
        currentMountDataSelected += objOperationSelected.AmountDraft;
        sumAmountOperationTaken += objOperationSelected.AmountOperationTaken;
      } else {
        setNotificationDisabled(true);
      }
      if (objOperationSelected) {
        setBankAccountIdSelected(objOperationSelected.BankAccountId);
      }
    });
    setsumAmountOperationTaken(sumAmountOperationTaken);
    setDataSelected(currentOperationsSelected);
    creditNotesToPay(currentMountDataSelected);
    setMountDataSelected(currentMountDataSelected);
    setButtonDisabled(!(currentOperationsSelected.length > 0));
  };

  const mapCursadasDetails = (
    currentOperationsSelected: Array<IFreeZoneOperationsMoneyOrderRequest>,
    responseTakenDetail: Array<FreeZoneOperationTakenDetailResponse>
  ) => {
    responseTakenDetail.map((data: FreeZoneOperationTakenDetailResponse) => {
      if (data.InOverdraft !== OPERATION_TAKEN.OUTDRAFT) {
        currentOperationsSelected.push(transformDataDetail(data));
      }
    });
  };

  /**
   * Método para enviar monto total a servicio de notas de crédito para pagar
   * @param currentMountDataSelected Monto total seleccionado
   */
  const creditNotesToPay = async (currentMountDataSelected: number) => {
    getCreditNote(currentMountDataSelected, businessType as BusinessTypeEnum).then((resp) => {
      setCreditNoteToPay(resp.data);
    });
  };

  const transformDataDetail = (data: FreeZoneOperationTakenDetailResponse) => {
    const objResponseTakenDetail = {
      FreeZoneOperationId: data.FreeZoneOperationId,
      InOverdraft:
        data.InOverdraft === OPERATION_TAKEN.CREDIT_LINE
          ? OPERATION_TAKEN_TRANSFORM.CREDIT_LINE
          : OPERATION_TAKEN_TRANSFORM.OVERDRAFT,
    } as IFreeZoneOperationsMoneyOrderRequest;

    return objResponseTakenDetail;
  };

  // useEffect para cargar notas de crédito sin usar
  useEffect(() => {
    const runEffect = async () => {
      getCreditNoteWithOutPay(businessType as BusinessTypeEnum).then((respCreditNote) => {
        setCreditNotesAvailable(respCreditNote.data);
      });
    };
    runEffect();
  }, [setDataOperations]);

  // useEffect para cargar notas de crédito sin usar
  useEffect(() => {
    const runEffect = async () => {
      getCreditNoteWithOutPay(businessType as BusinessTypeEnum).then((respCreditNote) => {
        setCreditNotesAvailable(respCreditNote.data);
      });
    };
    runEffect();
  }, [setDataOperations]);

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <div className={classes.body}>
      {status === 'fetching' && <Loading />}
      {status === 'fetched' && (
        <>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.container}
          >
            {takenOperationFreeZone?.length === 0 ? (
              <NotFound message="No existen operaciones cursadas para este cliente." />
            ) : (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="flex-start"
                  className={classes.header}
                >
                  <Grid item xs={1}>
                    <Checkbox
                      style={{ color: COLORS.secondary }}
                      onClick={handleToggleAll}
                      checked={checkedAll}
                      inputProps={{ 'aria-label': 'all items selected' }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    Operación
                  </Grid>
                  <Grid item xs={3}>
                    Resumen del giro
                  </Grid>
                  <Grid item xs={3}>
                    Distribución del giro
                  </Grid>
                </Grid>
                {takenOperationFreeZone?.map((operationByDealer) => {
                  return (
                    <ItemOperationsFreeZoneTaken
                      key={operationByDealer.ClientDealerId}
                      operation={operationByDealer}
                      checked={checked}
                      handleToggle={handleToggle}
                      setAnyDisabled={setAnyDisabled}
                    />
                  );
                })}
              </>
            )}
          </Grid>
          <div className={classes.footer}>
            <FooterTaken
              onUpdate={handleCreateMoneyOrderRequest}
              disabled={buttonDisabled}
              data={footerDataObject}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(OperationsFreeZoneTaken);
