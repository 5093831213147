import { Grid } from '@material-ui/core';
import React from 'react';
import { IDataLineCreditDealer } from '../../../../models/spareParts/DealerInterfaces';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './CreditLineDetailItem.style';

type Props = {
  dataDealers: IDataLineCreditDealer[];
};

const CreditLineDetailItem: React.FC<Props> = ({ dataDealers }) => {
  const classes = useStyles();

  const HEADER = {
    h2: 'Línea de crédito',
    h3: 'Sobregiro aprobado',
    h4: 'Línea utilizada',
    h5: 'Sobregiro utilizado',
  };

  return (
    <div className={classes.root}>
      {dataDealers.map((details, index: number) => {
        const {
          CreditLine,
          CreditOverdraft,
          CreditOverdraftApproved,
          DealerName,
          DealerRut,
          LineUsed,
          VinAssociate,
          VerificationNumber,
        } = details;
        const rutformat = rutFormater(DealerRut.toString() + VerificationNumber);

        return (
          <div className={classes.container} key={index}>
            <Grid
              key={index}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.body}
            >
              <Grid item xs={5} sm={5} md={6} className={classes.cell}>
                <div className={classes.cellTitleOne}>{DealerName}</div>
                <div className={classes.cellOneDetail}>
                  <span className={classes.delaerRut}>{rutformat}</span>
                  <span className={classes.emphasis}></span>
                </div>
              </Grid>
              <Grid item xs={7} sm={7} md={6} className={classes.cell}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Grid item xs={3} sm={3} className={classes.cellsLeft}>
                    <div className={classes.titleCenter}>{HEADER.h2}</div>
                    <div className={classes.amount}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditLine.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.cellsLeft}>
                    <div className={classes.titleCenter}>{HEADER.h3}</div>
                    <div className={classes.amount}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditOverdraftApproved.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.cellRightPrimary}>
                    <div className={classes.titleRight}>{HEADER.h4}</div>
                    <div className={classes.amount}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {LineUsed.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.cellRightSecundary}>
                    <div className={classes.titleRight}>{HEADER.h5}</div>
                    <div className={classes.amount}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditOverdraft.toLocaleString()}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default CreditLineDetailItem;
