import { Checkbox, Grid } from '@material-ui/core';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { COLORS } from '../../../../constants';
import { TakenOperationStateEnum } from '../../../../enums';
import { FreeZoneOperationTakenResponse } from '../../../../models/freeZone';
import { numberToMiles, rutFormater } from '../../../../utilities/string-functions';
import useStyles from './OperationsFreeZoneTaken.style';

type ContentCellProps = {
  title: string;
  value: string;
};

const ContentCell: React.FC<ContentCellProps> = ({ title, value }) => {
  return (
    <>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        {title}
      </Grid>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        {value}
      </Grid>
    </>
  );
};

type ItemProps = {
  operation: FreeZoneOperationTakenResponse;
  checked: number[];
  handleToggle: (value: number) => () => void;
  setAnyDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type StylesState = {
  classBodyRow: string;
  classRow: string;
  classFooter?: string;
};

const ItemOperationsFreeZoneTaken: React.FC<ItemProps> = (props) => {
  const { operation, checked, handleToggle, setAnyDisabled } = props;
  const classes = useStyles();

  const [draft, setDraft] = useState(TakenOperationStateEnum.CreditLine);
  const [disabledChecked, setDisabledChecked] = useState(false);
  const [styles, setStyles] = useState<StylesState>({
    classBodyRow: classes.bodyRow,
    classRow: classes.row,
  });

  const verifyDraft = useCallback(() => {
    const isOutDraft = find(operation.ResponseTakenDetail, {
      inOverdraft: TakenOperationStateEnum.OutDraft,
    });
    if (isOutDraft) {
      setDraft(TakenOperationStateEnum.OutDraft);
      setStyles({
        classBodyRow: classes.bodyRowRed,
        classRow: classes.rowWithFooter,
        classFooter: classes.footerRed,
      });
      setDisabledChecked(true);
      setAnyDisabled(true);
    } else {
      const isOverDraft = find(operation.ResponseTakenDetail, {
        inOverdraft: TakenOperationStateEnum.OverDraft,
      });

      if (isOverDraft) {
        setDraft(TakenOperationStateEnum.OverDraft);
        setStyles({
          classBodyRow: classes.bodyRowYellow,
          classRow: classes.rowWithFooter,
          classFooter: classes.footerYellow,
        });
        setDisabledChecked(true);
        setAnyDisabled(true);
      }
    }
  }, [
    operation.ResponseTakenDetail,
    classes.rowWithFooter,
    setAnyDisabled,
    classes.bodyRowRed,
    classes.bodyRowYellow,
    classes.footerRed,
    classes.footerYellow,
  ]);

  useEffect(() => {
    verifyDraft();
  }, [verifyDraft]);

  const prettyRut = rutFormater(operation.DealerRut.toString() + operation.VerificationNumber);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="flex-start"
      className={styles.classRow}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        className={styles.classBodyRow}
      >
        <Grid item xs={1}>
          <Checkbox
            style={{ color: COLORS.secondary }}
            disabled={disabledChecked}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={checked.indexOf(operation.DealerRut) !== -1}
            onClick={handleToggle(operation.DealerRut)}
            disableRipple
          />
        </Grid>
        <Grid container item xs={5} style={{ minHeight: '100%', paddingLeft: '0.3em' }}>
          <Grid container direction="column" item xs={5} className={classes.textBlue}>
            <span>{operation.DealerName}</span>
            <span>{prettyRut}</span>
          </Grid>
          <Grid
            container
            item
            xs={4}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell
              title="Monto cursadas"
              value={`$ ${numberToMiles(operation.AmountOperationTaken)}`}
            />
          </Grid>
          <Grid
            container
            item
            xs={3}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell title="Total VIN" value={numberToMiles(operation.NumberOperationTaken)} />
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.textBlue}>
          <Grid
            container
            item
            xs={6}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell title="Monto girar" value={`$ ${numberToMiles(operation.AmountDraft)}`} />
          </Grid>
          <Grid
            container
            item
            xs={6}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell
              title="VIN a girar"
              value={numberToMiles(operation.NumberOperationDraft)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={3}>
          <Grid
            container
            item
            xs={6}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell
              title="Línea de crédito"
              value={`$ ${numberToMiles(operation.LineUsed)}`}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            style={{ minHeight: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <ContentCell
              title="Sobregiro"
              value={`$ ${numberToMiles(operation.CreditOverdraft)}`}
            />
          </Grid>
        </Grid>
      </Grid>

      {draft > 0 && (
        <Grid
          container
          item
          xs={12}
          className={styles.classFooter}
          justifyContent="center"
          alignItems="center"
        >
          {operation.Message}
        </Grid>
      )}
    </Grid>
  );
};

export default ItemOperationsFreeZoneTaken;
