import apiConfig from '../config/api';
import { get, post, put } from '../utilities/request';
import { IPayment } from '../interfaces';

class PaymentServices {
  private baseUrl = `${apiConfig.apiUri}/api/payment`;

  async getPaymentMethod() {
    const url = `${this.baseUrl}/methods`;
    return get(url);
  }

  async createPayment(payment: IPayment) {
    const url = `${this.baseUrl}`;
    return post(url, payment);
  }

  async getPendingConfirmation() {
    const url = `${this.baseUrl}/paymentAppliedPendingConfirmation`;
    return get(url);
  }

  async acceptPendingConfirmation(paymentId: string) {
    const url = `${this.baseUrl}/paymentAppliedPendingConfirmation/confirm`;
    return post(url, { paymentId }, {});
  }

  async deletePendingConfirmation(paymentId: string) {
    const url = `${this.baseUrl}/paymentAppliedPendingConfirmation/refused`;
    return put(url, {}, { paymentId });
  }
}

export const paymentServices = new PaymentServices();
