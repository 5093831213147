import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const flexStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const cellStyleObj = {
  height: 30,
  fontFamily: 'Nunito Sans',
  ...flexStyleObj,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: COLORS.sextenary,
      fontSize: '12px',
    },
    container: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 87,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    emphasis: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: 16,
    },
    cellTitleOne: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
      ...cellStyleObj,
    },
    cellOneDetail: {
      ...cellStyleObj,
    },
    cellsLeft: {},
    cellRightPrimary: {
      alignItems: 'flex-end',
      borderLeft: `1px solid ${COLORS.quintinary}`,
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
    cellRightSecundary: {
      alignItems: 'flex-end',
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
    titleCenter: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
    titleRight: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
    amount: {
      color: COLORS.primary,
    },
    delaerRut: {
      fontWeight: 'bold',
      marginRight: 5,
      color: COLORS.secondary,
    },
  })
);

export default useStyles;
