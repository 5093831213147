import React from 'react';
import '../../styles/tanner-icon-font.css';

interface PropIcon {
  name: string;
  size?: string;
  cssClassName?: string;
}

export default function Icons(props: PropIcon) {
  const icon = 'ticon-' + props.name;
  const size = props.size == null ? '36px' : props.size;

  return <i className={icon + ' ' + props.cssClassName} style={{ fontSize: size }}></i>;
}
