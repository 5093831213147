import { makeStyles, createStyles } from '@material-ui/core';
import { COLORS } from '../../../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: COLORS.secondary,
      color: '#fff',
      textTransform: 'capitalize',
      width: '198px',
    },
    buttonDisabled: {
      color: '#A6A6A6',
      textTransform: 'capitalize',
      width: '198px',
    },
    container: {
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      backgroundColor: COLORS.sidebar,
      color: '#ffffff',
      paddingLeft: '33px',
      paddingRight: '80px',
      fontWeight: 'bold',
    },
    contentbutton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    contentinfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    labelCreditNotes: {
      textAlign: 'center',
      color: COLORS.primary,
    },
    labelAmount: {
      textAlign: 'center',
      paddingLeft: '68px',
      color: COLORS.primary,
    },
    totalAmount: {
      textAlign: 'right',
      fontSize: '25px',
    },
    verticalLine: {
      borderLeft: '1px solid #d2cccc',
      paddingLeft: '18px',
    },
    footerTotalMount: {
      fontSize: '25px',
      lineHeight: '38px',
      fontWeight: 'bold',
    },
  })
);

export default useStyles;
