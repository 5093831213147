import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Icons from './icons';
import { COLORS } from '../../constants';

/** region style */
const buttonContentStyleObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'capitalize',
      padding: 0,
    },
    buttonContentIcon: {
      ...buttonContentStyleObj,
      justifyContent: 'flex-end',
    },
    buttonContentText: {
      ...buttonContentStyleObj,
    },
  })
);
/** endregion */

interface IProps {
  buttonBackgroundColor?: string;
  buttonColor?: string;
  buttonHeight?: number;
  buttonWidth?: number;
  buttonKey: string;
  buttonMarginLeft?: number;
  buttonMarginRight?: number;
  fontFamily?: string;
  fontSize?: number;
  iconName: string;
  iconSize: number;
  label: string;
  objHandleEvent?: any;
}

const ButtonIconText = (props: IProps) => {
  const classes = useStyles();
  const {
    buttonBackgroundColor,
    buttonColor,
    buttonHeight,
    buttonWidth,
    buttonKey,
    buttonMarginLeft,
    buttonMarginRight,
    fontFamily,
    fontSize,
    iconName,
    iconSize,
    label,
    objHandleEvent,
  } = props;
  const [btnDisabled, setBtnDisabled] = useState(false);
  const btnColor = btnDisabled ? '#A6A6A6' : buttonBackgroundColor;
  const cssStyle = {
    buttonStyle: {
      backgroundColor: btnColor,
      color: buttonColor,
      fontFamily,
      fontSize,
      width: buttonWidth,
      height: buttonHeight,
      marginLeft: buttonMarginLeft,
      marginRight: buttonMarginRight,
    },
  };

  const onHandleEventClick = () => {
    if (objHandleEvent && typeof objHandleEvent.execute === 'function') {
      const params = objHandleEvent.params ? objHandleEvent.params : {};
      if (params.key) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
      objHandleEvent.execute(params.id);
    }
  };

  return (
    <Button
      disabled={btnDisabled}
      variant="contained"
      className={classes.button}
      style={cssStyle.buttonStyle}
      onClick={onHandleEventClick}
      key={buttonKey}
      id={buttonKey}
    >
      <Grid container direction="row" justifyContent="center">
        {iconSize > 0 && (
          <Grid item md={2} className={classes.buttonContentIcon}>
            <Icons name={iconName} size={`${iconSize}px`} />
          </Grid>
        )}
        <Grid item md={iconSize > 0 ? 8 : 12} className={classes.buttonContentText}>
          {label}
        </Grid>
      </Grid>
    </Button>
  );
};

ButtonIconText.defaultProps = {
  buttonBackgroundColor: COLORS.secondary,
  buttonColor: '#FFFFFF',
  buttonHeight: 28,
  buttonWidth: 138,
  buttonMarginLeft: 0,
  buttonMarginRight: 0,
  fontFamily: 'Nunito Sans',
  fontSize: 12,
};

export default ButtonIconText;
