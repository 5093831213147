import React from 'react';
import { Grid } from '@material-ui/core/';
import logo_black from '../../../assets/logo_credinissan_black.svg';
import logo_white from '../../../assets/logo_credinissan_white.svg';
import BusinessContext from './BusinessContext';
import config from '../../../config/api';
import useStyles from './companylogo.style';

type Props = {
  isHeader?: boolean;
};

const CompanyLogo: React.FC<Props> = ({ isHeader = false }) => {
  const classes = useStyles({ isHeader });
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <Grid>
        <img src={isHeader ? logo_white : logo_black} className={classes.logo} alt="Credinissan" />
      </Grid>
      <Grid className={classes.texto}>Wholesale</Grid>
      {[config.featureFlagUsed || config.featureFlagParts || config.featureFlagFreeZone].includes(
        true
      ) && (
        <Grid>
          <BusinessContext />
        </Grid>
      )}
    </Grid>
  );
};

export default CompanyLogo;
