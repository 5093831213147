import { post, get } from '../utilities/request';
import { createStorage, StorageKeys } from '../utilities/storageHelper';
import apiConfig from '../config/api';

class AuthorizationServices {
  private baseUrl = `${apiConfig.apiUri}/api/auth`;

  async login(options: RequestInit = {}, param = {}) {
    const url = `${this.baseUrl}/login`;
    return post(url, param, options, true, false);
  }

  logout = () => {
    createStorage(StorageKeys.BusinessType).clear();
    localStorage.setItem(StorageKeys.UserIsLogged, 'false');
    localStorage.removeItem(StorageKeys.Token);
    localStorage.removeItem(StorageKeys.Client);
  };

  checkUserStatus = () => {
    return localStorage.getItem(StorageKeys.Token) !== null;
  };

  checkHealth = () => {
    const url = `${this.baseUrl}/health`;
    return get(url, {}, {}, true);
  };
}
const authorizationServices = new AuthorizationServices();
export default authorizationServices;
