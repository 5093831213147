import { Button } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../../constants';
import { UsedOperationsChargeHistory } from '../../../../models/used';
import { convertDateReadableFormat } from '../../../../utilities/dates-functions';
import useStyles from './HistorialCargaOperacionesUsados.style';

type Props = {
  charge?: UsedOperationsChargeHistory;
  message?: string;
  handleDetailClick?: (id: string) => void;
};

const ItemHistorialCargaOperacionesUsados: React.FC<Props> = ({
  charge,
  message,
  handleDetailClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <tr className={classes.contenedor}>
      <td className={classes.celda}>
        <div className={classes.celdaUnoTitulo}>{convertDateReadableFormat(charge?.UploadAt)}</div>
      </td>
      <td className={classes.celda}>
        <div className={classes.titulo}>{charge?.VinsTotal} VIN</div>
      </td>
      <td className={classes.link}>
        <Button
          style={{ color: COLORS.secondary }}
          onClick={() => handleDetailClick(charge?.ChargeId ?? '')}
        >
          Revisar detalle
        </Button>
      </td>
    </tr>
  );
};

export default ItemHistorialCargaOperacionesUsados;
