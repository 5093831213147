import config from './api';

export type ApiDealer = {
  getLineDetail: string;
};

export type ApiUris = {
  apiDealer: ApiDealer;
};

const apiDealer: ApiDealer = {
  getLineDetail: `${config.sparePartSettings.apiUri}/api/dealer`,
};

const apiUris: ApiUris = {
  apiDealer,
};

export default apiUris;
