import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFileUsed } from '../../../../utilities/downloadFileUsed';
import { FetchConfig } from '../../../../config/fetchConfig';
import Notification from '../../../common/notification';
import { useAuth } from '../../../common/auth';
import useStyles from './OperacionesUsadasCursadas.style';
import { COLORS } from '../../../../constants';

type NotificationState = {
  show: boolean;
  variant: 'success' | 'error';
  message: string;
};

const DownloadOperacionesUsadasCursadas: React.FC = () => {
  const classes = useStyles();

  const [showNotificacion, setShowNotification] = useState<NotificationState>({
    show: false,
    variant: 'success',
    message: 'Archivo descargado correctamente',
  });
  const { clientInfo } = useAuth();
  const urlDownload = `/api/taken/download`;

  const handleDownloadFile = () => {
    downloadFileUsed(urlDownload, {
      fileName: `Operaciones_Cursadas_Cliente_${clientInfo.rut}.xlsx`,
    } as FetchConfig).then((state) => {
      if (state?.status === 'error') {
        setShowNotification({
          show: true,
          variant: 'error',
          message: 'Ocurrió un problema descargando el archivo.',
        });
      } else if (state?.status === 'success') {
        setShowNotification({
          show: true,
          variant: 'success',
          message: 'Archivo descargado correctamente',
        });
      }
    });
  };

  return (
    <Grid container item justifyContent="flex-end" alignItems="center" spacing={1}>
      <Grid item>Descarga las operaciones cursadas</Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={handleDownloadFile}
          className={classes.btnDownload}
          startIcon={<GetAppIcon />}
          style={{ backgroundColor: COLORS.secondary }}
        >
          Descargar excel
        </Button>
        {showNotificacion.show && (
          <Notification
            variant={showNotificacion.variant}
            message={showNotificacion.message}
            show={showNotificacion.show}
            onClose={() =>
              setShowNotification((p) => {
                return { ...p, show: false };
              })
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DownloadOperacionesUsadasCursadas;
