import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { PaginationResponse, PaymentsPendingConfirmation } from '../../../../models/commons';
import Error from '../../../common/Error/Error';
import useStyles, { StyledTableCellHeader } from './ConfirmationPendingFreeZone.style';
import ItemConfirmationPendingFreeZone from './itemConfirmationPendingFreeZone/ItemConfirmationPendingFreeZone';
import TablePaginationActions from '../../../common/table/tablePaginationActions';
import NotFoundBox from '../../../common/notFoundBox';
import { Loading } from '../../../common/loading';

const ConfirmationPendingFreeZone = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const url = `/api/payments/confirm?pageSize=${rowsPerPage}&pageNumber=${page + 1}`;
  const {
    status,
    data: paginationResponse,
    error,
    reload,
  } = useFetch<PaginationResponse<PaymentsPendingConfirmation>>(
    url,
    undefined,
    BUSINESSTYPE.FREEZONE
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let paymentsPending: PaymentsPendingConfirmation[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    paymentsPending = paginationResponse?.Result ?? paymentsPending;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && paymentsPending.length === 0 && (
          <NotFoundBox message="No existen pagos pendientes de confirmación." />
        )}
        {status === 'fetching' && <Loading />}
        {status === 'fetched' && paymentsPending?.length > 0 && (
          <TableContainer className={classes.body}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <StyledTableCellHeader>Razón social</StyledTableCellHeader>
                  <StyledTableCellHeader>ID pago</StyledTableCellHeader>
                  <StyledTableCellHeader>Facturas asociadas</StyledTableCellHeader>
                  <StyledTableCellHeader>Método de pago asociado</StyledTableCellHeader>
                  <StyledTableCellHeader>&nbsp;</StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentsPending?.map((pending) => {
                  return (
                    <ItemConfirmationPendingFreeZone
                      key={pending.PaymentId}
                      pending={pending}
                      reload={reload}
                    />
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {status === 'fetched' && paymentsPending?.length > 0 && (
                    <TablePagination
                      labelRowsPerPage="Filas por página:"
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                      colSpan={5}
                      count={totalItems}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default ConfirmationPendingFreeZone;
