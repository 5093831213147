import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

const heightRow = '10vh';
const heightRowWithFooter = '13vh';
const heightFooterRow = '3vh';
const heightFooter = '8vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      padding: '25px 20px 0px 0px',
      height: `calc(100vh - ${heightFooter} - 200px)`,
      overflowY: 'auto',
    },

    container: {
      height: 'auto',
      paddingLeft: '20px',
    },

    header: {
      '& > *': {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#666666 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        marginBottom: '10px',
        borderRight: '1px solid #D2CCCC',
      },

      '& > :nth-child(4)': {
        borderRight: '0px !important',
      },
    },

    row: {
      height: heightRow,
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#666666',
      marginBottom: '20px',
    },

    bodyRow: {
      height: heightRow,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #C3C3C3',
      background: '#EFEFEF',

      '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        borderRight: '1px solid #D2CCCC',
        minHeight: '100%',

        '& > *': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },

      '& > :nth-child(4)': {
        borderRight: '0px !important',
      },
    },

    rowWithFooter: {
      height: heightRowWithFooter,
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#666666',
      marginBottom: '20px',
    },

    bodyRowYellow: {
      height: heightRow,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '3px solid #FFD900',
      borderRight: '3px solid #FFD900',
      borderLeft: '3px solid #FFD900',
      background: '#FFF5DF',

      '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        borderRight: '1px solid #D2CCCC',
        minHeight: '100%',

        '& > *': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },

      '& > :nth-child(4)': {
        borderRight: '0px !important',
      },
    },

    bodyRowRed: {
      height: heightRow,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: `3px solid ${COLORS.secondary}`,
      borderRight: `3px solid ${COLORS.secondary}`,
      borderLeft: `3px solid ${COLORS.secondary}`,
      background: '#FFCCCC',

      '& > *': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        borderRight: '1px solid #D2CCCC',
        minHeight: '100%',

        '& > *': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },

      '& > :nth-child(4)': {
        borderRight: '0px !important',
      },
    },

    footerYellow: {
      background: '#FFD900',
      border: '#FFD900',
      borderRadius: '0px 0px 4px 4px',
      color: '#666666',
      fontWeight: 'bold',
      height: heightFooterRow,
    },

    footerRed: {
      background: COLORS.secondary,
      border: COLORS.secondary,
      borderRadius: '0px 0px 4px 4px',
      color: '#666666',
      fontWeight: 'bold',
      height: heightFooterRow,
    },

    textBlue: {
      color: COLORS.secondary,
    },

    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
      background: COLORS.sidebar,
      height: heightFooter,
      padding: '5px',

      '& > :nth-child(1)': {
        borderRight: '1px solid white',
        minHeight: '100%',
      },
    },

    btnSolicitarGiro: {
      backgroundColor: COLORS.quintinary,
      color: 'white',

      '&$disabled': {
        background: '#E0E0E0',
      },
    },

    disabled: {},

    btnDownload: {
      color: 'white',
      background: COLORS.secondary,
    },
  })
);

export default useStyles;
