import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Title from '../../../common/title';
import { IPagRequest, IlastGrantedOperations, IPagResponse } from '../../../../interfaces';
import { getGrantedOperations } from '../../../../services/granted-service';
import GiroHistorico from './girohistorico';
import NotFound from '../../../common/notfound';
import Paginator from '../../../common/paginator/paginator';

import MainContainer from '../../../maincontainer';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps {}

const initialRequest = {
  pageNumber: 1,
  pageSize: 5,
};

const ViewHistoricoGiros = (props: IProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [currentDataGranted, setcurrentDataGranted] = useState(
    {} as IPagResponse<IlastGrantedOperations>
  );
  const [existDataGranted, setExistDataGranted] = useState(false);
  const [pageRequest, setPageRequest] = useState(initialRequest);
  const [pageSize, setPageSize] = useState(initialRequest.pageSize);

  const handleGetGrantedOperations = async (objPagRequest: IPagRequest) => {
    getGrantedOperations(objPagRequest).then((response) => {
      setcurrentDataGranted(response.data);
      setExistDataGranted(response.data.Result.length > 0);
      setLoading(false);
    });
  };

  const handleRefreshData = (pagRequest: IPagRequest, size: number) => {
    setLoading(true);
    setPageSize(size);
    //handleGetGrantedOperations(pagRequest)
    setPageRequest(pagRequest);
  };

  useEffect(() => {
    if (loading) {
      handleGetGrantedOperations(pageRequest);
    }
  }, [loading, pageRequest]);

  const onShowTitle = () => {
    return (
      <Title title="Pendientes de giro" mostrarTabPendientes={true} tabSelected="historico-giros" />
    );
  };

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!loading && !existDataGranted && <NotFound message="No tienes historial de giros" />}
            {!loading &&
              existDataGranted &&
              currentDataGranted.Result.map((data: IlastGrantedOperations, index: number) => {
                return <GiroHistorico key={index} data={data} />;
              })}
            {!loading && existDataGranted && (
              <Grid
                item
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Paginator data={currentDataGranted} onUpdate={handleRefreshData} size={pageSize} />
              </Grid>
            )}
            {loading && <Loading />}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default ViewHistoricoGiros;
