import React from 'react';
import { BUSINESSTYPE } from '../../../constants';
import { useFetch } from '../../../hooks/useFetch';
import Error from '../../common/Error';
import { FreeZoneMoneyTransferPending } from '../../../models/freeZone';
import useStyles from './MoneyTransferPendingFreeZone.style';
import { Grid } from '@material-ui/core';
import NotFound from '../../common/notfound';
import { Loading } from '../../common/loading';
import ItemMoneyTransferPendingFreeZone from './ItemMoneyTransferPendingFreeZone';

const MoneyTransferPendingFreeZone = () => {
  const classes = useStyles();

  const url = '/api/moneyOrderRequest/pending';
  const {
    status,
    data: moneyTransferPendings,
    error,
  } = useFetch<FreeZoneMoneyTransferPending[]>(url, undefined, BUSINESSTYPE.FREEZONE);

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && moneyTransferPendings?.length === 0 && (
          <NotFound message="No existen operaciones pendientes de giro para este cliente." />
        )}
        {status === 'fetched' && moneyTransferPendings && moneyTransferPendings?.length > 0 && (
          <>
            <Grid container item xs={12} className={classes.headerTable}>
              <Grid item xs={3}>
                ID solicitud giro
              </Grid>
              <Grid item xs={2}>
                Monto total a girar
              </Grid>
              <Grid item xs={1}>
                Total VIN
              </Grid>
              <Grid item xs={6}>
                Última descarga
              </Grid>
            </Grid>
            {moneyTransferPendings?.map((pending) => (
              <ItemMoneyTransferPendingFreeZone pending={pending} key={pending.Id} />
            ))}
          </>
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default MoneyTransferPendingFreeZone;
