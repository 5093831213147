import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import useStyles from './HistorialPagosUsados.style';
import { UsedPaymentsHistoryResponse } from '../../../../models/used';
import { rutFormater } from '../../../../utilities/string-functions';
import { useAuth } from '../../../common/auth';
import { COLORS } from '../../../../constants';

type Props = {
  payment: UsedPaymentsHistoryResponse;
} & RouteComponentProps;

const ItemHistorialPagosUsados: React.FC<Props> = ({ payment, history }) => {
  const { setTitlePaymentDetail } = useAuth();

  const handleDetailClick = (clientDealerId: string) => {
    setTitlePaymentDetail(payment.DealerName);
    history.push(`historial/:${clientDealerId}`);
  };

  const classes = useStyles();
  return (
    <div className={classes.item}>
      <div className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item md={10} className={classes.columnDetail}>
            <div className={classes.dealerName}>{payment.DealerName}</div>
            <div>
              <span className={classes.dealerRut}>
                {rutFormater(payment.DealerRut.toString() + payment.DealerVerificationNumber)}
              </span>
              <span className={classes.paymentsTotal}>
                Pagos realizados {payment.PaymentsTotal}
              </span>
            </div>
          </Grid>
          <Grid container item md={2} className={classes.columnDetail}>
            <Button
              style={{ color: COLORS.secondary }}
              onClick={() => handleDetailClick(payment?.ClientDealerId ?? '')}
            >
              Revisar detalle
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(ItemHistorialPagosUsados);
