import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import { Loading } from '../../../common/loading';
import ErrorSpareParts from '../../commons/ErrorSpareparts';
import useStyles, { StyledTableCell } from './DetailGrantedSpareParts.style';
import { SparePartsGrantedOperationDetail } from '../../../../models/spareParts/SparePartsGrantedOperationDetail';
import { BUSINESSTYPE } from '../../../../constants';

type Props = {
  id: string;
  changeTitle: (t: string) => void;
};

const DetailGrantedSpareParts: React.FC<Props> = (props) => {
  const { id, changeTitle } = props;
  const classes = useStyles();

  const url = `/api/granteds/clientDealer/${id}`;
  const {
    status,
    data: grantedOperationsDetails,
    error,
  } = useFetch<SparePartsGrantedOperationDetail[]>(url, undefined, BUSINESSTYPE.PARTS);

  React.useEffect(() => {
    if (status === 'fetched') {
      if (grantedOperationsDetails && grantedOperationsDetails.length) {
        changeTitle(grantedOperationsDetails?.[0].DealerName ?? '');
      }
    }
  }, [status, grantedOperationsDetails, changeTitle]);

  if (status === 'error' && error)
    return (
      <ErrorSpareParts
        StatusCode={error.StatusCode}
        Message={error.Message}
        ErrorId={error.ErrorId}
      />
    );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell className={classes.daysElapsed} align="center">
                        Días transcurridos
                      </StyledTableCell>
                      <StyledTableCell align="center">Número de factura</StyledTableCell>
                      <StyledTableCell align="center">Fecha de emisión</StyledTableCell>
                      <StyledTableCell align="center">Estado de inventario</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {grantedOperationsDetails?.map((detail) => (
                      <TableRow key={detail.SpareOperationId}>
                        <TableCell className={classes.daysElapsedData} align="center">
                          {detail.PassedDays}
                        </TableCell>
                        <TableCell align="center">{detail.InvoiceNumber}</TableCell>
                        <TableCell align="center">{detail.InvoiceDate}</TableCell>
                        <TableCell align="center">{detail.StockState}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailGrantedSpareParts;
