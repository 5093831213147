import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

const useStyles = makeStyles(() => ({
  clientSelect: {
    textAlign: 'left',
    height: '50px',
    width: 'auto',
    borderRadius: '4px !important',
    boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginLeft: '10px',
    background: COLORS.primary,
    color: 'white',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'auto',
    color: 'white',
    textAlign: 'left',
    background: COLORS.primary,
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  rutClient: {
    margin: '0px',
    padding: '0px',
    color: 'white',
    fontFamily: 'Nunito Sans',
  },
  nameClient: {
    margin: '0px',
    padding: '0px',
    color: 'white',
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
  },
  wrapperText: {
    display: 'block',
    paddingRight: '20px',
  },
  icon: {
    display: 'block',
    color: 'white',
  },
}));

export default useStyles;
