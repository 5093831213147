import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './DetailApplyPaymentFreeZonePage.style';
import DetailApplyPaymentFreeZone from '../../../../components/freeZone/paymentPending/detailApplyPaymentFreeZone/DetailApplyPaymentFreeZone';
import DownloadPendingApplyPaymentFreeZone from '../../../../components/freeZone/paymentPending/detailApplyPaymentFreeZone/DownloadPendingApplyPayment';

type Params = { id: string };

const DetailApplyPaymentFreeZonePage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState('');

  const changeTitle = (t: string) => {
    setTitle(t);
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Title title={title} goBackButton />
            <DownloadPendingApplyPaymentFreeZone clientDealerId={match.params.id} />
          </Grid>
        </Grid>
      </Grid>
      <DetailApplyPaymentFreeZone clientDealerId={match.params.id} changeTitle={changeTitle} />
    </MainContainer>
  );
};

export default DetailApplyPaymentFreeZonePage;
