import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Title from '../../../common/title';
import Notification from '../../../common/notification';

import Operaciones from './operaciones';
import CabeceraDetalles from './cabeceradetalles';
import Detalles from './detalles';

import {
  getOperationBilling,
  getOperationBillingDetail,
} from '../../../../services/billing-service';

import MainContainer from '../../../maincontainer';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps {}

interface IParamsChangeView {
  visible: boolean;
  dealer: string;
}

const ViewFacturado = (props: IProps) => {
  const classes = useStyles();

  const [changeToDetails, setChangeToDetails] = useState(false);
  const [dealer, setDealer] = useState('');

  const [existDataOperationsBilling, setExistDataOperationsBilling] = useState(false);
  const [dataOperationsBilling, setDataOperationsBilling] = useState([]);

  const [existDataOperationsBillingDetail, setExistDataOperationsBillingDetail] = useState(false);
  const [dataOperationsBillingDetail, setDataOperationsBillingDetail] = useState([]);

  const [loading, setLoading] = useState(true);
  const [clientDealerIdSelected, setClientDealerId] = useState('');

  const [showSuccessPaymentMsg, setShowSuccessPaymentMsg] = useState(false);

  const handleShowDetailsFacturadas = (visible: boolean, name: string) => {
    setChangeToDetails(visible);
    setDealer(name);
  };

  const getOperationBillingDetailData = (clientDealerId: string) => {
    setDataOperationsBillingDetail([]);

    getOperationBillingDetail(clientDealerId).then((response) => {
      setClientDealerId(clientDealerId);
      setLoading(false);
      setDataOperationsBillingDetail(response.data);
      setExistDataOperationsBillingDetail(response.data.length > 0);
    });
  };

  const handleShowSuccessPaymentMsg = () => {
    setShowSuccessPaymentMsg(true);
  };

  const handleGetOperationBilling = async () => {
    if (changeToDetails) {
      handleShowDetailsFacturadas(false, '');
    }

    getOperationBilling().then((response) => {
      setLoading(false);
      setDataOperationsBilling(response.data);
      setExistDataOperationsBilling(response.data.length > 0);
    });
  };

  useEffect(() => {
    handleGetOperationBilling();
  }, []);

  const onShowTitle = () => {
    if (!changeToDetails) {
      return <Title title={'Otorgadas'} mostrarTabOperaciones={true} tabSelected={'facturado'} />;
    }

    return (
      <CabeceraDetalles title={dealer} onShowDetailsFacturadas={handleShowDetailsFacturadas} />
    );
  };

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item md={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item md={12}>
            {!changeToDetails && (
              <Operaciones
                onShowDetailsFacturadas={handleShowDetailsFacturadas}
                dataOperationBilling={dataOperationsBilling}
                loading={loading}
                setLoading={setLoading}
                getOperationBillingDetailData={getOperationBillingDetailData}
                existData={existDataOperationsBilling}
              />
            )}
            {changeToDetails && (
              <Detalles
                dataOperationsBillingDetail={dataOperationsBillingDetail}
                loading={loading}
                clientDealerIdSelected={clientDealerIdSelected}
                onRefresh={handleGetOperationBilling}
                existData={existDataOperationsBillingDetail}
                onShowSuccessPaymentMsg={handleShowSuccessPaymentMsg}
              />
            )}
          </Grid>
        </Grid>

        <Notification
          variant="success"
          message="Pago aplicado correctamente"
          show={showSuccessPaymentMsg}
          onClose={() => setShowSuccessPaymentMsg(false)}
        />
      </div>
    </MainContainer>
  );
};

export default ViewFacturado;
