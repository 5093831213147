import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { Loading } from '../../../common/loading';
import Error from '../../../common/Error';
import NotFound from '../../../common/notFoundBox';
import useStyles from './ApplyPaymentFreeZone.style';
import { ILabelDisplayedRows } from '../../../../interfaces';
import { PaginationResponse, PaymentPending } from '../../../../models/commons';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import TablePaginationActions from '../../../common/table/tablePaginationActions';
import ItemApplyPaymentFreeZone from './ItemApplyPaymentFreeZone';

type IProps = {} & RouteComponentProps;

const ApplyPaymentFreeZone: React.FC<IProps> = () => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const url = `/api/payments/pendings?pageSize=${rowsPerPage}&pageNumber=${page + 1}`;
  const {
    status,
    data: paginationResponse,
    error,
  } = useFetch<PaginationResponse<PaymentPending>>(url, undefined, BUSINESSTYPE.FREEZONE);

  let paymentPending: PaymentPending[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    paymentPending = paginationResponse?.Result ?? paymentPending;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLabelDisplayRows = ({ from, to, count }: ILabelDisplayedRows) =>
    `${from}–${to} de ${count}`;

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && paginationResponse?.Result.length === 0 && (
          <NotFound message="No existen operaciones pendientes de pago para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
        {status === 'fetched' && (
          <TableContainer className={classes.root}>
            <Table stickyHeader aria-label="sticky table" className={classes.table}>
              <TableBody>
                {status === 'fetched' &&
                  paymentPending?.map((payment) => {
                    return (
                      <ItemApplyPaymentFreeZone key={payment.ClientDealerId} pending={payment} />
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {status === 'fetched' && paymentPending?.length > 0 && (
                    <TablePagination
                      labelRowsPerPage="Filas por página:"
                      labelDisplayedRows={handleLabelDisplayRows}
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                      colSpan={3}
                      count={totalItems}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(ApplyPaymentFreeZone);
