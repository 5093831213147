import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Checkbox, IconButton, TextField } from '@material-ui/core';

import ModalCreditNote from '../../../common/paymentPending/detailApplyPayment/modalCreditNote';
import ButtonIconText from '../../../common/bottonIconText';
import NotFound from '../../../common/notfound';
import Icons from '../../../common/icons';

import ModalSolicitud from './modalsolicitud';
import Footer from './footer';

import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { numberToMiles } from '../../../../utilities/string-functions';
import { parse } from 'path';
import {
  ICreditNoteDetailsByOperation,
  IPendingBillingOperation,
  IBillingDetails,
} from '../../../../interfaces';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const heightFilter = 80;
const heightFooter = 70;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: heightFilter + heightFooter + 20,
};

const tituloContainerStyleObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 14,
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: 4,
      width: '100%',
      height: 'auto',
      maxWidth: 4000,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: '0 20px',
    },
    cabeceraContainer: {
      height: 60,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    cabeceraContainerTitulo: {
      height: 60,
      fontWeight: 'bold',
      ...tituloContainerStyleObj,
    },
    detalleContainer: {
      height: 'auto',
      marginBottom: 20,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    detalleContainerCelda: {
      height: 40,
      ...tituloContainerStyleObj,
    },
    gridFactura: {
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    btnPayment: {
      marginBottom: 20,
      backgroundColor: COLORS.secondary,
      color: '#fff',
      '&:hover': {
        backgroundColor: COLORS.secondary,
        color: '#fff',
      },
      '&:disabled': {
        backgroundColor: '#99e6098f',
        color: '#fff',
      },
    },
    creditnoteenable: {
      color: COLORS.secondary,
      cursor: 'pointer',
    },
    creditnotedisable: {
      color: '#ADADAD',
    },
    filterContainer: {
      padding: 20,
      paddingTop: 25,
      width: '100%',
      height: heightFilter,
      maxWidth: 4000,
      borderBottom: `1px solid ${COLORS.quintinary}`,
    },
    input: {
      height: 36,
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
    },
  })
);
/** endregion */

interface IProps {
  dataOperationsBillingDetail: Array<IBillingDetails>;
  loading: boolean;
  clientDealerIdSelected: string;
  onRefresh: () => void;
  existData: boolean;
  onShowSuccessPaymentMsg: () => void;
}

const Detalles = (props: IProps) => {
  const classes = useStyles();

  const {
    dataOperationsBillingDetail,
    loading,
    clientDealerIdSelected,
    onRefresh,
    existData,
    onShowSuccessPaymentMsg,
  } = props;

  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState([] as any);
  const [btnPayment, setBtnPayment] = useState(true);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [dataOperationsBD, setDataOperationsBD] = useState([] as any);
  const [vinfilter, setVinfilter] = useState('');
  const [facturafilter, setFacturafilter] = useState('');

  const [modalNC, setModalNC] = useState(false);
  const [vin, setVin] = useState('');
  const [creditNote, setCreditNote] = useState<ICreditNoteDetailsByOperation[]>([]);

  const handleOpenModalNC = (item: IBillingDetails) => {
    setVin(item.VIN);
    setCreditNote(item.CreditNote);
    setModalNC(true);
  };
  const handleCloseModalNC = () => {
    setVin('');
    setCreditNote([]);
    setModalNC(false);
  };

  const handleToggleAll = () => {
    setCheckedAll(checkedAll ? false : true);
    const newChecked = [] as any;
    let sum = 0 as number;

    if (!checkedAll) {
      dataOperationsBillingDetail.map((data: IBillingDetails, index: number) => {
        sum += data.Amount;
        newChecked.push(data.OperationId);
      });
    }

    setValue(sum);
    setBtnPayment(checkedAll);
    setChecked(newChecked);
  };

  const handleToggle = (value: any, number: number) => {
    // const number = parseInt(mount.replace(/\./g, ''));
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);

      setValue((prevState) => prevState + number);
    } else {
      setValue((prevState) => prevState - number);
      newChecked.splice(currentIndex, 1);
    }

    const witness = newChecked.length > 0 ? false : true;
    setBtnPayment(witness);
    setCheckedAllChange(newChecked);
    setChecked(newChecked);
  };

  const setCheckedAllChange = (newChecked: any) => {
    const noSelected = dataOperationsBillingDetail.filter(
      (data: IBillingDetails, index: number) => newChecked.indexOf(data.OperationId) < 0
    );
    const checketAll = noSelected.length > 0 ? false : true;
    setCheckedAll(checketAll);
  };

  const handleBtnOpenMdlPaymend = () => {
    setOpen(true);
  };

  const handleCloseModalPayment = async (isPayment: boolean) => {
    if (isPayment) {
      await onRefresh();
      onShowSuccessPaymentMsg();
    }
    setOpen(false);
  };

  const handleFilterData = () => {
    let dataOperations = dataOperationsBillingDetail.filter(
      (data: any) => data.VIN.toLowerCase().indexOf(vinfilter.toLowerCase()) > -1
    );
    dataOperations = dataOperations.filter(
      (data: any) =>
        String(data.TannerInvoice).toLowerCase().indexOf(String(facturafilter).toLowerCase()) > -1
    );
    setDataOperationsBD(dataOperations);
  };

  const counterChkPaymentSelected = checked.length;

  useEffect(() => {
    if (dataOperationsBD.length === 0) {
      setDataOperationsBD(dataOperationsBillingDetail);
    }
  }, [dataOperationsBillingDetail]);

  return (
    <div>
      {!loading && existData && (
        <div>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.filterContainer}
          >
            <Grid item md={6}>
              <TextField
                id="vin"
                label="VIN"
                variant="outlined"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true }}
                style={{ marginRight: 10 }}
                onChange={(e) => setVinfilter(e.target.value)}
              />
              <TextField
                id="factura"
                label="N° factura enthernet"
                variant="outlined"
                InputProps={{ className: classes.input }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFacturafilter(e.target.value)}
              />

              <ButtonIconText
                buttonBackgroundColor={COLORS.secondary}
                // buttonColor={""}
                buttonHeight={36}
                buttonWidth={90}
                buttonKey={'botonfiltrarfacturado'}
                buttonMarginLeft={10}
                // fontFamily={""}
                fontSize={14}
                iconName={'search'}
                iconSize={14}
                label={'Filtrar'}
                objHandleEvent={{
                  execute: handleFilterData,
                }}
              />
            </Grid>
          </Grid>

          <div className={classes.root}>
            <div className={classes.contenedor}>
              <Grid container direction="column">
                <Grid item className={classes.cabeceraContainer}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item sm={1}>
                      <Checkbox
                        id="rootChkGroupFactura"
                        style={{ color: COLORS.secondary }}
                        checked={checkedAll}
                        onChange={handleToggleAll}
                        title={'Seleccionar todos'}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      className={classes.cabeceraContainerTitulo}
                      style={{ color: COLORS.secondary }}
                    >
                      Días Transcurridos
                    </Grid>
                    <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                      VIN
                    </Grid>
                    <Grid item sm={1} className={classes.cabeceraContainerTitulo}>
                      Monto
                    </Grid>
                    <Grid item sm={1} className={classes.cabeceraContainerTitulo}>
                      NC
                    </Grid>
                    <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                      N° factura enthernet
                    </Grid>
                    <Grid item sm={1} className={classes.cabeceraContainerTitulo}>
                      Fecha Emisión
                    </Grid>
                    <Grid item sm={2} className={classes.cabeceraContainerTitulo}>
                      Estado de Inventario
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={classes.detalleContainer}>
                  {dataOperationsBD.map((data: any, index: number) => {
                    const {
                      Amount,
                      DayPassed,
                      GrantedDate,
                      IssueDateTannerInvoice,
                      OperationId,
                      StatusStockName,
                      StockId,
                      TannerInvoice,
                      VIN,
                      CreditNote,
                    } = data;
                    return (
                      <Grid
                        key={index}
                        className={classes.gridFactura}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item sm={1}>
                          <Checkbox
                            id={`chkGroupFactura${index}`}
                            style={{ color: COLORS.secondary }}
                            checked={checked.indexOf(OperationId) !== -1}
                            onChange={() => handleToggle(OperationId, Amount)}
                            title={`Seleccionar factura N° ${index + 1}`}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          title={'Días transcurridos'}
                          className={classes.detalleContainerCelda}
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {DayPassed}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          title={'VIM chasis'}
                          className={classes.detalleContainerCelda}
                        >
                          {VIN}
                        </Grid>
                        <Grid item sm={1} title={'Monto'} className={classes.detalleContainerCelda}>
                          ${numberToMiles(Amount)}
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          title={'Notas de Crédito'}
                          className={classes.detalleContainerCelda}
                        >
                          <IconButton
                            disabled={CreditNote.length < 1}
                            style={{
                              fontFamily: 'Nunito Sans',
                              fontSize: 14,
                            }}
                            onClick={() => handleOpenModalNC(data)}
                          >
                            <span
                              className={
                                CreditNote.length > 0
                                  ? classes.creditnoteenable
                                  : classes.creditnotedisable
                              }
                            >
                              <Icons name="paste" size="16px" />
                              <span
                                style={{
                                  paddingLeft: 2,
                                }}
                              >
                                {CreditNote.length}
                              </span>
                            </span>
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          title={'N° factura'}
                          className={classes.detalleContainerCelda}
                        >
                          {TannerInvoice}
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          title={'Fecha emisión'}
                          className={classes.detalleContainerCelda}
                        >
                          {getObjDateLocal(IssueDateTannerInvoice, false, 'dd/LL/yyyy').date}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          title={'Estado inventario'}
                          className={classes.detalleContainerCelda}
                        >
                          {StatusStockName}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <ModalCreditNote
                  open={modalNC}
                  vin={vin}
                  creditNote={creditNote}
                  onClose={handleCloseModalNC}
                />

                <ModalSolicitud
                  open={open}
                  chkSelected={counterChkPaymentSelected}
                  onClose={handleCloseModalPayment}
                  clientDealerIdSelected={clientDealerIdSelected}
                  operations={checked}
                  totalMount={value}
                />
              </Grid>
            </div>
            {/* <br />
                        <Button variant="contained" title={"Aplicar medio de pago"} id={'btnOpenMdlPayment'} onClick={handleBtnOpenMdlPaymend} size="small" className={classes.btnPayment} disabled={btnPayment} >
                            Aplicar medio de pago
                        </Button> */}
          </div>

          <div className={classes.footer}>
            <Footer
              countVins={counterChkPaymentSelected}
              totalMount={value}
              disabled={btnPayment}
              onApplyPayment={handleBtnOpenMdlPaymend}
              heightFooter={heightFooter}
            />
          </div>
        </div>
      )}
      {!loading && !existData && (
        <div className={classes.root}>
          <NotFound message="No se encontraron operaciones asociadas al Dealer seleccionado." />
        </div>
      )}
      {loading && (
        <div className={classes.root}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Detalles;
