import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Title from '../../../common/title';
import NotFound from '../../../common/notfound';

import ModalRechazarPago from './modalrechazarpago';
import ModalAceptarPago from './modalaceptarpago';
import Operations from './operations';

import { paymentServices } from '../../../../services/paymentServices';

import MainContainer from '../../../maincontainer';
import { Loading } from '../../../common/loading';
import { COLORS } from '../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    cabeceraBody: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 16,
      fontWeight: 'bold',
      height: 72,
      paddingTop: 0,
      ...containerStyleObj,
    },
  })
);
/** endregion */

interface IProps {}

const ViewPendientesDeConfirmacion = (props: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openModalAccept, setOpenModalAccept] = useState(false);
  const [dataOperationToAccept, setDataOperationToAccept] = useState('');
  const [dataOperationToRefuse, setDataOperationToRefuse] = useState('');
  const [pendingConfirmation, setPendingConfirmation] = useState([] as Array<any>);
  const [loading, setLoading] = useState(true);
  const [requestContainData, setRequestContainData] = useState(true);

  const [paymendIdPaid, setPaymentIdPaid] = useState([] as Array<string>);

  const [onlyPaymentData, setOnlyPaymentData] = useState([] as Array<any>);

  const handleGetDataPendingConfirmation = async () => {
    paymentServices.getPendingConfirmation().then((response) => {
      setLoading(false);

      if (response.data.length > 0) {
        setPendingConfirmation(response.data);
        handleSetOnlyPayment(response.data);
      } else {
        setRequestContainData(false);
      }
    });
  };

  const handleSetOnlyPayment = (pendingConfirmationDetail: Array<any>) => {
    const onlyPayment = [] as Array<any>;

    const currentPayment = pendingConfirmationDetail.map((data: any, index: number) => {
      data.PaymentAppliedPendingConfirmationDetail.map((payment: any, count: number) => {
        onlyPayment.push(payment);
      });
    });

    setOnlyPaymentData(onlyPayment);
  };

  const acceptPending = async (paymendId: string) => {
    paymentServices.acceptPendingConfirmation(paymendId).then((response) => {
      if (response.data) {
        const newPaymendIdPaid = [...paymendIdPaid];
        newPaymendIdPaid.push(paymendId);
        setPaymentIdPaid(newPaymendIdPaid);

        const domLeyendPayment = document.getElementById(`leyend_${paymendId}`);
        if (domLeyendPayment) {
          domLeyendPayment.style.display = 'block';
        }

        const domBotonera = document.getElementById(`botonera_${paymendId}`);
        if (domBotonera) {
          domBotonera.style.display = 'none';
        }

        setTimeout(() => {
          const domContainer = document.getElementById(`detailPendingConfirmation_${paymendId}`);

          if (domContainer) {
            domContainer.style.display = 'none';
          }

          const existDataPending = handleExistNotPaid(newPaymendIdPaid);
          setRequestContainData(existDataPending);
        }, 3000);
      }
    });
  };

  const handleAceptedPayment = (paymentId: string) => {
    setDataOperationToAccept(paymentId);
    setOpenModalAccept(true);
  };

  const handleExistNotPaid = (newPaymendIdPaid: any) => {
    const noPayment = onlyPaymentData.filter(
      (data) => newPaymendIdPaid.indexOf(data.PaymentId) < 0
    );
    return noPayment.length > 0;
  };

  const handleCancelPayment = (paymentId: string) => {
    setDataOperationToRefuse(paymentId);
    setOpen(true);
  };

  const handleCloseModalClick = (value: boolean) => {
    setOpen(false);
  };

  const handleCloseModalAcceptClick = (value: boolean) => {
    setOpenModalAccept(false);
  };

  useEffect(() => {
    handleGetDataPendingConfirmation();
  }, []);

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title
              title={'Otorgadas'}
              mostrarTabOperaciones={true}
              tabSelected={'pendientes_de_confirmacion'}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          {!loading && requestContainData && (
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.cabeceraBody}
              >
                <Grid item md={3}></Grid>
                <Grid item md={2}>
                  ID pago
                </Grid>
                <Grid item md={2}>
                  Facturas asociadas
                </Grid>
                <Grid item md={2}>
                  Método de pago asociado
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>

              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item>
                  {pendingConfirmation.map(
                    (dataPendingConfirmation: any, numberConfirmation: number) => {
                      return (
                        <Operations
                          key={numberConfirmation}
                          dataPendingConfirmation={dataPendingConfirmation}
                          onHandleAceptedPayment={handleAceptedPayment}
                          onHandleCancelPayment={handleCancelPayment}
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>
              <ModalRechazarPago
                open={open}
                paymentId={dataOperationToRefuse}
                onClose={handleCloseModalClick}
                history={props}
              />

              <ModalAceptarPago
                open={openModalAccept}
                paymentId={dataOperationToAccept}
                onClose={handleCloseModalAcceptClick}
                acceptPending={acceptPending}
                history={props}
              />
            </Grid>
          )}
          {!loading && !requestContainData && (
            <NotFound message="No se encontraron operaciones pendientes de confirmación." />
          )}
          {loading && <Loading />}
        </Grid>
      </div>
    </MainContainer>
  );
};

export default ViewPendientesDeConfirmacion;
