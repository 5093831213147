import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../maincontainer';
import Title from '../../../common/title';
import UploadFile from '../../../common/uploadfile';
import BarInfoUpload from '../../../common/header/barinfoupload';
import { useStyles } from './ViewChargeIcar.style';
import { postFormData } from '../../../../utilities/request';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../utilities/storageHelper';
import { BusinessTypeEnum } from '../../../../enums';
import config from '../../../../config/api';
import { TABS } from '../../../../constants';

interface IProps {}

export const handleHiddenDownload = () => {
  const headerContainerDownload = document.getElementById('containerdownload');
  if (headerContainerDownload) {
    headerContainerDownload.style.visibility = 'hidden';
  }
};

export const onShowTitle = () => {
  return (
    <Title title="Carga de archivo" mostrarTabSubirExcel={true} tabSelected={TABS.CHARGE_ICAR} />
  );
};

const ViewCargaICAR = (props: IProps) => {
  const classes = useStyles();
  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);
  useEffect(() => {
    handleHiddenDownload();
  });

  // Función que se ejecuta cuando se carga el archivo de i-car
  const handleFunctionLoadFile = async (formData: FormData) => {
    const options: RequestInit = {};
    let url;
    if (businessTypeStorage === BusinessTypeEnum.Nuevos) {
      url = `${config.apiUri}/api/chargeIcar/upload`;
    } else {
      url = `${config.freeZoneSettings.apiUri}/api/chargeIcar/upload`;
    }
    const dataResponse = await postFormData(url, options, formData);
    return dataResponse;
  };

  return (
    <MainContainer>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item className={classes.boxContainer}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              <Grid item md={6} className={classes.details}>
                <BarInfoUpload isHeader={false} />
              </Grid>
              <Grid item>
                <UploadFile
                  key="xlsx"
                  formato=".xlsx"
                  showfooter={false}
                  uploadFile={handleFunctionLoadFile}
                  loadComponent="LoadICAR"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default ViewCargaICAR;
