import { AxiosRequestConfig } from 'axios';
import {
  defaultPostFileConfig,
  FetchConfig,
  defaultPostConfig,
  defaultPutConfig,
} from '../config/fetchConfig';
import { axiosWithInterceptor } from './axios';
import apiConfig from '../config/api';
import { ErrorModel } from '../models/ErrorModel';
import { ApiResponse } from '../models/commons';
import { rutToNumberWithoutDv } from './string-functions';
import { StorageKeys } from './storageHelper';
import { ClientInfo } from '../interfaces';
import { BUSINESSTYPE } from '../constants';

const getAuthorizationHeadersSpareparts = () => {
  const reqHeaders: { [k: string]: unknown } = {};
  if (localStorage.getItem(StorageKeys.UserIsLogged) !== null) {
    if (localStorage.getItem(StorageKeys.UserIsLogged) === 'true') {
      reqHeaders.Authorization = `Bearer ${localStorage.getItem(StorageKeys.Token)}`;
    }
  }
  return reqHeaders;
};

const getClientInfoHeadersSpareparts = () => {
  const reqHeaders: { [k: string]: unknown } = {};
  if (localStorage.getItem(StorageKeys.UserIsLogged) !== null) {
    if (localStorage.getItem(StorageKeys.UserIsLogged) === 'true') {
      const client = JSON.parse(localStorage.getItem(StorageKeys.Client) as string) as ClientInfo;
      reqHeaders.ClientRut = rutToNumberWithoutDv((client && client.rut) || '0');
    }
  }
  return reqHeaders;
};

export const defaultHeadersSpareparts = (
  contentType = 'application/json',
  withAuthentication = true
) => {
  const reqHeaders: { [k: string]: unknown } = {};
  reqHeaders['Content-Type'] = contentType;
  reqHeaders['api-version'] = apiConfig.sparePartSettings.apiVersion;
  reqHeaders['Ocp-Apim-Subscription-Key'] = apiConfig.sparePartSettings.subscriptionKey;
  reqHeaders['Ocp-Apim-Trace'] = 'true';

  if (withAuthentication) {
    return {
      ...reqHeaders,
      ...getAuthorizationHeadersSpareparts(),
      ...getClientInfoHeadersSpareparts(),
    };
  }

  return reqHeaders;
};

export const postFile = async <T = unknown>(
  url: string,
  formData: FormData,
  config: FetchConfig = defaultPostFileConfig
): Promise<ApiResponse<T>> => {
  const customConfig: FetchConfig = {
    ...defaultPostFileConfig,
    ...config,
  };

  const customAxios = axiosWithInterceptor(customConfig, BUSINESSTYPE.PARTS);

  const customOptions: AxiosRequestConfig = {
    headers: {
      ...defaultHeadersSpareparts(customConfig.contentType, customConfig.withAuth),
    },
  };

  try {
    const response = await customAxios.post(url, formData, customOptions);
    return Promise.resolve({ status: 'success', data: response.data });
  } catch (error) {
    const errorModel: ErrorModel = {
      StatusCode: error?.status,
      ErrorId: error?.data?.ErrorId,
      Message: error?.data?.Message,
    };
    return Promise.resolve({ status: 'error', error: errorModel });
  }
};

export const post = async <T = unknown>(
  url: string,
  contentBody: Record<string, unknown>,
  config: FetchConfig = defaultPostConfig
): Promise<ApiResponse<T>> => {
  const customConfig: FetchConfig = {
    ...defaultPostConfig,
    ...config,
  };

  const customAxios = axiosWithInterceptor(customConfig, BUSINESSTYPE.PARTS);

  const customOptions: AxiosRequestConfig = {
    headers: {
      ...defaultHeadersSpareparts(customConfig.contentType, customConfig.withAuth),
    },
  };

  try {
    const response = await customAxios.post(url, contentBody, customOptions);
    return Promise.resolve({ status: 'success', data: response.data });
  } catch (error) {
    const errorModel: ErrorModel = {
      StatusCode: error?.status,
      ErrorId: error?.data?.ErrorId,
      Message: error?.data?.Message,
    };
    return Promise.resolve({ status: 'error', error: errorModel });
  }
};

export const put = async <T = unknown>(
  url: string,
  contentBody: Record<string, unknown>,
  config: FetchConfig = defaultPutConfig
): Promise<ApiResponse<T>> => {
  const customConfig: FetchConfig = {
    ...defaultPostConfig,
    ...config,
  };

  const customAxios = axiosWithInterceptor(customConfig, BUSINESSTYPE.PARTS);

  const customOptions: AxiosRequestConfig = {
    headers: {
      ...defaultHeadersSpareparts(customConfig.contentType, customConfig.withAuth),
    },
  };

  try {
    const response = await customAxios.put(url, contentBody, customOptions);
    return Promise.resolve({ status: 'success', data: response.data });
  } catch (error) {
    const errorModel: ErrorModel = {
      StatusCode: error?.status,
      ErrorId: error?.data?.ErrorId,
      Message: error?.data?.Message,
    };
    return Promise.resolve({ status: 'error', error: errorModel });
  }
};
