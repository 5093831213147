import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { UsedDetailPaymentPending } from '../../../../models/used';
import { numberToMiles } from '../../../../utilities/string-functions';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { COLORS } from '../../../../constants';

type Props = {
  pendingPayment: UsedDetailPaymentPending;
  checked: number[];
  handleToggle: (value: number) => () => void;
  setAnyDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  position: number;
};

const RowDetalleAplicarPagoUsados: React.FC<Props> = ({
  pendingPayment,
  checked,
  handleToggle,
  setAnyDisabled,
  position,
}) => {
  const [disabledChecked, setDisabledChecked] = useState(false);

  return (
    <TableRow key={pendingPayment.UsedOperationId}>
      <TableCell align="center">
        <Checkbox
          style={{ color: COLORS.secondary }}
          disabled={disabledChecked}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          checked={checked.indexOf(pendingPayment.Position) !== -1}
          onClick={handleToggle(pendingPayment.Position)}
          disableRipple
        />
      </TableCell>
      <TableCell align="center">{pendingPayment.PassedDays}</TableCell>
      <TableCell align="center">{pendingPayment.UsedVin}</TableCell>
      <TableCell align="center">{`$ ${numberToMiles(pendingPayment.Amount)}`}</TableCell>
      <TableCell align="center">{pendingPayment.InvoiceNumber}</TableCell>
      <TableCell align="center">
        {getObjDateLocal(pendingPayment.InvoiceDate, true, 'dd-LL-yyyy').date}
      </TableCell>
      <TableCell align="center">{pendingPayment.StockState}</TableCell>
    </TableRow>
  );
};

export default RowDetalleAplicarPagoUsados;
