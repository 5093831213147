import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

/** region style */
const useStyles = makeStyles(() =>
  createStyles({
    sinDatosContainer: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 101,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    textContainer: {
      textAlign: 'center',
    },
    text: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: 20,
    },
  })
);
/** endregion */

interface IProps {
  message: string;
}

const NotFound = (props: IProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.sinDatosContainer}
    >
      <Grid item sm={12} className={classes.textContainer}>
        <Typography variant="subtitle1" className={classes.text}>
          {props.message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
