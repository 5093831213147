import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { dealerServices } from '../../../../services/dealerServices';
import useStyles from './CreditLineDetail.style';

import MainContainer from '../../../maincontainer';
import Title from '../../../common/title';
import { Loading } from '../../../common/loading';
import CreditLineDetailItem from './CreditLineDetailItem';
import { IDataLineCreditDealer } from '../../../../models/spareParts/DealerInterfaces';
import UnExpectedError from '../../../common/pageError/UnExpectedError';

const CreditLineDetail: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorCreditLineService, setErrorCreditLineService] = useState(false);
  const [creditLineDetail, setCreditLineDetail] = useState([] as IDataLineCreditDealer[]);

  const getCreditLineDetail = async () => {
    dealerServices
      .getLineDetailsSpareParts()
      .then((response) => {
        setLoading(false);
        setCreditLineDetail(response.data);
        setErrorCreditLineService(false);
      })
      .catch((_) => {
        setErrorCreditLineService(true);
      });
  };

  useEffect(() => {
    getCreditLineDetail();
  }, []);

  return (
    <MainContainer>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          id={'title'}
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title title={'Detalle de líneas'} />
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!loading && creditLineDetail.length > 0 && !errorCreditLineService && (
              <CreditLineDetailItem dataDealers={creditLineDetail} />
            )}
            {loading && <Loading />}
            {errorCreditLineService && (
              <UnExpectedError message={`Se produjo un error inesperado`} />
            )}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default CreditLineDetail;
