import React from 'react';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import DetallesLineasUsados from '../../../../components/used/creditLine/DetallesLineasUsados';
import useStyles from './DetallesLineasUsadosPage.style';

const DetallesLineasUsadosPage: React.FC = () => {
  const classes = useStyles();
  return (
    <MainContainer>
      <Grid container id="title" className={classes.header}>
        <Grid item sm={12}>
          <Title title="Detalles de líneas" />
        </Grid>
      </Grid>
      <DetallesLineasUsados />
    </MainContainer>
  );
};

export default DetallesLineasUsadosPage;
