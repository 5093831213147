import React from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFetch } from '../../../../hooks/useFetch';
import { PaymentDetail, UsedPaymentsDetailResponse } from '../../../../models/used';
import { Loading } from '../../../common/loading';
import ErrorUsados from '../../commons/ErrorUsados';

import useStyles, { StyledTableCell } from './DetalleHistorialPagoUsados.style';
import { getObjDateLocal } from '../../../../utilities/dates-functions';
import { useAuth } from '../../../common/auth';
import { downloadFileUsed } from '../../../../utilities/downloadFileUsed';
import { FetchConfig } from '../../../../config/fetchConfig';
import TablePaginationActions from '../../../common/table/tablePaginationActions';

type Props = {
  id: string;
};

const DetalleHistorialPagoUsados: React.FC<Props> = ({ id }) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { setShowNotification } = useAuth();

  const url = `/api/payments/history/${id}?pageSize=${rowsPerPage}&pageNumber=${page + 1}`;
  const { status, data, error } = useFetch<UsedPaymentsDetailResponse>(url);

  const matches = useMediaQuery('(min-width:1440px)');

  let payments: PaymentDetail[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    payments = data?.Payments.Result ?? payments;
    totalItems = data?.Payments.TotalItems ?? 0;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadFile = (e: React.MouseEvent<HTMLButtonElement>, paymentId: string) => {
    e.preventDefault();
    const urlDownload = `/api/payments/history/${paymentId}/download`;
    downloadFileUsed(urlDownload, {
      fileName: paymentId,
    } as FetchConfig).then((state) => {
      if (state?.status === 'error') {
        setShowNotification({
          show: true,
          variant: 'error',
          message: 'Ocurrió un problema descargando el archivo.',
        });
      } else {
        setShowNotification({
          show: true,
          variant: 'success',
          message: 'Archivo descargado correctamente',
        });
      }
    });
  };

  if (status === 'error' && error)
    return (
      <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
    );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.title}
              >
                <Grid item className={classes.mainTitle}>
                  {data?.PayedVins === 1
                    ? `${data?.PayedVins} VIN pagado`
                    : `${data?.PayedVins} VIN pagados`}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {payments.map((row) => {
                      return (
                        <TableRow key={row.UsedOperationId}>
                          <StyledTableCell>
                            VIN: <b>{row.Vin}</b>
                          </StyledTableCell>
                          <StyledTableCell>
                            Fecha de pago:{' '}
                            <b>{getObjDateLocal(row.PaymentDate, true, 'dd-LL-yyyy').date}</b>
                          </StyledTableCell>
                          <StyledTableCell>
                            Id del pago: <b>{row.PaymentId}</b>
                          </StyledTableCell>
                          <StyledTableCell>
                            Método de pago: <b>{row.PaymentMethodName}</b>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="contained"
                              disabled={!row.WithAttachment}
                              className={classes.btnDownload}
                              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                handleDownloadFile(e, row.PaymentId)
                              }
                              startIcon={<GetAppIcon />}
                            >
                              {matches ? 'Descargar documento' : 'Descargar'}
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {status === 'fetched' && payments?.length > 0 && (
                        <TablePagination
                          labelRowsPerPage={() => 'Filas por página:'}
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={5}
                          count={totalItems}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      )}
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetalleHistorialPagoUsados;
