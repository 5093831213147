import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './ApplyPaymentSparePartPage.style';
import clsx from 'clsx';
import ApplyPaymentSparePart from '../../../../components/spareParts/paymentPending/applyPaymentSparePart';

const ApplyPaymentSparePartPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleGrantedClick = () => {
    props.history.push('/repuestos/otorgadas');
  };

  const handleApplyPaymentClick = () => {
    props.history.push('/repuestos/otorgadas/aplicacion-pago');
  };

  const handleConfirmationPendingClick = () => {
    props.history.push('/repuestos/otorgadas/pendientes-confirmacion');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Pendientes de pago">
            <Grid container className={classes.tabNavigation}>
              <Grid
                item
                sm={4}
                className={clsx(classes.tab, classes.borderBottom)}
                onClick={handleGrantedClick}
              >
                OTORGADAS
              </Grid>
              <Grid item sm={4} className={classes.tabSelected} onClick={handleApplyPaymentClick}>
                APLICACIÓN DE PAGO
              </Grid>
              <Grid
                item
                sm={4}
                className={clsx(classes.tab, classes.borderBottom)}
                onClick={handleConfirmationPendingClick}
              >
                PENDIENTES DE CONFIRMACIÓN
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>

      <ApplyPaymentSparePart />
    </MainContainer>
  );
};

export default ApplyPaymentSparePartPage;
