import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import { SuccessApiResponse } from '../../../../models/SuccessApiResponse';
import { put } from '../../../../utilities/requestsSpareparts';
import { useAuth } from '../../../common/auth';
import useStyles from './ConfirmPendingSpareParts.style';

type Props = {
  paymentId: string;
  isRejected: boolean;
  open: boolean;
  handleClose: () => void;
  reload: () => void;
};

const ModalConfirmPendingSpareParts: React.FC<Props> = ({
  paymentId,
  isRejected,
  open,
  handleClose,
  reload,
}) => {
  const classes = useStyles();
  const { setShowNotification } = useAuth();
  const [sending, setSending] = React.useState(false);

  const handleUpdatePaymentClick = async () => {
    setSending(true);
    const response = await put<SuccessApiResponse>(`/api/payments/confirm/${paymentId}`, {
      Status: `${isRejected ? 'reject' : 'confirm'}`,
    });
    setSending(false);
    if (response.status === 'success') {
      setShowNotification({
        show: true,
        variant: 'success',
        message: `Pago ${isRejected ? 'rechazado' : 'confirmado'} correctamente.`,
      });
    } else {
      setShowNotification({
        show: true,
        variant: 'error',
        message: `No se pudo actualizar el estado del pago.`,
      });
    }
    handleClose();
    reload();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.modalTitle}>
        {`${isRejected ? 'Rechazar' : 'Confirmar'} pago`}
      </DialogTitle>
      <DialogContent>
        <div className={classes.modalContent}>
          {`Usted está ${
            isRejected ? 'rechazando' : 'confirmando'
          } el pago con identificador ${paymentId}.`}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary">
          No
        </Button>
        <Button
          className={classes.btnConfirm}
          variant="contained"
          startIcon={<CheckCircleOutlineIcon />}
          onClick={() => handleUpdatePaymentClick()}
          disabled={sending}
        >
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmPendingSpareParts;
