import { Grid } from '@material-ui/core';
import React from 'react';
import { CHARGETYPE } from '../../../../constants';
import { IChargeCreditNotes } from '../../../../interfaces';
import { ApiResponse, OperationsChargeResponse } from '../../../../models/commons';
import { postFileChargeCreditNotes } from '../../../../utilities/requestsFreeZone';
import ResultChargeOperations from '../../../common/charge/resultChargeOperations';
import UploadFile from '../../../common/charge/uploadFile';
import LastChargeOperations from '../../commons/lastChargeOperations';
import useStyles from './ChargeCreditNotesFreeZone.style';

const ChargeCreditNotesFreeZone = () => {
  const classes = useStyles();
  const [showResult, setShowResult] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<OperationsChargeResponse>(
    {} as OperationsChargeResponse
  );

  const handleUploadFile = async (
    formData: FormData
  ): Promise<ApiResponse<OperationsChargeResponse>> => {
    const data: IChargeCreditNotes = {
      File: formData,
    };
    const dataResponse = await postFileChargeCreditNotes<OperationsChargeResponse>(
      `/api/chargeCreditNotes/upload`,
      data
    );
    return dataResponse;
  };

  const handleViews = (response: OperationsChargeResponse) => {
    setUploadResponse(response);
    setShowResult(true);
  };

  const handleUploadAgain = () => {
    setShowResult(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <LastChargeOperations chargeType={CHARGETYPE.CREDIT_NOTES} />

        {!showResult ? (
          <>
            <UploadFile uploadFile={handleUploadFile} handleViews={handleViews} />
          </>
        ) : (
          <ResultChargeOperations
            ChargeId={uploadResponse.ChargeId}
            Total={uploadResponse.Total}
            TotalSuccessful={uploadResponse.TotalSuccessful}
            TotalRejected={uploadResponse.TotalRejected}
            FileName={uploadResponse.FileName}
            UploadAt={uploadResponse.UploadAt}
            handleUploadAgain={handleUploadAgain}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChargeCreditNotesFreeZone;
