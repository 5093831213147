import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: 120,
      paddingTop: '120px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    boton: {
      color: '#FFFFFF',
      padding: 5,
      background: COLORS.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      borderRadius: 4,
      width: 27,
      height: 27,
      cursor: 'pointer',
    },
    tituloContainer: {
      height: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    text: {
      fontFamily: 'Nunito Sans',
      fontSize: 28,
    },
  })
);

export default useStyles;
