import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useAuth } from '../auth';
import { COLORS } from '../../../constants';

export type CompanyStyleProps = {
  isUsed: boolean;
};

const DivisionNames = {
  nuevos: 'Nuevos',
  usados: 'Usados',
  repuestos: 'Repuestos',
  zona: 'Zona Franca',
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      marginLeft: '20px',
      minHeight: '25px',
    },
    title: {
      fontSize: '18px',
      color: COLORS.secondary,
      fontWeight: 'bold',
    },
  })
);

const BusinessContext: React.FC<RouteComponentProps> = () => {
  const { businessType } = useAuth();
  const classes = useStyles();
  const divisionDic = Object.entries(DivisionNames).find(([key]) => key === businessType);
  const show =
    !window.location.pathname.endsWith('/negocio') && !window.location.pathname.endsWith('/login');

  return show ? (
    <div className={classes.container}>
      <span className={classes.title}>{divisionDic?.[1]}</span>
    </div>
  ) : (
    <></>
  );
};

export default withRouter(BusinessContext);
