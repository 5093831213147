import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MainContainer from '../../../../maincontainer';
import Title from '../../../../common/title';
import UploadFile from '../../../../common/uploadfile';
import BarInfoUpload from '../../../../common/header/barinfoupload';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { processCreditNoteFile } from '../../../../../services/file-manager-service';
import { COLORS, TABS } from '../../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;
const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cajaContainer: {
      fontFamily: 'Nunito Sans',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '0px 20px',
      paddingTop: 20,
    },
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    detalles: {
      color: '#666666',
      fontSize: 16,
      height: 72,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    tabContainer: {
      backgroundColor: COLORS.primary,
      height: 48,
      paddingLeft: 20,
      paddingRight: 20,
    },
    tab: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      background: '#FFFFFF',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
      ...tabStylesObj,
    },
  })
);
/** endregion */

interface IProps extends RouteComponentProps {}

export const onShowTitle = () => {
  return (
    <Title
      title="Carga de archivo"
      mostrarTabSubirExcel={true}
      tabSelected={TABS.CHARGE_CREDIT_NOTES}
    />
  );
};

export const handleFunctionLoadFile = async (params: FormData, key: any) => {
  return await processCreditNoteFile(params);
};

export const handleHiddenDownload = () => {
  const headerContainerDownload = document.getElementById('containerdownload');
  if (headerContainerDownload) {
    headerContainerDownload.style.visibility = 'hidden';
  }
};

const ViewCargaNotasCredito = (props: IProps) => {
  const classes = useStyles();

  const hadleSeeTabHistorialClick = async () => {
    props.history.push('/nuevos/carga-notas-de-credito/historial');
  };

  useEffect(() => {
    handleHiddenDownload();
  }, []);

  return (
    <MainContainer>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.tabContainer}
        >
          <Grid item sm={2} className={classes.tabSelected}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tab} onClick={hadleSeeTabHistorialClick}>
            Historial
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item className={classes.cajaContainer}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              <Grid item md={6} className={classes.detalles}>
                <BarInfoUpload isHeader={false} />
              </Grid>

              <Grid item>
                <UploadFile
                  key="csv"
                  formato=".csv"
                  showfooter={false}
                  uploadFile={handleFunctionLoadFile}
                  loadComponent="LoadNotasCredito"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default withRouter(ViewCargaNotasCredito);
