import apiConfig from '../config/api';
import { post, get } from '../utilities/request';
import { IBillingRequest } from '../interfaces';

export const getPendingOperations = async () => {
  const url = `${apiConfig.apiUri}/api/pendingBillingOperation/pending`;
  return get(url, {});
};

export const setRequestedOperations = async (billingRequest: IBillingRequest) => {
  const url = `${apiConfig.apiUri}/api/billingOperation`;
  return post(url, billingRequest);
};

export const getRequestedOperation = async () => {
  const url = `${apiConfig.apiUri}/api/pendingBillingOperation/requested`;
  return get(url, {});
};
