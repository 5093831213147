import { Button, Grid, TableCell, TableRow } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { PaymentsPendingConfirmation } from '../../../../../models/commons';
import { rutFormater } from '../../../../../utilities/string-functions';
import useStyles, { StyledTableCell } from './ItemConfirmationPendingFreeZone.style';
import ModalConfirmationPendingFreeZone from '../modalConfirmationPendingFreeZone/ModalConfirmationPendingFreeZone';

type Props = {
  pending: PaymentsPendingConfirmation;
  reload: () => void;
};

const ItemConfirmPendingFreeZone: React.FC<Props> = (props) => {
  const { pending, reload } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isRejected, setIsRejected] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectedClick = (isRej: boolean) => {
    setIsRejected(isRej);
    setOpen(true);
  };

  return (
    <TableRow className={classes.itemContainer}>
      <StyledTableCell className={classes.columnDealer}>
        <div style={{ fontSize: '1.1rem' }}>{pending.DealerName}</div>
        <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
          {rutFormater(`${pending.DealerRut}-${pending.VerificationNumber}`)}
        </div>
      </StyledTableCell>
      <StyledTableCell className={classes.columnPaymentId}>{pending.PaymentId}</StyledTableCell>
      <StyledTableCell className={classes.column}>{pending.Invoices}</StyledTableCell>
      <StyledTableCell className={classes.column}>{pending.PaymentMethodName}</StyledTableCell>
      <StyledTableCell>
        <Grid container justifyContent="space-evenly" className={classes.actions}>
          <Grid item>
            <Button
              variant="contained"
              className={classes.btnPaid}
              startIcon={<CheckCircleIcon />}
              onClick={() => handleRejectedClick(false)}
              data-testid="btn-paid"
            >
              PAGADO
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.btnReject}
              startIcon={<CancelIcon />}
              onClick={() => handleRejectedClick(true)}
              data-testid="btn-reject"
            >
              RECHAZAR PAGO
            </Button>
          </Grid>
          <ModalConfirmationPendingFreeZone
            paymentId={pending.PaymentId}
            isRejected={isRejected}
            open={open}
            handleClose={handleClose}
            reload={reload}
          />
        </Grid>
      </StyledTableCell>
    </TableRow>
  );
};

export default ItemConfirmPendingFreeZone;
