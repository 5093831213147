import config from '../config/api';
import { BUSINESSTYPE } from '../constants';

export const getDivisionBaseURL = (division?: string): string => {
  let apiUri;
  switch (division) {
    case BUSINESSTYPE.PARTS:
      apiUri = config.sparePartSettings.apiUri;
      break;
    case BUSINESSTYPE.FREEZONE:
      apiUri = config.freeZoneSettings.apiUri;
      break;
    default:
      apiUri = config.usedSettings.apiUri;
      break;
  }
  return apiUri;
};
