import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { COLORS } from '../../constants';

export const Loading = () => {
  return (
    <Grid container className="loading">
      <CircularProgress size={60} style={{ color: COLORS.secondary }} />
    </Grid>
  );
};
