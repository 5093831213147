import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants';

/** region style */
const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {},
    tab: {
      borderBottom: '1px solid #FFFFFF',
      height: 48,
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      borderBottom: '1px solid #FFFFFF',
      height: 48,
      textTransform: 'uppercase',
      cursor: 'default',
      background: '#FFFFFF',
      color: COLORS.primary,
      ...tabStylesObj,
    },
  })
);
/** endregion */

const TABS = {
  PENDIENTES: 'pendientes-giros',
  HISTORICO: 'historico-giros',
};

interface IProps extends RouteComponentProps {
  tabSelected: string;
}

const TabMenuPendientes = (props: IProps) => {
  const classes = useStyles();

  const { tabSelected } = props;

  const handleTabSubirExcelClick = () => {
    props.history.push('/nuevos/pendientes-giro');
  };

  const handleTabHistorialCargaClick = () => {
    props.history.push('/nuevos/pendientes-giro/historico-giros');
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.tabContainer}
    >
      <Grid
        item
        sm={4}
        onClick={handleTabSubirExcelClick}
        className={tabSelected === TABS.PENDIENTES ? classes.tabSelected : classes.tab}
      >
        Pendientes de giro
      </Grid>
      <Grid
        item
        sm={4}
        onClick={handleTabHistorialCargaClick}
        className={tabSelected === TABS.HISTORICO ? classes.tabSelected : classes.tab}
      >
        Histórico de giros
      </Grid>
    </Grid>
  );
};

export default withRouter(TabMenuPendientes);
