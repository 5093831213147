import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../constants';

export type LastChargeOperationsStyleProps = {
  isHeader: boolean;
};

const useStyles = makeStyles(() => ({
  root: (props: LastChargeOperationsStyleProps) => ({
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'auto',
    justifyContent: 'flex-start',
    height: `auto`,
    color: props.isHeader ? '#FFFFFF' : COLORS.sextenary,
    fontSize: '16px',

    '& > *': {
      marginRight: '20px',
    },
  }),
  boldTitle: (props: LastChargeOperationsStyleProps) => ({
    fontWeight: 'bold',
    paddingRight: 5,
    color: props.isHeader ? '#FFFFFF' : COLORS.primary,
  }),
  fileInfo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default useStyles;
