import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Title from '../../common/title';
import NotFound from '../../common/notfound';

import Tupla from './tupla';

import { IPendingRequest } from '../../../interfaces';

import { getPendingRequests } from '../../../services/money-order-request-service';

import MainContainer from '../../maincontainer';
import { Loading } from '../../common/loading';
import { COLORS } from '../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps {}

function PendientesGiro(props: IProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [dataPendingRequest, setDataPendingRequest] = useState([] as Array<IPendingRequest>);
  const [existDataPendingRequest, setExistDataPendingRequest] = useState(false);

  const handleGetPendingRequests = async () => {
    setLoading(true);

    getPendingRequests().then((response) => {
      setDataPendingRequest(response.data);
      setExistDataPendingRequest(response.data.length > 0);
      setLoading(false);
    });
  };

  useEffect(() => {
    handleGetPendingRequests();
  }, []);

  const onShowTitle = () => {
    return <Title title="Pendientes de giro" mostrarTabPendientes tabSelected="pendientes-giros" />;
  };

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            {onShowTitle()}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {!loading && !existDataPendingRequest && (
              <NotFound message="No tienes Pendientes de giro" />
            )}
            {!loading && existDataPendingRequest && (
              <Tupla data={dataPendingRequest} onRefresh={handleGetPendingRequests} />
            )}
            {loading && <Loading />}
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}

export default PendientesGiro;
