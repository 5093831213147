import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import DetalleCargaOperacionesUsados from '../../../../components/used/charge/DetalleCargaOperacionesUsados';
import useStyles from './DetalleCargaOperacionesUsadosPage.style';
import TituloDetallesUsados from '../../../../components/used/commons/TituloDetallesUsados';

type Params = { id: string };

const DetalleCargaOperacionesUsadosPage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;

  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'historial') props.history.push('/usados/carga-operaciones/historial');
    else props.history.push('/usados/carga-operaciones');
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <TituloDetallesUsados title="Detalle carga de operaciones" />
          </Grid>
        </Grid>

        <Grid container className={classes.tabNavigation}>
          <Grid item sm={2} className={classes.tab} onClick={handleClickOnChangeTab}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
            Historial
          </Grid>
        </Grid>
      </Grid>

      <DetalleCargaOperacionesUsados id={match.params.id} />
    </MainContainer>
  );
};

export default DetalleCargaOperacionesUsadosPage;
