import { Grid } from '@material-ui/core';
import React from 'react';
import { BUSINESSTYPE } from '../../../../constants';
import { useFetch } from '../../../../hooks/useFetch';
import { UsedDetailLine } from '../../../../models/used';
import Error from '../../../common/Error';
import { Loading } from '../../../common/loading';
import NotFoundUsados from '../../../used/commons/NotFoundUsados';
import useStyles from './DetailLineFreeZone.style';
import ItemDetailLineFreeZone from './ItemDetailLineFreeZone';

const DetailLineFreeZone: React.FC = () => {
  const classes = useStyles();

  const url = `/api/dealer`;
  const {
    status,
    data: usedDetailLines,
    error,
  } = useFetch<UsedDetailLine[]>(url, undefined, BUSINESSTYPE.FREEZONE);

  if (status === 'error' && error) {
    return <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          usedDetailLines?.map((line) => {
            return <ItemDetailLineFreeZone key={line.CreditLineId} detailLine={line} />;
          })}
        {status === 'fetched' && usedDetailLines?.length === 0 && (
          <NotFoundUsados message="No existen líneas de créditos para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default DetailLineFreeZone;
