import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { FetchConfig } from '../config/fetchConfig';
import { getDivisionBaseURL } from './apiHelper';

// por default siempre poner interceptor
export const axiosWithInterceptor = (
  customConfig: FetchConfig,
  businessType?: string
): AxiosInstance => {
  const baseURL = getDivisionBaseURL(businessType);
  const usedAxios = axios.create({
    baseURL,
  });

  usedAxios.interceptors.request.use(
    (config) => {
      if (customConfig.withFatalError) config.headers.WithFatalError = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  usedAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      localStorage.setItem('urlToReturn', window.location.pathname);

      if (error.response?.status === 401) {
        localStorage.setItem('isExceptionError', 'true');
        localStorage.setItem('codeExceptionError', error.response.status.toString());
        localStorage.setItem('traceIdExceptionError', error.response.data.id);
        localStorage.setItem('messageExceptionError', error.response.data.message);
        window.location.reload();
      }

      if (error.response?.status === 502) {
        localStorage.setItem('isExceptionError', 'true');
        localStorage.setItem('codeExceptionError', error.response.status.toString());
        localStorage.setItem('messageExceptionError', 'Servicio no disponible.');
        window.location.reload();
      }

      // Esto solo para ambiente local, en k8s el backend no lo manda
      if (error.response?.data.DeveloperMessage) {
        // eslint-disable-next-line no-console
        console.error(error.response.data.DeveloperMessage);
      }

      return Promise.reject(error.response);
    }
  );

  return usedAxios;
};
