import React from 'react';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './DetailLineFreeZonePage.style';
import { Grid } from '@material-ui/core';
import DetailLineFreeZone from '../../../../components/freeZone/creditLine/detailLineFreeZone';

const DetailLineFreeZonePage: React.FC = () => {
  const classes = useStyles();

  return (
    <MainContainer>
      <Grid container id="title" className={classes.header}>
        <Grid item sm={12}>
          <Title title="Detalles de líneas" />
        </Grid>
      </Grid>
      <DetailLineFreeZone />
    </MainContainer>
  );
};

export default DetailLineFreeZonePage;
