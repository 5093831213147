import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useStyles, { StyledTableCell } from './HistorialPendienteGiroUsados.style';
import { NotificationModel, UsedMoneyTransferHistoryResponse } from '../../../../models/used';
import Notification from '../../../common/notification';
import { numberToMiles } from '../../../../utilities/string-functions';
import { getDateWithoutTime, getObjDateLocal } from '../../../../utilities/dates-functions';
import { downloadFileUsed } from '../../../../utilities/downloadFileUsed';
import { FetchConfig } from '../../../../config/fetchConfig';
import { COLORS } from '../../../../constants';

type Props = {
  transfer: UsedMoneyTransferHistoryResponse;
};

const ItemHistorialPendienteGiroUsados: React.FC<Props> = ({ transfer }) => {
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  const [showNotificacion, setShowNotification] = React.useState<NotificationModel>({
    show: false,
    variant: 'success',
    message: 'Archivo descargado correctamente',
  });

  const handleHistoryDownload = (moneyTransferId: string) => {
    const urlDownload = `/api/moneyTransfer/download/${moneyTransferId}`;
    setBtnDisabled(true);
    setDownloading(true);
    downloadFileUsed(urlDownload, {
      fileName: `Solicitud de giro_${moneyTransferId}.xlsx`,
    } as FetchConfig).then((state) => {
      if (state?.status === 'error') {
        setShowNotification({
          show: true,
          variant: 'error',
          message: 'Ocurrió un problema descargando el archivo.',
        });
      } else {
        setShowNotification({
          show: true,
          variant: 'success',
          message: 'Archivo descargado correctamente',
        });
      }
      setDownloading(false);
      setBtnDisabled(false);
    });
  };

  return (
    <TableRow className={classes.contenedor}>
      <TableCell width="100%">
        <div className={classes.titleItem}>{getDateWithoutTime(transfer.Date)}</div>
        <Table width="100%" aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Hora de otorgado</StyledTableCell>
              <StyledTableCell>Id solicitud de giro</StyledTableCell>
              <StyledTableCell align="right">Monto a girar</StyledTableCell>
              <StyledTableCell>&nbsp;</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfer.Details.map((detail) => (
              <TableRow key={detail.MoneyTransferId}>
                <StyledTableCell width="20%">
                  {getObjDateLocal(detail.GrantedAt).time.split('.')[0]}
                </StyledTableCell>
                <StyledTableCell width="40%">
                  <span className={classes.textBlue}>{detail.MoneyTransferId}</span>
                </StyledTableCell>
                <StyledTableCell width="20%" align="right">
                  ${numberToMiles(detail.GrantAmount)}
                </StyledTableCell>
                <StyledTableCell width="20%">
                  <div className={classes.btnContent}>
                    <Button
                      variant="contained"
                      disabled={btnDisabled}
                      className={classes.btnDownload}
                      startIcon={<GetAppIcon />}
                      onClick={() => handleHistoryDownload(detail.MoneyTransferId)}
                      style={{ backgroundColor: COLORS.secondary }}
                    >
                      {downloading ? 'Descargando excel...' : 'Descargar excel'}
                    </Button>
                  </div>
                  {showNotificacion.show && (
                    <Notification
                      variant={showNotificacion.variant}
                      message={showNotificacion.message}
                      show={showNotificacion.show}
                      onClose={() =>
                        setShowNotification((p) => {
                          return { ...p, show: false };
                        })
                      }
                    />
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
};
export default ItemHistorialPendienteGiroUsados;
