import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core/';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IFooter } from '../../../../../interfaces';
import useStyles from './FooterOperationsFreeZone.style';

const DICTIONARY = {
  InitialMessageState: 'SOLICITAR GIRO',
  ProcessMessageState: 'Solicitando giro',
  h1: 'Total notas de crédito por cobrar',
  h2: 'Monto solicitud',
  h3: 'Notas de crédito',
  h4: 'Monto total a girar',
};

const FooterTaken = (props: IFooter) => {
  const classes = useStyles();
  const [message, setMesage] = useState(DICTIONARY.InitialMessageState as string);
  const {
    onUpdate,
    disabled,
    data: {
      debtsCreditNote,
      requestAmount,
      creditNote,
      totalMount,
      totalCrediNote,
      totalCreditNoteAvailable,
    },
  } = props;

  const handleUpdateClick = () => {
    setMesage(DICTIONARY.ProcessMessageState as string);
    onUpdate();
  };
  const creditNoteMsje = `$${creditNote} (${totalCrediNote} notas)`;
  const creditNoteAvailable = `${debtsCreditNote} (${totalCreditNoteAvailable} notas)`;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.container}
      style={{ height: 70 }}
    >
      <Grid item id={`gridFooterDebtsCreditNote`} xs={3} sm={3}>
        <Grid item xs={12} sm={12}>
          <label id="h1" role="h1" className={classes.labelCreditNotes}>
            {DICTIONARY.h1}
          </label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span id={`footerDebtsCreditNote`} className={classes.labelCreditNotes}>
            ${creditNoteAvailable}
          </span>
        </Grid>
      </Grid>
      <Grid item id={`gridFooterRequestAmount`} xs={2} sm={2} className={classes.verticalLine}>
        <Grid item xs={12} style={{ opacity: '60%' }} sm={12}>
          <label id="h2" className={classes.labelCreditNotes}>
            {DICTIONARY.h2}
          </label>
        </Grid>
        <Grid item xs={12} style={{ opacity: '60%' }} sm={12}>
          <span id={`footerRequestAmount`} className={classes.labelCreditNotes}>
            ${requestAmount}
          </span>
        </Grid>
      </Grid>
      <Grid id={`gridFooterCreditNote`} style={{ opacity: '60%' }} item xs={2} sm={2}>
        <Grid item xs={12} sm={12}>
          <label id="h3 " className={classes.labelCreditNotes}>
            {DICTIONARY.h3}
          </label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span id={'footerCreditNote'} className={classes.labelCreditNotes}>
            {creditNoteMsje}
          </span>
        </Grid>
      </Grid>
      <Grid id={`gridFooterTotalMount`} item xs={2} sm={2} className={classes.labelAmount}>
        <Grid item xs={12} sm={12}>
          <label id="h4">{DICTIONARY.h4}</label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <span className={classes.footerTotalMount} id={'footerTotalMount'}>
            ${totalMount.toLocaleString()}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={3} sm={3} className={classes.contentbutton}>
        <Button
          id={'btnCreateOrderRequest'}
          name={'btnCreateOrderRequest'}
          variant="contained"
          disabled={disabled}
          startIcon={<ArrowUpwardIcon />}
          className={!disabled ? classes.button : classes.buttonDisabled}
          onClick={handleUpdateClick}
        >
          <span id="footerRequestTurnBtn">{message}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterTaken;
