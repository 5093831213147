export class CancelRequest {
  isCancel: boolean;

  constructor() {
    this.isCancel = false;
  }

  cancel() {
    this.isCancel = true;
  }
}
