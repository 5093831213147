import { Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Title from '../../../../components/common/title';
import MainContainer from '../../../../components/maincontainer';
import ChargeOperations from '../../../../components/spareParts/charge/chargeOperations';
import useStyles from './ChargeOperationsPage.style';

const ChargeOperationsSparePartsPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'historial') props.history.push('/repuestos/carga-operaciones/historial');
    else props.history.push('/repuestos/carga-operaciones');
  };

  return (
    <MainContainer>
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item sm={12}>
            <Title title="Carga de operaciones" />
          </Grid>
        </Grid>

        <Grid container className={classes.tabNavigation}>
          <Grid item sm={2} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tab} onClick={handleClickOnChangeTab}>
            Historial
          </Grid>
        </Grid>
      </div>

      <ChargeOperations />
    </MainContainer>
  );
};

export default ChargeOperationsSparePartsPage;
