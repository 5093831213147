import { createStyles, makeStyles, TableCell, withStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../constants';

export const StyledTableCell = withStyles(() => ({
  head: {
    color: '#666666',
    fontSize: '16px',
    padding: '10px',
  },
  body: {
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}))(TableCell);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '20px 20px 0px 20px',
      height: 'calc(100vh - 200px)',
      overflowY: 'auto',
    },
    table: {
      height: '100%',
    },
    contenedor: {
      fontFamily: 'Nunito Sans',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#666666',
      fontSize: '12px',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'calc((100vh - 248px)/7.5)',
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      flexWrap: 'nowrap',
      marginBottom: '1.5em',
    },

    titleItem: {
      color: COLORS.secondary,
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: '1px solid #979797',
      padding: '10px 5px 10px 5px',
      width: '100%',
    },

    btnDownload: {
      color: 'white',
      fontSize: '14px',
      background: '#FF8C00',
      lineHeight: '0px',
      minWidth: '132px',
      textTransform: 'none',
    },

    textBlue: {
      color: COLORS.secondary,
      textTransform: 'uppercase',
    },
    btnContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rowNotFound: {
      display: 'flex',
      alignItems: 'flex-start',
      minHeight: '100%',
    },
  })
);

export default useStyles;
