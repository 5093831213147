import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './AlertUsados.style';

type AlertUsadosProps = {
  show?: boolean;
  type?: 'success' | 'info' | 'warning' | 'error' | undefined;
  text?: string;
};

const AlertUsados: React.FC<AlertUsadosProps | Partial<AlertUsadosProps>> = (props) => {
  const { show = false, type = 'success', text = '' } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(show);

  return (
    <Collapse className={classes.alert} in={open}>
      <Alert
        severity={type}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
};

export default AlertUsados;
