import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import VerticalAlignTopOutlinedIcon from '@material-ui/icons/VerticalAlignTopOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { COLORS } from '../../constants';

interface IProps {
  componente: string;
  dataCss: string;
  isActive: boolean;
}

const theme = createTheme({
  overrides: {
    MuiIcon: {
      colorPrimary: { main: '#2f5cbb' },
    },
    // primary: { main: '#2f5cbb' },
  },
});
export default function LoadIcon(props: IProps) {
  const { componente, dataCss, isActive } = props;
  // const color = isActive ? 'primary' : 'inherit';

  function getIcon(key: string) {
    switch (key) {
      case 'up-to-top':
        return <VerticalAlignTopOutlinedIcon htmlColor={isActive ? '#FFFFFF' : COLORS.primary} />;
        break;
      case 'list':
        return <FormatListBulletedOutlinedIcon htmlColor={isActive ? '#FFFFFF' : COLORS.primary} />;
        break;
      case 'thumbsup':
        return <ThumbUpAltOutlinedIcon htmlColor={isActive ? '#FFFFFF' : COLORS.primary} />;
        break;
      case 'time':
        return <WatchLaterOutlinedIcon htmlColor={isActive ? '#FFFFFF' : COLORS.primary} />;
        break;
      case 'document-add':
        return <NoteAddOutlinedIcon htmlColor={isActive ? '#FFFFFF' : COLORS.primary} />;
        break;
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={dataCss}>{getIcon(componente)}</div>
    </MuiThemeProvider>
  );
}
