import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { rutFormater } from '../../../../utilities/string-functions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Icons from '../../../common/icons';
import { COLORS } from '../../../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cajaContainer: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 72,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    cajaDetalle: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      height: 72,
      display: 'flex',
    },
    dealer: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: '10px',
    },
    containerBotonera: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    btnPagado: {
      width: 86,
      height: 28,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 12,
      marginRight: 20,
    },
    btnRechazarPago: {
      width: 140,
      height: 28,
      backgroundColor: COLORS.secondary,
      lineHeight: '16px',
      textAlign: 'center',
      color: 'white',
      textTransform: 'none',
      borderRadius: 4,
      fontSize: 12,
    },
    botonIcon: {
      fontSize: 16,
      marginRight: 5,
      color: '#FFFFFF',
    },

    cajaDetalleOk: {
      height: 72,
    },
    barraEstado: {
      height: '60px',
      borderRadius: '4px',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    barraEstadoIcon: {
      fontSize: 36,
      marginRight: 10,
      marginLeft: 10,
    },
    delaerRut: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
  })
);
/** endregion */

interface IProps {
  dataPendingConfirmation: any;
  onHandleAceptedPayment: (paymendId: string) => void;
  onHandleCancelPayment: (paymendId: string) => void;
}

const Operations = (props: IProps) => {
  const classes = useStyles();

  const { dataPendingConfirmation, onHandleAceptedPayment, onHandleCancelPayment } = props;

  const [data, setData] = useState(dataPendingConfirmation);

  const handleAceptedPayment = (paymendId: string) => {
    onHandleAceptedPayment(paymendId);
  };

  const handleCancelPayment = (paymendId: string) => {
    onHandleCancelPayment(paymendId);
  };

  const handleSetData = () => {
    setData(dataPendingConfirmation);
  };

  useEffect(() => {
    handleSetData();
  });

  return (
    <Fragment>
      {data.PaymentAppliedPendingConfirmationDetail.map((detail: any, numberDetail: number) => {
        const rutFormat = rutFormater(data.DealerId.toString() + data.VerificationNumber);
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.cajaContainer}
            key={`tabpendconfirm_${detail.PaymentId}`}
            id={`detailPendingConfirmation_${detail.PaymentId}`}
          >
            <Grid item md={12} className={classes.cajaDetalle}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={3}>
                  <span className={classes.dealer}>{data.DealerName}</span>
                  <span className={classes.delaerRut}>{rutFormat}</span>
                </Grid>
                <Grid item md={2} style={{ fontWeight: 'bold' }}>
                  {detail.PaymentId}
                </Grid>
                <Grid item md={2}>
                  {detail.OperationsRelated}
                </Grid>
                <Grid item md={2}>
                  {detail.PaymentMethodName}
                </Grid>
                <Grid
                  item
                  md={3}
                  className={classes.containerBotonera}
                  id={`botonera_${detail.PaymentId}`}
                >
                  <Button
                    id={`btnAceptPayment_${detail.PaymentId}`}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleAceptedPayment(detail.PaymentId);
                    }}
                    className={classes.btnPagado}
                  >
                    <CheckCircleIcon className={classes.botonIcon} />
                    Pagado
                  </Button>
                  <Button
                    id={`btnCancelPayment_${detail.PaymentId}`}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleCancelPayment(detail.PaymentId);
                    }}
                    className={classes.btnRechazarPago}
                  >
                    <Icons name="misuse-filled" size="16px" cssClassName={classes.botonIcon} />
                    Rechazar pago
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              className={classes.cajaDetalleOk}
              style={{ display: 'none' }}
              id={`leyend_${detail.PaymentId}`}
            >
              <Grid
                item
                xs={12}
                sm={12}
                className={classes.barraEstado}
                style={{ backgroundColor: COLORS.secondary }}
              >
                <CheckCircleOutlineIcon className={classes.barraEstadoIcon} />
                Todos los pagos han sido confirmados.
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default Operations;
