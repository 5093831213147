import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './ListGrantedFreeZoneOperations.style';
import config from '../../../../config/api';
import { useFetch } from '../../../../hooks/useFetch';
import { BUSINESSTYPE } from '../../../../constants';
import { FreeZoneGrantedOperationsByDealer } from '../../../../models/freeZone';
import { Loading } from '../../../common/loading';
import Error from '../../../common/Error';
import NotFoundBox from '../../../common/notFoundBox';
import ItemGrantedFreeZoneOperations from './ItemGrantedFreeZoneOperations';

const ListGrantedFreeZoneOperations: React.FC = () => {
  const classes = useStyles();

  const url = `${config.freeZoneSettings.apiUri}/api/granteds`;
  const {
    status,
    data: freeZoneGrantedByDealers,
    error,
  } = useFetch<FreeZoneGrantedOperationsByDealer[]>(url, undefined, BUSINESSTYPE.FREEZONE);

  return status === 'error' && error ? (
    <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' &&
          freeZoneGrantedByDealers?.map((freeZoneGrantedByDealer) => {
            return (
              <ItemGrantedFreeZoneOperations
                key={freeZoneGrantedByDealer.ClientDealerId}
                freeZoneGrantedByDealer={freeZoneGrantedByDealer}
              />
            );
          })}
        {status === 'fetched' && freeZoneGrantedByDealers?.length === 0 && (
          <NotFoundBox message="No existen operaciones otorgadas para los dealers de este cliente." />
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default ListGrantedFreeZoneOperations;
