import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch } from '../../../../hooks/useFetch';
import { UsedMoneyTransferPendingsResponse } from '../../../../models/used';
import { Loading } from '../../../common/loading';
import ErrorUsados from '../../commons/ErrorUsados';
import NotFoundUsados from '../../commons/NotFoundUsados';
import ItemPendienteGiroUsados from './ItemPendienteGiroUsados';
import useStyles from './PendienteGiroUsados.style';

const PendienteGiroUsados: React.FC = () => {
  const classes = useStyles();

  const url = `/api/moneyTransfer/pending`;
  const {
    status,
    data: moneyTransferPendings,
    error,
  } = useFetch<UsedMoneyTransferPendingsResponse[]>(url);

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && moneyTransferPendings?.length === 0 && (
          <NotFoundUsados message="No existen operaciones pendientes de giro para este cliente." />
        )}
        {status === 'fetched' && moneyTransferPendings && moneyTransferPendings?.length > 0 && (
          <>
            <Grid container item xs={12} className={classes.headerTable}>
              <Grid item xs={3}>
                Id de la solicitud de giro
              </Grid>
              <Grid item xs={2}>
                Monto total a girar
              </Grid>
              <Grid item xs={1}>
                Total VINs
              </Grid>
              <Grid item xs={6}>
                Última descarga
              </Grid>
            </Grid>
            {moneyTransferPendings?.map((pending) => {
              return (
                <>
                  <ItemPendienteGiroUsados key={pending.Id} pending={pending} />
                </>
              );
            })}
          </>
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default PendienteGiroUsados;
