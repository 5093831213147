import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Tooltip, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import BottonIconText from '../../../common/bottonIconText';

import { IAttachmentPaymentDetail } from '../../../../interfaces';
import { COLORS } from '../../../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    fileUpload: {
      color: '#666666',
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      fontSize: 16,
      marginLeft: 10,
      textTransform: 'none',
    },
  })
);
/** endregion */

interface IProps {
  onSetFileAttach: (attachmentPaymentDetail: IAttachmentPaymentDetail) => void;
  existsFileAttach: boolean;
}

const UploadImage = (props: IProps) => {
  const classes = useStyles();

  const { onSetFileAttach, existsFileAttach } = props;

  const toBase64 = async (file: any, callback: any) => {
    const reader = new FileReader();

    reader.onload = async () => {
      if (callback && typeof callback === 'function') {
        callback(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    await toBase64(acceptedFiles[0], (data: any) => {
      const result = {
        ContentType: acceptedFiles[0].type,
        FileContent: data.split('base64,')[1],
      };

      onSetFileAttach(result);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png',
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const files = acceptedFiles.map((file: any) => {
    const fileparts = file.name.split('.');
    let filename = fileparts[0];

    if (filename.length > 15) {
      filename = `${filename.substr(0, 15)}..`;
    }

    return (
      <div key={file.name}>
        <Tooltip title={file.name} placement="bottom">
          <Button
            className={classes.fileUpload}
          >{`${filename}.${fileparts[1].toLowerCase()}`}</Button>
        </Tooltip>
      </div>
    );
  });

  return (
    <Grid container>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.formControl}
      >
        <Grid
          item
          style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            Adjuntar Documento
          </div>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item md={4}>
              <BottonIconText
                buttonBackgroundColor={COLORS.secondary}
                buttonHeight={36}
                buttonWidth={183}
                buttonKey={'btnAttachFile'}
                fontSize={16}
                iconName={''}
                iconSize={0}
                label={'Adjuntar archivo'}
                objHandleEvent={{
                  execute: open,
                }}
              />
            </Grid>
            {existsFileAttach && (
              <Grid item md={6} className={classes.fileUpload}>
                {files}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadImage;
