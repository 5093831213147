import React, { useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { ErrorModel } from '../../../../models/ErrorModel';

type ErrorSparepartsProps = (ErrorModel | typeof defaultProps) & ChildrenProp;

const defaultProps = {
  ErrorId: '',
  StatusCode: 500,
  Message: 'Ha ocurrido un error en el servidor',
};

type ChildrenProp = {
  children?: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '25px',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
      overflowWrap: 'anywhere',
    },
    box: {
      backgroundColor: 'white',
      fontFamily: 'Nunito Sans',
      borderRadius: '4px',
      width: '100%',
      minHeight: 'auto',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      boxShadow:
        '0 2px 4px 0 rgba(255,0,0,0.46), 0px 6px 6px 6px rgba(255,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
      color: 'black',

      '& h1': {
        color: 'red',
        margin: '0px',
      },
    },
  })
);

const ErrorSpareparts: React.FC<ErrorSparepartsProps> = ({
  ErrorId,
  StatusCode,
  Message,
  children,
}) => {
  const classes = useStyles();

  const [message, setMessage] = useState(
    Message === undefined ? 'Ha ocurrido un error en el servidor' : Message
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <h1>
          Error {!StatusCode ? 500 : StatusCode} {!!ErrorId && `: ${ErrorId}`}
        </h1>
        <h2>{message}</h2>
        {children}
      </Grid>
    </Grid>
  );
};

export default ErrorSpareparts;
