import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { ErrorModel } from '../../../models/ErrorModel';
import useStyles from './Error.style';

type ErrorProps = (ErrorModel | typeof defaultProps) & ChildrenProp;

const defaultProps = {
  ErrorId: '',
  StatusCode: 500,
  Message: 'Ha ocurrido un error en el servidor',
};

type ChildrenProp = {
  children?: React.ReactNode;
};

const Error: React.FC<ErrorProps> = ({ ErrorId, StatusCode, Message, children }) => {
  const classes = useStyles();

  const [message, setMessage] = useState(
    Message === undefined ? 'Ha ocurrido un error en el servidor' : Message
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
      data-testid="error-box"
    >
      <Grid item className={classes.box}>
        <h1>
          Error {!StatusCode ? 500 : StatusCode} {!!ErrorId && `: ${ErrorId}`}
        </h1>
        <h2>{message}</h2>
        {children}
      </Grid>
    </Grid>
  );
};

export default Error;
