import {
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { COLORS } from '../../../../constants';

export const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 'bold',
  },
}))(TableCell);

export const FailedTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#e57373',
    },
  })
)(TableRow);

const heightFooter = '8vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
    },
    box: {
      fontFamily: 'Nunito Sans',
      background: theme.palette.credinissanBackground.default,
      borderRadius: '4px',
      width: '100%',
      minHeight: '100%',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '20px 20px',
    },
    title: {
      borderBottom: '1px solid #666666',
      color: COLORS.secondary,
      fontSize: '16px',
    },

    mainTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },

    table: {
      marginTop: '10px',
      boxShadow: '0px 0px 0px 0px',
    },

    headTable: {
      '& > *': {
        color: '#666666',
      },
    },

    btnAplicarPago: {
      backgroundColor: COLORS.secondary,
      color: 'white',
      marginBottom: '0.5em',

      '&$disabled': {
        background: '#E0E0E0',
      },
    },

    btnUploadDoc: {
      borderColor: COLORS.secondary,
      color: COLORS.secondary,
    },

    disabled: {},

    footer: {
      color: 'white',
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
      background: theme.palette.credinissanHeaderBackground.default,
      height: heightFooter,
      padding: '5px',
    },

    formSelect: {
      margin: theme.spacing(1),
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },

    dialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rootForm: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      flexBasis: 200,
    },
    dialogBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: '100%',
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    searchInput: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflowX: 'inherit',
      margin: '0.5rem 2rem !important',
    },
  })
);

export default useStyles;
