import React, { Fragment, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Operacion from './operacion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: 10,
    },
  })
);

function CursadaDetalle(props: any) {
  const classes = useStyles();

  const { data, selectedChecked, onChecked, statecheck } = props;

  // if (!props.loading && props.data === undefined) {
  //     return <div>
  //         <h1>No se encontraron resultados</h1>
  //     </div>
  // }

  const getDetalle = (data: any) => {
    return data.map((detalle: any, index: number) => {
      return (
        <Fragment key={`divDetalle${index}`}>
          <Operacion
            data={detalle}
            key={`detalle${index}`}
            selectedChecked={selectedChecked}
            onChecked={handleOperationCheckedClick}
            statecheck={statecheck}
          />
        </Fragment>
      );
    });
  };

  const handleOperationCheckedClick = (value: number) => {
    onChecked(value);
  };

  return <Fragment>{getDetalle(data)}</Fragment>;
}

export default CursadaDetalle;
