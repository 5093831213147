import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useFetch } from '../../../../hooks/useFetch';
import { UsedOperationsChargeHistory, UsedPaginationResponse } from '../../../../models/used';

import { Loading } from '../../../common/loading';
import ErrorUsados from '../../commons/ErrorUsados';
import useStyles from './HistorialCargaOperacionesUsados.style';
import ItemHistorialCargaOperacionesUsados from './ItemHistorialCargaOperacionesUsados';
import NotFoundUsados from '../../commons/NotFoundUsados';
import config from '../../../../config/api';
import TablePaginationActions from '../../../common/table/tablePaginationActions';

const HistorialCargaOperacionesUsados: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const url = `${config.usedSettings.apiUri}/api/charge?pageSize=${rowsPerPage}&pageNumber=${
    page + 1
  }`;
  const {
    status,
    data: paginationResponse,
    error,
  } = useFetch<UsedPaginationResponse<UsedOperationsChargeHistory>>(url);

  let charges: UsedOperationsChargeHistory[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    charges = paginationResponse?.Result ?? charges;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailClick = (id = '') => {
    props.history.push(`:${id}`);
  };

  return status === 'error' && error ? (
    <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <TableContainer className={classes.root}>
      {status === 'fetching' && <Loading />}
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableBody>
          {status === 'fetched' &&
            charges?.map((charge) => {
              return (
                <ItemHistorialCargaOperacionesUsados
                  key={charge.ChargeId}
                  charge={charge}
                  handleDetailClick={handleDetailClick}
                />
              );
            })}
          {status === 'fetched' && charges?.length === 0 && (
            <NotFoundUsados key={0} message="No existen cargas anteriores." />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {status === 'fetched' && charges?.length > 0 && (
              <TablePagination
                labelRowsPerPage={() => 'Filas por página:'}
                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                colSpan={3}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default withRouter(HistorialCargaOperacionesUsados);
