import React from 'react';
import { Button, Grid } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import useStyles from './DetailApplyPaymentSpareParts.style';
import { numberToMiles } from '../../../../utilities/string-functions';
import ModalDetailApplyPayment from '../../../common/paymentPending/detailApplyPayment/modalDetailApplyPayment';

type Props = {
  amount: number;
  operationIds: string[];
  clientDealerId: string;
  reload: () => void;
};

const FooterDetailApplyPaymentSpareParts: React.FC<Props> = ({
  amount,
  operationIds,
  clientDealerId,
  reload,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container className={classes.footer} justifyContent="center" alignItems="center">
      <Grid container direction="column" item xs={9} justifyContent="center" alignItems="center">
        <Grid style={{ fontSize: '18px', fontWeight: 'bold' }}>Monto total</Grid>
        <Grid style={{ fontSize: '24px', fontWeight: 'bold' }}>${numberToMiles(amount)}</Grid>
      </Grid>
      <Grid container item xs={3} justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          disabled={operationIds.length === 0}
          startIcon={<PaymentIcon />}
          className={classes.btnApplyPayment}
          onClick={() => setOpen(true)}
        >
          APLICAR MEDIO DE PAGO
        </Button>
      </Grid>
      <ModalDetailApplyPayment
        open={open}
        handleClose={handleClose}
        operationIds={operationIds}
        clientDealerId={clientDealerId}
        reload={reload}
      />
    </Grid>
  );
};

export default FooterDetailApplyPaymentSpareParts;
