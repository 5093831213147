import React, { useEffect } from 'react';
import { History } from 'history';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MainContainer from '../maincontainer';
import Title from './title';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { StorageKeys } from '../../utilities/storageHelper';
import { COLORS } from '../../constants';

/** START STYLES */
const heightHeader = 200;
const minHeight = heightHeader;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) => ({
  contenedor: {
    fontFamily: 'Nunito Sans',
  },
  header: {
    background: COLORS.primary,
    color: '#ffffff',
    height: heightHeader,
    paddingTop: 10,
    fontFamily: 'Nunito Sans',
    ...containerStyleObj,
  },
  body: {
    paddingTop: 25,
    ...containerStyleObj,
    height: `calc(100vh - ${minHeight}px)`,
    overflowY: 'auto',
  },
  sinDatosContainer: {
    background: '#FFFFFF',
    borderRadius: '4px',
    width: '100%',
    height: 140,
    boxShadow:
      '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontFamily: 'Nunito Sans',
    color: '#666666',
    fontSize: 20,
    paddingBottom: '5px',
  },
  textContainer: {
    textAlign: 'center',
  },
  button: {
    background: COLORS.secondary,
    color: '#fff',
    textTransform: 'capitalize',
    width: '198px',
  },
}));
/** FINISH STYLES */

interface IProps {
  history: History;
}

const PageNotFound = (props: IProps) => {
  const classes = useStyles();

  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  const redirect = () => {
    return props.history.push(`/${businessTypeStorage}`);
  };

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title title={'Página no encontrada'} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.sinDatosContainer}
            >
              <Grid item sm={12} className={classes.textContainer}>
                <Typography variant="subtitle1" className={classes.text}>
                  No hemos encontrado la página que buscas
                </Typography>
                <Button
                  id={`navHeaderBarBtnDowload`}
                  variant="contained"
                  onClick={redirect}
                  className={classes.button}
                >
                  Volver al inicio
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default PageNotFound;
