import React from 'react';
import { makeStyles, Theme, createStyles, Grid, Link } from '@material-ui/core';
import { convertDateReadableFormat } from '../../../../../../utilities/dates-functions';

import { ILoadedFiles, IParamsTitleDetails } from '../../../../../../interfaces';
import { COLORS } from '../../../../../../constants';

//CSS Styles region
const flexStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};
const celdaStyleObj = {
  height: 30,
  fontFamily: 'Nunito Sans',
  ...flexStyleObj,
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 87,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    celda: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: 16,
    },
    celdaUnoTitulo: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
      ...celdaStyleObj,
    },
    titulo: {
      fontWeight: 'bold',
    },
    link: {
      color: '#666666',
      fontSize: 16,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

interface IProps {
  data: ILoadedFiles;
  onShowDetails: (params: IParamsTitleDetails) => void;
}

const Charge = (props: IProps) => {
  const classes = useStyles();

  const { data, onShowDetails } = props;

  const handlerShowDetails = (name: string, clientDealerId: string) => {
    const params = {
      visible: true,
      id: clientDealerId,
      text: name,
    } as IParamsTitleDetails;

    onShowDetails(params);
  };

  return (
    <div className={classes.root}>
      <div className={classes.contenedor}>
        <Grid
          key={data.ChargeId}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.body}
        >
          <Grid item xs={12} sm={12} md={5} className={classes.celda}>
            <div className={classes.celdaUnoTitulo}>
              {convertDateReadableFormat(data.CreationDate)}
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={5} className={classes.celda}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <div className={classes.titulo}>{data.VinsCount} VIN</div>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={2} className={classes.link}>
            <Link
              href="#"
              onClick={() => handlerShowDetails(data.CreationDate, data.ChargeId)}
              style={{ color: COLORS.secondary }}
            >
              Revisar detalle
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Charge;
