import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { SparePartsGrantedByDealer } from '../../../../models/spareParts/SparePartsGrantedByDealer';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './GrantedSpareParts.style';

type Props = {
  sparePartsGrantedByDealer: SparePartsGrantedByDealer;
} & RouteComponentProps;

const ItemGrantedSpareParts: React.FC<Props> = (props) => {
  const { sparePartsGrantedByDealer } = props;
  const classes = useStyles();

  const handleDetailClick = (id = '') => {
    props.history.push(`/repuestos/otorgadas/detalle-otorgadas/:${id}`);
  };

  return (
    <div className={classes.item}>
      <div className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item xs={5} sm={5} md={6} className={classes.columnDetail}>
            <div className={classes.dealerName}>{sparePartsGrantedByDealer.DealerName}</div>
            <div>
              <span className={classes.dealerRut}>
                {rutFormater(
                  sparePartsGrantedByDealer.DealerRut.toString() +
                    sparePartsGrantedByDealer.DealerVerificationNumber
                )}
              </span>
              <span className={classes.spareParts}>
                {sparePartsGrantedByDealer.Granted} Repuestos otorgados
              </span>
            </div>
          </Grid>
          <Grid item xs={7} sm={7} md={6} className={classes.watchDetail}>
            <Button
              onClick={() => handleDetailClick(sparePartsGrantedByDealer.ClientDealerId)}
              className={classes.buttonDetail}
            >
              Revisar detalle
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(ItemGrantedSpareParts);
