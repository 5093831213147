import React from 'react';
import { Grid, Button, Divider } from '@material-ui/core';
import { Redirect, RouteComponentProps } from 'react-router';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CompanyLogo from '../../components/common/header/companylogo';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { StorageKeys } from '../../utilities/storageHelper';
import { useAuth } from '../../components/common/auth';
import newCarsImg from '../../assets/icon_new_cars.svg';
import usedCarsImg from '../../assets/icon_used_cars.svg';
import partsImg from '../../assets/icon_parts.svg';
import freeZoneImg from '../../assets/icon_free_zone.svg';
import useStyles from './BusinessTypePage.style';
import config from '../../config/api';
import { BUSINESSTYPE } from '../../constants';
import { DIVISIONS, DivisionType } from './BusinessTypeSettings';

const BusinessTypePage = (props: RouteComponentProps) => {
  const classes = useStyles();
  const [, setBusinessTypeStorage] = useLocalStorage(StorageKeys.BusinessType, '');

  const { setBusinessType } = useAuth();

  const [isAuthenticated] = useLocalStorage(StorageKeys.UserIsLogged);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const businessType = event.currentTarget.name;
    setBusinessTypeStorage(businessType);
    setBusinessType(businessType);
    props.history.push(`/${businessType}`);
    window.location.reload();
  };

  const pickImages = [
    <img key={1} src={newCarsImg} className={classes.imgCar} alt="Negocio nuevos" />,
    <img key={2} src={usedCarsImg} className={classes.imgCar} alt="Negocio usados" />,
    <img key={3} src={freeZoneImg} className={classes.imgCar} alt="Negocio zona franca" />,
    <img key={4} src={partsImg} className={classes.imgCar} alt="Negocio repuestos" />,
  ];

  return !isAuthenticated ? (
    <Redirect to="/login" />
  ) : (
    <div className={classes.businessTypeScreen}>
      <div className={classes.header}>
        <Grid item md={12} className={classes.mainContainer}>
          <div className={classes.selectLogo}>
            <CompanyLogo isHeader />
          </div>
          <div className={classes.selectTitle}>Selecciona la división en que desea ingresar</div>
          <div className={classes.selectBox}>
            {DIVISIONS.map((division: DivisionType, index) => {
              switch (true) {
                case !config.featureFlagUsed && division.name === BUSINESSTYPE.USED:
                case !config.featureFlagParts && division.name === BUSINESSTYPE.PARTS:
                case !config.featureFlagFreeZone && division.name === BUSINESSTYPE.FREEZONE:
                  return false;
                default:
                  return (
                    <div key={division.title}>
                      {index !== 0 && <Divider variant="middle" className={classes.divider} />}
                      <div className={classes.select}>
                        <div className={classes.wrapperTitleImg}>
                          {pickImages[index]}
                          <div className={classes.titleCar}>
                            <span className={classes.title}>{division.title}</span>
                            <span className={classes.subtitle}>{division.subtitle}</span>
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          className={clsx(classes.btn)}
                          startIcon={<ArrowForwardIosIcon />}
                          name={division.name}
                          onClick={handleOnClick}
                        >
                          IR A {division.btnName}
                        </Button>
                      </div>
                    </div>
                  );
              }
            })}
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default BusinessTypePage;
