import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { SparePartsPaymentPending } from '../../../../models/spareParts';
import { rutFormater } from '../../../../utilities/string-functions';
import useStyles from './ApplyPaymentSparePart.style';

type Props = {
  pending: SparePartsPaymentPending;
} & RouteComponentProps;

const ItemApplyPaymentSparePart: React.FC<Props> = (props) => {
  const { pending } = props;
  const classes = useStyles();

  const handleDetailClick = (id = '') => {
    props.history.push(`/repuestos/otorgadas/aplicacion-pago/:${id}`);
  };

  return (
    <div className={classes.item}>
      <div className={classes.boxDetail}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bodyDetail}
        >
          <Grid item xs={5} sm={5} md={6} className={classes.columnDetail}>
            <div className={classes.dealerName}>{pending.DealerName}</div>
            <div>
              <span className={classes.dealerRut}>
                {rutFormater(pending.DealerRut.toString() + pending.DealerVerificationNumber)}
              </span>
              <span className={classes.vins}>
                | Repuestos Pendientes {pending.PendingSpareParts}
              </span>
            </div>
          </Grid>
          <Grid item xs={7} sm={7} md={6} className={classes.watchDetail}>
            <Button color="primary" onClick={() => handleDetailClick(pending.ClientDealerId)}>
              Aplicar pagos
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(ItemApplyPaymentSparePart);
