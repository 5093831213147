import React, { Fragment } from 'react';
import { Grid, Checkbox } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Loading } from '../../common/loading';
import { COLORS } from '../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFilter: {
      color: COLORS.sextenary,
      fontFamily: 'Nunito Sans',
      fontSize: 16,
      fontWeight: 'bold',
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cabecera: {
      height: 66,
      marginTop: -15,
    },
  })
);

const Header = {
  h1: 'Operación',
  h2: 'Resumen del giro',
  h3: 'Distribución del giro',
};

const FilterCursada = (props: any) => {
  const classes = useStyles();

  const { isAllChecked, onCheckedAll, statecheck } = props;

  const handleOperationCheckedAllClick = () => {
    onCheckedAll();
  };

  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.cabecera}>
        <Grid item md={1} className={classes.root}>
          {statecheck && <Loading />}
          {!statecheck && (
            <Checkbox
              key={Math.random()}
              checked={isAllChecked}
              onChange={handleOperationCheckedAllClick}
              style={{ color: COLORS.secondary }}
            />
          )}
        </Grid>
        <Grid item md={2} />
        <Grid item md={9}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item md={4} className={classes.textFilter}>
              <span>{Header.h1}</span>
            </Grid>
            <Grid item md={4} className={classes.textFilter} style={{ paddingLeft: '12px' }}>
              <span>{Header.h2}</span>
            </Grid>
            <Grid item md={4} className={classes.textFilter} style={{ paddingLeft: '9px' }}>
              <span>{Header.h3}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterCursada;
