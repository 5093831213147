import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch } from '../../../hooks/useFetch';
import useStyles from './MoneyTransferPending.style';
import { Loading } from '../../common/loading';
import { SparepartsMoneyTransferPending } from '../../../models/spareParts/SparepartsMoneyTransferPending';
import ItemMoneyTransferPending from './ItemMoneyTransferPending';
import config from '../../../config/api';
import NotFoundSpareparts from '../commons/NotFoundSpareparts';
import ErrorSpareparts from '../commons/ErrorSpareparts';
import { BUSINESSTYPE } from '../../../constants';

const MoneyTransferPending: React.FC = () => {
  const classes = useStyles();

  const url = `${config.sparePartSettings.apiUri}/api/moneyTransfer/pending`;
  const {
    status,
    data: moneyTransferPendings,
    error,
  } = useFetch<SparepartsMoneyTransferPending[]>(url, undefined, BUSINESSTYPE.PARTS);

  return status === 'error' && error ? (
    <ErrorSpareparts
      StatusCode={error.StatusCode}
      Message={error.Message}
      ErrorId={error.ErrorId}
    />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.body}
    >
      <Grid item sm={12}>
        {status === 'fetched' && moneyTransferPendings?.length === 0 && (
          <NotFoundSpareparts message="No existen operaciones pendientes de giro para este cliente." />
        )}
        {status === 'fetched' && moneyTransferPendings && moneyTransferPendings?.length > 0 && (
          <>
            <Grid container item xs={12} className={classes.headerTable}>
              <Grid item xs={3}>
                ID solicitud giro
              </Grid>
              <Grid item xs={2}>
                Monto total a girar
              </Grid>
              <Grid item xs={1}>
                Total repuestos
              </Grid>
              <Grid item xs={6}>
                Última descarga
              </Grid>
            </Grid>
            {moneyTransferPendings?.map((pending) => (
              <ItemMoneyTransferPending pending={pending} key={pending.Id} />
            ))}
          </>
        )}
        {status === 'fetching' && <Loading />}
      </Grid>
    </Grid>
  );
};

export default MoneyTransferPending;
