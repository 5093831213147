import React from 'react';
import { Button, Dialog, FormControl, MenuItem, Select } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFetch } from '../../../../hooks/useFetch';
import useStyles from './PendienteGiroUsados.style';
import { NotificationModel } from '../../../../models/used';
import AlertUsados from '../../commons/AlertUsados';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../utilities/storageHelper';
import { ClientInfo } from '../../../../interfaces';
import { downloadFileUsed } from '../../../../utilities/downloadFileUsed';
import { FetchConfig } from '../../../../config/fetchConfig';
import { BankAccountsByClientResponse } from '../../../../models/commons';

type Props = {
  open: boolean;
  handleClose: () => void;
  requestId: string;
  handleNotification: (value: NotificationModel) => void;
  handleDownloadDate: (value: string) => void;
};

const DialogSeleccionCuentaGiro: React.FC<Props> = ({
  open,
  handleClose,
  requestId,
  handleNotification,
  handleDownloadDate,
}) => {
  const classes = useStyles();

  const url = `/api/client/bankAccounts`;

  const { status, data: bankAccounts, error } = useFetch<BankAccountsByClientResponse[]>(url);

  const [selectAccount, setSelectAccount] = React.useState('');
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);

  const handleChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    if (evt.target.value !== '') {
      setBtnDisabled(false);
    }
    setSelectAccount(evt.target.value as string);
  };

  const urlDownload = `/api/moneyTransfer/download/${requestId}?bankAccountId=${selectAccount}`;

  const handleDownload = () => {
    setBtnDisabled(true);
    setDownloading(true);
    downloadFileUsed(urlDownload, {
      fileName: `Solicitud de giro_${requestId}.xlsx`,
    } as FetchConfig).then((state) => {
      if (state?.status === 'error') {
        handleNotification({
          show: true,
          variant: 'error',
          message: 'Ocurrió un problema descargando el archivo.',
        });
      } else {
        handleDownloadDate(new Date().toISOString().replace('Z', ''));
        handleNotification({
          show: true,
          variant: 'success',
          message: 'Archivo descargado correctamente',
        });
      }
      setDownloading(false);
      handleClose();
      setSelectAccount('');
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="responsive-dialog-title">Seleccione la cuenta a girar</DialogTitle>
      <DialogContent>
        {status === 'error' && <AlertUsados show type="error" text={error?.Message} />}
        <FormControl className={classes.formSelect}>
          <Select
            value={selectAccount}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              {status === 'fetched' && bankAccounts?.length === 0
                ? 'No existen cuentas bancarias para este cliente'
                : 'Seleccione la cuenta a girar'}
            </MenuItem>
            {status === 'fetched' &&
              bankAccounts?.map((account) => (
                <MenuItem
                  key={account.BankAccountId}
                  value={account.BankAccountId}
                >{`${account.BankAccoutNumber} - ${account.BankName}`}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button disabled={btnDisabled} color="primary" autoFocus onClick={handleDownload}>
          {downloading ? 'Descargando...' : 'Descargar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSeleccionCuentaGiro;
