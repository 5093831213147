const ROUTES = [
  {
    menu: false,
    name: 'Selección tipo de negocio',
    order: 0,
    pathselected: '',
    path: '/negocio',
    component: 'BusinessTypePage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Detalles de líneas',
    order: 1,
    pathselected: '/zona-franca',
    path: '/zona-franca',
    component: 'DetailLineFreeZonePage',
    icon: 'list',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Cursadas',
    order: 2,
    pathselected: '/zona-franca/cursadas',
    path: '/zona-franca/cursadas',
    component: 'OperationsFreeZoneTakenPage',
    icon: 'up-to-top',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Pendientes de giro',
    order: 3,
    pathselected: '/zona-franca/pendientes-giro',
    path: '/zona-franca/pendientes-giro',
    component: 'MoneyTransferPendingFreeZonePage',
    icon: 'time',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Otorgadas',
    order: 4,
    pathselected: '/zona-franca/otorgadas',
    path: '/zona-franca/otorgadas',
    component: 'ListGrantedFreeZoneOperationsPage',
    icon: 'thumbsup',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Aplicación de pagos',
    order: 0,
    pathselected: '/zona-franca/otorgadas',
    path: '/zona-franca/otorgadas/aplicacion-pago',
    component: 'ApplyPaymentFreeZonePage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/zona-franca/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle aplicar pago',
    order: 0,
    pathselected: '/zona-franca/otorgadas',
    path: '/zona-franca/otorgadas/aplicacion-pago/:id',
    component: 'DetailApplyPaymentFreeZonePage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/zona-franca/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Pendientes de confirmación',
    order: 0,
    pathselected: '/zona-franca/otorgadas',
    path: '/zona-franca/otorgadas/pendientes-confirmacion',
    component: 'ConfirmationPendingFreeZonePage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/zona-franca/otorgadas',
        name: 'Otorgadas',
      },
    ],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/zona-franca/pendientes-giro',
    path: '/zona-franca/pendientes-giro/historial',
    component: 'HistoricMoneyTransferPendingFreeZonePage',
    icon: 'time',
    breadcrumb: [
      {
        path: '/zona-franca/pendientes-giro',
        name: 'Pendientes de giro',
      },
    ],
  },
  {
    menu: true,
    name: 'Carga operaciones',
    order: 5,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-operaciones',
    component: 'ChargeOperationsFreeZonePage',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-operaciones/historial',
    component: 'HistoricChargeOperationsFreeZonePage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/zona-franca/carga-operaciones',
        name: 'Carga de archivos',
      },
    ],
  },
  {
    menu: false,
    name: 'Carga de I-CAR',
    order: 0,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-icar',
    component: 'ChargeICAR',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Detalle',
    order: 0,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-operaciones/:id/:UploadAt',
    component: 'DetailChargeOperationFreeZonePage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/zona-franca/carga-operaciones',
        name: 'Carga de operaciones',
      },
    ],
  },
  {
    menu: false,
    name: 'Carga de Notas de Crédito',
    order: 0,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-notas-de-credito',
    component: 'ChargeCreditNotesPage',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/zona-franca/carga-operaciones',
    path: '/zona-franca/carga-notas-de-credito/historial',
    component: 'HistoryChargeCreditNotes',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/zona-franca/carga-notas-de-credito',
        name: 'Carga de Notas de Crédito',
      },
    ],
  },
  {
    menu: false,
    name: 'Detalle otorgadas',
    order: 0,
    pathselected: '/zona-franca/otorgadas',
    path: '/zona-franca/otorgadas/detalle/:id',
    component: 'DetailGrantedFreeZoneOperationPage',
    icon: 'thumbsup',
    breadcrumb: [
      {
        path: '/zona-franca/otorgadas',
        name: 'Operaciones otorgadas',
      },
    ],
  },
  /* {
    menu: false,
    name: 'Selección tipo de negocio',
    order: 0,
    pathselected: '',
    path: '/negocio',
    component: 'BusinessTypePage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Detalles de líneas',
    order: 1,
    pathselected: '/repuestos',
    path: '/repuestos',
    component: 'CreditLineDetail',
    icon: 'list',
    breadcrumb: [],
  },
  {
    menu: true,
    name: 'Carga de Operaciones',
    order: 5,
    pathselected: '/repuestos/carga-operaciones',
    path: '/repuestos/carga-operaciones',
    component: 'ChargeOperationsPage',
    icon: 'document-add',
    breadcrumb: [],
  },
  {
    menu: false,
    name: 'Historial',
    order: 0,
    pathselected: '/repuestos/carga-operaciones',
    path: '/repuestos/carga-operaciones/historial',
    component: 'HistoricChargeOperationsSparePartsPage',
    icon: 'document-add',
    breadcrumb: [
      {
        path: '/repuestos/carga-operaciones',
        name: 'Carga de Operaciones',
      },
    ],
  },
  */
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/exception-page',
    component: 'ExceptionPage',
    icon: '',
    breadcrumb: [],
  },
  {
    menu: false,
    name: '',
    order: 0,
    pathselected: '',
    path: '/pagenotfound',
    component: 'PageNotFound',
    icon: '',
    breadcrumb: [],
  },
];

export default ROUTES;
