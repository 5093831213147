import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Checkbox,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { find } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import useStyles, { StyledTableCell } from './DetalleAplicarPagoUsados.style';
import ErrorUsados from '../../commons/ErrorUsados';
import { Loading } from '../../../common/loading';
import { UsedDetailPaymentPending } from '../../../../models/used';
import RowDetalleAplicarPagoUsados from './RowDetalleAplicarPagoUsados';
import FooterDetalleAplicarPagoUsados from './FooterDetalleAplicarPagoUsados';
import { COLORS } from '../../../../constants';

function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: number[], b: number[]) {
  return [...a, ...not(b, a)];
}

type Props = {
  clientDealerId: string;
  changeTitle: (t: string) => void;
} & RouteComponentProps;

const DetalleAplicarPagoUsados: React.FC<Props> = (props) => {
  const { clientDealerId, changeTitle } = props;
  const classes = useStyles();

  const [checked, setChecked] = React.useState<number[]>([]);
  const [positions, setPositions] = React.useState<number[]>([]);
  const [anyDisabled, setAnyDisabled] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [usedOperationIds, setUsedOperationIds] = React.useState<string[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [pendingPayments, setPendingPayments] = React.useState<UsedDetailPaymentPending[]>([]);

  const url = `/api/payments/pendings/${clientDealerId}`;
  const {
    status,
    data: pendingPaymentsAll,
    error,
    reload,
  } = useFetch<UsedDetailPaymentPending[]>(url);

  React.useEffect(() => {
    if (pendingPaymentsAll) {
      setPendingPayments(
        pendingPaymentsAll.filter((p) =>
          p.UsedVin.toUpperCase().trim().includes(searchTerm.toUpperCase().trim())
        )
      );
    }
  }, [searchTerm, pendingPaymentsAll]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  React.useEffect(() => {
    if (status === 'fetched' && pendingPaymentsAll?.length === 0) {
      props.history.replace(`/usados/otorgadas/pendientes-pago/`);
    } else if (status === 'fetched') {
      changeTitle(pendingPaymentsAll?.[0].DealerName ?? '');
      setPositions(pendingPaymentsAll?.map((_, idx) => idx) ?? []);
      setTotalAmount(0);
      setChecked([]);
      setUsedOperationIds([]);
    }
  }, [status, pendingPayments, changeTitle, pendingPaymentsAll]);

  React.useEffect(() => {
    let totAmount = 0;
    const ids = [] as string[];
    checked.map((ch) => {
      const pa = find(pendingPayments, {
        Position: ch,
      });
      const amount = pa !== undefined ? (pa as UsedDetailPaymentPending).Amount : 0;
      totAmount += amount;
      if (pa !== undefined) {
        ids.push((pa as UsedDetailPaymentPending).UsedOperationId);
      }
      return true;
    });

    setUsedOperationIds(ids);
    setTotalAmount(totAmount);
  }, [checked, pendingPayments]);

  // React.useEffect(() => {
  //   setTotalAmount(0);
  //   setChecked([]);
  // }, [reload, checked, totalAmount]);

  const numberOfChecked = (items: number[]) => intersection(checked, items).length;

  const handleToggleAll = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  if (status === 'error' && error)
    return (
      <ErrorUsados StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
    );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <div className={classes.searchInput}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Buscador de VIN"
                    style={{ minWidth: '25%' }}
                    value={searchTerm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(e.target.value);
                    }}
                    InputProps={
                      searchTerm.length === 0
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                onClick={() => setSearchTerm('')}
                                style={{ cursor: 'pointer' }}
                              >
                                <CloseIcon />
                              </InputAdornment>
                            ),
                          }
                    }
                  />
                </div>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell align="center">
                        <Checkbox
                          style={{ color: COLORS.secondary }}
                          onClick={handleToggleAll(positions)}
                          checked={
                            numberOfChecked(positions) === positions.length &&
                            positions.length !== 0
                          }
                          indeterminate={
                            numberOfChecked(positions) !== positions.length &&
                            numberOfChecked(positions) !== 0
                          }
                          disabled={
                            positions.length === 0 || anyDisabled || pendingPayments.length === 0
                          }
                          inputProps={{ 'aria-label': 'all items selected' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">Días transcurridos</StyledTableCell>
                      <StyledTableCell align="center">VIN</StyledTableCell>
                      <StyledTableCell align="center">Monto</StyledTableCell>
                      <StyledTableCell align="center">Número factura</StyledTableCell>
                      <StyledTableCell align="center">Fecha emisión</StyledTableCell>
                      <StyledTableCell align="center">Estado inventario</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingPayments?.map((row, rowNumber) => {
                      return (
                        <RowDetalleAplicarPagoUsados
                          key={row.UsedOperationId}
                          checked={checked}
                          pendingPayment={row}
                          handleToggle={handleToggle}
                          setAnyDisabled={setAnyDisabled}
                          position={rowNumber}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
      <FooterDetalleAplicarPagoUsados
        usedOperationIds={usedOperationIds}
        amount={totalAmount}
        clientDealerId={clientDealerId}
        reload={reload}
      />
    </Grid>
  );
};

export default withRouter(DetalleAplicarPagoUsados);
