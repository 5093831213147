import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnConfirm: {
      color: 'white',
      fontSize: '1rem',
      background: COLORS.secondary,
      textTransform: 'none',
    },
    modalTitle: {
      '& > *': {
        fontSize: '1.2rem',
        fontWeight: 'bold',
      },
    },
    modalContent: {
      fontSize: '1.1rem',
    },
  })
);

export default useStyles;
