import React, { Fragment, useState } from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';

import { IFleetPaymentDetail } from '../../../../../interfaces';

const MdlItemFlota = (props: any) => {
  const {
    banks,
    handleChangeSlcBanco,
    classes,
    banco,
    setDatePaymentFlota,
    setExpirationDatePaymentFlota,
    setNumberPaymentFlota,
    setGaranteeBankId,
    noSelected,
  } = props;

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item md={6}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.formControl}
          >
            <Grid
              item
              style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}
            >
              Fecha de la solicitud
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                  <TextField
                    id="datePaymentFlota"
                    type="date"
                    margin="normal"
                    variant="filled"
                    onChange={(e) => setDatePaymentFlota(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.formControl}
          >
            <Grid
              item
              style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}
            >
              Vencimiento
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                  <TextField
                    id="expirationDatePaymentFlota"
                    type="date"
                    margin="normal"
                    variant="filled"
                    onChange={(e) => setExpirationDatePaymentFlota(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item md={6}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.formControl}
          >
            <Grid
              item
              style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}
            >
              Número de la garantía
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                  <TextField
                    id="numberPaymentFlota"
                    type="number"
                    margin="normal"
                    variant="filled"
                    onChange={(e) => setNumberPaymentFlota(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.formControl}
          >
            <Grid
              item
              style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: 16, height: 35 }}
            >
              Banco de la garantía
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                  <Select
                    value={banco}
                    style={{ minWidth: '233px' }}
                    displayEmpty
                    id={'slcBankFlota'}
                    onChange={handleChangeSlcBanco}
                  >
                    <MenuItem disabled value={noSelected}>
                      <em>{'Seleccione Banco'}</em>
                    </MenuItem>

                    {banks.map((banco: any, index: number) => {
                      return (
                        <MenuItem value={banco.BankId} key={`medioPagoOptBanco${index}`}>
                          {banco.BankName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MdlItemFlota;
