import { Grid } from '@material-ui/core';
import React from 'react';
import { ApiResponse, OperationsChargeResponse } from '../../../../models/commons';
import { postFile } from '../../../../utilities/requestsSpareparts';
import LastChargeOperations from '../../commons/lastChargeOperations';
import ResultChargeOperations from '../../../common/charge/resultChargeOperations';
import UploadFile from '../../../common/charge/uploadFile';
import useStyles from './ChargeOperations.style';

const ChargeOperations: React.FC = () => {
  const classes = useStyles();
  const [showResult, setShowResult] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<OperationsChargeResponse>(
    {} as OperationsChargeResponse
  );

  const handleUploadFile = async (
    formData: FormData
  ): Promise<ApiResponse<OperationsChargeResponse>> => {
    const dataResponse = await postFile<OperationsChargeResponse>('/api/charges', formData);
    return dataResponse;
  };

  const handleViews = (response: OperationsChargeResponse) => {
    setUploadResponse(response);
    setShowResult(true);
  };

  const handleUploadAgain = () => {
    setShowResult(false);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <LastChargeOperations />

        {!showResult ? (
          <UploadFile uploadFile={handleUploadFile} handleViews={handleViews} />
        ) : (
          <ResultChargeOperations
            ChargeId={uploadResponse.ChargeId}
            Total={uploadResponse.Total}
            TotalSuccessful={uploadResponse.TotalSuccessful}
            TotalRejected={uploadResponse.TotalRejected}
            FileName={uploadResponse.FileName}
            UploadAt={uploadResponse.UploadAt}
            handleUploadAgain={handleUploadAgain}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChargeOperations;
