import React from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { COLORS } from '../../../../constants';

/** region style */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: 120,
      paddingTop: '120px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    boton: {
      color: '#FFFFFF',
      padding: 5,
      backgroundColor: COLORS.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      borderRadius: 4,
      width: 27,
      height: 27,
      cursor: 'pointer',
    },
    tituloContainer: {
      height: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  })
);
/** endregion */

interface IParamsChangeView {
  visible: boolean;
  dealer: string;
}

interface IProps {
  title: string;
  onShowDetailsOtorgadas: (paramsChangeView: IParamsChangeView) => void;
}

const CabeceraDetalles = (props: any) => {
  const classes = useStyles();
  const { title, onShowDetailsFacturadas } = props;

  const handlerShowDetails = () => {
    onShowDetailsFacturadas(false, '');
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.header}
    >
      <Grid item sm={12} className={classes.tituloContainer}>
        <ArrowBackIosOutlinedIcon
          titleAccess="Volver atrás"
          className={classes.boton}
          onClick={handlerShowDetails}
        />
        <Typography variant="h4">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default CabeceraDetalles;
