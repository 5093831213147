import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import MainContainer from '../../../../maincontainer';
import Title from '../../../../common/title';

import Solicitadas from './subcomponentes/solicitadas';

import {
  getPendingOperations,
  getRequestedOperation,
} from '../../../../../services/pending-billing-operation-service';
import { IDataRequestedOperations } from '../../../../../interfaces';
import { COLORS } from '../../../../../constants';

/** region style */
const heightHeader = 200;
const minHeight = 250;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    tabContainer: {
      backgroundColor: COLORS.primary,
      height: 48,
      paddingLeft: 20,
      paddingRight: 20,
    },
    tab: {
      color: '#FFFFFF',
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      background: '#FFFFFF',
      color: COLORS.primary,
      textTransform: 'uppercase',
      cursor: 'default',
      ...tabStylesObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
/** endregion */

interface IProps extends RouteComponentProps {}

const ViewSolicitadas = (props: IProps) => {
  const classes = useStyles();

  const [loadingRequested, setLoadingRequested] = useState(true);
  const [dataRequested, setDataRequested] = useState([] as Array<IDataRequestedOperations>);

  const [countPendientes, setCountPendientes] = useState(0);
  const [countSolicitadas, setCountSolicitadas] = useState(0);

  const hadleSeeTabPendientesClick = async () => {
    props.history.push('/nuevos/otorgadas/pedientes-de-facturacion');
  };

  const handleGetPendingOperations = async () => {
    getPendingOperations().then((response) => {
      setCountPendientes(response.data.length);
    });
  };

  const handleGetRequestedOperation = async () => {
    setLoadingRequested(true);
    getRequestedOperation().then((response) => {
      handleGetPendingOperations();
      setDataRequested(response.data);
      setCountSolicitadas(response.data.length);
      setLoadingRequested(false);
    });
  };

  useEffect(() => {
    handleGetRequestedOperation();
  }, []);

  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title
              title={'Otorgadas'}
              mostrarTabOperaciones={true}
              tabSelected={'pendientes_de_facturacion'}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.tabContainer}
        >
          <Grid item sm={2} className={classes.tab} onClick={hadleSeeTabPendientesClick}>
            Pendientes ({countPendientes})
          </Grid>
          <Grid item sm={2} className={classes.tabSelected}>
            Solicitadas ({countSolicitadas})
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            <Solicitadas
              dataOperaciones={dataRequested}
              loading={loadingRequested}
              onRefresh={handleGetRequestedOperation}
            />
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default withRouter(ViewSolicitadas);
