import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Title from '../../common/title';
import CursadaDetalle from './cursadaDetalle';
import FilterCursada from './filterCursada';
import Footer from './footer';
import {
  createMoneyOrderRequest,
  getCreditNoteWithOutPay,
  getCreditNote,
} from '../../../services/money-order-request-service';
import {
  IOperationsMoneyOrderRequest,
  IMoneyOrderRequest,
  IResponseTakenDetail,
  IFooterDataObject,
} from '../../../interfaces';
import { dealerServices } from '../../../services/dealerServices';
import NotFound from '../../common/notfound';
import DownloadCursadas from './downloadcursadas';
import MainContainer from '../../maincontainer';
import { numberToMiles, rutToNumberWithoutDv } from '../../../utilities/string-functions';
import { useAuth } from '../../common/auth';
import { Loading } from '../../common/loading';
import { COLORS, OPERATION_TAKEN, OPERATION_TAKEN_TRANSFORM } from '../../../constants';
import { BusinessTypeEnum } from '../../../enums';

/** START STYLES */
const heightHeader = 200;
const heightFooter = 70;
const minHeight = heightHeader + heightFooter;

const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      fontFamily: 'Nunito Sans',
    },
    header: {
      // background: linear-gradient(180deg, rgba(0,92,185,1) 0%, rgba(0,92,185,1) 350px, rgba(247,247,247,1) 350px, rgba(247,247,247,1) 1500px)',
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      fontFamily: 'Nunito Sans',
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100% !important',
    },
  })
);
/** FINISH STYLES */

interface IProps extends RouteComponentProps {}

const Cursadas = (props: IProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [dataOperations, setDataOperations] = useState([] as Array<any>);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState([] as Array<number>);
  const [dataSelected, setDataSelected] = useState([] as Array<any>);
  const [mountDataSelected, setMountDataSelected] = useState(0);
  const [bankAccountIdSelected, setBankAccountIdSelected] = useState({} as any);
  const [botonDisabled, setBotonDisabled] = useState(true);
  const [notificationDisabled, setNotificationDisabled] = useState(false);
  /// notas de creditos disponibles
  const [creditNotesAvailable, setCreditNotesAvailable] = useState({} as any);
  // suma de las operaciones
  const [sumAmountOperationTaken, setsumAmountOperationTaken] = useState(0 as number);
  //checket loading
  const [statecheck, setStatecheck] = useState(false as boolean);
  //notas de credito por pagar
  const [creditNoteToPay, setCreditNoteToPay] = useState({} as any);

  const { clientInfo, businessType } = useAuth();
  const clientRut = rutToNumberWithoutDv(clientInfo.rut);
  1;
  /**
   * marcar/desmarcar todas las operaciones
   */
  const handleOperationCheckedAllClick = () => {
    setCheckedAll(checkedAll ? false : true);
    const newChecked = [] as Array<number>;

    if (!checkedAll) {
      dataOperations.map((data: any, index: number) => {
        if (data.AmountDraft > 0) {
          newChecked.push(data.DealerRut);
        }
      });
    }
    handleMarkOperationsCheckedChange(newChecked);
    setChecked(newChecked);
  };

  /**
   * marcar/desmarcar la operacion mediante el checkbox
   * @param value DealerRut
   */
  const handleOperationCheckedClick = (value: number) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    handleIsAllCheckedChange(newChecked);
    handleMarkOperationsCheckedChange(newChecked);
    setChecked(newChecked);
  };

  /**
   * Crear solicitud de giro
   * */
  const handleCreateMoneyOrderRequest = async () => {
    setBotonDisabled(true);
    let moneyRequestOrder = setMoneyOrderRequest();
    createMoneyOrderRequest(moneyRequestOrder, businessType as BusinessTypeEnum).then(
      (response) => {
        props.history.push('/nuevos/pendientes-giro');
      }
    );
  };

  /**
   * verifica si todas las operaciones estan checkeadas
   * @param newChecked array of number
   */
  const handleIsAllCheckedChange = (newChecked: any) => {
    let dataNotChecked = dataOperations.filter((data) => newChecked.indexOf(data.DealerRut) < 0);
    let existDataNotChecked = dataNotChecked.length > 0 ? true : false;
    setCheckedAll(!existDataNotChecked);
  };

  /**
   * obtener operaciones seleccionadas
   */
  const handleMarkOperationsCheckedChange = (checked: Array<number>) => {
    let currentOperationsSelected = [] as Array<IOperationsMoneyOrderRequest>;
    let currentMountDataSelected = 0;
    let sumAmountOperationTaken = 0;

    checked.map((value: number, index: number) => {
      let objOperationSelected = _.find(dataOperations, {
        DealerRut: value,
      });
      if (objOperationSelected && objOperationSelected.AmountDraft > 0) {
        mapCursadasDetails(currentOperationsSelected, objOperationSelected.ResponseTakenDetail);
        currentMountDataSelected += objOperationSelected.AmountDraft;
        sumAmountOperationTaken += objOperationSelected.AmountOperationTaken;
      } else {
        setNotificationDisabled(true);
      }

      setBankAccountIdSelected(objOperationSelected.BankAccountId);
    });
    setsumAmountOperationTaken(sumAmountOperationTaken);
    setDataSelected(currentOperationsSelected);
    creditNotesToPay(currentMountDataSelected);
    setMountDataSelected(currentMountDataSelected);
    setBotonDisabled(!(currentOperationsSelected.length > 0));
  };

  const creditNotesToPay = async (currentMountDataSelected: number) => {
    getCreditNote(currentMountDataSelected, businessType as BusinessTypeEnum).then((resp) => {
      setCreditNoteToPay(resp.data);
    });
  };

  const setMoneyOrderRequest = () => {
    const creditNotee =
      creditNoteToPay.CreditNotes === undefined ? [] : creditNoteToPay.CreditNotes.CreditNotes;
    const moneyOrderRequest = {
      ClientId: clientRut,
      BankAccountId: bankAccountIdSelected,
      TotalMoneyOrderRequest: mountDataSelected,
      TotalOperations: sumAmountOperationTaken, /// suma de la operaciones seleccionadas.
      TotalCreditNotes: creditNotesAvailable.TotalPrice, // suma de las notas de credito usadas.
      Operations: dataSelected,
      CreditNotesUsed: creditNotee, // se envian las que se usaron.
    } as IMoneyOrderRequest;
    return moneyOrderRequest;
  };

  const mapCursadasDetails = (
    currentOperationsSelected: Array<IOperationsMoneyOrderRequest>,
    responseTakenDetail: Array<IResponseTakenDetail>
  ) => {
    responseTakenDetail.map((data: any, index: number) => {
      if (data.InOverdraft !== OPERATION_TAKEN.OUTDRAFT) {
        currentOperationsSelected.push(transformDataDetail(data));
      }
    });
  };

  const transformDataDetail = (data: any) => {
    const objResponseTakenDetail = {
      OperationId: data.OperationId,
      InOverdraft:
        data.InOverdraft === OPERATION_TAKEN.CREDIT_LINE
          ? OPERATION_TAKEN_TRANSFORM.CREDIT_LINE
          : OPERATION_TAKEN_TRANSFORM.OVERDRAFT,
    } as IOperationsMoneyOrderRequest;

    return objResponseTakenDetail;
  };

  useEffect(() => {
    const runEffect = async () => {
      dealerServices.getMoneyOrderRequest().then((response) => {
        setLoading(false);
        setDataOperations(response.data);
      });
      getCreditNoteWithOutPay(businessType as BusinessTypeEnum).then((respCreditNote) => {
        setCreditNotesAvailable(respCreditNote.data);
      });
    };
    runEffect();
  }, [setDataOperations]);

  let creditNote = creditNoteToPay.CreditNotes;
  let countCrediNote = 0;

  if (creditNote != undefined) {
    countCrediNote = creditNote.CreditNotes.length;
    creditNote = creditNote.TotalPrice;
  }

  const countCreditNoteAvailable =
    creditNotesAvailable.CreditNotes === undefined ? 0 : creditNotesAvailable.CreditNotes.length;
  const totalMount = creditNote > 0 ? mountDataSelected - creditNote : mountDataSelected;
  const footerDataObject: IFooterDataObject = {
    debtsCreditNote: numberToMiles(creditNotesAvailable.TotalPrice),
    requestAmount: numberToMiles(mountDataSelected),
    creditNote: numberToMiles(creditNote),
    totalMount: numberToMiles(totalMount),
    totalCrediNote: countCrediNote,
    totalCreditNoteAvailable: countCreditNoteAvailable,
  };
  return (
    <MainContainer>
      <div className={classes.contenedor}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          <Grid item sm={12}>
            <Title
              title={'Cursadas'}
              objDownloadCursadas={{
                mostrarBtnDownload: true,
                component: DownloadCursadas,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.body}
        >
          <Grid item sm={12}>
            {dataOperations.length <= 0 && !loading && (
              <NotFound message="No se encontraron operaciones en cursadas." />
            )}
            {!loading && dataOperations.length > 0 && (
              <div>
                <FilterCursada
                  isAllChecked={checkedAll}
                  onCheckedAll={handleOperationCheckedAllClick}
                  statecheck={statecheck}
                />
                <CursadaDetalle
                  key={'cursadaDetalle'}
                  data={dataOperations}
                  selectedChecked={checked}
                  onChecked={handleOperationCheckedClick}
                  statecheck={statecheck}
                />
              </div>
            )}
            {loading && <Loading />}
          </Grid>
        </Grid>

        <div className={classes.footer}>
          <Footer
            heightFooter={heightFooter}
            onUpdate={handleCreateMoneyOrderRequest}
            disabled={botonDisabled}
            data={footerDataObject}
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default Cursadas;
