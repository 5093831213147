import React, { useState, FormEvent } from 'react';
import { Button, Grid, TextField } from '@material-ui/core/';
import { makeStyles, Theme, styled } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import authorizationServices from '../../services/authorizationServices';

import CompanyLogo from '../common/header/companylogo';
import Notification from '../common/notification';

import { useAuth } from '../common/auth';
import { masterServices } from '../../services/masterServices';
import { ClientInfo } from '../../interfaces';
import { setClientInfoHeader } from '../../utilities/request';
import { StorageKeys } from '../../utilities/storageHelper';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  header: {
    backgroundColor: COLORS.primary,
    color: '#ffffff',
    height: '200px',
  },
  paper: {
    height: 'auto',
    width: '329px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  relleno: {
    height: '342px',
    width: '395px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boton: {
    height: '36px',
    width: '149px',
    borderRadius: '4px',
    backgroundColor: COLORS.secondary,
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '28px',
  },
}));

const StyledTextField = styled(TextField)({
  '& label': {
    color: COLORS.primary,
  },
  '& label.Mui-focused': {
    color: COLORS.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: COLORS.primary,
    },
    '&:hover fieldset': {
      borderColor: COLORS.primary,
      borderWidth: 2,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primary,
    },
  },
});

export const Login = (props: RouteComponentProps) => {
  const classes = useStyles();

  const { setIsAuthenticated, setClients, setClientInfo } = useAuth();

  const [email, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoginFailedMsg, setShowLoginFailedMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Debes completar los datos solicitados');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  let isLogged = false;

  const logostyles = {
    height: {
      height: 120,
      paddingLeft: 80,
      paddingRight: 40,
    },
  };

  const processRedirect = () => {
    return props.history.push('/negocio');
  };

  const doLogin = async () => {
    setIsDisabled(true);

    if (errorEmail === '' && errorPassword === '' && email !== '' && password !== '') {
      await authorizationServices
        .login(
          {},
          {
            UserId: email,
            Password: password,
          }
        )
        .then((response) => {
          isLogged = response.status === 200;

          if (isLogged) {
            const token = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('userIsLogged', isLogged.toString());
            localStorage.setItem(StorageKeys.BusinessType, '');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              if (
                localStorage.getItem('userIsLogged') !== null &&
                localStorage.getItem('userIsLogged') === 'true'
              ) {
                setErrorMsg('Se ha producido un error relacionado con los datos de autenticación');
              }
              setErrorMsg('El usuario y/o contraseña ingresados no coinciden');
            }
          }

          setShowLoginFailedMsg(true);
          setIsDisabled(false);
        });

      await masterServices.getClients().then((response) => {
        if (response && response.data) {
          const clients: ClientInfo[] = response.data;

          setClients(clients);

          const client = JSON.parse(localStorage.getItem(StorageKeys.Client) as string);
          if (client && client.rut !== '') {
            setClientInfoHeader(client);
            setClientInfo(client);
          } else {
            setClientInfoHeader(clients[0]);
            setClientInfo(clients[0]);
          }
        }
      });

      setIsAuthenticated(isLogged);
      setIsDisabled(false);
      processRedirect();
    } else {
      setShowLoginFailedMsg(true);
      setIsDisabled(false);
    }
  };

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    doLogin();
  };

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const mail = event.target.value as string;
    if (mail === '') {
      setErrorEmail('Campo requerido');
    } else {
      if (validateEmail(mail)) {
        setErrorEmail('');
      } else {
        setErrorEmail('Correo electrónico con formato inválido');
      }
    }
    setMail(mail);
  };

  const handleChangePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
    const password = event.target.value as string;
    if (password === '') {
      setErrorPassword('Campo requerido');
    } else {
      setErrorPassword('');
    }
    setPassword(password);
  };

  const validateEmail = (email: string) => {
    const expression = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return expression.test(String(email).toLowerCase());
  };

  return (
    <div>
      <div className={classes.header}>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} sm={4} style={logostyles.height}>
            <div style={{ paddingTop: '30px' }}>
              <CompanyLogo isHeader />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.container}>
            <div className={classes.relleno}>
              <div className={classes.paper}>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                  <StyledTextField
                    variant="outlined"
                    margin="normal"
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    autoComplete="off"
                    error={errorEmail !== ''}
                    helperText={errorEmail}
                    autoFocus
                    required
                    fullWidth
                    onChange={handleChangeEmail}
                  />
                  <StyledTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    error={errorPassword !== ''}
                    helperText={errorPassword}
                    value={password}
                    autoComplete="current-password"
                    onChange={handleChangePassword}
                    style={{ marginTop: 20 }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        doLogin();
                      }
                    }}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isDisabled}
                    className={classes.boton}
                    onClick={doLogin}
                  >
                    Iniciar Sesión
                  </Button>
                </form>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}></Grid>
        </Grid>

        <Notification
          variant="error"
          message={errorMsg}
          show={showLoginFailedMsg}
          onClose={() => setShowLoginFailedMsg(false)}
        />
      </div>
    </div>
  );
};

export default withRouter(Login);
