import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { history } from './utilities/history';

import './styles/main.css';

const render = () => {
  ReactDOM.render(<App history={history} />, document.getElementById('root'));
};

render();
