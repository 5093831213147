import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Checkbox } from '@material-ui/core/';
import { numberToMiles, rutFormater } from '../../../utilities/string-functions';
import { Loading } from '../../common/loading';
import { COLORS } from '../../../constants';

const objRootStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divDetalleOutDraft: {
      border: `2px solid ${COLORS.secondary}`,
      borderBottom: '2px #222222',
      borderRadius: '4px 4px 0 0',
      background: '#FFCCCC',
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
      height: 82,
    },
    divMensajeOutDraft: {
      border: `2px solid ${COLORS.secondary}`,
      background: COLORS.secondary,
      textAlign: 'center',
      borderRadius: '0 0 4px 4px',
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
      color: '#FFFFFF',
      marginBottom: 20,
      height: 22,
    },
    divDetalleOverDraft: {
      border: '2px solid #FFD900',
      borderBottom: '2px #222222',
      borderRadius: '4px 4px 0 0',
      background: '#FFF5DF',
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
      height: 82,
    },
    divMensajeOverDraft: {
      border: '2px solid #FFD900',
      background: '#FFD900',
      textAlign: 'center',
      borderRadius: '0 0 4px 4px',
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
      color: '#666666',
      marginBottom: 20,
      height: 22,
    },
    divDetalleCreditLine: {
      border: `1px solid ${COLORS.tertiary}`,
      background: '#efefef',
      borderRadius: 4,
      fontWeight: 'bold',
      fontFamily: 'Nunito Sans',
      marginBottom: 20,
      height: 82,
    },
    gridItemDealer: {
      height: '100%',
      fontSize: '15px',
      fontWeight: 'bold',
      ...objRootStyle,
      justifyContent: 'flex-start',
    },
    gridItemDealerRut: {
      height: '100%',
      fontSize: '13px',
      fontWeight: 'bold',
      ...objRootStyle,
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    griItem: {
      color: COLORS.sextenary,
      alignItems: 'center',
      // textAlign: 'center',
      // ...objRootStyle
    },
    txtPrimary2: {
      color: COLORS.secondary,
    },
    root: {
      height: '100%',
      ...objRootStyle,
    },
    containerInternoDetalles: {
      height: 40,
      ...objRootStyle,
    },
    verticalLine: {
      borderLeft: `1px solid ${COLORS.quintinary}`,
      paddingLeft: '10px',
    },
  })
);

const OPERATIONTAKEN = {
  CREDITLINE: 0, // NORMAL --> false
  OVERDRAFT: 1, // SOBREGIRO --> true
  OUTDRAFT: 2, // EXCESO
};

const ESTADO = {
  BLOCK: 'block',
  NONE: 'none',
};

const HEADER = {
  h1: 'Monto cursadas',
  h2: 'Total VIN',
  h3: 'Monto girar',
  h4: 'VIN a girar',
  h5: 'Línea de crédito',
  h6: 'Sobregiro',
};

function Operacion(props: any) {
  const classes = useStyles();

  const { data, selectedChecked, onChecked, statecheck } = props;

  const [active, setActive] = useState(false);
  const [marked, setMarked] = useState(ESTADO.NONE);
  const [styleDiv, setStyleDiv] = useState(classes.divDetalleCreditLine);
  const [styleMessageDiv, setStyleMessageDiv] = useState(classes.divMensajeOverDraft);

  const handleMarkOverdraftOrExcessesChange = () => {
    let objWithExcesses = _.find(data.ResponseTakenDetail, {
      InOverdraft: OPERATIONTAKEN.OUTDRAFT,
    });

    if (objWithExcesses) {
      setMarked(ESTADO.BLOCK);
      setStyleDiv(classes.divDetalleOutDraft);
      setStyleMessageDiv(classes.divMensajeOutDraft);
    } else {
      let objWithOverdraft = _.find(data.ResponseTakenDetail, {
        InOverdraft: OPERATIONTAKEN.OVERDRAFT,
      });

      if (objWithOverdraft) {
        setMarked(ESTADO.BLOCK);
        setStyleDiv(classes.divDetalleOverDraft);
        setStyleMessageDiv(classes.divMensajeOverDraft);
      }
    }
  };

  const handleOperationCheckedClick = (value: number) => {
    onChecked(value);
  };

  useEffect(() => {
    handleMarkOverdraftOrExcessesChange();
  });
  const rutFormat = rutFormater(data.DealerRut.toString() + data.VerificationNumber);
  return (
    <>
      <Grid container direction="row" alignItems="center" className={styleDiv}>
        <Grid item md={1} className={classes.root}>
          {statecheck && <Loading />}
          {!statecheck && (
            <Checkbox
              disabled={data.AmountDraft < 1}
              key={data.DealerRut}
              checked={selectedChecked.indexOf(data.DealerRut) !== -1}
              onChange={() => handleOperationCheckedClick(data.DealerRut)}
              style={{ color: COLORS.secondary }}
            />
          )}
        </Grid>

        <Grid item md={2} container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item md={12} className={classes.gridItemDealer}>
            <label className={classes.txtPrimary2}>{data.DealerName}</label>
          </Grid>
          <Grid item md={12} className={classes.gridItemDealerRut}>
            <label className={classes.txtPrimary2}>{rutFormat}</label>
          </Grid>
        </Grid>

        <Grid item md={9}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item md={4}>
              <Grid container justifyContent="flex-start" alignItems="stretch">
                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <label>{HEADER.h1}</label>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <label>{HEADER.h2}</label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <span>{`$ ${numberToMiles(data.AmountOperationTaken)}`}</span>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <span>{numberToMiles(data.NumberOperationTaken)}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.verticalLine}
                style={{ paddingLeft: '20px !important' }}
              >
                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <label className={classes.txtPrimary2}>{HEADER.h3}</label>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <label className={classes.txtPrimary2}>{HEADER.h4}</label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <span className={classes.txtPrimary2}>{`$ ${numberToMiles(
                        data.AmountDraft
                      )}`}</span>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <span className={classes.txtPrimary2}>
                        {numberToMiles(data.NumberOperationDraft)}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.verticalLine}
              >
                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <label>{HEADER.h5}</label>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <label>{HEADER.h6}</label>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} className={classes.containerInternoDetalles}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} className={classes.griItem}>
                      <span>{`$ ${numberToMiles(data.LineUsed)}`}</span>
                    </Grid>
                    <Grid item md={6} className={classes.griItem}>
                      <span>{`$ ${numberToMiles(data.CreditOverdraft)}`}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ display: marked }}
        className={styleMessageDiv}
      >
        <Grid item xs={12} sm={12}>
          {data.Message}
        </Grid>
      </Grid>
    </>
  );
}

export default Operacion;
