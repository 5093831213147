import { Grid, MenuItem } from '@material-ui/core';
import React, { useEffect, ChangeEvent } from 'react';
import { ApiResponse, OperationsChargeResponse } from '../../../../models/commons';
import { postFileChargeOperation } from '../../../../utilities/requestsFreeZone';
import LastChargeOperations from '../../commons/lastChargeOperations';
import UploadFile from '../../../common/charge/uploadFile';
import ResultChargeOperations from '../../../common/charge/resultChargeOperations';
import useStyles, { StyledTextField } from './ChargeOperationsFreeZone.style';
import { masterServices } from '../../../../services/masterServices';
import { ICreditLines } from '../../../../models/commons/CreditLines';
import { IChargeOperations } from '../../../../interfaces';

const ChargeOperationsFreeZone: React.FC = () => {
  const classes = useStyles();
  const [showResult, setShowResult] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState<OperationsChargeResponse>(
    {} as OperationsChargeResponse
  );
  const [creditLineTypes, setCreditLineTypes] = React.useState<ICreditLines[]>([]);
  const [creditLineType, setCreditLineType] = React.useState('none');

  const handleUploadFile = async (
    formData: FormData
  ): Promise<ApiResponse<OperationsChargeResponse>> => {
    const data: IChargeOperations = {
      File: formData,
      CreditLineType: creditLineType,
    };
    const dataResponse = await postFileChargeOperation<OperationsChargeResponse>(
      `/api/charges`,
      data
    );
    return dataResponse;
  };

  const handleViews = (response: OperationsChargeResponse) => {
    setUploadResponse(response);
    setShowResult(true);
  };

  const handleUploadAgain = () => {
    setShowResult(false);
  };

  const handleGetCreditLineTypes = () => {
    masterServices.getCreditLineTypes().then((response) => {
      setCreditLineTypes(response.data);
    });
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCreditLineType(event.target.value as string);
  };

  useEffect(() => {
    handleGetCreditLineTypes();
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item className={classes.box}>
        <LastChargeOperations />

        {!showResult ? (
          <>
            <Grid item md={6} className={classes.detalles}>
              <StyledTextField
                value={creditLineType}
                onChange={handleChange}
                name="creditLineType"
                className={classes.select}
                variant="outlined"
                select
              >
                <MenuItem disabled value="none">
                  <em>Seleccione el tipo de línea de crédito</em>
                </MenuItem>

                {creditLineTypes.map((creditLine: ICreditLines) => {
                  return (
                    <MenuItem
                      key={creditLine.CreditLineTypeId}
                      value={creditLine.CreditLineTypeId}
                      className={classes.selectItem}
                    >
                      {creditLine.Name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <UploadFile uploadFile={handleUploadFile} handleViews={handleViews} />
          </>
        ) : (
          <ResultChargeOperations
            ChargeId={uploadResponse.ChargeId}
            Total={uploadResponse.Total}
            TotalSuccessful={uploadResponse.TotalSuccessful}
            TotalRejected={uploadResponse.TotalRejected}
            FileName={uploadResponse.FileName}
            UploadAt={uploadResponse.UploadAt}
            handleUploadAgain={handleUploadAgain}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChargeOperationsFreeZone;
