/* eslint-disable indent */
import { useState, useEffect, useRef, useReducer } from 'react';
import { AxiosRequestConfig } from 'axios';
import { FetchConfig, defaultFetchConfig } from '../config/fetchConfig';
import { axiosWithInterceptor } from '../utilities/axios';
import { ErrorModel } from '../models/ErrorModel';
import { useAuth } from '../components/common/auth';
import { defaultHeadersUsed } from '../utilities/requestsUsed';
import { BUSINESSTYPE } from '../constants';

interface State<T> {
  status: 'init' | 'fetching' | 'error' | 'fetched';
  data?: T;
  error?: ErrorModel;
  reload: () => void;
}

interface Cache<T> {
  [url: string]: T;
}

type Action<T> =
  | { type: 'request' }
  | { type: 'success'; payload: T }
  | { type: 'failure'; payload: ErrorModel };

export function useFetch<T = unknown>(
  url: string,
  config: FetchConfig = defaultFetchConfig,
  businessType: string = BUSINESSTYPE.USED
): State<T> {
  const cache = useRef<Cache<T>>({});
  const [reload, setReload] = useState<number>(0);
  let cancelRequest = false;
  const initialState: State<T> = {
    status: 'init',
    error: undefined,
    data: undefined,
    reload: () => {},
  };
  const { setIsAuthenticated } = useAuth();

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'request':
        return { ...initialState, status: 'fetching' };
      case 'success':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'failure':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const customConfig: FetchConfig = {
    ...defaultFetchConfig,
    ...config,
  };

  const customAxios = axiosWithInterceptor(customConfig, businessType);

  const customOptions: AxiosRequestConfig = {
    headers: {
      ...defaultHeadersUsed(customConfig.contentType, customConfig.withAuth),
    },
    method: customConfig.method,
  };

  useEffect(() => {
    if (!url) return;
    const fetchData = async () => {
      dispatch({ type: 'request' });
      if (cache.current[url] && reload === 0) {
        dispatch({ type: 'success', payload: cache.current[url] });
      } else {
        try {
          const response = await customAxios(`${url}`, customOptions);
          cache.current[url] = response?.data;

          if (cancelRequest) return;

          dispatch({ type: 'success', payload: response.data });
        } catch (error) {
          if (cancelRequest) return;

          const errorModel: ErrorModel = {
            StatusCode: error?.status,
            ErrorId: error?.data?.ErrorId,
            Message: error?.data?.Message,
          };

          if (errorModel.StatusCode === 401) {
            setIsAuthenticated(false);
          }

          dispatch({ type: 'failure', payload: errorModel });
        }
      }
    };
    fetchData();
    return () => {
      cancelRequest = true;
    };
  }, [url, reload]);
  return {
    ...state,
    reload: (): void => {
      setReload(reload + 1);
    },
  };
}
