import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS, TABS } from '../../../../constants';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../utilities/storageHelper';

/** region style */
const tabStylesObj = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {},
    tab: {
      borderBottom: '1px solid #FFFFFF',
      height: 48,
      textTransform: 'uppercase',
      cursor: 'pointer',
      ...tabStylesObj,
    },
    tabSelected: {
      borderBottom: '1px solid #FFFFFF',
      height: 48,
      textTransform: 'uppercase',
      cursor: 'default',
      background: '#FFFFFF',
      color: COLORS.primary,
      ...tabStylesObj,
    },
  })
);
/** endregion */

interface IProps extends RouteComponentProps {
  tabSelected: string;
}

const TabMenuSubirExcel = (props: IProps) => {
  const classes = useStyles();

  const { tabSelected } = props;
  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  const handleTabCargaOperaciones = () => {
    props.history.push(`/${businessTypeStorage}/carga-operaciones`);
  };

  const handleTabHistorialCargaClick = () => {
    props.history.push(`/${businessTypeStorage}/subir-excel/historial-carga`);
  };

  const handleTabCargaICAR = () => {
    props.history.push(`/${businessTypeStorage}/carga-icar`);
  };

  const handleTabCargaNotasCredito = () => {
    props.history.push(`/${businessTypeStorage}/carga-notas-de-credito`);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.tabContainer}
    >
      <Grid
        item
        sm={4}
        onClick={handleTabCargaOperaciones}
        className={tabSelected === TABS.CHARGE_OPERATIONS ? classes.tabSelected : classes.tab}
      >
        Carga de Operaciones
      </Grid>
      {/* <Grid item sm={3} onClick={handleTabHistorialCargaClick} className={tabSelected === TABS.HISTORIAL_DE_CARGA ? classes.tabSelected : classes.tab}>Historial de Carga</Grid>
       */}
      <Grid
        item
        sm={4}
        onClick={handleTabCargaICAR}
        className={tabSelected === TABS.CHARGE_ICAR ? classes.tabSelected : classes.tab}
      >
        CARGA DE I-CAR
      </Grid>
      <Grid
        item
        sm={4}
        onClick={handleTabCargaNotasCredito}
        className={tabSelected === TABS.CHARGE_CREDIT_NOTES ? classes.tabSelected : classes.tab}
      >
        CARGA NOTAS DE CRÉDITO
      </Grid>
    </Grid>
  );
};

export default withRouter(TabMenuSubirExcel);
