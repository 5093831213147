import React from 'react';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';
import { IDataDealer } from '../../../interfaces';
import { rutFormater } from '../../../utilities/string-functions';
import { COLORS } from '../../../constants';

const flexStyleObj = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const celdaStyleObj = {
  height: 30,
  fontFamily: 'Nunito Sans',
  ...flexStyleObj,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Nunito Sans',
      color: COLORS.sextenary,
      fontSize: '12px',
    },
    contenedor: {
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 87,
      maxWidth: '4000px',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 20,
    },
    body: {
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    enfasis: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    celda: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      fontSize: 16,
    },
    celdaUnoTitulo: {
      color: COLORS.secondary,
      fontSize: 20,
      fontWeight: 'bold',
      ...celdaStyleObj,
    },
    celdaUnoDetalle: {
      ...celdaStyleObj,
    },
    celdasLeft: {},
    celdasRightPrimario: {
      alignItems: 'flex-end',
      borderLeft: `1px solid ${COLORS.quintinary}`,
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
    celdasRightSecundario: {
      alignItems: 'flex-end',
      color: COLORS.secondary,
      display: 'flex',
      flexDirection: 'column',
    },
    tituloCenter: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
    tituloRight: {
      fontWeight: 'bold',
      color: COLORS.secondary,
    },
    monto: {
      color: COLORS.primary,
    },
    delaerRut: {
      fontWeight: 'bold',
      marginRight: 5,
      color: COLORS.secondary,
    },
  })
);

interface IProps {
  data: Array<IDataDealer>;
}

const HEADER = {
  h1: 'VIN Otorgados',
  h2: 'Línea de crédito',
  h3: 'Sobregiro aprobado',
  h4: 'Línea utilizada',
  h5: 'Sobregiro utilizado',
};

export const OperacionDetalleLinea = (props: IProps) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <div className={classes.root}>
      {data.map((details: IDataDealer, index: number) => {
        const {
          CreditLine,
          CreditOverdraft,
          CreditOverdraftApproved,
          DealerName,
          DealerRut,
          LineUsed,
          VinAssociate,
          VerificationNumber,
        } = details;
        const rutformat = rutFormater(DealerRut.toString() + VerificationNumber);
        return (
          <div className={classes.contenedor} key={index}>
            <Grid
              key={index}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.body}
            >
              <Grid item xs={5} sm={5} md={6} className={classes.celda}>
                <div className={classes.celdaUnoTitulo}>{DealerName}</div>
                <div className={classes.celdaUnoDetalle}>
                  <span className={classes.delaerRut}>{rutformat}</span>
                  <span className={classes.enfasis}>{`${HEADER.h1} ${VinAssociate}`}</span>
                </div>
              </Grid>
              <Grid item xs={7} sm={7} md={6} className={classes.celda}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Grid item xs={3} sm={3} className={classes.celdasLeft}>
                    <div className={classes.tituloCenter}>{HEADER.h2}</div>
                    <div className={classes.monto}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditLine.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.celdasLeft}>
                    <div className={classes.tituloCenter}>{HEADER.h3}</div>
                    <div className={classes.monto}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditOverdraftApproved.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.celdasRightPrimario}>
                    <div className={classes.tituloRight}>{HEADER.h4}</div>
                    <div className={classes.monto}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {LineUsed.toLocaleString()}
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} className={classes.celdasRightSecundario}>
                    <div className={classes.tituloRight}>{HEADER.h5}</div>
                    <div className={classes.monto}>
                      <span style={{ marginRight: 5 }}>$</span>
                      {CreditOverdraft.toLocaleString()}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default OperacionDetalleLinea;
