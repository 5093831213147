export enum BusinessTypeEnum {
  Nuevos = 'nuevos',
  Usados = 'usados',
  Repuestos = 'repuestos',
  ZonaFranca = 'zona-franca',
}

export enum TakenOperationStateEnum {
  CreditLine = 0, // NORMAL --> false
  OverDraft = 1, // SOBREGIRO --> true
  OutDraft = 2, // EXCESO
}

export enum PaymentMethodTypes {
  ValeVista = '92C911B1-2899-4A98-B896-4B7D72314ED0',
  Efectivo = '734F5EEE-74A3-46F7-982F-63F3561DB30A',
  CreditoTanner = '799B216A-9920-4D28-96E3-783F94281768',
  Transferencia = 'D6B8B2F5-C0C2-40CB-81ED-861A73CBD49A',
  Cheque = 'FCD5CACB-F794-4B1C-9F70-8A5DEE1A08EB',
  Flota = 'FCAAF623-340C-42DD-A4DB-D89CE6F4A444',
}
