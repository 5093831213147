import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, Hidden } from '@material-ui/core/';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Menu from './common/menu';
import Headers from './common/header/headers';
import { useAuth } from './common/auth';
import { BusinessTypeEnum } from '../enums';

const drawerWidth = 60;
const heightHeader = 120;
const paddingLeftHeader = 60;
const paddingRightHeader = 0;

interface StylesProps {
  isUsed: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: StylesProps) => ({
      display: 'flex',
      backgroundColor: props.isUsed ? theme.palette.credinissanBackground.default : 'white',
    }),
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        height: heightHeader,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    Header: {
      height: heightHeader,
    },
    drawerPaper: {
      width: drawerWidth,
      top: heightHeader,
      backgroundColor: '#F6F6F6',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
    },
  })
);

interface IProps {
  children: React.ReactNode;
}

export const MainContainers = (props: IProps): JSX.Element => {
  const { businessType } = useAuth();
  const isUsed = businessType === BusinessTypeEnum.Usados;

  const classes = useStyles({ isUsed });
  const theme = useTheme();

  const { children } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <Headers
        heightheder={heightHeader}
        paddingleftheader={paddingLeftHeader}
        paddingrightheader={paddingRightHeader}
      />

      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Menu />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
export default MainContainers;
