import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#FFFFFF !important',
      borderRadius: '4px',
      width: '100%',
      height: '87px',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0,0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      marginBottom: '20px',
      fontFamily: 'Nunito Sans',
      color: '#666666',
      fontSize: '12px',
    },

    message: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  })
);
