import { Button } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../../../constants';
import { BusinessTypeEnum } from '../../../../../enums';
import { useLocalStorage } from '../../../../../hooks/useLocalStorage';
import { OperationsChargeHistory } from '../../../../../models/commons';
import { convertDateReadableFormat } from '../../../../../utilities/dates-functions';
import { StorageKeys } from '../../../../../utilities/storageHelper';
import useStyles from './ItemHistoricChargeOperations.style';

type Props = {
  charge?: OperationsChargeHistory;
  handleDetailClick?: (id: string, UploadAt: string) => void;
};

const ItemHistoricChargeOperations: React.FC<Props> = ({
  charge,
  handleDetailClick = () => {},
}) => {
  const classes = useStyles();
  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  return (
    <tr className={classes.container}>
      <td className={classes.column}>
        <div className={classes.firstColumnTitle}>
          {convertDateReadableFormat(charge?.UploadAt)}
        </div>
      </td>
      <td className={classes.column}>
        <div className={classes.title}>
          {charge?.VinsTotal}{' '}
          {businessTypeStorage === BusinessTypeEnum.Repuestos ? 'repuestos' : 'VIN'}
        </div>
      </td>
      <td className={classes.link}>
        <Button
          style={{ color: COLORS.secondary, fontWeight: 'bold' }}
          onClick={() =>
            handleDetailClick(
              charge?.ChargeId ?? '',
              convertDateReadableFormat(charge?.UploadAt, false, '-', 'dd-LL-yyyy')
            )
          }
        >
          Revisar detalle
        </Button>
      </td>
    </tr>
  );
};

export default ItemHistoricChargeOperations;
