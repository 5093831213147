import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Title from '../../../../components/common/title';
import MainContainer from '../../../../components/maincontainer';
import HistoricChargeOperationsFreeZone from '../../../../components/freeZone/charge/historicChargeOperationsFreeZone';
import useStyles from './HistoricChargeOperationsFreeZonePage.style';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { StorageKeys } from '../../../../utilities/storageHelper';

const HistoricChargeOperationsFreeZonePage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const [businessTypeStorage] = useLocalStorage(StorageKeys.BusinessType);

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'historial') {
      props.history.push(`/${businessTypeStorage}/carga-operaciones/historial`);
    } else {
      props.history.push(`/${businessTypeStorage}/carga-operaciones`);
    }
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item sm={12}>
            <Title title="Carga de archivos" mostrarTabSubirExcel />
          </Grid>
        </Grid>

        <Grid container className={classes.tabNavigation}>
          <Grid item sm={2} className={classes.tab} onClick={handleClickOnChangeTab}>
            Carga
          </Grid>
          <Grid item sm={2} className={classes.tabSelected} onClick={handleClickOnChangeTab}>
            Historial
          </Grid>
        </Grid>
      </Grid>

      <HistoricChargeOperationsFreeZone />
    </MainContainer>
  );
};

export default HistoricChargeOperationsFreeZonePage;
