import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import newRoutes from '../../menusettings';
import usedRoutes from '../../usedMenuSettings';
import sparePartsRoutes from '../../sparePartMenuSettings';
import freeZoneRoutes from '../../freeZoneMenuSettings';
import { useAuth } from '../auth';
import { BusinessTypeEnum } from '../../../enums';

type Bread = {
  path: string | undefined;
  name: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    bread: {
      fontFamily: 'Nunito Sans',
      color: '#FFFFFF',
      fontSize: '0.9rem',
    },
    link: {
      fontWeight: 'bold',
      color: '#FFFFFF',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
  })
);

export default function CustomSeparator() {
  const classes = useStyles();
  const { businessType } = useAuth();
  const [breadcrumb, setBreadcrumb] = useState<Bread[]>();

  let routes = newRoutes;

  if (businessType === BusinessTypeEnum.Usados) {
    routes = usedRoutes;
  } else if (businessType === BusinessTypeEnum.Repuestos) {
    routes = sparePartsRoutes;
  } else if (businessType === BusinessTypeEnum.ZonaFranca) {
    routes = freeZoneRoutes;
  }

  // FIXME: Al recargar una pagina routes viene vacio, pues se pierde el contexto,
  //  por lo que el breadcumb muestra solo Inicio
  useEffect(() => {
    const run = () => {
      routes.map((route: any) => {
        if (
          route.path.toLowerCase() === window.location.pathname.toLowerCase() ||
          window.location.pathname.toLowerCase().split(':')[0] ===
            route.path.toLowerCase().split(':')[0]
        ) {
          const breadlist = [] as Bread[];

          if (route.breadcrumb.length > 0) {
            route.breadcrumb.map((breadcrumb: Bread) => {
              breadlist.push(breadcrumb);
              return true;
            });
          }

          breadlist.push({
            path: undefined,
            name: route.name,
          });

          setBreadcrumb(breadlist);
        }
        return true;
      });
    };

    run();
  }, [routes]);

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.bread}
      >
        <RouterLink
          color="white"
          to="/"
          style={{ textDecoration: 'none' }}
          // onClick={handleDefaultClick}
        >
          <span className={classes.link}>Inicio</span>
        </RouterLink>

        {breadcrumb &&
          breadcrumb.map((bread: Bread) => {
            if (bread.path) {
              return (
                <RouterLink
                  key={bread.name}
                  color="inherit"
                  to={bread.path}
                  style={{ textDecoration: 'none' }}
                >
                  <span className={classes.link}>{bread.name}</span>
                </RouterLink>
              );
            }

            return <div key={bread.name}>{bread.name}</div>;
          })}
      </Breadcrumbs>
    </div>
  );
}
