import React from 'react';
import { Button, Grid } from '@material-ui/core';
import useStyles from './FooterDetailApplyPaymentFreeZone.style';
import { numberToMiles } from '../../../../../utilities/string-functions';
import ModalDetailApplyPayment from '../../../../common/paymentPending/detailApplyPayment/modalDetailApplyPayment';

type Props = {
  amount: number;
  selectedVIN: number;
  reload: () => void;
  clientDealerId: string;
  operationIds: string[];
};

const FooterDetailApplyPaymentFreeZone: React.FC<Props> = ({
  amount,
  selectedVIN,
  reload,
  clientDealerId,
  operationIds,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container className={classes.footer} justifyContent="flex-end" alignItems="center">
      <Grid
        container
        direction="column"
        item
        xs={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid className={classes.titleItem}>VIN Seleccionados</Grid>
        <Grid className={classes.numberItem} data-testid="selectedVIN">
          {numberToMiles(selectedVIN)}
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={2} justifyContent="center" alignItems="flex-end">
        <Grid className={classes.titleItem}>Monto total</Grid>
        <Grid className={classes.numberItem} data-testid="totalAmount">
          ${numberToMiles(amount)}
        </Grid>
      </Grid>
      <Grid container item xs={3} justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          disabled={selectedVIN === 0}
          className={classes.btnApplyPayment}
          data-testid="btnApplyPayment"
          onClick={handleOpen}
        >
          APLICAR MEDIO DE PAGO
        </Button>
        <ModalDetailApplyPayment
          open={open}
          handleClose={handleClose}
          operationIds={operationIds}
          clientDealerId={clientDealerId}
          reload={reload}
        />
      </Grid>
    </Grid>
  );
};

export default FooterDetailApplyPaymentFreeZone;
