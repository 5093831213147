import apiConfig from '../config/api';
import apiUris from '../config/apiUris';
import { get } from '../utilities/request';

class DealerServices {
  private baseUrl = `${apiConfig.apiUri}/api/dealer`;

  async getLineDetails() {
    const url = `${this.baseUrl}`;
    return get(url);
  }

  async getMoneyOrderRequest() {
    const url = `${this.baseUrl}/operationtaken`;
    return get(url);
  }

  async getLineDetailsSpareParts() {
    return get(apiUris.apiDealer.getLineDetail);
  }
}

export const dealerServices = new DealerServices();
