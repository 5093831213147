import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: `calc(100vh - 200px)`,
      overflowY: 'auto',
    },
    formSelect: {
      margin: theme.spacing(1),
      minWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },

    btnUploadDoc: {
      borderColor: COLORS.secondary,
      color: COLORS.secondary,
    },

    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      flexBasis: 200,
    },
    btnApplyPayment: {
      backgroundColor: COLORS.secondary,
      color: 'white',

      '&$disabled': {
        background: '#EFEFEF',
      },
    },
    disabled: {},
  })
);

export default useStyles;
