import apiConfig from '../config/api';
import { getFromUrl } from '../utilities/request';

export const getOperationBilling = async () => {
  const url = `${apiConfig.apiUri}/api/billingOperation`;
  return getFromUrl(url);
};

export const getOperationBillingDetail = async (clientDealerId: string) => {
  const url = `${apiConfig.apiUri}/api/billingOperation/detail?clientDealerId=${clientDealerId}`;
  return getFromUrl(url);
};
