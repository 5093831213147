import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import MainContainer from '../../../../components/maincontainer';
import TituloDetallesUsados from '../../../../components/used/commons/TituloDetallesUsados';
import useStyles from './DetalleAplicarPagoUsadosPage.style';
import DetalleAplicarPagoUsados from '../../../../components/used/paymentPending/DetalleAplicarPagoUsados';

type Params = { id: string };

const DetalleAplicarPagoUsadosPage: React.FC<RouteComponentProps<Params>> = (props) => {
  const { match } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState('');

  const changeTitle = (t: string) => {
    setTitle(t);
  };

  return (
    <MainContainer>
      <Grid className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <TituloDetallesUsados title={title} />
          </Grid>
        </Grid>
      </Grid>
      <DetalleAplicarPagoUsados clientDealerId={match.params.id} changeTitle={changeTitle} />
    </MainContainer>
  );
};

export default DetalleAplicarPagoUsadosPage;
