import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Checkbox,
  Button,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { find } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import useStyles, { StyledTableCell } from './DetailApplyPaymentSpareParts.style';
import ErrorSpareparts from '../../commons/ErrorSpareparts/ErrorSpareparts';
import { Loading } from '../../../common/loading';
import { BUSINESSTYPE, COLORS } from '../../../../constants';
import FooterDetailApplyPaymentSpareParts from './FooterDetailApplyPaymentSpareParts';
import config from '../../../../config/api';
import { SparePartsDetailPaymentPending } from '../../../../models/spareParts/SparePartsDetailPaymentPending';
import ItemApplyPaymentSpareParts from './ItemApplyPaymentSpareParts';

function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: number[], b: number[]) {
  return [...a, ...not(b, a)];
}

type Props = {
  clientDealerId: string;
  changeTitle: (t: string) => void;
} & RouteComponentProps;

const DetailApplyPaymentSpareParts: React.FC<Props> = (props) => {
  const { clientDealerId, changeTitle } = props;
  const classes = useStyles();

  const [checked, setChecked] = React.useState<number[]>([]);
  const [positions, setPositions] = React.useState<number[]>([]);
  const [anyDisabled, setAnyDisabled] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [operationIds, setOperationIds] = React.useState<string[]>([]);
  const [invoiceNumber, setInvoiceNumber] = React.useState<number | string>('');
  const [pendingPayments, setPendingPayments] = React.useState<SparePartsDetailPaymentPending[]>(
    []
  );

  const url = `${config.sparePartSettings.apiUri}/api/payments/pendings/${clientDealerId}`;
  const {
    status,
    data: pendingPaymentsAll,
    error,
    reload,
  } = useFetch<SparePartsDetailPaymentPending[]>(url, undefined, BUSINESSTYPE.PARTS);

  // useEffect para actualizar listado de todas las facturas
  React.useEffect(() => {
    if (pendingPaymentsAll) {
      setPendingPayments(pendingPaymentsAll);
    }
  }, [pendingPaymentsAll]);

  // Función para actualizar el listado de facturas por número de factura
  const handleFilter = () => {
    if (pendingPaymentsAll) {
      if (invoiceNumber) {
        setPendingPayments(pendingPaymentsAll.filter((p) => p.InvoiceNumber === invoiceNumber));
      } else {
        setPendingPayments(pendingPaymentsAll);
      }
    }
  };

  // Función para el onChange de los checkbox de ItemApplyPayment
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  React.useEffect(() => {
    if (status === 'fetched' && pendingPaymentsAll?.length === 0) {
      props.history.replace(`/repuestos/otorgadas/aplicacion-pago/`);
    } else if (status === 'fetched') {
      changeTitle(pendingPaymentsAll?.[0].DealerName ?? '');
      setPositions(pendingPaymentsAll?.map((_, idx) => idx) ?? []);
      setTotalAmount(0);
      setChecked([]);
      setOperationIds([]);
    }
  }, [status, pendingPayments, changeTitle, pendingPaymentsAll]);

  React.useEffect(() => {
    let totAmount = 0;
    const ids = [] as string[];
    checked.map((ch) => {
      const pa = find(pendingPayments, {
        Position: ch,
      });
      const amount = pa !== undefined ? (pa as SparePartsDetailPaymentPending).Amount : 0;
      totAmount += amount;
      if (pa !== undefined) {
        ids.push((pa as SparePartsDetailPaymentPending).OperationId);
      }
      return true;
    });

    setOperationIds(ids);
    setTotalAmount(totAmount);
  }, [checked, pendingPayments]);

  const numberOfChecked = (items: number[]) => intersection(checked, items).length;

  // Chequea todas las facturas
  const handleToggleAll = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  if (status === 'error' && error)
    return (
      <ErrorSpareparts
        StatusCode={error.StatusCode}
        Message={error.Message}
        ErrorId={error.ErrorId}
      />
    );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.root}
    >
      {status === 'fetching' ? (
        <Loading />
      ) : (
        <Grid item className={classes.box}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
            <Grid item xs={12}>
              <div className={classes.searchForm}>
                <div className={classes.searchInput}>
                  <label htmlFor="customerInvoiceNumber">N° factura cliente</label>
                  <TextField
                    placeholder="Ingrese N° factura cliente"
                    id="customerInvoiceNumber"
                    style={{ minWidth: '25%' }}
                    value={invoiceNumber || ''}
                    variant="outlined"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInvoiceNumber(Number(e.target.value));
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  className={classes.btnFilter}
                  onClick={() => handleFilter()}
                >
                  <SearchIcon /> Filtrar
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.headTable}>
                    <TableRow>
                      <StyledTableCell>
                        <Checkbox
                          style={{ color: COLORS.secondary }}
                          onClick={handleToggleAll(positions)}
                          checked={
                            numberOfChecked(positions) === positions.length &&
                            positions.length !== 0
                          }
                          indeterminate={
                            numberOfChecked(positions) !== positions.length &&
                            numberOfChecked(positions) !== 0
                          }
                          disabled={
                            positions.length === 0 || anyDisabled || pendingPayments.length === 0
                          }
                          inputProps={{ 'aria-label': 'all items selected' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>Días Transcurridos</StyledTableCell>
                      <StyledTableCell>Monto Neto</StyledTableCell>
                      <StyledTableCell>N°Factura Cliente</StyledTableCell>
                      <StyledTableCell>Fecha Emisión</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingPayments?.map((item) => {
                      return (
                        <ItemApplyPaymentSpareParts
                          key={item.OperationId}
                          checked={checked}
                          pendingPayment={item}
                          handleToggle={handleToggle}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
      <FooterDetailApplyPaymentSpareParts
        operationIds={operationIds}
        amount={totalAmount}
        clientDealerId={clientDealerId}
        reload={reload}
      />
    </Grid>
  );
};

export default withRouter(DetailApplyPaymentSpareParts);
