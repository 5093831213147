import fileDownload from 'js-file-download';
import { AxiosRequestConfig } from 'axios';
import { defaultFetchConfig, FetchConfig } from '../config/fetchConfig';
import { ErrorModel } from '../models/ErrorModel';
import { axiosWithInterceptor } from './axios';
import { defaultHeadersUsed } from './requestsUsed';

type StateDownloadResponse = {
  status: 'success' | 'error';
  error?: ErrorModel;
};

export const downloadFileUsed = async (
  url: string,
  config: FetchConfig
): Promise<StateDownloadResponse> => {
  const customConfig: FetchConfig = {
    ...defaultFetchConfig,
    ...config,
  };

  const customAxios = axiosWithInterceptor(customConfig);

  const customOptions: AxiosRequestConfig = {
    headers: {
      ...defaultHeadersUsed(customConfig.contentType, customConfig.withAuth),
    },
    method: customConfig.method,
    responseType: 'blob',
  };
  try {
    const response = await customAxios(url, customOptions);
    fileDownload(
      response.data,
      customConfig.fileName === ''
        ? response.headers.get('Content-Disposition')
        : customConfig.fileName
    );
    return Promise.resolve({ status: 'success' });
  } catch (error) {
    const errorModel: ErrorModel = {
      StatusCode: error?.status,
      ErrorId: error?.data?.ErrorId,
      Message: error?.data?.Message,
    };
    return Promise.resolve({ status: 'error', error: errorModel });
  }
};
