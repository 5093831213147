import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants';

const heightHeader = 200;
const minHeight = heightHeader;
const containerStyleObj = {
  padding: 20,
  paddingBottom: 0,
};

export const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      fontFamily: 'Nunito Sans',
      background: '#FFFFFF',
      borderRadius: '4px',
      width: '100%',
      height: 'auto',
      minHeight: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.16), 0 3px 4px 0 rgba(0,0,0,0.16), 0 1px 5px 0 rgba(0,0,0,0.16)',
      padding: '0px 20px',
      paddingTop: 20,
    },
    details: {
      color: '#666666',
      fontSize: 16,
      height: 72,
    },
    header: {
      backgroundColor: COLORS.primary,
      color: '#ffffff',
      height: heightHeader,
      paddingTop: 10,
      ...containerStyleObj,
    },
    body: {
      paddingTop: 25,
      ...containerStyleObj,
      height: `calc(100vh - ${minHeight}px)`,
      overflowY: 'auto',
    },
  })
);
