import { DateTime } from 'luxon';
import React from 'react';
import {
  convertDateReadableFormat,
  getDiffBetweenDates,
} from '../../../../utilities/dates-functions';
import { FetchConfig } from '../../../../config/fetchConfig';
import useStyles from './UltimaCargaUsados.style';
import { useFetch } from '../../../../hooks/useFetch';

type Props = {
  isHeader?: boolean;
};

type LastChargeUsedOperations = {
  ChargeId: string;
  UploadAt: DateTime | string;
  FileName: string;
} & typeof defaultLast;

const defaultLast = {
  ChargeId: '',
  UploadAt: '',
  FileName: '',
};

const url = `/api/charge/last`;

const UltimaCargaUsados: React.FC<Props> = ({ isHeader = false }) => {
  const classes = useStyles({ isHeader });

  // const [loading, setLoading] = useState<boolean>(true);
  let lastUpload = '';
  let dateUpload = '';

  const handleDateChange = (date: string) => {
    if (date === '') {
      lastUpload = 'No existe carga inicial para operaciones usadas';
      dateUpload = '';
    } else {
      dateUpload = convertDateReadableFormat(date.replace('Z', ''), true);
      const between = getDiffBetweenDates(date);
      if (between !== '') {
        lastUpload = `Hace ${between}`;
      }
    }
  };

  const { status, data, error } = useFetch<LastChargeUsedOperations>(url, {
    withFatalError: false,
  } as FetchConfig);

  if (status === 'fetched' || status === 'error') {
    if (!data || error) {
      handleDateChange('');
    } else {
      handleDateChange(data.UploadAt);
    }
  }

  return (
    <div className={classes.root}>
      {status === 'fetched' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            {lastUpload}
          </div>
          <div>{dateUpload}</div>
          {/* <div className={classes.fileInfo}>
            <Icons name="document" size="26px" />
            archivo-carga.xls
          </div> */}
        </>
      )}
      {status === 'error' && (
        <>
          <div>
            <span className={classes.boldTitle}>Última Carga:</span>
            Ocurrió un error obteniendo la última carga.
          </div>
          {/* <div className={classes.fileInfo}>
            <Icons name="document" size="26px" />
            archivo-carga.xls
          </div> */}
        </>
      )}
    </div>
  );
};

export default UltimaCargaUsados;
