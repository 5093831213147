import React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './PendienteGiroUsadosPage.style';
import PendienteGiroUsados from '../../../../components/used/moneyTransferPending/PendienteGiroUsados';

const PendienteGiroUsadosPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickHistorial = () => {
    props.history.push('/usados/pendientes-giro/historial');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Pendientes de giro">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tabSelected}>
                PENDIENTES DE GIRO
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickHistorial}>
                HISTÓRICO DE GIROS
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>
      <PendienteGiroUsados />
    </MainContainer>
  );
};

export default PendienteGiroUsadosPage;
