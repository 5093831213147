import React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import MainContainer from '../../../../components/maincontainer';
import Title from '../../../../components/common/title';
import useStyles from './OtorgadasUsadosPage.style';
import OtorgadasUsados from '../../../../components/used/granted/OtorgadasUsados';

const OtorgadasUsadosPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const handleClickOnChangeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const valueClicked = e.currentTarget.textContent?.trim()?.toLowerCase() ?? '';
    if (valueClicked === 'otorgadas') props.history.push('/usados/otorgadas');
    else if (valueClicked === 'pendientes pago')
      props.history.push('/usados/otorgadas/pendientes-pago');
    else props.history.push('/usados/otorgadas/pendientes-confirmacion');
  };

  return (
    <MainContainer>
      <Grid container className={classes.header}>
        <Grid item sm={12}>
          <Title title="Otorgadas">
            <Grid container className={classes.tabNavigation}>
              <Grid item sm={4} className={classes.tabSelected}>
                OTORGADAS
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickOnChangeTab}>
                PENDIENTES PAGO
              </Grid>
              <Grid item sm={4} className={classes.tab} onClick={handleClickOnChangeTab}>
                PENDIENTES CONFIRMACIón
              </Grid>
            </Grid>
          </Title>
        </Grid>
      </Grid>

      <OtorgadasUsados />
    </MainContainer>
  );
};

export default OtorgadasUsadosPage;
