import apiConfig from '../config/api';
import {
  get,
  postDownloadFile,
  postFormData,
  getUrlFormat,
  getDownloadFile,
} from '../utilities/request';

import { IDownloadRequest, IDownloadRequestHistory } from '../interfaces';

export const processCreditNoteFile = async (params: FormData, options: RequestInit = {}) => {
  const url = `${apiConfig.apiUri}/api/chargeCreditNotes/upload`;
  return postFormData(url, options, params);
};

export const setPaymentRequestOperations = async (params: IDownloadRequest) => {
  const url = `${apiConfig.apiUri}/api/fileManager/operationMoneyOrderRequest/download`;
  return postDownloadFile(url, params);
};

export const setPaymentRequestOperationsHistory = async (params: IDownloadRequestHistory) => {
  const url = `${apiConfig.apiUri}/api/fileManager/operationMoneyOrderRequest/download`;
  return postDownloadFile(url, params, {}, true);
};

export const downloadChargeDetails = async (chargeId: string) => {
  const url = `${apiConfig.apiUri}/api/chargeOperations/detailsDownload`;
  return getDownloadFile(url, { chargeId: chargeId });
};

export const uploadSetTannerBilling = async (params: FormData, options: object = {}) => {
  const url = `${apiConfig.apiUri}/api/fileManager/setTannerBilling`;
  return postFormData(`${getUrlFormat(url, options)}`, {}, params);
};

export const getOperationTakenDealer = async () => {
  const url = `${apiConfig.apiUri}/api/fileManager/operationTakenDealer/download`;
  return getDownloadFile(url);
};

export const downloadEnthernetFile = async (billingRequestId: string) => {
  const url = `${apiConfig.apiUri}/api/fileManager/enthernet/download`;
  return get(url, { billingRequestId: billingRequestId });
};
