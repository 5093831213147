import React from 'react';
import { Button, Grid } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import useStyles from './OperacionesUsadasCursadas.style';
import { numberToMiles } from '../../../../utilities/string-functions';
import { COLORS } from '../../../../constants';

type FooterProps = {
  amount?: number;
  handleClickCreateRequest: () => Promise<void>;
  requesting: boolean;
};

const FooterOperacionesUsadasCursadas: React.FC<FooterProps> = ({
  amount = 0,
  handleClickCreateRequest,
  requesting,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.footer} justifyContent="center" alignItems="center">
      <Grid container direction="column" item xs={8} justifyContent="center" alignItems="center">
        <Grid style={{ fontSize: '18px', fontWeight: 'bold', color: COLORS.primary }}>
          Monto total a girar
        </Grid>
        <Grid style={{ fontSize: '24px', fontWeight: 'bold', color: COLORS.primary }}>
          $ {numberToMiles(amount)}
        </Grid>
      </Grid>
      <Grid container item xs={4} justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          disabled={amount === 0 || requesting}
          startIcon={<ArrowUpwardIcon />}
          onClick={handleClickCreateRequest}
          classes={{
            root: classes.btnSolicitarGiro,
            disabled: classes.disabled,
          }}
        >
          {requesting ? 'Solicitando giro...' : 'Solicitar giro'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterOperacionesUsadasCursadas;
