import apiConfig from '../config/api';
import { checkStatus, get, post, getFromUrl, putFromUrl } from '../utilities/request';
import { IFreeZoneMoneyOrderRequest, IMoneyOrderRequest } from '../interfaces';
import { BusinessTypeEnum } from '../enums';

const urlsByBusiness = {
  [BusinessTypeEnum.Nuevos as string]: apiConfig.apiUri,
  [BusinessTypeEnum.ZonaFranca as string]: apiConfig.freeZoneSettings.apiUri,
};

export const getCreditNote = async (totalSum: number, businessType: BusinessTypeEnum) => {
  const url = `${urlsByBusiness[businessType]}/api/creditNotes/toPay/${totalSum}`;
  return get(url);
};

export const getCreditNoteWithOutPay = async (businessType: BusinessTypeEnum) => {
  const url = `${urlsByBusiness[businessType]}/api/creditNotes/withoutPay`;
  return getFromUrl(url);
};

export const createMoneyOrderRequest = async (
  moneyOrderRequest: IFreeZoneMoneyOrderRequest | IMoneyOrderRequest,
  businessType: BusinessTypeEnum
) => {
  const url = `${urlsByBusiness[businessType]}/api/moneyOrderRequest`;
  return post(url, moneyOrderRequest);
};

export const getPendingRequests = async () => {
  const url = `${apiConfig.apiUri}/api/moneyOrderRequest`;
  return get(url, {});
};

export const putGrantTurnRequest = async (moneyOrderRequestId: string) => {
  const url = `${apiConfig.apiUri}/api/moneyOrderRequest/${moneyOrderRequestId}`;
  return putFromUrl(url);
};
