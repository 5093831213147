import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { FreeZoneLastGrantedOperationResponse } from '../../../../models/freeZone';
import { PaginationResponse } from '../../../../models/commons';
import { Loading } from '../../../common/loading';
import Error from '../../../common/Error';
import NotFoundBox from '../../../common/notFoundBox';
import useStyles from './HistoricMoneyTransferPendingFreeZone.style';
import ItemHistoricMoneyTransferPendingFreeZone from './ItemHistoricMoneyTransferPendingFreeZone';
import { BUSINESSTYPE } from '../../../../constants';
import TablePaginationActions from '../../../common/table/tablePaginationActions';

const HistoricMoneyTransferPendingFreeZone = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const url = `/api/taken/lastGrantedOperations?pageSize=${rowsPerPage}&pageNumber=${page + 1}`;

  const {
    status,
    data: paginationResponse,
    error,
  } = useFetch<PaginationResponse<FreeZoneLastGrantedOperationResponse>>(
    url,
    undefined,
    BUSINESSTYPE.FREEZONE
  );

  let moneyTransfers: FreeZoneLastGrantedOperationResponse[] = [];
  let totalItems = 0;
  if (status === 'fetched') {
    moneyTransfers = paginationResponse?.Result ?? moneyTransfers;
    totalItems = paginationResponse?.TotalItems ?? 0;
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return status === 'error' && error ? (
    <Error StatusCode={error.StatusCode} Message={error.Message} ErrorId={error.ErrorId} />
  ) : (
    <TableContainer className={classes.root}>
      {status === 'fetching' && <Loading />}
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableBody>
          {status === 'fetched' &&
            moneyTransfers?.map((transfer) => {
              return (
                <ItemHistoricMoneyTransferPendingFreeZone
                  key={transfer.GrantedDate}
                  transfer={transfer}
                />
              );
            })}
          {status === 'fetched' && moneyTransfers?.length === 0 && (
            <TableRow className={classes.rowNotFound}>
              <TableCell width="100%" style={{ borderBottom: 'none' }}>
                <NotFoundBox key={0} message="No existen solicitudes de giros anteriores." />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {status === 'fetched' && moneyTransfers?.length > 0 && (
              <TablePagination
                labelRowsPerPage="Filas por página:"
                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: totalItems }]}
                colSpan={3}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default HistoricMoneyTransferPendingFreeZone;
